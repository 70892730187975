import { Component, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { environment } from 'src/environments/environment';
import { Utility } from 'src/modules/utility';
import { ItemService } from 'src/modules/services/item.service';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import * as _ from 'lodash';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';

@Component({
  selector: 'opt-extra-allocation',
  templateUrl: './extra-allocation.component.html',
  styleUrls: ['./extra-allocation.component.scss']
})
export class ExtraAllocationComponent implements OnInit {
  @Output() onSubmit = new EventEmitter<any>(true);
  webAPiBaseUrl: string;
  apiUrl: string;
  facilitySummary: any;
  upsellSummary: any;
  priceConcessionId: any;
  selectedClass: any;
  bookingItemUpsells :any [];
  isAllSelected: boolean = false;
  constructor(public dialogRef: MatDialogRef<ExtraAllocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _itemService: ItemService, private snackBar: MatSnackBar) { 
    this.webAPiBaseUrl = environment.WebApiUrl;
    this.apiUrl = environment.ApiUrl;
    this.facilitySummary = data.fc;
    this.upsellSummary = data.upsell;
    this.priceConcessionId = data.priceConcessionId;
    let selectedClassDetails = environment.PackageClasses.find(a => a.Id != "0" && a.PriceMinConsessionId == data.priceConcessionId || a.PriceMaxConsessionId == data.priceConcessionId);
    if (selectedClassDetails != undefined) this.selectedClass = selectedClassDetails.Id; 
  }

  

  ngOnInit() {
    this.calculatePrice();
  }

  calculatePrice() {
    var thisPage = this;
    let priceConsession = { minConsessionId: this.priceConcessionId, maxConsessionId: 0 };

    if (environment.PriceDisplayOption == 3 && this.priceConcessionId == 0) {
      let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
      if (selectedPackageClass.PriceMinConsessionId != "" && selectedPackageClass.PriceMaxConsessionId != "") {
        priceConsession.minConsessionId = selectedPackageClass.PriceMinConsessionId;
        priceConsession.maxConsessionId = selectedPackageClass.PriceMaxConsessionId;
      }
    }

    this.bookingItemUpsells = [];
    this.facilitySummary.bookingItemSummaries.forEach((itemSummary, index) => {
      let item = {
        index: index,
        itemId: this.upsellSummary.id,
        minPriceConcessionId: priceConsession.minConsessionId,
        maxPriceConcessionIs: priceConsession.maxConsessionId,
        quantity: this.upsellSummary.qty,
        startTime: Utility.convertToISO(Utility.convertISOToDate(itemSummary.startTime)),
        endTime: Utility.convertToISO(Utility.convertISOToDate(itemSummary.endTime))
      };

      thisPage.bookingItemUpsells.push(item);

    });


    if (this.bookingItemUpsells.length > 0) {
      let itemPrice = this._itemService.GetItemPrices(thisPage.bookingItemUpsells);
      itemPrice.subscribe(result => {
        if (result != undefined) {
          result.forEach(caluatedItem => {
            this.bookingItemUpsells[caluatedItem.index].minPrice = new ItemPriceGroup;
            this.bookingItemUpsells[caluatedItem.index].maxPrice = new ItemPriceGroup;
            this.bookingItemUpsells[caluatedItem.index].minPrice.priceIncludingTax = caluatedItem.minimumPrice;
            this.bookingItemUpsells[caluatedItem.index].maxPrice.priceIncludingTax = caluatedItem.maximumPrice;
            this.bookingItemUpsells[caluatedItem.index].maxPrice.priceIncludingTax = caluatedItem.maximumPrice;
            this.bookingItemUpsells[caluatedItem.index].isSelected = false;
            if (thisPage.facilitySummary.bookingItemSummaries[caluatedItem.index].upsellSummaries && thisPage.facilitySummary.bookingItemSummaries[caluatedItem.index].upsellSummaries.length > 0)
            {
              let existingItem = thisPage.facilitySummary.bookingItemSummaries[caluatedItem.index].upsellSummaries.find(i => i.id == caluatedItem.itemId);
              if (existingItem) {
                this.bookingItemUpsells[caluatedItem.index].isSelected = true;
                this.bookingItemUpsells[caluatedItem.index].isReadOnly = true;
                this.bookingItemUpsells[caluatedItem.index].quantity = existingItem.qty;
                this.bookingItemUpsells[caluatedItem.index].totalAmount = existingItem.totalAmount;

              }
            }
          });
        }
      });
    }
  }

  isPriceRange(upsell) {
    return BookingAdaptor.isRange(upsell.minPrice, upsell.maxPrice);
  }

  selectAll(val) {
    this.bookingItemUpsells.forEach((itemSummary, index) => {
      if (!itemSummary.isReadOnly)
          itemSummary.isSelected = val.checked;
    });
  }

  selectChange(upsellSummary) {
    upsellSummary.isSelected = !upsellSummary.isSelected;
  }

  apply() {
    let seletedUpsells = [];
    this.bookingItemUpsells.forEach((upsellItemSummary, index) => {
      if ((!upsellItemSummary.isReadOnly) && upsellItemSummary.isSelected) {
        let seletedUpsell = _.clone(this.upsellSummary);
        seletedUpsell.minPrice = upsellItemSummary.minPrice;
        seletedUpsell.maxPrice = upsellItemSummary.maxPrice;
        seletedUpsell.qty = upsellItemSummary.quantity;
        seletedUpsell.startTime = upsellItemSummary.startTime;
        seletedUpsell.endTime = upsellItemSummary.endTime;
        seletedUpsell.index = upsellItemSummary.index;
        seletedUpsells.push(seletedUpsell);
      }
    });

    if (seletedUpsells.length > 0) {
      this.onSubmit.emit(seletedUpsells);
      this.dialogRef.close();
    } else {
      this.snackBar.openFromComponent(AlertMessageComponent, {
        data: ["Please select minimum one facility"],
        duration: 3000,
        verticalPosition: 'top'
      });
    }
  }
  errorHandler(event) {
    event.target.src = environment.ApiUrl + "dist/assets/images/no-image.png";
  }
}

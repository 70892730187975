import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { environment } from 'src/environments/environment';
import { Utility } from 'src/modules/utility';
import { ItemService } from 'src/modules/services/item.service';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import * as _ from 'lodash';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';

@Component({
  selector: 'opt-extra-deletion',
  templateUrl: './extra-deletion.component.html',
  styleUrls: ['./extra-deletion.component.scss']
})
export class ExtraDeletionComponent implements OnInit {

  @Output() onSubmit = new EventEmitter<any>(true);
  webAPiBaseUrl: string;
  apiUrl: string;
  facilitySummary: any;
  upsellSummary: any;
  bookingItemDeletedUpsells :any [];
  isAllSelected: number = 0;
  totalCount: number = 0;
  constructor(public dialogRef: MatDialogRef<ExtraDeletionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _itemService: ItemService, private snackBar: MatSnackBar)
  {
    let thisPage = this;
    this.webAPiBaseUrl = environment.WebApiUrl;
    this.apiUrl = environment.ApiUrl;
    this.facilitySummary = data.fc;
    this.upsellSummary = data.upsell;
    this.isAllSelected = 0;
    if (this.facilitySummary && this.facilitySummary.bookingItemSummaries && this.facilitySummary.bookingItemSummaries.length > 0) {
      this.facilitySummary.bookingItemSummaries.forEach(function (bookingItem) {
        if (bookingItem.upsellSummaries && bookingItem.upsellSummaries.length > 0) {
          let UpsellIndex = bookingItem.upsellSummaries.findIndex(u => u.id == data.upsell.id && u.bookingItemId == undefined);
          if (UpsellIndex >= 0) {
            thisPage.totalCount++;
          }
        }
      });
    }
  }

  ngOnInit() {
  }
  isPriceRange(upsell) {
    return BookingAdaptor.isRange(upsell.minPrice, upsell.maxPrice);
  }

  delete() {
    this.onSubmit.emit(this.isAllSelected==1);
    this.dialogRef.close();    
  }
  errorHandler(event) {
    event.target.src = environment.ApiUrl + "dist/assets/images/no-image.png";
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';

import { Observable } from "rxjs";
import { CurrencyFormat } from 'src/modules/currency-format';

//Store
import { Store, ICart } from '../../store/index';
import { SetContact, SignOut, ClearCart } from '../../store/index';
import { isCardExpired } from 'src/modules/store/cart-reducer';
import { Contact } from '../../models/client/contact';
import { RoutePath } from '../../booking-portal/route-path';
import { Router, ActivatedRoute } from '@angular/router';
import { FacilitySearchLoading } from 'src/modules/store/loading/actions'
import { Utility } from 'src/modules/utility';
import { environment } from 'src/environments/environment';

import { DeleteConfirmationComponent } from 'src/modules/shared/delete-confirmation/delete-confirmation.component';

@Component({
    selector: 'opt-sub-header',
    templateUrl: './sub-header.component.html'
})
export class SubHeaderComponent implements OnInit, OnDestroy {
    ApiUrl: string;
    RegularBookingPortalUrl: string;
    CasualBookingPortalUrl: string = environment.CasualBookingPortalUrl;
    cart$: Observable<ICart>;
    minAmount: number;
    maxAmount: number;
    imgUrl;
    customer_type: string;
    totalCount: number;
    cart_subscriber: any;
    packageClassId: number;
    showHomeIcon: boolean = true;
    isAllImmediateConfirmed: boolean;
    systemDefaultPriceConsessionId: number;
    isSystemAllowedImmediateConfirmation: boolean;
    isLoggedIn: boolean;
    customerDefaultPriceConsessionId: number;
    RegularVenueHire: number = 0;
    isDisplayCart: boolean = true;
    storeBookingIds: any[];
    constructor(private store: Store<any>, private router: Router, private currencyPipe: CurrencyFormat, private route: ActivatedRoute, private _snackBar: MatSnackBar, public dialog: MatDialog) {
        this.showHomeIcon = window.location.hash != "#/facility";
        this.systemDefaultPriceConsessionId = 0;
        this.customerDefaultPriceConsessionId = 0;
        this.isSystemAllowedImmediateConfirmation = false;
        this.cart$ = this.store.select('cart');//.subscribe((state => this.cart = state))
        this.ApiUrl = environment.ApiUrl;
        if (window["ShowSessionOut"] == "1")
        {
            window["ShowSessionOut"] = "";
           // this._snackBar.open("Session Timeout", "", {
            //    duration: 2000,
            //});
        }

    }
    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }
    ngOnInit() {

        this.RegularBookingPortalUrl = environment.RegularBookingPortalUrl;
        this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
        this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;

      this.cart_subscriber = this.cart$.subscribe(state => {
            if ((environment.BookingExpiryInMinutes && environment.BookingExpiryInMinutes != 0) && state.LastUpdatedTime != null && isCardExpired(state.LastUpdatedTime)) {
              this.store.dispatch(new ClearCart());
            }
            this.populateCartDetails(state);
            //let _bookingType = state.contact.bookingTypeList.filter(x => x.id == "1");
            //if (_bookingType.length > 0) {
            //    this.RegularBookingPortalUrl = environment.RegularBookingPortalUrl;
            //} else {
            //    this.RegularBookingPortalUrl = environment.CasualBookingPortalUrl;
            //}
            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
            this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
            this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? state.contact.client.priceConcessionId : 0;
            this.totalCount = 0;
            let thisPage = this;

            this.imgUrl = state.contact.profilePicture;
            if (this.imgUrl == undefined || state.contact.profilePicture == '')
                this.imgUrl = 'dist/assets/images/user-icon.png'
            //debugger;
            let minAmount = 0;
            let maxAmount = 0;
            this.packageClassId = (state.packageFilter != null && state.packageFilter != undefined) ? state.packageFilter.PackageClass :0 ;
            if (state.addedFacilites != undefined && state.addedFacilites.length > 0) {
                state.addedFacilites.forEach(function (fc) {

                    minAmount += (fc.item.calculatedMinPrice && fc.item.calculatedMinPrice.priceIncludingTax) ? fc.item.calculatedMinPrice!.priceIncludingTax :
                        ((fc.item.calculatedMaxPrice && fc.item.calculatedMaxPrice.priceIncludingTax) ? fc.item.calculatedMaxPrice!.priceIncludingTax : 0);
                    maxAmount += (fc.item.calculatedMaxPrice && fc.item.calculatedMaxPrice.priceIncludingTax) ? fc.item.calculatedMaxPrice!.priceIncludingTax :
                        (fc.item.calculatedMinPrice && fc.item.calculatedMinPrice.priceIncludingTax) ? fc.item.calculatedMinPrice!.priceIncludingTax : 0;

                    if (fc.upsellSummaries && fc.upsellSummaries.length > 0) {
                        fc.upsellSummaries.forEach(function (fcUpsell) {
                            minAmount += (fcUpsell.minPrice && fcUpsell.minPrice.priceIncludingTax) ? fcUpsell.minPrice!.priceIncludingTax :
                                         ((fcUpsell.maxPrice && fcUpsell.maxPrice.priceIncludingTax) ? fcUpsell.maxPrice!.priceIncludingTax : 0);
                            maxAmount += (fcUpsell.maxPrice && fcUpsell.maxPrice.priceIncludingTax) ? fcUpsell.maxPrice!.priceIncludingTax :
                                         (fcUpsell.minPrice && fcUpsell.minPrice.priceIncludingTax) ? fcUpsell.minPrice!.priceIncludingTax : 0;
                            thisPage.totalCount++;
                        })
                    }
                    thisPage.totalCount++;
                })
            }

            this.minAmount = parseFloat(this.currencyPipe.transform(minAmount, true));
            this.maxAmount = parseFloat(this.currencyPipe.transform(maxAmount, true));

        });
    }

    populateCartDetails(state) {
        if (state.contact) {

            /* RB linked to be disabled when:
            there are no booking types available
            there are only casual or waste booking types are avaialable
            */

            let _bookingTypeList = state.contact.bookingTypeList ? state.contact.bookingTypeList : (state.contact.client?state.contact.client.bookingTypes:undefined);

            if (_bookingTypeList && _bookingTypeList.length > 0) {

                let _notCasualorWaste = _bookingTypeList.find(e => Number(e.id) != 1 && Number(e.id) != 5);
                this.RegularVenueHire = (_notCasualorWaste == null) ? 0 : 1;
            }

            if (environment.PortalType != "" && state.addedFacilites) {
                this.isDisplayCart = ((environment.PortalType == "2" || environment.PortalType == "3") && state.addedFacilites.length == 0) ? false : true;
            }
        }

    }


    myBooking() {
        var ul = environment.ApiUrl + '#/bookings';
        window.open(ul, "_self");
    }

    myprofile() {

        this.router.navigate(['/my-profile']);

    }


    cartClick() {

        this.router.navigate(['/booking-cart']);

    }
    signOut() {
        this.RegularVenueHire = 0;
    if (environment.PortalType == "3") {
      var ul = environment.ApiUrl + '#/client/login?regularBooking=1';
      window.open(ul, "_self");
    } else {
      window.location.href = this.CasualBookingPortalUrl + '#/facility';
    }

        this.store.dispatch(new SignOut());
    }
    testClick() {
        var cn = new Contact();
        cn.firstName = "AAAAA";
        this.store.dispatch(new SetContact(cn));
        this.router.navigate(['/facility-detail']);

    }
    changePassword() {
        this.router.navigate(['/change-password']);
    }
    venueSearch() {
        if (this.totalCount > 0) {
            let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
                data: {
                    message: 'Starting a New Casual Hire will clear all the items in the Cart. Please confirm to proceed',
                    yesText: 'Confirm',
                    noText: 'Cancel'
                },
                panelClass: ['custom-dialog-container', 'w30modal'],
                height: 'auto',
                width: '30%'
            });

            const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
                if (data == true) {
                    this.store.dispatch(new ClearCart());
                    window.location.href = this.CasualBookingPortalUrl + '#/facility';
                }
            });
        } else {
            window.location.href = this.CasualBookingPortalUrl + '#/facility';
        }
    }

    linkClick(event) {
      //  this.store.dispatch(new FacilitySearchLoading());
        Utility.urlHistorty.push(this.router.url);
    }

    NewBooking() {

        var url = environment.RegularBookingPortalUrl;
        if (url.endsWith('/')) {
            url = url.slice(0, url.length - 1)
        }
        url = (url + '#/home?isNewBooking=1');
        window.open(url, "_self")
    }

    RedirectToPortal() {

        if (environment.PortalType == "3") {
            var url = environment.RegularBookingPortalUrl;
            window.open(url, "_self")
        }
        else {
            var url = environment.CasualBookingPortalUrl;
            window.open(url, "_self")
        }

    }

}

﻿import { BaseResource } from '../base-resource';

export class Timeslot extends BaseResource {
    preliminary: boolean;
    multidayId: number;
    capacity: number;
    allocatedQuantity: number;
    startTime: Date;
    endTime: Date;
    reserved: false;
    blockedReason: string;
    asset: BaseResource;
    eventConfiguration: BaseResource;
    privateEventInstance: BaseResource;
    owner: Timeslot;
  
}

export class CalenderTimeslot {
    constructor() {
        this.isUnAvailableDueToOperationalHours = false;
        this.isBumpInBumpOut=false;
        this.isSelectedTimeSlot = false;       
    }

    start: number;
    end: number;
    startTime: Date;
    endTime: Date;

    availableStartTime: Date;
    availableEndTime: Date;
    
    isUnAvailableDueToOperationalHours: boolean;
    isBumpInBumpOut: boolean;
    isSelectedTimeSlot: boolean;
}
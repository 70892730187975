import { Component, OnInit, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';

import { Question } from 'src/modules/models/item/question';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { Store, ICart, UpdateFacility, UpdateBookingPackage } from 'src/modules/store/index';
import { Observable } from "rxjs";
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
@Component({
    selector: 'opt-questionnaire',
    templateUrl: './questionnaire.component.html' 
})

export class QuestionnaireComponent implements OnInit, OnDestroy {
    cart$: Observable<ICart>;
    cart_subscriber: any;
    isFormValid: boolean = true;
    item: any;
    facilitySummary: FacilitySummary;
    package: BespokePackage;
    isFullBookingView: boolean = false;
    booking: any;
    answeredCurrentCount: number = 0;
    isReadOnly: boolean;
    isUpsellHasQuestion: boolean = false;
    upsellTotalQuestion: number = 0;
    @Output() onSubmit = new EventEmitter<any>(true);
    isAnswerChanged: boolean = false;
    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private store: Store<any>, public snackBar: MatSnackBar) {
        this.facilitySummary = data.fc;
        this.cart$ = this.store.select('cart');
        this.item = data.item;
        if (data.packageFilter && data.packageFilter.PackageDetail) {
            this.package = data.packageFilter.PackageDetail;
        }
        else if (data.isFullBookingView == true && data.booking) {
            this.isFullBookingView = data.isFullBookingView;
            this.booking = data.booking;
        }
        this.isReadOnly = (data.isReadOnly == true);

        if (this.item != undefined) {
            this.item.question = (data.item) ? data.item.question as Question[] : [];
            this.item.upsellSummaries = (this.facilitySummary) ? this.facilitySummary.upsellSummaries : data.item.upsellSummaries;
            if (this.item.upsellSummaries)
                this.item.upsellSummaries.forEach((upslSum) => {
                    if (upslSum.question) {
                        this.isUpsellHasQuestion = true;
                        this.upsellTotalQuestion += upslSum.question.length;
                    }
                });
        }
        this.countAnswer();
    }

    ngOnInit() {
        if (this.item) {
            for (let qus of this.item.question) {
                switch (qus.type) {
                    case "textQuestion":
                        qus.answerTxtBox = true;
                        break;
                    case "booleanQuestion":
                        qus.answerBool = true;
                        break;
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }
    updateQuestion(ques) {
        this.isAnswerChanged = true;
        this.countAnswer();
    }
    countAnswer() {
        var count = 0;
        this.isFormValid = true;
        if (!this.isFullBookingView) {
        if (this.item) {
            if (!this.data.isUpsellItem) {
                for (var q of this.item.question) {
                    if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion")
                        && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != "") && q.mandatory !== undefined) {
                        count++;
                    } else if (q.type == "multipleChoiceQuestion") {
                      var answeredCount = 0;
                        if (q.bookingQuestionChoices && q.isBookingQuestion) {
                            q.bookingQuestionChoices.forEach((answ, indx) => {
                          if (q.quantityRequired) {
                            if (answ.Quantity)
                              answeredCount++;
                          } else if (answ.isSelectedAnswer)
                            answeredCount++;
                        });
                        if (answeredCount > 0)
                          count++;
                      } else if (q.answerChoices && !q.isBookingQuestion) {
                        q.answerChoices.forEach((answ, indx) => {
                          if (q.quantityRequired) {
                            if (answ.Quantity)
                              answeredCount++;
                          } else if (answ.isSelectedAnswer)
                            answeredCount++;
                        });
                        if (answeredCount > 0)
                          count++;
                      }
                    }
                    else if (q.mandatory) {
                        this.isFormValid = false;
                    }
                }
            }

            if (this.item.upsellSummaries) {
                this.item.upsellSummaries.forEach((upsl, i) => {
                    if (upsl.question) {
                        for (var q of this.item.upsellSummaries[i].question) {
                            if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion")
                                && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != "")
                                && q.mandatory !== undefined) {
                                count++;
                            } else if (q.type == "multipleChoiceQuestion") {
                              var answeredCount = 0;
                                if (q.bookingQuestionChoices && q.isBookingQuestion) {
                                    q.bookingQuestionChoices.forEach((answ, indx) => {
                                  if (q.quantityRequired) {
                                    if (answ.Quantity)
                                      answeredCount++;
                                  } else if (answ.isSelectedAnswer)
                                    answeredCount++;
                                });
                                if (answeredCount > 0)
                                  count++;
                              } else if (q.answerChoices && !q.isBookingQuestion) {
                                q.answerChoices.forEach((answ, indx) => {
                                  if (q.quantityRequired) {
                                    if (answ.Quantity)
                                      answeredCount++;
                                  } else if (answ.isSelectedAnswer)
                                    answeredCount++;
                                });
                                if (answeredCount > 0)
                                  count++;
                              }
                            }
                            else if (q.mandatory) {
                                this.isFormValid = false;
                            }
                        }
                    }
                });
            }
            this.item.totalAnsweredQuestions = count;
        }
            let totalPackageAnsweredQuestions = 0;
            if (this.package) {
                for (var question of this.package.question) {
                    var isQuestionAnswerd = this.isAnswerd(question);
                    if (isQuestionAnswerd) {
                        totalPackageAnsweredQuestions++;
                    }
                }
                this.package.totalAnsweredQuestions = totalPackageAnsweredQuestions;
            }
            this.answeredCurrentCount = count + totalPackageAnsweredQuestions;
        }

        else if (this.isFullBookingView) {
            if (this.booking) {
                if (this.booking.packageQuestion && this.booking.packageQuestion.length > 0) {
                    this.booking.totalAnsweredPackageQuestions = 0;
                    for (var pQuestion of this.booking.packageQuestion) {
                        var isQuestionAnswerd = this.isAnswerd(pQuestion);
                        if (isQuestionAnswerd) {
                            this.booking.totalAnsweredPackageQuestions++;
                            //count++;
                        }
                    }
                }
                if (this.booking.facilitySummaries && this.booking.facilitySummaries.length > 0) {
                    for (var facilitySummary of this.booking.facilitySummaries) {
                        facilitySummary.totalQuestions = 0;
                        facilitySummary.totalAnswerdQuestions = 0;
                        if (facilitySummary.bookingItemSummaries && facilitySummary.bookingItemSummaries.length > 0) {
                            for (var bookingitemSummary of facilitySummary.bookingItemSummaries) {
                                if (bookingitemSummary.question && bookingitemSummary.question.length > 0) {
                                    // item question
                                    for (var itemQuestion of bookingitemSummary.question) {

                                        var isQuestionAnswerd = this.isAnswerd(itemQuestion);
                                        if (isQuestionAnswerd) {
                                            facilitySummary.totalAnswerdQuestions++;
                                            //count++;
                                        }
                                        facilitySummary.totalQuestions++;
                                    }

                                    // upsell item question
                                    if (bookingitemSummary.upsellSummaries && bookingitemSummary.upsellSummaries.length > 0)
                                        for (var upsellSummary of bookingitemSummary.upsellSummaries) {
                                            if (upsellSummary.question && upsellSummary.question.length > 0) {
                                                for (var upsellQuestion of upsellSummary.question) {
                                                    bookingitemSummary.isUpsellHasQuestion = true;
                                                    var isQuestionAnswerd = this.isAnswerd(upsellQuestion);
                                                    if (isQuestionAnswerd) {
                                                        facilitySummary.totalAnswerdQuestions++;
                                                        //count++;
                                                    }
                                                    facilitySummary.totalQuestions++;
                                                }
                                            }
                                        }
                                }
                            }
                        }

                    }
                }

            }
        }
    }

    isAnswerd(q) {
        var isQuestionAnswerd = false;
        if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion")
            && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != "") && q.mandatory !== undefined) {
            isQuestionAnswerd = true;
        } else if (q.type == "multipleChoiceQuestion") {
          var answeredCount = 0;
          if (q.bookingQuestionChoices && q.isBookingQuestion) {
            q.bookingQuestionChoices.forEach((answ, indx) => {
              if (q.quantityRequired) {
                if (answ.Quantity)
                  answeredCount++;
              } else if (answ.isSelectedAnswer)
                answeredCount++;
            });
            if (answeredCount > 0)
              isQuestionAnswerd = true;
          } else if (q.answerChoices && !q.isBookingQuestion) {
            q.answerChoices.forEach((answ, indx) => {
              if (q.quantityRequired) {
                if (answ.Quantity)
                  answeredCount++;
              } else if (answ.isSelectedAnswer)
                answeredCount++;
            });
            if (answeredCount > 0)
              isQuestionAnswerd = true;
          }
        }
        else if (q.mandatory) {
            this.isFormValid = false;
        }
        return isQuestionAnswerd;
    }

    submitBookingData() {
        console.log("submitBookingData", this.booking);
        // return;
        if (this.isFullBookingView) {
            this.onSubmit.emit({ booking: this.booking, isAnswerChanged: this.isAnswerChanged });
            this.dialog.closeAll();
        }
    }
    submitData(data) {
        this.countAnswer();
        if (this.isFormValid) {
            if (!this.isFullBookingView && this.data.isEditMode == true) {
                this.onSubmit.emit(this.item);
                this.dialog.closeAll();
                return;
            }
            if (this.package) {
                this.data.packageFilter.PackageDetail = this.package;
                this.store.dispatch(new UpdateBookingPackage(this.data.packageFilter));
                this.dialog.closeAll();
                return;
            }  
            if (this.data.isUpsellItem) {
                let item = this.facilitySummary.upsellSummaries.find(us => us.id == this.item.id);
                if (item != undefined) {
                    item.question = this.item.question;
                }
            } else {
                this.facilitySummary.item.question = this.item.question;
            }
            this.store.dispatch(new UpdateFacility(this.facilitySummary));
            this.dialog.closeAll();
        } else {
            this.openSnackBarError(['Please fill all mandatory fields.']);
        }
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top'
        });
    }
}

﻿export class FacilityImages {
    public small: string[];
    public medium: string[];
    public large: string[];

    constructor() {
        this.small = [];
        this.medium = [];
        this.large = [];
    }
}
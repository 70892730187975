import { environment } from 'src/environments/environment'
import { SiteLayoutModule as MCCLayoutModule } from '../layout/site-layout/site-layout.module';
import { SiteLayoutComponent as MCCLayoutComponent } from '../layout/site-layout/site-layout.component';
import { SiteHeaderComponent as MCCSiteHeaderComponent } from 'src/modules/layout/site-layout/site-header/site-header.component';
import { SiteFooterComponent as MCCSiteFooterComponent } from 'src/modules/layout/site-layout/site-footer/site-footer.component';

import { SiteLayoutModule as NBCLayoutModule } from '../layout/site-layout-nbc/site-layout.module';
import { SiteLayoutComponent as NBCLayoutComponent } from '../layout/site-layout-nbc/site-layout.component';
import { SiteHeaderComponent as NBCSiteHeaderComponent } from 'src/modules/layout/site-layout-nbc/site-header/site-header.component';
import { SiteFooterComponent as NBCSiteFooterComponent } from 'src/modules/layout/site-layout-nbc/site-footer/site-footer.component';
//
import { SiteLayoutModule as TOCLayoutModule } from '../layout/site-layout-toc/site-layout.module';
import { SiteLayoutComponent as TOCLayoutComponent } from '../layout/site-layout-toc/site-layout.component';
import { SiteHeaderComponent as TOCSiteHeaderComponent } from 'src/modules/layout/site-layout-toc/site-header/site-header.component';
import { SiteFooterComponent as TOCSiteFooterComponent } from 'src/modules/layout/site-layout-toc/site-footer/site-footer.component';

//COG
import { SiteLayoutModule as COGLayoutModule } from '../layout/site-layout-cog/site-layout.module';
import { SiteLayoutComponent as COGLayoutComponent } from '../layout/site-layout-cog/site-layout.component';
import { SiteHeaderComponent as COGSiteHeaderComponent } from 'src/modules/layout/site-layout-cog/site-header/site-header.component';
import { SiteFooterComponent as COGSiteFooterComponent } from 'src/modules/layout/site-layout-cog/site-footer/site-footer.component';

//SCC
import { SiteLayoutModule as SCCLayoutModule } from '../layout/site-layout-scc/site-layout.module';
import { SiteLayoutComponent as SCCLayoutComponent } from '../layout/site-layout-scc/site-layout.component';
import { SiteHeaderComponent as SCCSiteHeaderComponent } from 'src/modules/layout/site-layout-scc/site-header/site-header.component';
import { SiteFooterComponent as SCCSiteFooterComponent } from 'src/modules/layout/site-layout-scc/site-footer/site-footer.component';

//COC
import { SiteLayoutModule as COCLayoutModule } from '../layout/site-layout-coc/site-layout.module';
import { SiteLayoutComponent as COCLayoutComponent } from '../layout/site-layout-coc/site-layout.component';
import { SiteHeaderComponent as COCSiteHeaderComponent } from 'src/modules/layout/site-layout-coc/site-header/site-header.component';
import { SiteFooterComponent as COCSiteFooterComponent } from 'src/modules/layout/site-layout-coc/site-footer/site-footer.component';

//COSL
import { SiteLayoutModule as COSLLayoutModule } from '../layout/site-layout-cosl/site-layout.module';
import { SiteLayoutComponent as COSLLayoutComponent } from '../layout/site-layout-cosl/site-layout.component';
import { SiteHeaderComponent as COSLSiteHeaderComponent } from 'src/modules/layout/site-layout-cosl/site-header/site-header.component';
import { SiteFooterComponent as COSLSiteFooterComponent } from 'src/modules/layout/site-layout-cosl/site-footer/site-footer.component';

//LCC
import { SiteLayoutModule as LCCLayoutModule } from '../layout/site-layout-lcc/site-layout.module';
import { SiteLayoutComponent as LCCLayoutComponent } from '../layout/site-layout-lcc/site-layout.component';
import { SiteHeaderComponent as LCCSiteHeaderComponent } from 'src/modules/layout/site-layout-lcc/site-header/site-header.component';
import { SiteFooterComponent as LCCSiteFooterComponent } from 'src/modules/layout/site-layout-lcc/site-footer/site-footer.component';
//IWC
import { SiteLayoutModule as IWCLayoutModule } from '../layout/site-layout-iwc/site-layout.module';
import { SiteLayoutComponent as IWCLayoutComponent } from '../layout/site-layout-iwc/site-layout.component';
import { SiteHeaderComponent as IWCSiteHeaderComponent } from 'src/modules/layout/site-layout-iwc/site-header/site-header.component';
import { SiteFooterComponent as IWCSiteFooterComponent } from 'src/modules/layout/site-layout-iwc/site-footer/site-footer.component';

//RB
import { SiteLayoutModule as RBLayoutModule } from '../layout/site-layout-rb/site-layout.module';
import { SiteLayoutComponent as RBLayoutComponent } from '../layout/site-layout-rb/site-layout.component';
import { SiteHeaderComponent as RBSiteHeaderComponent } from 'src/modules/layout/site-layout-rb/site-header/site-header.component';
import { SiteFooterComponent as RBSiteFooterComponent } from 'src/modules/layout/site-layout-rb/site-footer/site-footer.component';

//COCB
import { SiteLayoutModule as COCBLayoutModule } from '../layout/site-layout-cocb/site-layout.module';
import { SiteLayoutComponent as COCBLayoutComponent } from '../layout/site-layout-cocb/site-layout.component';
import { SiteHeaderComponent as COCBSiteHeaderComponent } from 'src/modules/layout/site-layout-cocb/site-header/site-header.component';
import { SiteFooterComponent as COCBSiteFooterComponent } from 'src/modules/layout/site-layout-cocb/site-footer/site-footer.component';

//GMCC
import { SiteLayoutModule as GMCCLayoutModule } from '../layout/site-layout-gmcc/site-layout.module';
import { SiteLayoutComponent as GMCCLayoutComponent } from '../layout/site-layout-gmcc/site-layout.component';
import { SiteHeaderComponent as GMCCSiteHeaderComponent } from 'src/modules/layout/site-layout-gmcc/site-header/site-header.component';
import { SiteFooterComponent as GMCCSiteFooterComponent } from 'src/modules/layout/site-layout-gmcc/site-footer/site-footer.component';

//MRCC
import { SiteLayoutModule as MRCCLayoutModule } from '../layout/site-layout-mrcc/site-layout.module';
import { SiteLayoutComponent as MRCCLayoutComponent } from '../layout/site-layout-mrcc/site-layout.component';
import { SiteHeaderComponent as MRCCSiteHeaderComponent } from 'src/modules/layout/site-layout-mrcc/site-header/site-header.component';
import { SiteFooterComponent as MRCCSiteFooterComponent } from 'src/modules/layout/site-layout-mrcc/site-footer/site-footer.component';

//COPP
import { SiteLayoutModule as COPPLayoutModule } from '../layout/site-layout-copp/site-layout.module';
import { SiteLayoutComponent as COPPLayoutComponent } from '../layout/site-layout-copp/site-layout.component';
import { SiteHeaderComponent as COPPSiteHeaderComponent } from 'src/modules/layout/site-layout-copp/site-header/site-header.component';
import { SiteFooterComponent as COPPSiteFooterComponent } from 'src/modules/layout/site-layout-copp/site-footer/site-footer.component';

//YPRL
import { SiteLayoutModule as YPRLLayoutModule } from '../layout/site-layout-yprl/site-layout.module';
import { SiteLayoutComponent as YPRLLayoutComponent } from '../layout/site-layout-yprl/site-layout.component';
import { SiteHeaderComponent as YPRLSiteHeaderComponent } from 'src/modules/layout/site-layout-yprl/site-header/site-header.component';
import { SiteFooterComponent as YPRLSiteFooterComponent } from 'src/modules/layout/site-layout-yprl/site-footer/site-footer.component';

export class ThemeUtility {
    static theme() {
        var th = (environment.Theme == undefined || environment.Theme == "") ? window["Theme"] : environment.Theme;
        return th == undefined ? "" : th;
    }

    static getThemeComponent(): any {
        switch (ThemeUtility.theme().toLowerCase()) {
            case "nbc":
                return NBCLayoutComponent
            case "toc":
                return TOCLayoutComponent
            case "cog":
                return COGLayoutComponent
            case "mcc":
                return MCCLayoutComponent
            case "scc":
                return SCCLayoutComponent
            case "coc":
                return COCLayoutComponent
            case "cosl":
                return COSLLayoutComponent
            case "lcc":
                return LCCLayoutComponent
            case "rb":
                return RBLayoutComponent
            case "iwc":
                return IWCLayoutComponent
            case "cocb":
                return COCBLayoutComponent
            case "gmcc":
                return GMCCLayoutComponent
            case "mrcc":
                return MRCCLayoutComponent
            case "copp":
                return COPPLayoutComponent
            case "yprl":
                return YPRLLayoutComponent
            default:
                return MCCLayoutComponent
        }
    }

    static getHeaderComponent(): any {
        switch (ThemeUtility.theme().toLowerCase()) {
            case "nbc":
                return NBCSiteHeaderComponent
            case "toc":
                return TOCSiteHeaderComponent
            case "cog":
                return COGSiteHeaderComponent
            case "mcc":
                return MCCSiteHeaderComponent
            case "scc":
                return SCCSiteHeaderComponent
            case "coc":
                return COCSiteHeaderComponent
            case "cosl":
                return COSLSiteHeaderComponent
            case "lcc":
                return LCCSiteHeaderComponent
            case "rb":
                return RBSiteHeaderComponent
            case "iwc":
                return IWCSiteHeaderComponent
            case "cocb":
                return COCBSiteHeaderComponent
            case "gmcc":
                return GMCCSiteHeaderComponent
            case "mrcc":
                return MRCCSiteHeaderComponent
            case "copp":
                return COPPSiteHeaderComponent
            case "yprl":
                return YPRLSiteHeaderComponent
            default:
                return MCCSiteHeaderComponent
        }
    }

    static getFooterComponent(): any {
        switch (ThemeUtility.theme().toLowerCase()) {
            case "nbc":
                return NBCSiteFooterComponent
            case "toc":
                return TOCSiteFooterComponent
            case "cog":
                return COGSiteFooterComponent
            case "mcc":
                return MCCSiteFooterComponent
            case "scc":
                return SCCSiteFooterComponent
            case "coc":
                return COCSiteFooterComponent
            case "cosl":
                return COSLSiteFooterComponent
            case "lcc":
                return LCCSiteFooterComponent
            case "rb":
                return RBSiteFooterComponent
            case "iwc":
                return IWCSiteFooterComponent
            case "cocb":
                return COCBSiteFooterComponent
            case "gmcc":
                return GMCCSiteFooterComponent
            case "mrcc":
                return MRCCSiteFooterComponent
            case "copp":
                return COPPSiteFooterComponent
            case "yprl":
                return YPRLSiteFooterComponent
            default:
                return MCCSiteFooterComponent
        }
    }

    static getThemeModule(): any {
        switch (ThemeUtility.theme().toLowerCase()) {
            case "nbc":
                return NBCLayoutModule
            case "toc":
                return TOCLayoutModule
            case "cog":
                return COGLayoutModule
            case "mcc":
                return MCCLayoutModule
            case "scc":
                return SCCLayoutModule
            case "coc":
                return COCLayoutModule
            case "cosl":
                return COSLLayoutModule
            case "lcc":
                return LCCLayoutModule
            case "rb":
                return RBLayoutModule
            case "iwc":
                return IWCLayoutModule
            case "cocb":
                return COCBLayoutModule
            case "gmcc":
                return GMCCLayoutModule
            case "mrcc":
                return MRCCLayoutModule
            case "copp":
                return COPPLayoutModule
            case "yprl":
                return YPRLLayoutModule
            default:
                return MCCLayoutModule
        }
    }
}

﻿import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'select-configuration',
    templateUrl: './select-configuration.component.html'
})

export class SelectConfigurationComponent implements OnInit {

    

    //passing data to configuration-option-768
    @Input() displayMode: number;
    @Input() configurations: any[];
    @Output() messageEvent = new EventEmitter<string>();
    @Input() selectedConfiguration: any;

    //edit model ekata danna me tika

    reciveMessage: any;
    modeMessage = 1;
    public dropDownMessage: any[];
    constructor(public dialog: MatDialog) { }

    ngOnInit() {
      
     
    }

    changeConfiguration(event) {
        const changeConfigurationId = event.value;
        this.messageEvent.emit(changeConfigurationId);
    }

}

﻿import { Question } from 'src/modules/models/item/question';

export class QuestionSummary {
    id: string;
    startTime: string;
    endTime: string;
    itemId: string;
    bookingItemId: string;
    isUpsellItem: boolean;
    questions: Question[];
    totelAnsweredQuestions: number;
    packageId: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';
import { BaseService } from '../services/base/base.service';

import { environment } from '../../environments/environment'

//Models
import { Login } from '../models/login/login';
import { Contact } from '../models/client/contact';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { ClientRegistration } from 'src/modules/models/settings/casual-portal/client-registration-config';

import { QASAddress } from '../models/client/qas-address';
import { Addresses, AddressDetail } from '../models/client/Address';
import { ReferenceData } from '../models/reference-data';
import { Client } from '../models/client/Client';
import { CustomerAdditionalDetail } from 'src/modules/models/client/customer-additional-detail';
import { IndividualClient } from '../models/client/Individual-client';;
import { CorporateClient } from '../models/client/corporate-client';;
import { ChangePassword } from 'src/modules/models/client/change-password';
import { CommunicationType } from '../models/client/communication-type';
import { ClientEmailModel } from 'src/modules/models/client/client-email-model';


@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private _baseService: BaseService,

    ) {

    }


    /****| GET |****/

    getClientTiltles(): any {
        return this._baseService.WebAPIGet("api/V4.1/customers/titles");
    }

    CommunicationTypes(): Observable<CommunicationType[]> {
        return this._baseService.WebAPIGet("api/V4.1/customers/communication-types?fields=Name,Format").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);

                var lst: CommunicationType[] = [];
                json.forEach((c, i) => {
                    lst.push((c as CommunicationType));
                });
                return lst;
            }));
    }

    getCommunicationTypes(): any {
        return this._baseService.WebAPIGet("api/V4.1/customers/communication-types?fields=Name,Format");
    }

    getClientRegistrationConfig(): Observable<AppSetting> {
        return this._baseService.InternalWebAPIGET("api/system/app-settings");
    }

    SendCustomerRegisterConfirmation(email: string, customerId: string): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/Customer/SendCustomerRegisterConfirmation?email=" + email + "&contactid=" + customerId).pipe(
            map((response) => {
                return response;
            })
        );
    }

    AdditionalDetail(customerAdditionalDetail: CustomerAdditionalDetail): Observable<any> {
        return this._baseService.InternalWebAPIPOST("api/Customer/additional-detail", customerAdditionalDetail).pipe(
            map((response) => {
                return response;
            })
        );
    }
    GetindividualClient(ClientID: string) {
        return this._baseService.WebAPIGet("api/V4.1/customers/contacts/" + ClientID + "?include=client,mailingAddress,communicationMethods,invoiceAddress").pipe(map(response => {
            var json = (new Deserializer().deserialize(response));
            console.log("GET --> Customer Details.....", json);
            return json;
        }));


    }

    GetCoporateClient(ClientID: string) {
        return this._baseService.WebAPIGet("api/V4.1/customers/contacts/" + ClientID + "?include=client,mailingAddress,communicationMethods,invoiceAddress,ClientCommunicationMethods").pipe(map(response => {
            var json = (new Deserializer().deserialize(response));
            console.log("GetCoporateClient() --> Starting.....");
            console.log(json);

            console.log("GetCoporateClient() --> Ended.....");
            return json;
        }));


    }

    SelectContact(contactId: string): Observable<Contact> {
        return this._baseService.WebAPIGet("api/V4.1/customers/contacts/" + contactId + "?include=client,mailingAddress,communicationMethods,invoiceAddress,ClientCommunicationMethods").pipe(map(response => {
            var json = (new Deserializer().deserialize(response));

            var cnt = new Contact();
            cnt.firstName = json.firstName;
            cnt.lastName = json.lastName;
            cnt.userName = json.userName;
            cnt.id = json.id;
            cnt.type = json.type;
            cnt.ref = json.ref;
            cnt.title = json.title;
            cnt.titleId = json.titleId;
            cnt.communicationMethods = json.communicationMethods;
            cnt.invoiceAddress = json.invoiceAddress;
            cnt.mailingAddress = json.mailingAddress;
            cnt.deliveryAddress = json.deliveryAddress;
            cnt.active = json.active;
            cnt.defaultVenue = json.defaultVenue;
            cnt.accountManager = json.accountManager;
            cnt.isPrimaryContact = json.isPrimaryContact;
            if (json.communicationMethods != undefined && json.communicationMethods.length > 0) {
                json.communicationMethods.forEach(function (communicationMethod) {
                    if (communicationMethod.communicationType.toLowerCase().indexOf("email") > -1) {
                        cnt.invoiceEmailAddress = communicationMethod.value;
                    }
                });
            }

            if (json.profilePicture != undefined)
                cnt.profilePicture = environment.WebApiUrl + json.profilePicture;
            if (json.client != undefined) {



                if (json.client.type != undefined) {
                  
                    if (json.client.type == "corporateClient") {
                        cnt.client = new CorporateClient();
                        (cnt.client as CorporateClient).companyRegistrationNo = json.client.companyRegistrationNo;
                        (cnt.client as CorporateClient).companyName = json.client.companyName;
                        (cnt.client as CorporateClient).legalName = json.client.legalName;
                        (cnt.client as CorporateClient).bookingTypes = json.client.bookingTypes;
                    }
                    else {
                        cnt.client = new IndividualClient();
                        (cnt.client as IndividualClient).firstName = json.client.firstName;
                        (cnt.client as IndividualClient).lastName = json.client.lastName;
                        (cnt.client as IndividualClient).bookingTypes = json.client.bookingTypes;
                    }
                    cnt.client.type = json.client.type;
                    cnt.client.communicationMethods = json.client.communicationMethods;
                    cnt.client.accountManager = json.client.accountManager;
                    cnt.client.defaultVenue = json.client.defaultVenue;
                    cnt.client.clientCategoryId = json.client.clientCategoryId;
                    cnt.client.clientTypeId = json.client.clientTypeId;
                    cnt.client.address = json.client.address;
                    cnt.client.id = json.client.id;
                    cnt.client.clientRef = json.client.clientRef;
                    cnt.client.profilePicture = json.client.profilePicture;

                }

                if (json.client.clientName != undefined) {
                    cnt.client.clientName = json.client.clientName;
                }
            }
            return cnt;
        }));
    }

    SelectClientDetails(clientId: string): Observable<any> {
        return this._baseService.WebAPIGet("api/V4.1/customers/clients/" + clientId + "?include=documents,notes").pipe(map(response => {
            var json = (new Deserializer().deserialize(response));
            return json;
        }));
    }

    GetPasswordPolicies(contactId: string): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/system/GetPasswordPolicies?contactId=" + contactId).pipe(
            map((response) => {
                return response;
            })
        );
    }

    GetAnonymousPasswordPolicies(): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/system/GetAnonymousPasswordPolicies").pipe(
            map((response) => {
                return response;
            })
        );
    }




    /****| POST |  this._store.dispatch(new StoreAssets(this.SelectedList)); ****/


    clientLogin(postdata: Login): Observable<Contact> {
        var loginJson = JSON.stringify(postdata);
        return this._baseService.Internal_WebAPI_POST("api/customer/login?include=client,CommunicationMethods", loginJson).pipe(
            map((response: any) => {
                console.log(response);
                var JsonData = JSON.parse(response.body.data);
                var json = new Deserializer().deserialize(JsonData);
                console.log(json);

                var cnt = new Contact();

                cnt.ref = json.ref;
                cnt.firstName = json.firstName;
                cnt.lastName = json.lastName;
                if (json.communicationMethods != undefined && json.communicationMethods.length > 0) {
                    json.communicationMethods.forEach(function (communicationMethod) {
                        if (communicationMethod.communicationType.toLowerCase().indexOf("email") > -1 && communicationMethod.value != "" && communicationMethod.value != undefined) {
                            cnt.invoiceEmailAddress = communicationMethod.value;
                        }
                    });
                }
                cnt.userName = json.userName == undefined ? cnt.invoiceEmailAddress : json.userName;

                cnt.id = json.id;
                cnt.type = json.type;

                if (json.client != undefined) {
                    cnt.client = new Client();
                    cnt.client.id = json.client.id;
                    cnt.client.clientRef = json.client.clientRef;
                    if (json.client.profilePicture != undefined)
                        cnt.profilePicture = json.client.profilePicture;
                    if (json.client.type != undefined) {
                        cnt.client.type = json.client.type;
                    }
                    if (json.client.priceConcession != undefined) {
                        cnt.client.priceConcessionId = json.client.priceConcession.id;
                    }

                    if (json.client.clientName != undefined) {
                        cnt.client.clientName = json.client.clientName;
                    }
                }

                cnt.bookingTypeList = json.bookingTypes ? json.bookingTypes : json.client.bookingTypes;
                cnt.clientVenueIds = json.client.clientVenues;

                //map type
                console.log("Deserialized web api output");
                console.log(cnt);
                return cnt;


            })
        )
    }

    clientSave(postdata: any): Observable<CorporateClient> {    //corporate client save -> calling from Corporate Client Setup
        //let endPoint = `api/V4.1/customers/clients?include=contacts`;
        var json = new Serializer().serialize(postdata)
        // return this._baseService.WebAPIPOST(endPoint, json).pipe(map(response => {
        //     var corporateClient = new CorporateClient();
        //     corporateClient = (new Deserializer().deserialize(response));
        //     return corporateClient;
        // }));
        return this._baseService.Internal_WebAPI_POST("api/customer/clients?include=contacts", json).pipe(
            map((response: any) => {
                var corporateClient = new CorporateClient();
                if (response.body.isError == true) {
                    throw new Error(JSON.stringify(response.body));
                }
                corporateClient = (new Deserializer().deserialize(response.body.data));
                return corporateClient;
            }));
    }

    individualClientSave(postdata: IndividualClient): Observable<IndividualClient> { //individual client save -> calling from individual Client Setup
        var json = new Serializer().serialize(postdata)
        // return this._baseService.WebAPIPOST("api/V4.1/customers/clients?include=Contacts", json).pipe(map(response => {
        //     var corporateClient = new Contact();
        //     corporateClient = (new Deserializer().deserialize(response));
        //     return corporateClient;
        // }));
        return this._baseService.Internal_WebAPI_POST("api/customer/clients?include=contacts", json).pipe(
            map((response: any) => {
                var corporateClient = new Contact();
                if (response.body.isError == true) {
                    throw new Error(JSON.stringify(response.body));
                }
                else {
                    corporateClient = (new Deserializer().deserialize(response.body.data));
                    return corporateClient;
                }
            }));
    }

    getQASAddress(postData: QASAddress): Observable<Addresses> {
         return this._baseService.InternalWebAPIPOST("api/customer/address-list", postData);
    }

    ChangePassword(changepassword: ChangePassword): Observable<any> {
        return this._baseService.InternalWebAPIPOST("api/system/ChangePassword", changepassword).pipe(
            map((response) => {
                return response;
            })
        );
    }

    sendForgetPasswordEmail(email: ClientEmailModel): Observable<any> {
        return this._baseService.InternalWebAPIPOST("api/customer/request-new-password", email);
    }


    /****| PATCH |****/


    ClientPatch(postdata: any): Observable<any> {  //currently using Direct patch from customer service 
        var json = new Serializer().serialize(postdata)
        if (!postdata.id) {
            postdata.id = postdata.tid;
        }
        return this._baseService.WebAPIPATCHResponse("api/V4.1/customers/client/" + postdata.id, json);
    }

    patchPostContact(contact: any) { //* ClientPatch() get success (200) --> Updating Contact Patch  using MVC */
        var json = new Serializer().serialize(contact)
        return this._baseService.Internal_WebAPI_PATCH("api/customer/customer-patch/", json);
    }




    /****| DELETE |****/


    CommunicationMethodDelete(id: string, contactid: string): Observable<any> {
        let endPoint = `api/V4.1/customers/contacts/${contactid}/Communication-methods/${id}`;
        return this._baseService.WebAPIDELETEResponse(endPoint);
    }


   
        communication_method_delete(id: string, clientId: string): Observable < any > {
        
            return this._baseService.InternalWebAPIPOST("api/customer/communication-method-detele?" + "&clientId=" + clientId + "&contactId=" + id, true).pipe(
            map((response) => {
                return response;
            })
        );

    }





    // temp  need to remove 

    UpdateClient(Customer: any) {

        console.log("Updating..Individual Customer() --> Started.....", Customer);

        let result = this._baseService.Internal_WebAPI_POST("api/customer/customer-update", Customer);

        console.log("Updating..Individual Customer() --> END.....");
        return result;
    }

    GetContactDetails(clientId) {

        var json = '';
        return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=14&clientId=" + clientId, json).pipe(
            map((response: any) => {
                if (response.body.data) {
                    var JsonData = JSON.parse(response.body.data);
                    var result = new Deserializer().deserialize(JsonData);
                }
                return result;
            })
        )

    }

    GetBookingTypes(): any {
       
        return this._baseService.Internal_WebAPI_GET("api/regular-bookings/bookingTypes").pipe(
            map((response: any) => {
                if (response.body) {
                    return response.body;
                }
            })
        )

    }
}

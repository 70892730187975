﻿export class Utility {
    static index = -1;
    static getIndex()
    {
        this.index = this.index - 1;
        return this.index;
    }
    static convertToISO(dt: Date) {
        //2018-04-23T10:26:00
        var month = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1);
        return dt.getFullYear() + '-' + this.generateTwoDigitNo(dt.getMonth() + 1) + '-' + this.generateTwoDigitNo(dt.getDate()) + 'T' + this.generateTwoDigitNo(dt.getHours()) + ':' + this.generateTwoDigitNo(dt.getMinutes()) + ':' + this.generateTwoDigitNo(dt.getSeconds());
    }

    static convertToDate(dt: Date) {
        //2018-04-23
        var month = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1);
        return dt.getFullYear() + '-' + this.generateTwoDigitNo(dt.getMonth() + 1) + '-' + this.generateTwoDigitNo(dt.getDate());
    }

    static convertISOToDate(dt: any) {
        if (!(dt instanceof Date)) {
            if (dt != undefined) {
                var arr = dt.split(/[-T:]/);
                return new Date(+arr[0], (+arr[1]) - 1, +arr[2], +arr[3], +arr[4], 0);
            }
            else return undefined;
        }
        else return dt;
    }

    static getClosestHalfHour(d) {
        d.setMilliseconds(0);
        d.setSeconds(0);
        var minutes = d.getMinutes();
        var neededMin;
        if (minutes == 0) {
            neededMin = 0;
        }
        else if (0 < minutes && minutes <= 30) {
            neededMin = 30 - minutes;
        }
        else {
            neededMin = 60 - minutes;
        }
        d.setMinutes(d.getMinutes() + neededMin)
        return d;
    }

    static setMinTime(dt) {
        dt.setMinutes(dt.getMinutes() + 1);
        return this.getClosestHalfHour(dt);
    }
    static appendTimePart(currentDt: Date, timeObject: Date, isNewObject: boolean = false) {
        if (currentDt) {
            var dt = isNewObject ? new Date(currentDt.getTime()) : currentDt;
            if (dt) {
                dt.setHours(timeObject.getHours());
                dt.setMinutes(timeObject.getMinutes());
                dt.setSeconds(0);
            }
            return dt;
        }
        return null;
    }

    


    static changeDatePart(currentDt: Date, newDt: Date) {
        if (currentDt) {
            var hr = currentDt.getHours();
            var min = currentDt.getMinutes();
            currentDt = newDt;
            currentDt.setHours(hr);
            currentDt.setMinutes(min);
            currentDt.setSeconds(0);
        }
        return currentDt;
    }

    static roundToMinDuration(dt: Date, MinimumBookingDuration: number, isEndTime = false, isStartTime = false): any {
        var isDateChange: Boolean = false;
        dt.setSeconds(0);
        var hr = dt.getHours();
        var min = dt.getMinutes();

        if (!(hr == 23 && min == 59)) {
            var totMin = hr * 60 + min
            var mxMin = 23 * 60 + 59;

            var minBookingDuration = MinimumBookingDuration;
            if (mxMin >= totMin && (min % MinimumBookingDuration) > 0) {
                var roundMin = (parseInt((min / minBookingDuration).toString()) + 1) * minBookingDuration;
                dt.setMinutes(roundMin);
                isDateChange = true;
            }
        }

        return { newDate: dt, isDateChanged: isDateChange };
    }

    static urlHistorty: string[] = [];

    static sortParams(link) {
        link = decodeURIComponent(link);
        let queryParams = link.split('?')[1];
        let params;
        let pair = null;
        let data = {};

        if (queryParams) params = queryParams.split('&');

        if (params && params.length > 0) {
            params.forEach((d) => {
                pair = d.split('=');
                data[`${pair[0]}`] = pair[1];
            });
        }
        return data;
    }

    static toCamel(o) {
        var newO, origKey, newKey, value;
        let thisPage = this;
        if (o instanceof Array) {
            return o.map(function (value) {
                if (typeof value === "object") {
                    value = thisPage.toCamel(value);
                }
                return value;
            })
        }
        else {
            newO = {};
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                    value = o[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = thisPage.toCamel(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }

    private static generateTwoDigitNo(no: number): string {
        return no < 10 ? ('0' + no) : no.toString().substring(0, 2);
    }

    static calculateFileSize(bytes: number): string {
        if (bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
        }
    }

    static sentenceCase(address) {
        //console.log(address);
        if (address != '') {
            return address.replace(/\w\S*/g, (letter) => {
                return letter.charAt(0).toUpperCase() + letter.substr(1).toLowerCase();
            })
        }
        return;
    }

    static isPriceUndefined(node) {
        if (node != undefined && node.data != undefined) {
            if (node.data.items.length == 0 || node.data.items == undefined) {
                return false;
            }
            return true;
        };
        return true;
    }
    static showErrorMessage(response) {
        if (!response.body || response.status > 400) {
            let messageDetail = "something went wrong please try again";
            //this.showErrorMessage = true;

            return { messageDetail, showErrorMessage: true };
        }
        return {showErrorMessage: false };
    }


    static displaySidepanel(node, parentId) {
        if (!node.selectable) {
            return true;
        } else {
            
            if (node.parentId == parentId) {
                return true;
            }

            return false;
        }
        return false;
    }
  
}
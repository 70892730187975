﻿import { BaseResource } from '../../../base-resource';
import { Package } from 'src/modules/models/booking/package';
import { BookingItem } from './booking-item';
export class Booking extends BaseResource {
    constructor() {
        super();      
    }
     
    confirmedDate: string;
    cancelled: boolean;
    confirmed: boolean;
    salesChannelId: number;
    bookingStatus: BookingStatus;
    contact: Contact
    bookingPackages: BookingPackage[]; 
    priceConcession: PriceConcession;
    temporary: boolean;
    seasonId :number;
    bookingTypeId: number;
    eventName: string;
}

export class BookingStatus extends BaseResource {
    constructor() {
        super();
    }
}
export class Contact extends BaseResource {
    constructor() {
        super();
    }
    client: Client;
}
export class Client extends BaseResource {
    constructor() {
        super();
    }
    priceConcessionId: string;
}

export class BookingPackage extends BaseResource {
    constructor() {
        super();
    }
    name:string;
    attendees:number;
    quantity: number;
    package: Package;
    startDate: string;
    endDate: string;
    bookingItems: BookingItem[]

}
export class PriceConcession extends BaseResource {
    constructor() {
        super();
    }
}
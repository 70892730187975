import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, filter, tap, scan, switchMap, catchError, withLatestFrom, concatMap } from "rxjs/operators";


import { Store, ICart, AppState, PostBooking, AddFacility, UpdateFacility, UpdateCart, LogError, UpdatePackages, PatchBookingContact, CompleteBooking, Redirect, PostInvoice, UpdateFacilityPrice, DeleteFacilityPrice, UpdateBusinessArea, UpdateBookingPackage, SetImmediateData, MakePayment} from 'src/modules/store/index'
import { BookingSaveStarted, BookingSaveCompleted, FacilitySearchLoading, FacilitySearchLoaded } from 'src/modules/store/loading/actions'
import { CartActionTypes } from 'src/modules/store/actions'
import { PackageFilter } from 'src/modules/models/booking/common';
import { BookingService } from 'src/modules/services/booking.service'
import { PackageService } from 'src/modules/services/package.service'
import { Booking, BookingInvoice } from 'src/modules/models/booking/booking'
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import { Event } from 'src/modules/models/booking/event';
import { Package } from 'src/modules/models/booking/package';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package'
import { Allocations } from 'src/modules/models/booking/allocations'
import { BookingItems, BookingItemAssociation } from 'src/modules/models/booking/booking-items'
import { BookingFacility } from 'src/modules/models/asset/booking-facility'
import { FacilitySummary } from 'src/modules/models/asset/facility-summary'
import { RoutePath } from 'src/modules/booking-portal/route-path'
import { ErrorState } from 'src/modules/models/error/error'
import { environment } from 'src/environments/environment';
import { Utility } from 'src/modules/utility';
import { BookingStatus } from 'src/modules/models/booking/booking-status'
import { BookingClient } from 'src/modules/models/booking/booking-client';
import { BookingContact } from 'src/modules/models/booking/booking-contact';
import { PriceConcession } from 'src/modules/models/booking/price-group'
import { MatSnackBar } from '@angular/material';

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';

import { Contact } from 'src/modules/models/client/contact';
import { SystemConfig } from 'src/modules/system-configuration'
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { AssetService } from 'src/modules/services/asset.service';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { QuestionSummary } from 'src/modules/models/booking/question-summary';
import { Deserializer } from 'json-api-format';
import { ItemService } from 'src/modules/services/item.service';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { UpsellSummary, Item } from 'src/modules/models/item/item';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable()
export class FacilityEffects {

    constructor(private actions$: Actions,
        private store: Store<ICart>,
        private bookingService: BookingService,
        private _itemService: ItemService,
        private packageService: PackageService,
        private _assetService: AssetService,
        private router: Router,
        public snackBar: MatSnackBar,
        private _sysConfig: SystemConfig,
        private sanitizer: DomSanitizer
    ) {
    }


    @Effect({})
    addFacility$ = this.actions$.pipe(
        ofType(CartActionTypes.ADD_FACILITY),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {
            console.log('ADD_FACILITY');
            var fc = (action as AddFacility).payload as FacilitySummary;
            var store = storeState as AppState;
            var cart = store.cart;
            if (!(fc.item && fc.item.salesCategories && fc.item.salesCategories.length > 0)) {
                fc.isUpsellAvailable = false;
            }
            let salesCategories = fc.item.salesCategories.map(s => s.id).join(",");
            let bookingResult = this._assetService.IsUpsellAvailable(salesCategories);
            return bookingResult.pipe(
                map((bookingdata) => {
                    fc.isUpsellAvailable = false;
                    if (bookingdata != undefined && bookingdata != null) {
                        fc.isUpsellAvailable = bookingdata.isUpsellAvailable as boolean;
                        fc.availableUpsellItemCategories = bookingdata.itemCategories;
                    }

                    //this.store.dispatch(new UpdateFacility(fc))
                    return { type: CartActionTypes.UPDATE_FACILITY_UPSELL_FLAG, payload: fc }
                })
                , catchError((error) => {
                    console.log('LogError');
                    return of(new LogError(error));
                })
            );
        }));



    @Effect({ dispatch: false })
    updateFacility$ = this.actions$.pipe(
        ofType(CartActionTypes.EDIT_FACILITY),
        map((action) => {
            console.log('EDIT_FACILITY');
            console.log(action);
            var bk = (action as UpdateFacility).payload as FacilitySummary;
            this.store.dispatch(new AddFacility(bk));

        })

    );

    @Effect({ dispatch: false })
    doBooking$ = this.actions$.pipe(
        ofType(CartActionTypes.DO_BOOKING),
        withLatestFrom(this.store.source),
        map(([cart, storeState]) => {
            var store = storeState as AppState;
            var cartData = store.cart;
            if ((cartData.contact.client != undefined && cartData.contact.client != null)) {
                var bk = new Booking();
                bk.bookingStatus = new BookingStatus();
                bk.bookingStatus.id = (cartData.isAllImmediateConfirmed) ? String(environment.BookingApproveStatus) : String(environment.BookingStatus);
                bk.salesChannelId = environment.SalesChannel;
                bk.eventName = cartData.packageFilter.EventName;
                bk.poReference = cartData.packageFilter.ledgerNumber;
                bk.temporary = false;

                //   this.store.dispatch(new BookingSaveStarted());
                this.store.dispatch(new PostBooking(bk));
            } else {
                //   this.store.dispatch(new BookingSaveCompleted());
                this.store.dispatch(new Redirect("CART-LOGIN"));
            }
        })

    );

    @Effect({ dispatch: false })
    setImmediateData$ = this.actions$.pipe(
        ofType(CartActionTypes.SET_IMMEDIATE_DATA),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {
            let filter = new PackageFilter();
            var bkService = this.bookingService;
            var store = storeState as AppState;
            var cart = store.cart;
            var bk = (action as PostBooking).payload as Booking;
            let bookingSelectResult = bkService.BookingSelect(bk.id);
            return bookingSelectResult.pipe(
                map((data) => {
                    filter.BussinessAreaId = data.data.package.businessAreaId;
                    filter.PackageClass = cart.packageFilter.PackageClass;
                    filter.Package = +cart.packageFilter.Package;
                    filter.EventName = cart.packageFilter.EventName;
                    filter.FilteredPackageClasses = cart.packageFilter.FilteredPackageClasses;
                    this.store.dispatch(new UpdateBookingPackage(filter));

                    let invoicedAmount = +(data.data.invoicedAmount);
                    let paidAmount = +(data.data.paidAmount);

                  if (invoicedAmount && paidAmount) {
                    if (paidAmount == invoicedAmount) {
                      this.store.dispatch(new CompleteBooking());
                      this.store.dispatch(new Redirect("CART-COMPLETE"));
                    } else if (paidAmount < invoicedAmount) {
                      this.store.dispatch(new MakePayment(bk));
                    }
                  } else {
                    this.store.dispatch(new MakePayment(bk));
                  }

                }
                ));
        }));

    @Effect({})
    makePayment$ = this.actions$.pipe(
        ofType(CartActionTypes.MAKE_PAYMENT),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {
            //generate booking structure from BookingFacility
            console.log('booked facility')
            var bk = (action as PostBooking).payload as Booking;
            var store = storeState as AppState;
            var cart = store.cart;

            let bookingReference = cart.booking.bookingReference;
            let OutStandingAmount = cart.booking.grossAmount - cart.booking.paidAmount;
            let businessAreaId = +(cart.packageFilter && cart.packageFilter.BussinessAreaId != null) ? cart.packageFilter.BussinessAreaId : environment.DefaultBussinessAreaId;
            let result = this.packageService.packageBusinessAreaSearch();

            return result.pipe(
                map((businessAreas) => {
                    if (OutStandingAmount > 0) {
                        let url = "";
                        if (businessAreas != undefined && businessAreas instanceof Array && businessAreas.length > 0 && businessAreaId != 0) {
                            let businessArea = businessAreas.filter(businessArea => +businessArea.id == businessAreaId);
                            if (businessArea[0].billerCode != "") {
                                let billerCode = businessArea[0].billerCode;
                                let bookingId = cart.booking.id;
                                if (environment.ExternalIntegrationIdForPaymentGateway == "21") {
                                    url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "" : "/") + "Payment/Pay?InvoiceRef=" + bookingReference;
                                    if (OutStandingAmount != undefined && +OutStandingAmount > 0)
                                        url = url + "&amount=" + OutStandingAmount.toString();
                                    if (bookingId != undefined && bookingId != "") {
                                        url = url + "&bookingId=" + bookingId;
                                    }
                                    if (billerCode != undefined && billerCode != "") {
                                        url = url + "&billerCode=" + billerCode;
                                    }
                                    if (cart.isAllImmediateConfirmed) {
                                        url = url + "&immediate=1";
                                    }
                                    url = url + "&bookingTypeId=1";

                                    window.location.href = url;
                                } else {
                                    url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "" : "/") + "Payment/Pay?bookingId=" + bookingId + "&immediate=1&InvoiceRef=" + bookingReference + "&amount=" + OutStandingAmount.toString() + "&billerCode=" + billerCode + "&bookingTypeId=1";
                                    let paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                                }
                            }
                        }
                        if (url != "") {
                            this.store.dispatch(new Redirect(url));
                        }
                    }
                }
                ));

        })
    );

    @Effect({})
    postBooking$ = this.actions$.pipe(
        ofType('POST_BOOKING'),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {
            //generate booking structure from BookingFacility
            console.log('booked facility')
            var bk = (action as PostBooking).payload as Booking;
            var store = storeState as AppState;
            var appsetting = environment.AppSetting as AppSetting;
            var cart = store.cart;
            bk.confirmed = false;
            bk.tid = "1";
            bk.attendees = 1;
            bk.cancelled = false;
            bk.margin = 0;
            bk.bookingPackages = [];
            var questionSummaries = [];
            // var questionData = JSON.stringify({ QuestionSummaries: _QuestionSummaries });
            var bookingItemTypeNames = [];
            if (cart.contact.client != undefined) {
                bk.contact = new BookingContact();
                bk.contact.id = cart.contact.id;
            }

            bk.confirmed = true;
            bk.temporary = false;

            let tid = 1;
            let selectedPackage = cart.bespokePackages.find(p => p.id == cart.packageFilter.Package.toString());
            if (selectedPackage == undefined) {
                return;
            }
            if (cart.packageFilter.PackageDetail.question && cart.packageFilter.PackageDetail.question.length > 0) {
                let questionSummary = new QuestionSummary();
                questionSummary.id = cart.packageFilter.Package.toString();
                questionSummary.packageId = cart.packageFilter.Package.toString();
                questionSummary.questions = cart.packageFilter.PackageDetail.question;
                questionSummary.isUpsellItem = false;
                questionSummaries.push(questionSummary);
            }
            let bkpackages = new BookingPackages();
            bkpackages.event = new Event();
            bkpackages.package = new Package();
            bkpackages.package.id = selectedPackage.id;
            if (appsetting.BookingConfig.SetEventAsPackageName) {
                bkpackages.name = bk.eventName;
            }
            else {
                bkpackages.name = selectedPackage.name;
            }
            bkpackages.package.name = selectedPackage.name;
            bkpackages.priceTypeId = selectedPackage.priceTypeId;
            bkpackages.event.id = selectedPackage.eventId.toString();
            bkpackages.quantity = "1";
            bkpackages.taxRate = 0;
            bkpackages.margin = 0;
            bkpackages.attendees = 1;
            bkpackages.singleDayPackage = false;
            bkpackages.id = ((bk.bookingPackages.length + 1) * (-1)).toString();
            bkpackages.bookingItems = [];
            let isNewPackage = true;

            let selectedPackageClassDetail = environment.PackageClasses.find(a => a.Id == cart.packageFilter.PackageClass);

            var priceConcessionId;
            if (cart.contact.client && cart.contact.client.priceConcessionId &&
                cart.contact.client.priceConcessionId != 0 &&
                cart.contact.client.priceConcessionId != "") {
                priceConcessionId = cart.contact.client.priceConcessionId.toString();
            } else if (environment.ImmediateConfirmation.DefaultPriceConsessionId != 0)
                if (environment.ImmediateConfirmation.Allow &&
                    environment.ImmediateConfirmation.DefaultPriceConsessionId != 0 &&
                    cart.isAllImmediateConfirmed) {
                    priceConcessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId.toString();
                } else if (appsetting.BookingConfig.DefaultPriceConsessionId) {
                    priceConcessionId = appsetting.BookingConfig.DefaultPriceConsessionId.toString();
                }
                else {
                    //Todo this need to be validate when adding new facilities
                    if (!environment.IsAllowMultiplePriceConsession) {
                        if (((environment.AppSetting as (AppSetting)).BookingConfig.PriceDisplayOption == 3) && (!(selectedPackageClassDetail.PriceMinConsessionId == "" && selectedPackageClassDetail.PriceMaxConsessionId == ""))) {
                            let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(cart.addedFacilites, cart.packageFilter.PackageClass);
                            if (isPriceConcessionAvailable.isHasValidMinPriceConcession) priceConcessionId = (isPriceConcessionAvailable.PriceMinConsessionId);
                            if (isPriceConcessionAvailable.isHasValidMaxPriceConcession) priceConcessionId = (isPriceConcessionAvailable.PriceMaxConsessionId);
                        }
                    }
                }

            if (priceConcessionId != null) {
                bk.priceConcession = new PriceConcession();
                bk.priceConcession.id = priceConcessionId;
            }



            var facilityVenueEmailId = "";
            cart.addedFacilites.forEach((fc, i) => {

                bk.attendees = (fc.attendees > bk.attendees) ? fc.attendees : bk.attendees;
                bkpackages.attendees = (fc.attendees > bkpackages.attendees) ? fc.attendees : bkpackages.attendees;

                if (fc.venue && fc.venue.email && facilityVenueEmailId == "") {
                    facilityVenueEmailId = fc.venue.email;
                }
                // populate BookingItems
                var bookingItem = new BookingItems();
                //Temp solu
                var tp = FacilityAdaptor.getItemPrefix(fc.item.type.toLowerCase()) + fc.item.type;
                if (fc.item.type.toLowerCase()=="assetitem")
                {
                    tp = "PrivateBookingGeneralAdmissionItem";
                }
                bookingItem.type = tp;
                bookingItemTypeNames.push(tp);
                bookingItem.allocations = [];
                bookingItem.quantity = 1;
                bookingItem.item.id = fc.item.id;
                bookingItem.item.type = fc.item.type;

                bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                bookingItem.upsell = true;
                bookingItem.name = fc.item.name;

                // populate suitable package


                if (fc.itemPrice != undefined && !(isNaN(fc.itemPrice.concessionId))) {
                    bookingItem.priceTypeId = fc.itemPrice.priceTypeId;
                }

                // populate BookingPackages

                let packageStartTime = null;
                let packageEndTime = null;


                // Package start and End time calculation
                if (isNewPackage) {
                    packageStartTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                    packageEndTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                    isNewPackage = false;
                }
                else {
                    if (Utility.convertISOToDate(fc.startTime) < Utility.convertISOToDate(bkpackages.startDate)) {
                        packageStartTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                    }
                    else {
                        packageStartTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.startDate));
                    }

                    if (Utility.convertISOToDate(fc.endTime) > Utility.convertISOToDate(bkpackages.endDate)) {
                        packageEndTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                    }
                    else {
                        packageEndTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.endDate));
                    }
                }

                bkpackages.startDate = packageStartTime;
                bkpackages.endDate = packageEndTime;

                bookingItem.id = (tid * (-1)).toString();
                if (bookingItem.allocations == undefined || bookingItem.allocations == null) {
                    bookingItem.allocations = [];
                }

                fc.allocations.forEach(function (alc) {

                    alc.id = (tid * (-1)).toString();
                    alc.timeslot.id = (tid * (-1)).toString();
                    alc.attendees = fc.attendees;
                    bookingItem.allocations.push(alc);
                    tid++;
                });
                bkpackages.bookingItems.push(bookingItem);


                // Question summary populate

                if (fc.item.question != undefined) {
                    let questionSummary = new QuestionSummary();
                    questionSummary.id = fc.id;
                    questionSummary.itemId = fc.item.id;
                    questionSummary.startTime = bookingItem.startTime;
                    questionSummary.endTime = bookingItem.endTime;
                    questionSummary.questions = fc.item.question;
                    questionSummary.isUpsellItem = false;

                    questionSummaries.push(questionSummary);
                }

                if (fc.upsellSummaries != null && fc.upsellSummaries != undefined && fc.upsellSummaries.length > 0) {
                    fc.upsellSummaries.forEach(function (upsellSummary) {

                        // populate BookingItems
                        var bookingUpsellItem = new BookingItems();
                        bookingUpsellItem.type = FacilityAdaptor.getItemPrefix(upsellSummary.type.toLowerCase()) + upsellSummary.type;
                        bookingItemTypeNames.push(FacilityAdaptor.getItemPrefix(upsellSummary.type.toLowerCase()) + upsellSummary.type);
                        bookingUpsellItem.quantity = upsellSummary.qty;
                        bookingUpsellItem.item.id = upsellSummary.id
                        bookingUpsellItem.item.type = upsellSummary.type;
                        bookingUpsellItem.startTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                        bookingUpsellItem.endTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                        bookingUpsellItem.upsell = true;
                        bookingUpsellItem.name = upsellSummary.name;

                        // populate suitable package

                        upsellSummary.itemPrice = upsellSummary.minPrice == undefined || upsellSummary.minPrice == null ? upsellSummary.maxPrice : upsellSummary.minPrice;

                        if (upsellSummary.itemPrice != undefined && !(isNaN(upsellSummary.itemPrice.concessionId))) {
                            bookingUpsellItem.priceTypeId = upsellSummary.itemPrice.priceTypeId;
                        }

                        // populate BookingPackages Time

                        let packageStartTime = null;
                        let packageEndTime = null;

                        if (Utility.convertISOToDate(upsellSummary.startTime) < Utility.convertISOToDate(bkpackages.startDate)) {
                            packageStartTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));
                        }
                        else {
                            packageStartTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.startDate));
                        }

                        if (Utility.convertISOToDate(upsellSummary.endTime) > Utility.convertISOToDate(bkpackages.endDate)) {
                            packageEndTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.endTime));
                        }
                        else {
                            packageEndTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.endDate));
                        }


                        bkpackages.startDate = packageStartTime;
                        bkpackages.endDate = packageEndTime;

                        bookingUpsellItem.id = (tid * (-1)).toString();

                        bookingUpsellItem.bookingItemAssociations = [];
                        let bookingItemAssociation = new BookingItemAssociation();
                        bookingItemAssociation.id = (tid * (-1)).toString();;
                        bookingItemAssociation.allocatedQuantity = upsellSummary.qty;
                        bookingItemAssociation.startTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));
                        bookingItemAssociation.endTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.endTime));
                        bookingItemAssociation.asset.id = fc.id;
                        bookingItemAssociation.primaryBookingSpaceItem = {
                            id: bookingItem.id,
                            type: "bookingItem"
                        }

                        bookingUpsellItem.bookingItemAssociations.push(bookingItemAssociation);
                        //bookingUpsellItem.allocations = [];
                        //if (upsellSummary.itemTypeID == 4) {

                        //    let allocation = new Allocations();

                        //  //  allocation.timeslot.asset.id = action.payload.id;

                        //    allocation.timeslot.startTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));
                        //    allocation.timeslot.endTime = Utility.convertToISO(Utility.convertISOToDate(upsellSummary.startTime));

                        //    //allocation.timeslot.eventConfiguration.id = action.payload.configuration.id.toString();

                        //    allocation.id = (tid * (-1)).toString();
                        //    allocation.timeslot.id = (tid * (-1)).toString();
                        //    bookingUpsellItem.allocations.push(allocation);
                        //    tid++;
                        //}
                        bkpackages.bookingItems.push(bookingUpsellItem);
                        tid++;

                        // Question summary populate

                        if (upsellSummary.question != undefined) {
                            let questionUpsellSummary = new QuestionSummary();
                            questionUpsellSummary.id = fc.id;
                            questionUpsellSummary.itemId = upsellSummary.id;
                            questionUpsellSummary.startTime = upsellSummary.startTime;
                            questionUpsellSummary.endTime = upsellSummary.endTime;
                            questionUpsellSummary.questions = upsellSummary.question;
                            questionUpsellSummary.isUpsellItem = true;

                            questionSummaries.push(questionUpsellSummary);
                        }

                    });
                }
            });
            bk.bookingPackages.push(bkpackages);
            var bkService = this.bookingService;
            let bookingResult = this.bookingService.BookingSave(bk, bookingItemTypeNames);
            return bookingResult.pipe(
                map((bookingdata) => {
                    let booking;
                    let errorData = [];
                    if (bookingdata.booking)
                        booking = (bookingdata.booking as Booking);
                    else if (bookingdata.errors) {
                        console.log(bookingdata.errors);

                        errorData.push(bookingdata.errors);
                        this.snackBar.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: errorData,
                        });
                        return { type: CartActionTypes.ERROR_LOG, payload: bookingdata.errors }


                    }
                    if (booking)
                    {
                    //let booking = (bookingdata as Booking);
                    if (cart.AddedDocuments != undefined && cart.AddedDocuments.length > 0) {
                        let DocumentCount = cart.AddedDocuments.length;
                        let UloadedDocumentCount = 0;
                        cart.AddedDocuments.forEach(function (file) {
                            let documentResult = bkService.BookingDocumentsSave(booking.id, file);
                            documentResult.subscribe(data => {
                            },
                                err => {
                                    console.log("Error", err);
                                },
                            );
                        });
                    }




                        // question population
                        if (questionSummaries != undefined && questionSummaries.length > 0) {
                          if (booking.bookingItems && booking.bookingItems.length > 0) {

                            questionSummaries.forEach(function (questionSummary) {
                                  let bookingItem = booking.bookingItems.find(bi => bi.item.id == questionSummary.itemId && bi.startTime == questionSummary.startTime && bi.endTime == questionSummary.endTime);

                              let bookingPackage = booking.bookingPackages.find(bp => bp.package.id == questionSummary.packageId);
                                if (bookingItem != undefined || bookingPackage != undefined) {
                                    if (bookingItem)
                                        questionSummary.bookingItemId = bookingItem.id;

                                        if (questionSummary.questions != undefined && questionSummary.questions.length > 0) {

                                            questionSummary.questions.forEach((question) => {
                                              if (FacilityAdaptor.isQuestionAnswerd(question)) {
                                              if (booking.bookingQuestionnaires != undefined && booking.bookingQuestionnaires.length > 0) {

                                                booking.bookingQuestionnaires.forEach(function (bookingQuestionnairy) {

                                                    if (bookingQuestionnairy.bookingItem != undefined || bookingQuestionnairy.bookingPackage != undefined) {

                                                        if (((bookingItem && bookingQuestionnairy.bookingItem && bookingItem.id == bookingQuestionnairy.bookingItem.id) || ((!bookingQuestionnairy.bookingItem) && bookingPackage && bookingQuestionnairy.bookingPackage && bookingPackage.id == bookingQuestionnairy.bookingPackage.id)) && bookingQuestionnairy.bookingQuestions != undefined && bookingQuestionnairy.bookingQuestions.length > 0) {

                                                                let bookingQuestion = undefined
                                                                //  let bookingQuestion = bookingQuestionnairy.bookingQuestions.find(bq => bq.question.id == question.id);
                                                                bookingQuestionnairy.bookingQuestions.forEach((bookingQus) => {

                                                                    if (bookingQus.question)
                                                                        if (bookingQus.question.id == question.id)
                                                                            bookingQuestion = bookingQus;

                                                                });
                                                                if (bookingQuestion != undefined) {

                                                                    question.bookingQuestionId = bookingQuestion.id;

                                                                    if (bookingQuestion.question.type == "multipleChoiceQuestion") {

                                                                      if (bookingQuestion.bookingQuestionChoices != undefined && bookingQuestion.bookingQuestionChoices.length > 0) {

                                                                            question.answerChoices.forEach(function (ac) {

                                                                                if (question.quantityRequired) { //Qty Required Question

                                                                                    if (ac.Quantity) {
                                                                                      let answerChoice = bookingQuestion.bookingQuestionChoices.find(a => a.name == ac.name);
                                                                                        if (answerChoice != undefined) {
                                                                                            ac.id = answerChoice.id; //binding bookingQuestion ID as a Answer Choice ID
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    if (ac.isSelectedAnswer == true) {
                                                                                      let answerChoice = bookingQuestion.bookingQuestionChoices.find(a => a.name == ac.name);
                                                                                        if (answerChoice != undefined) {
                                                                                            ac.id = answerChoice.id;
                                                                                            ac.Quantity = 1; // 1 => isSelected
                                                                                        }
                                                                                    }
                                                                                }


                                                                            });
                                                                        }
                                                                    }
                                                                    else if (bookingQuestion.question.type == "singleChoiceQuestion") {

                                                                      if (bookingQuestion.bookingQuestionChoices != undefined && bookingQuestion.bookingQuestionChoices.length > 0 && question.answeredText != "" && question.answeredText != undefined) {
                                                                        var selectedAnswer = question.answerChoices.find(a => a.id == question.answeredText);
                                                                        let answerChoice = bookingQuestion.bookingQuestionChoices.find(a => a.name == selectedAnswer.name);
                                                                        if (answerChoice != undefined) {
                                                                          selectedAnswer.id = answerChoice.id;
                                                                          question.answeredText = answerChoice.id;
                                                                        }
                                                                      }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        //else if ( bookingQuestionnairy.bookingQuestions != undefined && bookingQuestionnairy.bookingQuestions.length > 0) {
                                                        //     if (bookingQuestionnairy.bookingQuestions != undefined && bookingQuestionnairy.bookingQuestions.length > 0) {
                                                        //        let bookingQuestion = bookingQuestionnairy.bookingQuestions.find(bq => bq.question.id == question.id);
                                                        //        if (bookingQuestion != undefined) {
                                                        //            question.bookingQuestionId = bookingQuestion.id;
                                                        //        }
                                                        //    }
                                                        //}
                                                    });
                                                }

                                              }
                                            });
                                        }

                                    }
                                });

                                console.log("Booking populated questions ", questionSummaries);

                            if (booking.bookingQuestionnaires != undefined && booking.bookingQuestionnaires.length > 0) {
                                    let bookingQuestionAndAnswerResult = bkService.PostQuestionAndAnswer(questionSummaries, booking.id);
                                    bookingQuestionAndAnswerResult.subscribe(data => { },
                                        err => {
                                            console.log("Error Question Post ", err);
                                        },
                                    )
                                };

                            }
                        }
                        var zeroPriceFacilities: FacilitySummary[] = [];
                        if (cart.isAllImmediateConfirmed) {

                            if (cart.addedFacilites != undefined && cart.addedFacilites.length > 0) {
                                if (cart.priceChangedFacilities != undefined && cart.priceChangedFacilities.length > 0) {

                                    cart.addedFacilites.forEach(facility => {
                                        console.log(facility)
                                        console.log(facility.minPrice)
                                        console.log(facility.minPrice.priceIncludingTax)
                                        if (facility.minPrice != undefined && facility.minPrice.priceIncludingTax == 0)
                                            zeroPriceFacilities.push(facility);
                                    });
                                }
                            }
                            if (zeroPriceFacilities.length == 0) {
                                this.store.dispatch(new SetImmediateData(booking));
                            } else {
                                let bookingEnquiryConfirmationEmailResult = bkService.SendBookingEnquiryConfirmationEmail(booking.id, cart.contact.invoiceEmailAddress, booking.bookingReference, facilityVenueEmailId, cart.packageFilter.PackageClass.toString());
                                bookingEnquiryConfirmationEmailResult.subscribe(data => { },
                                    err => {
                                        console.log("Error", err);
                                    },
                                );
                            }

                        } else {
                            let bookingEnquiryConfirmationEmailResult = bkService.SendBookingEnquiryConfirmationEmail(booking.id, cart.contact.invoiceEmailAddress, booking.bookingReference, facilityVenueEmailId, cart.packageFilter.PackageClass.toString());
                            bookingEnquiryConfirmationEmailResult.subscribe(data => { },
                                err => {
                                    console.log("Error", err);
                                },
                            );
                        }
                        //   this.store.dispatch(new BookingSaveCompleted());
                        return { type: CartActionTypes.UPDATE_CART, payload: booking }
                    }


                    return { type: CartActionTypes.ERROR_LOG, payload: 'Something went wrong...' }

                })
                , catchError((error) => {
                    //  this.store.dispatch(new BookingSaveCompleted());
                    let errorData = [];
                    if (error.status == 500) {
                        if (error.statusText)
                            errorData.push(error.statusText);
                        if (error.detail)
                            errorData.push(error.detail);
                    }

                    if (error.error != undefined && error.error.errors != null && error.error.errors.length > 0) {
                        error.error.errors.forEach((error, i) => {
                            if (error.detail)
                                errorData.push(error.detail);
                        });
                    }

                    console.log(errorData);
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                    return of(new LogError(error));
                })
            );
        }));



    // navigate user to cart page
    @Effect({ dispatch: false })
    updateCartSuccess$ = this.actions$.pipe(
        ofType(CartActionTypes.UPDATE_CART),
        withLatestFrom(this.store.source),
        map(([cart, storeState]) => {
            var store = storeState as AppState;
            var cartData = store.cart;
            console.log('UPDATE_CART');

            if ((cartData.contact.client != undefined)) {
                if (cartData.booking.bookingStatus && cartData.booking.bookingStatus.id == environment.BookingExpireStatus) {

                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: ["Your Booking Enquiry session has expired. Please try again."],
                    });
                    this.store.dispatch(new CompleteBooking());
                    this.store.dispatch(new Redirect("FACILITY"));
                } else {
                    //    this.store.dispatch(new CompleteBooking()); // testing purpose only  comment this to keep store as it is after boking save
                    if (!cartData.isAllImmediateConfirmed) {
                        this.store.dispatch(new CompleteBooking());
                        this.store.dispatch(new Redirect("CART-COMPLETE"));
                    }

                }
            }
            else {
                this.store.dispatch(new Redirect("CART-LOGIN"));
            }
        })
    );

    @Effect({})
    patchBookingContact$ = this.actions$.pipe(
        ofType('PATCH_BOOKING_CONTACT'),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {

            var store = storeState as AppState;
            var cart = store.cart;
            var bk = new Booking();
            bk.id = cart.booking.id;
            bk.salesChannelId = environment.SalesChannel;
            if (cart.contact.client != undefined) {
                bk.contact = new BookingContact();
                bk.contact.id = cart.contact.id;
                bk.confirmed = true;
                bk.temporary = false;
            }
            //bk.entityConfigurationProfileId = +environment.BookingEntityConfigurationProfileId;
            let bookingResult = this.bookingService.BookingPatch(bk.id, bk);
            return bookingResult.pipe(
                map((bookingdata) => {
                    let booking = (bookingdata as Booking);
                    //    this.store.dispatch(new BookingSaveCompleted());
                    return { type: CartActionTypes.UPDATE_CART, payload: booking }
                })
                , catchError((error) => {
                    //  this.store.dispatch(new BookingSaveCompleted());
                    let errorData = [];
                    if (error.status == 500) {
                        if (error.statusText)
                            errorData.push(error.statusText);
                        if (error.Detail)
                            errorData.push(error.Detail);
                    }

                    if (error.error != undefined && error.error.errors != null && error.error.errors.length > 0) {
                        error.error.errors.forEach((error, i) => {
                            if (error.Detail)
                                errorData.push(error.Detail);
                        });
                    }

                    console.log(errorData);
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                    return of(new LogError(error));
                })
            );
        }));


    //navigate user to cart page
    @Effect({ dispatch: false })
    updateFacilitySuccess$ = this.actions$.pipe(
        ofType(CartActionTypes.UPDATE_FACILITY_UPSELL_FLAG),
        map((cart) => {
            console.log('UPDATE_FACILITY_UPSELL_FLAG');
            //    this.store.dispatch(new BookingSaveCompleted());
            this.store.dispatch(new Redirect("CartFromFC"));
        })

    );

    @Effect()
    packageSearch$ = this.actions$.pipe(
        ofType(CartActionTypes.PACKAGE_SEARCH),
        withLatestFrom(this.store),
        switchMap((state) => {
            console.log('PACKAGE_SEARCH');
            var cart = state[1] as ICart;
            //parameter todo
            //ex:event dates

            if (cart.bespokePackages == undefined || cart.bespokePackages.length == 0) {
                var _bookingTypeId = 1;
                var startDte = "";
                var endDate = "";
                return this.packageService.privatePackages(_bookingTypeId, startDte, endDate)
                    .pipe(
                        map((res) => {
                            console.log(res);
                            return { type: CartActionTypes.UPDATE_PACKAGES, payload: res }
                            //return of(new UpdatePackages(res));
                        })
                        , catchError((error) => {
                            var er = new ErrorState();
                            er.action = 'PACKAGE_SEARCH: packageSearch$ ';
                            er.error = error;

                            return of(new LogError(er));
                        })
                    );
            }
        })

    );

    @Effect({ dispatch: false })
    signOut$ = this.actions$.pipe(
        ofType(CartActionTypes.SIGNOUT),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {
            var thisPage = this;
            var store = storeState as AppState;
            var cart = store.cart;
            let facilitySummaryRequest = [];
            let upsellItemRequest = [];
            let facilities: FacilitySummary[] = cart.addedFacilites;
            //   this.store.dispatch(new FacilitySearchLoading());
            if (facilities.length > 0) {
                facilities.forEach((facilitySummary, index) =>
                {
                    FacilityAdaptor.populateItemDetail(facilitySummary.item, facilitySummary.item.itemPriceGroups);
                });

                let cmp = new BookingCommonComponent(this.store, this._itemService, this.snackBar);
                cmp.populateStoreData(cart);
                cmp.calculateCartPrices();
                return of(facilities);
                /*
                let priceConsession = { minConsessionId: environment.ImmediateConfirmation.DefaultPriceConsessionId, maxConsessionId: 0 };
                if (environment.PriceDisplayOption == 3) {
                    let selectedClass = (cart.packageFilter.PackageClass != undefined) ? cart.packageFilter.PackageClass : 1;
                    let selectedPackageClass = environment.PackageClasses.find(a => a.Id == selectedClass);

                    if (selectedPackageClass.PriceMinConsessionId != "" && selectedPackageClass.PriceMaxConsessionId != "") {
                        priceConsession.minConsessionId = selectedPackageClass.PriceMinConsessionId;
                        priceConsession.maxConsessionId = selectedPackageClass.PriceMaxConsessionId;
                    }
                }

                facilities.forEach((facilitySummary, index) => {
                    if (facilitySummary.item) {
                        let item = {
                            index: index,
                            itemId: facilitySummary.item.id,
                            minPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && environment.ImmediateConfirmation.Allow) ? + environment.ImmediateConfirmation.DefaultPriceConsessionId : +priceConsession.minConsessionId,
                            maxPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && environment.ImmediateConfirmation.Allow) ? 0 : + priceConsession.maxConsessionId,
                            quantity: 1,
                            startTime: facilitySummary.startTime,
                            endTime: facilitySummary.endTime
                        };

                        facilitySummaryRequest.push(item);

                        if (facilitySummary.upsellSummaries != undefined && facilitySummary.upsellSummaries.length > 0) {
                            //     let time = 0;
                            facilitySummary.upsellSummaries.forEach(function (us) {
                                if (us.minPrice == undefined || us.maxPrice == undefined || (us.minPrice.priceTypeId != 5 && us.minPrice.priceTypeId != 7 && us.maxPrice.priceTypeId != 5 && us.maxPrice.priceTypeId != 7)) {

                                    let upselItem = {
                                        index: index,
                                        itemId: us.id,
                                        minPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && environment.ImmediateConfirmation.Allow) ? + environment.ImmediateConfirmation.DefaultPriceConsessionId : +priceConsession.minConsessionId,
                                        maxPriceConcessionId: (facilitySummary.item.allowImmediateConfirmedBookings == 1 && environment.ImmediateConfirmation.Allow) ? 0 : + priceConsession.maxConsessionId,
                                        quantity: us.qty,
                                        startTime: Utility.convertToISO(Utility.convertISOToDate(facilitySummary.startTime)),
                                        endTime: Utility.convertToISO(Utility.convertISOToDate(facilitySummary.endTime))
                                    };
                                    upsellItemRequest.push(upselItem);
                                }
                            });
                        }
                    }
                })
                if (facilitySummaryRequest != undefined && facilitySummaryRequest.length > 0) {
                    let itemPrice = this._itemService.GetItemPrices(facilitySummaryRequest);
                    return itemPrice.pipe(
                        map((itemData) => {

                            if (itemData != undefined) {
                                itemData.forEach(data => {
                                    facilities[data.index].minPrice = new ItemPriceGroup;
                                    facilities[data.index].maxPrice = new ItemPriceGroup;

                                    facilities[data.index].minPrice.priceIncludingTax = data.minimumPrice;
                                    facilities[data.index].maxPrice.priceIncludingTax = data.maximumPrice;

                                    if (facilities[data.index].upsellSummaries.length == 0) {
                                        this.store.dispatch(new UpdateFacility(facilities[data.index]));
                                        //  this.store.dispatch(new FacilitySearchLoaded());
                                    }
                                    //Get Upsell Prices
                                    if (upsellItemRequest.length > 0) {
                                        let upsellItemPrice = this._itemService.GetItemPrices(upsellItemRequest);
                                        upsellItemPrice.subscribe(result => {
                                            //   this.store.dispatch(new FacilitySearchLoaded());
                                            if (result != undefined)
                                                result.forEach(data => {
                                                    let facilityItem = JSON.parse(JSON.stringify(facilities[data.index]));
                                                    if (facilityItem.upsellSummaries != undefined && facilityItem.upsellSummaries.length > 0) {
                                                        let UpsellSummary: UpsellSummary = facilityItem.upsellSummaries.filter(upsell => upsell.id == data.itemId);
                                                        let indexnum = facilityItem.upsellSummaries.findIndex(x => x.id == data.itemId);
                                                        if (UpsellSummary != undefined && UpsellSummary != null) {

                                                            UpsellSummary[indexnum].minPrice = new ItemPriceGroup;
                                                            UpsellSummary[indexnum].maxPrice = new ItemPriceGroup;

                                                            UpsellSummary[indexnum].minPrice.priceIncludingTax = data.minimumPrice;
                                                            UpsellSummary[indexnum].maxPrice.priceIncludingTax = data.maximumPrice;
                                                            facilityItem.upsellSummaries[indexnum] = UpsellSummary[indexnum];
                                                            facilities[data.index] = facilityItem;

                                                            this.store.dispatch(new UpdateFacility(facilities[data.index]));
                                                        }
                                                    }
                                                });
                                        });
                                    }

                                });
                            }
                        })
                    );
                }
                */
            } else {
                var er = new ErrorState();
                er.action = 'Facilities not found';
                er.error = "No facilities";

                return of(new LogError(er));
            }
        }));
    @Effect()
    CalculateCartPrice = this.actions$.pipe(
        ofType(CartActionTypes.CALCULATE_CART_PRICES),
        withLatestFrom(this.store),
        switchMap((state) => {
            console.log('PACKAGE_SEARCH');
            var cart = state[1] as ICart;
            //parameter todo
            //ex:event dates

            if (cart.bespokePackages == undefined || cart.bespokePackages.length == 0) {
                var _bookingTypeId = 1;
                var startDte = "";
                var endDate = "";
                return this.packageService.privatePackages(_bookingTypeId, startDte, endDate)
                    .pipe(
                        map((res) => {
                            console.log(res);
                            return { type: CartActionTypes.UPDATE_PACKAGES, payload: res }
                            //return of(new UpdatePackages(res));
                        })
                        , catchError((error) => {
                            var er = new ErrorState();
                            er.action = 'PACKAGE_SEARCH: packageSearch$ ';
                            er.error = error;

                            return of(new LogError(er));
                        })
                    );
            }
        })

    );

}

import { Injectable } from '@angular/core';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import { SystemService } from 'src/modules/services/system.service';

@Injectable()

export class SystemConfig {

    constructor(public systemService: SystemService) { }

    async loadAppConfiguration() {
        if (!environment.ConfigurationLoaded) {
            return await this.systemService.loadConfiguration().toPromise().then(res => {

                environment.LeadDaysForBooking = res.BookingConfig.LeadDaysForBooking;
                environment.DisableDatePickerForLeadDays = res.BookingConfig.DisableDatePickerForLeadDays;
                environment.BookingLeadDayValidationMessage = res.BookingConfig.BookingLeadDayValidationMessage;

                if (res.DateFormat != null && res.DateFormat.length > 0) environment.DateFormat = res.DateFormat;
                if (res.TimeFormat != null && res.TimeFormat.length > 0) environment.TimeFormat = res.TimeFormat;
                if (res.DateTimeFormat != null && res.DateTimeFormat.length > 0) environment.DateTimeFormat = res.DateTimeFormat;

                environment.SalesChannel = res.BookingConfig.SalesChannel;
                environment.Currency = res.BookingConfig.Currency;
                environment.BookingStatus = res.BookingConfig.BookingStatus;
                environment.BookingApproveStatus = res.BookingConfig.BookingApproveStatus;
                environment.BookingExpireStatus = res.BookingConfig.BookingExpireStatus;

                environment.PriceDisplayOption = res.BookingConfig.PriceDisplayOption;
                environment.PriceMinConsessionId = res.BookingConfig.PriceMinConsessionId;
                environment.PriceMaxConsessionId = res.BookingConfig.PriceMaxConsessionId;
                environment.DefaultBussinessAreaId = res.BookingConfig.DefaultBussinessAreaId;
                environment.DefaultPackageClassId = res.BookingConfig.DefaultPackageClassId;
                environment.PriceTypeId = res.BookingConfig.PriceTypeId;
              environment.BookingExpiryInMinutes = res.BookingConfig.BookingExpiryInMinutes;

                //environment.BookingEntityConfigurationProfileId = res.BookingConfig.BookingEntityConfigurationProfileId.toString();
                //environment.PackageEntityConfigurationProfileId = res.BookingConfig.PackageEntityConfigurationProfileId.toString();
                //environment.ItemEntityConfigurationProfileId = res.BookingConfig.ItemEntityConfigurationProfileId.toString();

                environment.GoogleAPIKEY = res.BookingConfig.GoogleAPIKEY;
                environment.PackageClasses = res.BookingConfig.PackageClases;
                environment.MinimumBookingDuration = res.BookingConfig.MinimumBookingDuration;
                environment.BookingSearchDescriptionTextLimitHigh = res.BookingConfig.BookingSearchDescriptionTextLimitHigh
                environment.BookingSearchDescriptionTextLimitMedium = res.BookingConfig.BookingSearchDescriptionTextLimitMedium
                environment.BookingSearchDescriptionTextLimitLow = res.BookingConfig.BookingSearchDescriptionTextLimitLow
                environment.IsDisableInputsInPayment = res.IsDisableInputsInPayment
                environment.AllowToDoManualInvoiceSearch = res.AllowToDoManualInvoiceSearch
                environment.IsDisableInputsInPayment = res.IsDisableInputsInPayment
                environment.PrivacyPolicyLink = res.ClientRegistrationConfig.PrivacyPolicyLink;
                //environment.TermsandConditionLink = res.BookingConfig.TermsandConditionLink;
                environment.ReCaptchaSiteKey = res.ClientRegistrationConfig.ReCaptchaSiteKey;
                environment.DefaultCurrencyId = res.ClientRegistrationConfig.Currency.Id;
                environment.LatestFutureBookingDate = res.BookingConfig.LatestFutureBookingDate;
                environment.LatestFutureBookingDateErrorMessage = res.BookingConfig.LatestFutureBookingDateErrorMessage;
                environment.AllowToDoBookingForNonOperationalHours = (res.BookingConfig.AllowToDoBookingForNonOperationalHours && res.BookingConfig.AllowToDoBookingForNonOperationalHours.toLocaleLowerCase() == 'true');
                environment.BookingTermAndConditionMessage = res.BookingConfig.BookingTermAndConditionMessage;
                environment.CommunityClassAlertMessage = res.BookingConfig.CommunityClassAlertMessage;
                environment.IsBookingSearchByClient = res.BookingConfig.IsBookingSearchByClient;
                environment.FacilityFilters = res.BookingConfig.FacilityFilters;
              environment.BookingRestrictions = res.BookingConfig.BookingRestrictions;
                // Price calculation hours 
                //environment.HalfDayFrom = (+res.BookingConfig.PriceTypeFilters.HalfDayHours.split(',')[0]);
                //environment.HalfDayTo = (+res.BookingConfig.PriceTypeFilters.HalfDayHours.split(',')[1]);
                //environment.FullDayFrom = (+res.BookingConfig.PriceTypeFilters.FullDayHours.split(',')[0]);
                //environment.FullDayTo = (+res.BookingConfig.PriceTypeFilters.FullDayHours.split(',')[1]);
                //environment.SessionFrom = (+res.BookingConfig.PriceTypeFilters.SessionHours.split(',')[0]);
                //environment.SessionTo = (+res.BookingConfig.PriceTypeFilters.SessionHours.split(',')[1]);

                environment.UpsellPrice = res.BookingConfig.UpsellPrice;
                environment.BookingStates = res.BookingConfig.BookingStates;                

                environment.CommunicationTypeValidation = res.ClientRegistrationConfig.CommunicationTypeValidation
                environment.DefaultCountryId = res.ClientRegistrationConfig.DefaultCountryId.Id;
                environment.DefaultCountryName = res.ClientRegistrationConfig.DefaultCountryId.Name;
                environment.AgreedText = res.ClientRegistrationConfig.AgreedText;
                environment.PriceConcessionQuestionText = res.ClientRegistrationConfig.PriceConcessionQuestionText;
                environment.PriceConcessionAnswerOptions = res.ClientRegistrationConfig.PriceConcessionAnswerOptions;
                environment.IsEnableToCreateAdditionalDetailsSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDetailsSectionForIndividualClients;
                environment.IsEnableToCreateAdditionalDetailsSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDetailsSectionForCorporateClients;
                environment.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients;
                environment.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients;
                environment.IsEnableToCreateAdditionalDocumentSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDocumentSectionForIndividualClients;
                environment.IsEnableToCreateAdditionalDocumentSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToCreateAdditionalDocumentSectionForCorporateClients;
                environment.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients;
                environment.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients = res.ClientRegistrationConfig.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients;
                environment.IsPriceConcessionQuestionRequired = res.ClientRegistrationConfig.IsPriceConcessionQuestionRequired;
                environment.EnableWebPortalAccess = res.ClientRegistrationConfig.EnableWebPortalAccess;
                environment.EnableProfileImageUpload = res.ClientRegistrationConfig.EnableProfileImageUpload;

                environment.AutoPopulateUsernameFromEmail = res.ClientRegistrationConfig.AutoPopulateUsernameFromEmail;
                environment.DisableUsernameTextBox = res.ClientRegistrationConfig.DisableUsernameTextBox;

                environment.RequestToAccessReqularBookingHeaderText = res.ClientRegistrationConfig.RequestToAccessReqularBookingHeaderText;
                environment.RequestToAccessReqularBookingAcceptText = res.ClientRegistrationConfig.RequestToAccessReqularBookingAcceptText;
                environment.ReqularBookingAccessRequestHeaderText = res.ClientRegistrationConfig.ReqularBookingAccessRequestHeaderText;
                environment.ReqularBookingAccessRequestSubHeaderText = res.ClientRegistrationConfig.ReqularBookingAccessRequestSubHeaderText;
                environment.ReqularBookingAccessRequestReviewText = res.ClientRegistrationConfig.ReqularBookingAccessRequestReviewText;
                environment.ReqularBookingAccessRequestCompleteText = res.ClientRegistrationConfig.ReqularBookingAccessRequestCompleteText;
                environment.ProfileImageHeaderTextForIndividualClients = res.ClientRegistrationConfig.ProfileImageHeaderTextForIndividualClients;
                environment.ProfileImageHeaderTextForCorporateClients = res.ClientRegistrationConfig.ProfileImageHeaderTextForCorporateClients;

                environment.IsBookingSearchByClient = res.BookingConfig.IsBookingSearchByClient;
                environment.IsGroupByFacilityNameInBookingView = res.BookingConfig.IsGroupByFacilityNameInBookingView;            
                if (res.BookingConfig.Messages != undefined && res.BookingConfig.Messages != null) {
                    environment.Messages.BookingDetailsPage = res.BookingConfig.Messages.BookingDetailsPage;
                    environment.Messages.BookingCartPageTopNavPannel = res.BookingConfig.Messages.BookingCartPageTopNavPannel;
                    environment.InsufficientSelectedTime = res.BookingConfig.Messages.InsufficientSelectedTime.replace("#LeadDays#", environment.LeadDaysForBooking.toString());
                }
                environment.IsAllowMultiplePriceConsession = (res.BookingConfig.IsAllowMultiplePriceConsession && res.BookingConfig.IsAllowMultiplePriceConsession.toLocaleLowerCase() == 'true');
                environment.IsShowSetupTimeInCalendar = (res.BookingConfig.IsShowSetupTimeInCalendar && res.BookingConfig.IsShowSetupTimeInCalendar.toLocaleLowerCase() == 'true');

                if (res.BookingConfig.ImmediateConfirmation != undefined && res.BookingConfig.ImmediateConfirmation != null) {
                    environment.ImmediateConfirmation = res.BookingConfig.ImmediateConfirmation;
                }
                environment.ConfigurationLoaded = true;
                environment.ExternalIntegrationIdForPaymentGateway = res.ExternalIntegrationIdForPaymentGateway;

                environment.ConfigurationSelection.Enabled = res.BookingConfig.ConfigurationSelection.Enabled;
                environment.ConfigurationSelection.DisplayMode = res.BookingConfig.ConfigurationSelection.DisplayMode;
                environment.CheckAvailability.EnableAutoAvailabilityCheck = res.BookingConfig.CheckAvailability.EnableAutoAvailabilityCheck;
                environment.CheckAvailability.EnableTimeLineAdvancedMode = res.BookingConfig.CheckAvailability.EnableTimeLineAdvancedMode;
                environment.CheckAvailability.TimeLineColors = res.BookingConfig.CheckAvailability.TimeLineColors;
                environment.CheckAvailability.DisplayPreliminarySlotAsUnavailble = res.BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble;

                environment.ConfigurationSelection.DisplayBumpInOutNotification = res.BookingConfig.ConfigurationSelection.DisplayBumpInOutNotification;
                environment.ForgetPasswordPlaceHolder = res.ClientRegistrationConfig.ForgetPasswordPlaceHolder;
                environment.ForgetPasswordMessage = res.ClientRegistrationConfig.ForgetPasswordMessage;
                environment.ForgotPasswordLink  = res.ClientRegistrationConfig.ForgotPasswordLink ;
                              
                environment.PriceConcessions = res.BookingConfig.PriceConcessions;
                environment.ShowMapView = res.ShowMapView;
                environment.AppSetting = res;
                environment.SortOptions = res.BookingConfig.SortOptions;
                environment.SubmitEnquiryMessage = res.SubmitEnquiryConfig.Message;
                environment.ExternalIntegrationIdForAddress = res.ExternalIntegrationIdForAddress;
            });
        }
    }
    async loadRegularBookingConfiguration() {
        if (!environment.ConfigurationLoaded) {
            return await this.systemService.loadRegularBookingConfiguration().toPromise().then(res => {

                environment.RegularBookingSetting = res;
            })
        }
    }
}

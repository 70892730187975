﻿import { environment } from 'src/environments/environment';
import { BaseParam } from '../base-param';


export class VenueAssetsearchparams extends BaseParam {
    searchpharese: string;
    assetclass: number = 1;
    activeStatus: number = 1;
    salesChannelId: number = environment.SalesChannel;
    assetname: string = "";
    considerOpeningHours: boolean;
    listVenuids: string;
    SalesCategoryId: string;
    From: number;
    To: number;
    assetTypeIds: string;
    assetIds: string;
    page: Page;
    priceConcessionIds: string;
    bookingTypeIds: string;
  //  page.size = 1000 & page.number=1

    geturlforsearch() {

        var pageSize = 0;
        var pageNumber = 0;
        if (this.page) {
            pageSize = this.page.size;
            pageNumber = this.page.number;
        }

        var url;
        var filters;
        var includes = "&include=venue,Facility.Amenities,Facility.configurations,LinkedAssets";

        filters =
            "capacityFrom=" + this.From +
            "&capacityTo=" + this.To +
            "&VenueId=" + this.listVenuids +
            "&assetTypeIds=" + this.assetTypeIds +
            "&assetIds=" + this.assetIds +
            "&SalesCategoryId=" + this.SalesCategoryId +
            "&activeStatus=" + this.activeStatus +
            "&saleschannelId=" + this.salesChannelId +
            "&page.size=" + pageSize +
            "&page.number=" + pageNumber;

        if (this.searchpharese !== "") {

            filters = "SearchText=" + this.searchpharese + "&" + filters;

        }

        if (this.bookingTypeIds != "0" && this.bookingTypeIds != undefined && this.bookingTypeIds != "") {
            filters = filters + "&bookingTypeIds=" + this.bookingTypeIds;
        }

        if (this.priceConcessionIds) {
            filters = filters + "&PriceConcessionIds=" + this.priceConcessionIds;
        }

        url = filters + includes;

        return url;
    }


}

export class Page {
    size: number = 0;
    number: number = 0;
}
﻿import { BaseResource } from 'src/modules/models/base-resource';


export class TimeSlot extends BaseResource {

   
    startTime: Date;
    endTime: Date;
    preliminary: boolean;
    reserved: boolean;
}
﻿
import { Component, OnInit, Input, Inject, Output, ViewChild, EventEmitter } from '@angular/core';

//store
import { Store, SaveSeason, LoadBooking, IRegularBooking, SpinnerLoaded, SpinnerLoading } from 'src/modules/store/regular-booking/index';
//common componennt
import { BookingCommonComponent } from 'src/modules/regular-booking/booking-common.component';


//route
import { Router, ActivatedRoute, Params } from '@angular/router';
//services
import { RegualrBookingService } from 'src/modules/services/regular-booking.service';
import { AssetService } from 'src/modules/services/asset.service';
import { PackageService } from 'src/modules/services/package.service';

//model
import { Booking } from 'src/modules/models/regular-portal/booking/booking';

@Component({
    selector: 'opt-wp-booking-rollover',
    templateUrl: './wp-booking-rollover.html',
  
})

export class WPBoookingRolloverComponent extends BookingCommonComponent implements OnInit{
    $bookingSelectRollover = new EventEmitter();

    constructor(_store: Store<IRegularBooking>, private _route: ActivatedRoute, private _regBookingService: RegualrBookingService, public _packageService: PackageService,
    public _assetService: AssetService) {
        super(_store, _route, _regBookingService, _assetService, _packageService);
    }

    ngOnInit() {
        super.ngOnInit();

        //this.RolloverBookingLoading()
     
        //this.$bookingSelectRollover.safeSubscribe(this, (result) => {
        //    if (result) {
        //        this.StoreRollOverBooking(result);
        //    }
        //});

        //load Store booking

    }

    RolloverBookingLoading() {
        const _rolloverBookingId = this._route.snapshot.queryParams["rollOverBookingId"];
        if (_rolloverBookingId) {
            console.log(_rolloverBookingId);
            this.LoadBooking(_rolloverBookingId, this.$bookingSelectRollover,false);
        }
    }

    StoreRollOverBooking(booking: Booking) {
        let rollOverBooking = new Booking();
      //  rollOverBooking = booking;
        booking.id = '-1';
        //setup bookingpackage id as -1
        booking.bookingPackages.forEach((bkpacakge, bPIndex) => {
            if (bkpacakge.id != '-1')
                bkpacakge.id = '-1';
           
        })

    }


    ReciveSeasonChanges(event) {

    }


    navigateNextStepper(event) {

    }
}
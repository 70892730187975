import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { BookingCommonComponent } from 'src/modules/regular-booking/booking-common.component';
import { environment } from 'src/environments/environment';
import { RegularBookingSettings } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
import { Store, SaveSeason, LoadBooking, IRegularBooking } from 'src/modules/store/regular-booking/index';
import { Observable } from "rxjs";
import { Booking } from 'src/modules/models/regular-portal/booking/booking';
import { RegualrBookingService } from 'src/modules/services/regular-booking.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AssetService } from 'src/modules/services/asset.service';
import { PackageService } from 'src/modules/services/package.service';
import { BookingService } from 'src/modules/services/booking.service';

///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateFormat } from 'src/modules/date-format';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
const moment = _rollupMoment || _moment;

@Component({
    selector: 'opt-wp-seasons-carousel',
    templateUrl: './wp-seasons-carousel.component.html',
    styleUrls: ['./wp-seasons-carousel.component.scss']
})


export class WpSeasonsCarouselComponent extends BookingCommonComponent implements OnInit {
    caraousalData: any;

    @Input() inputSeasonId: number;
    ////////////////////////////////////////////
    @Input() inputBookingTypeId: number;
    ////////////////
    @Output() emitChangeSeason = new EventEmitter<any>();

    setupNameForBooking: string;

    // seasonIdValue: number;
    showErrorMessage: boolean = false;
    displayMessage: string;

    disabledCarousal: boolean = false;
    //  showRolloverTeams: boolean = false;

    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
        load: 10,
        //interval: { timing: 4000, initialDelay: 1000 },
        loop: true,
        touch: true,
        velocity: 0.2,
        point: {
            visible: true,
            hideOnSingleSlide: true
        }
    }
    carouselItems = [];

    constructor(_store: Store<IRegularBooking>, private _route: ActivatedRoute, public _packageService: PackageService, private _regBookingService: RegualrBookingService,
        public _assetService: AssetService, private _bookingService: BookingService) {


        super(_store, _route, _regBookingService, _assetService, _packageService);


        this.rbStore$.safeSubscribe(this, cart => {
            if (cart != undefined) {
                
                var openBookingId = this._route.snapshot.queryParams["OpenBooking"];
                if (cart.BookingType == null && !openBookingId) {


                    this.showErrorMessage = true;
                    this.displayMessage = 'Please select a booking type';

                } else {
                    //this.bookingTypeId = cart.BookingType.id
                }


                //if (cart.BookingType && cart.BookingType != null) {
                //    this.bookingTypeId = cart.BookingType.id
                //}

            }
        });

        //this.isSeasonSelected = this.rolloverSeasonId != undefined ? false : true;
    }
    //constructor(private _store: Store<IRegularBooking>) {

    //  //  this.caraousalData = environment.RegularBooking;
    //    this.seasonId_subscriber = this._store.select('rbStore').subscribe(data => {
    //        this.seasonIdValue = data.seasonId;

    //    })

    //}

    ngOnInit() {
        super.ngOnInit();
        // this.seasonIdValue = super.returnSeasonId();
        //if (this.rollOverBookingTypeId != undefined) {

        //    this.bookingTypeId = this.rollOverBookingTypeId;


        //}

        var seasonalArray = [];
        ///var _seasonAdded = false;

        if (environment.RegularBookingSetting != undefined) {
            var caraousalData = (environment.RegularBookingSetting as RegularBookingSettings)
            //find the selected booking ID

            //if (caraousalData != undefined && this.bookingTypeId != undefined) {
            if (caraousalData != undefined && this.inputBookingTypeId != undefined) {
                var findBookingType = caraousalData.booking.bookingTypes.find(x => x.id == String(this.inputBookingTypeId));
                this.setupNameForBooking = (findBookingType.name == 'season') ? this.setupNameForBooking = 'season' : this.setupNameForBooking = 'Booking term';
                var currentDate = moment().format('YYYY-MM-DD');
                findBookingType.seasons.forEach((season, index) => {
                    var _seasonAdded = false;
                    if (this.inputSeasonId) {
                        //If there is booking

                        if (Number(season.id) == Number(this.inputSeasonId)) {
                            this._store.dispatch(new SaveSeason(Number(season.id)));
                            _seasonAdded = true;
                            this.carouselItems.push(season);
                        }
                    };
                    if (!_seasonAdded) {

                        if (season.cutoffDate != "" && season.cutoffDate != undefined) {
                            if (new Date(currentDate) <= new Date(season.cutoffDate)) {           
                                    this.carouselItems.push(season);
                            }
                        } else {
                            if (new Date(currentDate) <= new Date(season.to)) {
                                    this.carouselItems.push(season);
                            }
                        }

                    }
                })
                if (this.carouselItems.length == 1) { // is one season is 
                    this.emitChangeSeason.emit(true);
                    this._store.dispatch(new SaveSeason(Number(this.carouselItems[0].id)));


                }
                //If there is booking
                //if (this.booking && this.booking.seasonId) {
                //    if (caraousalData != undefined && this.booking.bookingTypeId != undefined) {
                //        let _findBookingType = caraousalData.booking.bookingTypes.find(bkType => bkType.id == String(this.booking.bookingTypeId));
                //        if (_findBookingType) {
                //            const _findSeason = _findBookingType.seasons.find(s => s.id == String(this.booking.seasonId));
                //            if (_findSeason && _findSeason.id)
                //            this._store.dispatch(new SaveSeason(Number(_findSeason.id)));
                //        }
                //    }
                //}
                //this.carouselItems.push()
                // console.log(seasonalArray);




            }
        }


    }


    //ngAfterViewInit() {
    //    let buttons = document.querySelectorAll('.left-nav-icon,.right-nav-icon');

    //    if (buttons) {
    //        console.log('dsd')
    //    }
    //}

    isCutoffDateAvailable(item) {

        if (item.cutoffDate == undefined || item.cutoffDate == "") {
            return false;
        }

        return true;

    }

    storeBookingId(item) {

        var _seasonId = Number(item.id);
        //if (this.carouselItems.length == 1) {
        //    _seasonId = Number(this.carouselItems[0].id)
        //}
        if (this.booking != null) {

            var _findItemAddedPackage = this.booking.bookingPackages.find(bk => bk.bookingVenues.length > 0);
            if (_findItemAddedPackage) {

            } else {
                if (!this.booking.bookingPackages) {
                    this._store.dispatch(new SaveSeason(_seasonId));
                }
                //this.emitChangeSeason.emit(true);
            }
        } else {
            this._store.dispatch(new SaveSeason(_seasonId));
            this.emitChangeSeason.emit(_seasonId);
        }
        //rolloverPop
        //if (this.inputSeasonId != undefined && this.booking == undefined) {
        //    this.inputSeasonId = _seasonId;
        //    this.emitChangeSeason.emit(this.inputSeasonId);
        //}
        this.checkSeasonId(item);




    }

    checkSeasonId(item) {

        if (this.carouselItems.length == 1) { // is one season is 


            this._store.dispatch(new SaveSeason(Number(this.carouselItems[0].id)));
            return true;
        }
        if (Number(item.id) == this.seasonId) {
            return true;
        }
        return false;
    }


    displaySeasonDate(selectedDate) {
        if (selectedDate != undefined) {
            var seasonDate = this.convertSeasonDateToDate(selectedDate);

            return seasonDate;
        }


    }

    get checkCaraousalHide() {
        if (this.carouselItems && this.carouselItems.length > 3) {
            return false
        }
        return true;

    }

    checkSeasonSelection(item) {
        if (item && item.id && this.inputSeasonId && item.id.toString() == this.inputSeasonId.toString()) {
            return true;
        }
        return false;
    }
}

﻿import { BaseResource } from 'src/modules/models/base-resource';
import { Questionnaire } from '../question/questionnaire';
import { BookingQuestion } from '../question/booking-question';
import { BookingPackage } from '../booking-package';
import { BookingItems } from 'src/modules/models/booking/booking-items';

//export class BookingQuestionnaire extends BaseResource {

//questionnaire: Questionnaire;
//bookingQuestions: BookingQuestion[];
//bookingPackage: BookingPackage;
//bookingItem: BookingItems;
//}

export class GlobalBookingQuestionnaire //extends BookingQuestionnaire
{
    questionsTobeDisplay: BookingQuestion[];
}
export class PackageBookingQuestionnaire {
    packageId: string;
    packageName: string;
    isHaveMandatoryQuestion: boolean;
    type: string;
    questionsTobeDisplay: BookingQuestion[];
}
//export class ItemBookingQuestionnaire extends BookingQuestionnaire {
export class ItemBookingQuestionnaire{
    
    itemName: string;
    itemId: string;
    isHaveMandatoryQuestion: boolean;
    answerWisedBookingQuestionnaireGroups: AnswerWisedBookingQuestionnaire[];
}

export class AnswerWisedBookingQuestionnaire {
    dates: DateWiseBookingQuestion[];
    questionsTobeDisplay: BookingQuestion[];
}

export class DateWiseBookingQuestion
{
    startTime:Date
    endTime :Date
    //actual list of questions link with virtual item
    actualQuestionTobePatch: BookingQuestion//this is what need to be patch
        
}


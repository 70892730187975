﻿import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpRequest, HttpHandler, HttpEvent,
    HttpInterceptor,
    HttpResponse, HttpErrorResponse, HttpEventType, HTTP_INTERCEPTORS
} from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, tap, mergeMap } from 'rxjs/operators';
import { Store, ICart, AddFacility, PackageSearch } from 'src/modules/store/index';
import { FacilitySearchLoading, FacilitySearchLoaded, BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch'
@Injectable()
export class APIRequestHandler implements HttpInterceptor {
    requestCounter: number = 0;
    constructor(private _http: HttpClient, private store: Store<any>, ) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCounter++;
        if (this.requestCounter === 1) {
            setTimeout(() => { this.store.dispatch(new FacilitySearchLoading()); });

        }

        request = request.clone({
            setHeaders: {
                'Token': environment.Token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

                this.requestCounter--;
                if (this.requestCounter == 0)
                    setTimeout(() => { this.store.dispatch(new FacilitySearchLoaded()); });
            }
            return event;
        }, ((err: any) => {
            this.requestCounter--;
            if (this.requestCounter == 0) { setTimeout(() => { this.store.dispatch(new FacilitySearchLoaded()); }); }

            return (err);

        }), () => {

        }), catchError((err) => {
            this.requestCounter--;
            if (this.requestCounter == 0) { setTimeout(() => { this.store.dispatch(new FacilitySearchLoaded()); }); }
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {

                    return this._http.get((environment.ApiUrl + "/api/system/renew-token?token")).pipe(mergeMap(res => {
                        environment.Token = res.toString();
                        request = request.clone({
                            setHeaders: {
                                'Token': res.toString(),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        });
                        return next.handle(request);
                    }));
                } else {
                    return throwError(err);
                }

            } else {
                return throwError(err);
            }
        })
        );

    }

}

export const OptHttpProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: APIRequestHandler,
    multi: true
};

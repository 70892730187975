import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'opt-modal-content',
  templateUrl: './modal-content.component.html'
})

export class ModalContentComponent {
    constructor( public matDialogRef: MatDialogRef<ModalContentComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

    public close() {
        this.matDialogRef.close();
    }
}

﻿import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { LoadingActionTypes, LoadingActions } from 'src/modules/store/loading/actions'

export let initialState: ILoadingStatus = 
{
    isLoading_facilitySearch: false,
    isLoading_bookingSave: false,
    isLoading: false
};

export function statusReducer(state = initialState, action: LoadingActions) {
    switch (action.type) {
        case LoadingActionTypes.FACILITY_SEARCH_LOADING:
            return { ...state, isLoading_facilitySearch: true };
        case LoadingActionTypes.FACILITY_SEARCH_LOADED:
            return { ...state, isLoading_facilitySearch: false };

        case LoadingActionTypes.BOOKING_SAVE_STARTED:
            return { ...state, isLoading_bookingSave: true };
        case LoadingActionTypes.BOOKING_SAVE_COMPLETED:
            return { ...state, isLoading_bookingSave: false };

        case LoadingActionTypes.LOADING:
            return { ...state, isLoading: true };
        case LoadingActionTypes.LOADED:
            return { ...state, isLoading: false };

        default:
            return state;
    }
}
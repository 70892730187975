﻿export class VenueWiseAssets {


    id: number;
    name: string;
    List: Asset[]

}


export class Asset {
    id: number;
    name: string;
    //capacity: number;
    //facilityType: string;
}


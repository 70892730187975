import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material';

import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { Login } from './../../../models/login/login';
import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { CustomerService } from '../../../services/customer.service';
import { ItemService } from 'src/modules/services/item.service';

import { Utility } from 'src/modules/utility';
import { Store, ICart, SetContact, Redirect, PatchBookingContact, DoBooking} from '../../../store/index';
import { FacilitySearchLoaded } from 'src/modules/store/loading/actions';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
/** Raise error when invalid control is dirty, touched, or submitted. */
export class StateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../../../../sass/pages/_login.scss']
})

export class LoginComponent extends BookingCommonComponent implements OnInit {
    @Input() isFromBooking: string
    ApiUrl: string;
    RegularBookingPortalUrl: string;
    matcher = new StateMatcher();
    frmLogin: FormGroup;
    login: Login;
    errorMessage: string;
    directLoginFromBooking: boolean = false;
    cart$: Observable<ICart>;
   // cart_subscriber: any;
    isSystemAllowedImmediateConfirmation: boolean;
    systemDefaultPriceConsessionId: number;
   // customerDefaultPriceConsessionId: number;
   // isAllImmediateConfirmed: boolean;
    loader$: Observable<ILoadingStatus>;
    store_BookingType: any ;
    bookingTypeArray:any;
    regularBooking: boolean = false;
    displayForgotUserNameLink: boolean;
    ForgotPasswordLink : string;

    constructor(private store: Store<any>, private _customerService: CustomerService,
                private router: Router,
                private route: ActivatedRoute, private formBuilder: FormBuilder,
                _itemService: ItemService,
                snackBar: MatSnackBar
                )
    {
        super(store, _itemService,snackBar); 
        this.login = new Login();
        //this.cart$ = this.store.select('cart');
        this.isSystemAllowedImmediateConfirmation = false;
        this.systemDefaultPriceConsessionId = 1;
        this.customerDefaultPriceConsessionId = 0;
        this.bookingTypeArray = [];
        this.isAllImmediateConfirmed = false;        
        this.ApiUrl = environment.ApiUrl;
        this.RegularBookingPortalUrl = environment.RegularBookingPortalUrl;

        var appsetting = environment.AppSetting as AppSetting;
        this.displayForgotUserNameLink = appsetting.ClientRegistrationConfig.DisplayForgotUserNameLink;
        this.ForgotPasswordLink = appsetting.ClientRegistrationConfig.ForgotPasswordLink;
    }

    ngOnInit() {
        super.ngOnInit();
        this.route.queryParamMap.subscribe(params => {
            if (params.get("DirectLoginFromBooking") == "1") {
                this.directLoginFromBooking = true;
            }
            if (params.get("regularBooking") == "1") {
                this.regularBooking = true;
            }
            
        });
      //  this.store.dispatch(new FacilitySearchLoaded());

        this.frmLogin = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.errorMessage = '';

        //this.cart$.safeSubscribe(this,state => {
        //    this.customerDefaultPriceConsessionId = state.contact.client.priceConcessionId;
        //    this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        //    console.log("customerDefaultPriceConsessionId LOGIN ", this.customerDefaultPriceConsessionId);

        //});
    }

    onSubmit() {
        if (!this.frmLogin.invalid)
        {
            this.login.Email = this.frmLogin.controls.username.value;
            this.login.Password = this.frmLogin.controls.password.value;
           
            this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
            this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;

          this._customerService.clientLogin(this.login).subscribe(data => {
            this.store.dispatch(new SetContact(data));
            this.customerDefaultPriceConsessionId = data.client.priceConcessionId;
            let priceChanged = false;

            //if (this.regularBooking && data.bookingTypeList != undefined
            //    && data.bookingTypeList.filter(x => x.id != "1")
            //  && data.bookingTypeList.filter(x => x.id != "1").length > 0 && environment.PortalType == "3") 
            //{

            // if portal setup it as regular Portal Only
            if (environment.PortalType == "3" || this.regularBooking) {
              if (((data.bookingTypeList != undefined 
                && data.bookingTypeList.filter(x => x.id != "1")
                && data.bookingTypeList.filter(x => x.id != "1").length > 0))) {
                this.store.dispatch(new Redirect("MyBooking"));
              } else {
                this.frmLogin.controls.password.setValue('');
                this.errorMessage = 'User does not have permission to access to the regular-portal';
                setTimeout(() => this.store.dispatch(new Redirect("MyProfile")), 1000);
              }
              return;
            }

            if ((this.customerDefaultPriceConsessionId && this.customerDefaultPriceConsessionId > 0) &&
              (!this.isAllImmediateConfirmed || this.customerDefaultPriceConsessionId !== this.systemDefaultPriceConsessionId)
            ) {
              priceChanged = true;
              this.calculateCartPrices();
            }

            // checking the URL History for Casual portal Only or Both (PortalType not setup means Both)

            if (this.directLoginFromBooking || this.isFromBooking == "1" && (environment.PortalType == "1" || (!environment.PortalType))) {

              if (priceChanged) {
                this.store.dispatch(new Redirect("CART-PRICECHANGE"));
              } else {
                if (this.directLoginFromBooking)
                  this.store.dispatch(new Redirect("CART"));
                else
                  this.store.dispatch(new DoBooking());
              }
              return;
            } else if (Utility.urlHistorty.length > 0) {
              var link = Utility.urlHistorty[Utility.urlHistorty.length - 1];
              if (link != "/client/setup%3FfromLogin%3D1") {
                var qry = { queryParams: Utility.sortParams(link) };
                this.router.navigate([`${link.split('?')[0]}`], qry)
                return;
              }
            }

            // if there is No history and Not from Cart Page blow logic will execute 
            else if (environment.PortalType == "1") {
              this.store.dispatch(new Redirect("FACILITY"));
              return;
            } else if (!environment.PortalType) {

              // if user have RB access 
              if (((data.bookingTypeList != undefined
                && data.bookingTypeList.filter(x => x.id != "1")
                && data.bookingTypeList.filter(x => x.id != "1").length > 0))) {
                this.store.dispatch(new Redirect("MyBooking"));
                return;
              }
              // portal type not defined  and User does not have access for Regular booking 
              else  {
                this.store.dispatch(new Redirect("FACILITY"));
                return;
              }

            }
            // this will redirect to My booking page when portal type is not defined and History is not there and User have Both access (regular and casual  ) 
            this.store.dispatch(new Redirect("MyBooking"));
          },
            err => {
              this.frmLogin.controls.password.setValue('');
              this.errorMessage = 'Invalid Credentials';
            },
            () => { }
          );

        } else {
            this.frmLogin.controls.password.setValue('');
            this.errorMessage = 'Invalid Credentials';
        }
    }
  
    signupClick() {
        if (this.directLoginFromBooking) {
            this.store.dispatch(new Redirect("SIGNUPFROMBOOKINGDETAIL"));
        }
        else if (this.isFromBooking == "1") {
            this.store.dispatch(new Redirect("CART-SIGNUP"));
        } else {
            this.store.dispatch(new Redirect("SIGNUP"));
        }

        window.scrollTo(0, 0);

    }

    
}

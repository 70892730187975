import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment'

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Deserializer, Serializer } from 'json-api-format';

import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { BaseParam } from '../models/base-param';
import { Item } from '../models/item/item'
import { QuestionSummary } from 'src/modules/models/booking/question-summary';
import { Question } from '../models/item/question'
import { ItemSearchParams } from '../models/item/item-search-params'

@Injectable({
    providedIn: 'root'
})

export class ItemService {

    constructor(private _baseService: BaseService) {

    }
    private getPortalConcessions(): any[]
    {
        let portalConcessions = [];    
        if (environment.AppSetting) {
            var appSett = environment.AppSetting as AppSetting;
            if (appSett.BookingConfig && appSett.BookingConfig.PriceConcessions) {
                appSett.BookingConfig.PriceConcessions.forEach(pc => { portalConcessions.push(pc.Id) });
            }
        }
        return portalConcessions;  
    }
    itemSearch(baseParam: ItemSearchParams): Observable<Item[]> {
        return this._baseService.WebAPIGet("api/v4.1/products/items?" + baseParam.geturlforsearch()).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("Deserialized web api output item");
                console.log(json);
                console.log("Convert to object");
                var lst: Item[] = [];

                var portalConcessions = this.getPortalConcessions();              
               
                json.forEach((c, i) => {                  
                    var item = (c as Item);
                    let salesChannel = c.itemSalesChannels.filter(isc => isc.salesChannel && isc.salesChannel.id=="5" && isc.allowImmediateConfirmedBookings == 1);
                    item.allowImmediateConfirmedBookings =  (salesChannel.length > 0)? 1: 0;
                    item.question = c.itemQuestion;
                    if (c.itemQuestion) {
                      item.question = c.itemQuestion.filter(iq => iq.bookingStatusId == environment.BookingStatus || (item.allowImmediateConfirmedBookings && String(environment.BookingApproveStatus) == iq.bookingStatusId ));
                    }
                    if (c.itemPriceGroups)
                        item.itemPriceGroups = c.itemPriceGroups.filter(ipg => portalConcessions.indexOf(ipg.concessionId.toString()) >= 0);
                    else
                        item.itemPriceGroups = [];
                    lst.push(item);
                });
                return lst
            })
        );
    }

    GetUpsells(SalesCategoryIds: string, SearchKey: string, FromDate: string, ToDate: string, PageNumber: number, PageSize: number, selectedItemType: number,itemName:string): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/assets/GetUpsells?SalesCategoryIds=" + SalesCategoryIds + "&SearchKey=" + SearchKey + "&FromDate=" + FromDate + "&ToDate=" + ToDate + "&PageNumber=" + PageNumber + "&PageSize=" + PageSize + "&ItemType=" + selectedItemType.toString() +"&itemName="+itemName).pipe(
            map((response: any) => {
                var json = response.Data;
                var portalConcessions = this.getPortalConcessions();         
                if (json)
                {
                  json.forEach(itm => {
                        if (itm.itemQuestion) {
                          itm.question = itm.itemQuestion.filter(iq => iq.bookingStatusId == environment.BookingStatus);
                        }
                        if (itm.ItemPriceGroups)
                            itm.ItemPriceGroups = itm.ItemPriceGroups.filter(ipg => portalConcessions.indexOf(ipg.ConcessionId.toString()) >= 0);
                        else
                            itm.ItemPriceGroups = [];
                    });
                }
                return { Data: json, Meta: { TotalResults: response.Meta.TotalResults } };
            })
        );
    }

    GetItemCategories(): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/packages/item-categories").pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    GetItemPrices(data:any): Observable<any> {
        return this._baseService.InternalWebAPIPOST("api/assets/calculated-prices",data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    CalculateItemPrice(itemId: string, priceConsessionId: string, qty: number, startTime: string, endTime: string) {
        return this._baseService.WebAPIGet("api/v4.1/products/items/" + itemId + "/calculate-prices?include=calculatedItempricegroups&Quantity=" + qty + "&PriceConcessionId=" + priceConsessionId + "&StartTime=" + startTime + "&EndTime=" + endTime).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("Deserialized web api output CalculateItemPrice");
                console.log(json);
                console.log("Convert to object");
                // this.testFunction();
                return json;
            })
        );
    }

    PostQuestions(_Question: Question[]) {
        console.log("PostQuestion");
        var json = new Serializer().serialize(_Question);
        console.log(json);
        var obj = new Deserializer().deserialize(json);
        let result = this._baseService.Internal_WebAPI_POST("api/booking/questions", obj);

        return result;
    }  

    PostQuestionAndAnswer(questionSummaries:QuestionSummary[], bookingID: string) {
        return this._baseService.Internal_WebAPI_POST("api/booking/answer-post/" + bookingID, questionSummaries).subscribe();
    }
}

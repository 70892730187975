
import { Injectable } from '@angular/core';

import { BaseService } from './base/base.service';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { BaseParam } from '../models/base-param';
import { BookingPackages } from '../models/booking/booking-packages';
import { BookingItems } from '../models/booking/booking-items';

import { BespokePackage } from '../models/asset/bespoke-package';
import { ReferenceData } from 'src/modules/models/reference-data';
import { BusinessArea } from 'src/modules/models/booking/package';
import { Booking } from 'src/modules/models/regular-portal/booking/booking';


@Injectable({
    providedIn: 'root'
})
export class PackageService {

    constructor(private _baseService: BaseService) {

    }

    //todo: parameter
    PackageSearch(baseParam: BaseParam): Observable<BookingPackages> {
        return this._baseService.WebAPIGet("api/v4.1/products/private-packages" + baseParam.fields).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                var bookingPackages = new BookingPackages();
                bookingPackages.tid = "1";
                if (json instanceof Array && (json.length > 0)) {
                    bookingPackages.package.id = json[0].id;
                    bookingPackages.package.name = json[0].name;
                    bookingPackages.priceTypeId = json[0].priceTypeId;
                    bookingPackages.quantity = "1";
                    if (json[0].privateEvent != null && json[0].privateEvent != undefined) {
                        bookingPackages.event.id = json[0].privateEvent.id;
                    }

                    if (json[0].packageItems != null && json[0].packageItems != undefined && json[0].packageItems.length > 0) {
                        var bookingItem = new BookingItems();
                        bookingItem.tid = "1";
                        bookingItem.priceTypeId = json[0].priceTypeId;
                        bookingItem.item.id = json[0].packageItems[0].id;
                        bookingItem.item.type = json[0].packageItems[0].type;
                        bookingItem.name = json[0].packageItems[0].name;
                        bookingItem.quantity = 1;
                        bookingPackages.bookingItems.push(bookingItem);
                    }
                }
                return bookingPackages;
            })
        );
    }

    //todo: parameter
    privatePackages(bookingTypeId ,seasonStartDate , seasonEndDate): Observable<BespokePackage[]> {
        return this._baseService.InternalWebAPIGET("api/packages/private-packages?bookingTypeId=" + bookingTypeId + "&seasonStartDate=" + seasonStartDate + "&seasonEndDate=" + seasonEndDate).pipe(
            map((response) => {
                console.log(response);
                var lst: BespokePackage[] = [];;
                (response as any[]).forEach((c, i) => {
                    var pk = new BespokePackage();

                    pk.id = c.id;
                    pk.name = c.name;
                    pk.eventId = c.privateEvent.id;
                    pk.businessArea = c.businessArea;
                    pk.businessAreaId = c.businessAreaId;
                    pk.packageClassId = c.packageClassId;
                    pk.question = c.packageQuestions;
                    if (c.packageQuestions) {
                        pk.question = c.packageQuestions.filter(pq => !pq.bookingTypes || pq.bookingTypes.length == 0 || pq.bookingTypes.find(a => a.id == bookingTypeId) != null);
                    }
                    pk.price = c.fixedPriceIncludingTax;
                    pk.priceTypeId = c.priceTypeId;
                    pk.taxId = c.taxId
                    if (c.salesCategories != undefined) {
                        var arr = (c.salesCategories as Array<any>)
                        pk.salesCategories = [];
                        arr.forEach(s => {
                            pk.salesCategories.push(s.id)
                        });

                    }
                    lst.push((pk));
                });
                return lst;
            })
        );
    }

    packageClassesSearch(): Observable<ReferenceData[]> {
        return this._baseService.WebAPIGet("/api/V4.1/products/package-classes").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("Deserialized web api output");
                console.log(json);
                console.log("Convert to object");
                var lst: ReferenceData[] = [];
                json.forEach((c, i) => {
                    let refData = new ReferenceData();
                    refData.Id = c.id;
                    refData.Name = c.name;
                    lst.push(refData);
                });
                return lst
            })
        );
    }

    packageBusinessAreaSearch(): Observable<BusinessArea[]> {
        return this._baseService.WebAPIGet("/api/V4.1/products/business-areas").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                var lst: BusinessArea[] = [];
                json.forEach((c, i) => {
                    let businessArea = new BusinessArea();
                    businessArea.id = c.id;
                    businessArea.name = c.name;
                    businessArea.billerCode = c.billerCode;
                    lst.push(businessArea);
                });
                return lst
            })
        );
    }


    //RegularBookingSelect(id: string): Observable<Booking> {

    //    return this._baseService.InternalWebAPIGET("api/booking/" + id).pipe(
    //        map((response) => {
    //            return response;
    //        })
    //    );
    //}
}


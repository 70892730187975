﻿import { BaseResource } from '../base-resource';
import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';

export class RecurrentTimeSlots extends BaseResource {
    id: string;
    startTime: string;
    endTime: string;
    availability: number;
}

export class RolloverRecurrentTimeSlots extends BaseResource {
    id: string;
    availableFinalDate: string;
    totalCount: number;
    availableTimeslots: RecurrentTimeSlots[];
    recurrenceOption: RecurrenceOption;
}
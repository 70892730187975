import { Component, OnInit, Inject } from '@angular/core';

import { MAT_SNACK_BAR_DATA   } from '@angular/material';
import { MatDialogModule, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
    selector: 'opt-success-message',
    templateUrl: './success-message.component.html',
    styleUrls: ['./success-message.component.scss']

})
export class SuccessMessageComponent implements OnInit {

    dataArray: any=[];

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public dialog: MatDialog) {

    }


    ngOnInit() {
        this.isArray();
    }

    isArray() {
        if (this.data instanceof Array) {
            this.dataArray = this.data;
        } else {
            this.dataArray.push(this.data);
        }
    }
    
     
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html'
})

export class SiteHeaderComponent {
    status: boolean = true;
    clickEvent() {
        this.status = !this.status;
    }
    constructor() { }
}

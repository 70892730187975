﻿import { BaseResource } from 'src/modules/models/base-resource';
import { BookingPackage } from 'src/modules/models/regular-portal/booking/booking-package';
import { BookingContact } from 'src/modules/models/booking/booking-contact';
import { ItemBookingQuestionnaire, PackageBookingQuestionnaire, GlobalBookingQuestionnaire } from 'src/modules/models/regular-portal/booking/question/booking-questionnaire'
import { QuestionPackageFilters, QuestionItemFilters} from 'src/modules/models/regular-portal/booking/question/question-filter'

export class Booking extends BaseResource {
    //id: number
    bookingReference: string;
    eventName: string;
    contact: BookingContact;
    bookingStatus: BookingStatus;
    bookingDate: Date;
    eventStartDate: Date;
    totalAmount: number;
    salesChannelId: number;
    bookingTypeId: number;
    seasonId: number;
    bookingPackages: BookingPackage[];
    itembookingQuestionnaires: ItemBookingQuestionnaire[];
    packagebookingQuestionnaires: PackageBookingQuestionnaire[];
    globalbookingQuestionnaires: GlobalBookingQuestionnaire;
    updateDate: string;
    questionPackageFilters: QuestionPackageFilters[];
    totalSelectedDates: number;
    unavailableDates: Date [];
}
export class BookingStatus extends BaseResource {
    name: string;
}
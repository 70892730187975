﻿import { Client, Venue } from './client';

export class CorporateClient extends Client {
    legalName: string;
    companyName: string;
    headOfficeClient: CorporateClient;
    headOffice: boolean;
    companyRegistrationNo: string;
    webSite: string;
    dateFinalised: Date;
    clientVenues: Venue[];
}


﻿import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OptFormModules } from 'src/modules/shared/form.modules';
import { OptCommonModules } from 'src/modules/shared/common.modules';
import { OptMaterialModule } from 'src/modules/shared/opt-material/opt-material.module';

import { CorporateComponent } from './setup/corporate/corporate.component';
import { IndividualComponent } from './setup/individual/individual.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from 'src/modules/booking-portal/account/change-password/change-password.component';

import { NgxCaptchaModule } from 'ngx-captcha';


// File Drop 
import { FileDropModule } from 'ngx-file-drop';

@NgModule({
    imports: [
        CommonModule,
        OptFormModules,
        OptCommonModules,
        NgxCaptchaModule,
        OptMaterialModule,
        FileDropModule
    ],
    exports: [
        CommonModule,
        CorporateComponent,
        IndividualComponent,
        LoginComponent,
        ChangePasswordComponent,
        OptMaterialModule
    ],
    declarations: [
        CorporateComponent,
        IndividualComponent,
        LoginComponent,
        ChangePasswordComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class CusModules { }

﻿import { NgModule, Injectable } from '@angular/core';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Init } from 'src/modules/Init'
import { environment } from 'src/environments/environment'

@Injectable()

export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral {
    apiKey: string;
    constructor() {
        Init().then(() => {
            this.apiKey = environment.GoogleAPIKEY;
        })
    }
}
﻿import { BaseResource } from '../base-resource';
import { Event } from './event';
import { Package } from './package';
import { BookingItems } from './booking-items';
import { jsonIgnore } from 'json-ignore';


export class BookingPackages extends BaseResource {
    constructor() {
        super();
        this.bookingItems = [];
        this.type = "BookingPackage";
    }

    endDate: string;
    margin: number;
    name: string;
    priceTypeId: number;
    quantity: string;
    singleDayPackage: false
    startDate: string;
    taxRate: number;
    bookingItems: BookingItems[];
    package: Package;
    event: Event;
    attendees: number;
    @jsonIgnore() entityConfigurationProfileId: number;
}
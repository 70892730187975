import { Component, Inject, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

import { Utility } from 'src/modules/utility';

import { CalenderTimeslot } from 'src/modules/models/asset/timeslots';
import { Configuration } from 'src/modules/models/asset/configuration';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { ModalContentComponent } from "../modal-content/modal-content.component";
import { environment } from 'src/environments/environment'
@Component({
    selector: 'opt-calendar-modal',
    templateUrl: './calendar-modal.component.html'
})

export class CalendarModalComponent implements OnInit {
    @Input() inputSlots: CalenderTimeslot[];
    @Input() inputAvailableSlots: CalenderTimeslot[];
    @Input() configuration: Configuration;
    @Input() isAny: boolean;
    @Output() updateAvailableSlots = new EventEmitter<CalenderTimeslot[]>();
    slots: CalenderTimeslot[];
    availableSlots: CalenderTimeslot[];
    isPopup: boolean;
    timeLineItems: any[];
   
    isEnableTimeLineAdvancedMode: boolean;  

    constructor(public matDialogRef: MatDialogRef<ModalContentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {       
        this.inputSlots = (data.slots != undefined) ? data.slots : [];
        this.inputAvailableSlots = (data.availableSlots != undefined) ? data.availableSlots : [];
        this.isPopup = (data.availableSlots != undefined);

        this.configuration = data.configuration ;
        if (environment.CheckAvailability != undefined && environment.CheckAvailability.TimeLineColors != undefined && environment.CheckAvailability.TimeLineColors.length > 0) {
            this.timeLineItems = environment.CheckAvailability.TimeLineColors;
        }
        this.isEnableTimeLineAdvancedMode = environment.CheckAvailability.EnableTimeLineAdvancedMode; 
        if (data.slots || data.availableSlots)
            this.populateSlotsWithBumpInOut();   

        this.isAny = data.isAny;
    }

    ngOnInit() {
     
      
    }

    ngOnChanges(changes: SimpleChanges) {
       
        console.log(changes);
        const dataInputSlots: SimpleChange = changes.inputSlots;
        const dataInputAvailableSlots: SimpleChange = changes.inputAvailableSlots;

        if (dataInputSlots != undefined && dataInputSlots.currentValue != undefined) {
            this.inputSlots = dataInputSlots.currentValue as CalenderTimeslot[];
            console.log("SETUP --- ", this.slots)
        }
        if (dataInputAvailableSlots != undefined && dataInputAvailableSlots.currentValue != undefined) {
            this.inputAvailableSlots = dataInputAvailableSlots.currentValue as CalenderTimeslot[];
        }       
        this.populateSlotsWithBumpInOut();
    }
    
    populateSlotsWithBumpInOut()
    {
        console.log('populateSlotsWithBumpInOut');
        console.log(this.inputAvailableSlots);
        this.slots =  Object.assign([], this.inputSlots); //JSON.parse(JSON.stringify(this.inputSlots));
        this.availableSlots = Object.assign([], this.inputAvailableSlots);

        if (this.configuration)
        {
            var bumpIn = 0, bumpOut = 0;
            if (this.configuration.setupTime)
                bumpIn = this.configuration.setupTime;
            if (this.configuration.teardownTime)
                bumpOut = this.configuration.teardownTime;
            if (this.availableSlots && (bumpIn > 0 || bumpOut > 0)) {
                var _av: CalenderTimeslot[] = [];
                //based on bump in and out adjust available slot
                this.availableSlots.forEach(tm => {
                   {
                        var t = new CalenderTimeslot();
                        t.startTime=new Date(tm.startTime.getTime());
                        t.endTime= new Date(tm.endTime.getTime());
                        t.availableStartTime = new Date(t.startTime.getTime());
                        t.availableEndTime = new Date(t.endTime.getTime());

                        var st = new Date(t.startTime.getTime());
                        var end = new Date(t.endTime.getTime());
                        t.startTime.setMinutes(t.startTime.getMinutes() + bumpIn);
                        t.endTime.setMinutes(t.endTime.getMinutes() - bumpOut);
                        if (t.startTime < t.endTime) {

                            t.start = FacilityAdaptor.getTimeLinePosition(t.startTime);
                            t.end = FacilityAdaptor.getTimeLinePosition(t.endTime);

                            // add bump in/out to unavailable slot
                            if (environment.IsShowSetupTimeInCalendar) {
                                var bump = FacilityAdaptor.populateCalenderTimeslot(st, t.startTime);
                                bump.isBumpInBumpOut = true;
                                this.slots.push(bump);
                                var bumpO = FacilityAdaptor.populateCalenderTimeslot(t.endTime, end);
                                bumpO.isBumpInBumpOut = true;
                                this.slots.push(bumpO);
                            }
                            _av.push(t);


                        } else {
                            //due to bump in out not bookable 
                            var _slot = FacilityAdaptor.populateCalenderTimeslot(st, end);                           
                            this.slots.push(_slot);
                        }
                    }
                });
                this.availableSlots = _av;
                console.log(this.slots);

            }
        } 
        this.updateAvailableSlots.emit(this.availableSlots)
    }
    getTimelineClass(){
        if(this.getTimeLineColor('available'))
            return 'time-line--available';   
        return 'timeline';
    }
    getClass(slot: CalenderTimeslot)
    {
        if (slot.isSelectedTimeSlot && slot.isBumpInBumpOut) {
            return 'time-line--teardown-up';       
        } else if (slot.isSelectedTimeSlot) {
            return 'time-line--selected';
        } else if (slot.isUnAvailableDueToOperationalHours) {
            return 'time-line--unavailable';
        }else if(!slot.isSelectedTimeSlot && slot.isBumpInBumpOut){
            return 'time-line--bump';
        } else {
            return 'time-line--sub';
        }
    }
    getBg(slot: CalenderTimeslot) {    
     
        if (slot.isSelectedTimeSlot && slot.isBumpInBumpOut) {
            return this.getTimeLineColor('setupteardown');
        } else if (slot.isSelectedTimeSlot) {
            return this.getTimeLineColor('selected');
        }else if(slot.isUnAvailableDueToOperationalHours) {
            return this.getTimeLineColor('noneoperational');
        }else if(!slot.isSelectedTimeSlot && slot.isBumpInBumpOut){
            return this.getTimeLineColor('bumpunavailable');
        }else {
            return this.getTimeLineColor('bookedColor');
        }
   
    }
        
    
    public close() {
        this.matDialogRef.close();
    }

    getLegendColor(item): string {
        return item.Value;
    }

    getTimeLineColor(identifier: string): string {
        let colorItem = this.timeLineItems.filter(x => x.Identifier == identifier);
        if (colorItem != undefined && colorItem.length> 0)
            return colorItem[0].Value;
        return null;
    }
}

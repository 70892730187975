﻿import { BookingItemVm } from 'src/modules/models/regular-portal/booking/manage-teams/booking-item-vm';
import { RecurrentBookingItem, AdHocBookingItem } from 'src/modules/models/regular-portal/booking/manage-teams/recurrent-booking';
import { BookingPackage, BookingVenues } from 'src/modules/models/regular-portal/booking/booking-package';
import { environment } from 'src/environments/environment';
import { RegularBookingSettings, BookingTypes } from 'src/modules/models/settings/regular-portal/regular-booking-setting';

///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateFormat } from 'src/modules/date-format';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
const moment = _rollupMoment || _moment;

import { Utility } from 'src/modules/utility';
export class RegularBookingAdapter {

    static distinctdates: any[] = [];
    static Totaldistinctdates: any[] = [];
    static TotaldistinctdatesBooking: Date[];

    static getStartDateEndDate(fromDate) {
        var currentDate = moment().format('YYYY-MM-DD');
        var startDate = "";
        if (new Date(currentDate) <= new Date(fromDate)) {
            startDate = Utility.convertToISO(Utility.appendTimePart(moment(fromDate).toDate(), moment(fromDate).toDate(), true));
        } else if (new Date(currentDate) >= new Date(fromDate)) {
            startDate = Utility.convertToISO(Utility.appendTimePart(moment(currentDate).toDate(), moment(currentDate).toDate(), true));
        }
        return startDate;
    }

    static unavailableDatesBooking(bkngPkgs: BookingPackage[]) {
        this.TotaldistinctdatesBooking = [] as Date[];
        if (bkngPkgs.length > 0) {

            bkngPkgs.forEach((p, i) => {

                if (p.unavailableDatesPackage) {
                    p.unavailableDatesPackage.forEach((d, i) => {
                        var isDates = this.TotaldistinctdatesBooking.find(dt => dt.toString() == d.toString())
                        if (!isDates) {
                            this.TotaldistinctdatesBooking.push(d);
                        }
                    });

                }

            });

            return this.TotaldistinctdatesBooking;
        }

       
       
    }

    static unavailableDatesItem(itm: BookingItemVm) {
        let count = [];
        if (itm != undefined && itm.unavailableDates != undefined) {

            if (itm.unavailableDates && itm.unavailableDates.length > 0)
            {
                if (itm.recurrentBookingItem && itm.recurrentBookingItem.length > 0) {

                    itm.recurrentBookingItem.forEach((x, i) => {
                      
                        var _ItemStartDate =Utility.convertToISO(Utility.appendTimePart(Utility.convertISOToDate(x.startDate.toString()),new Date(2020,1,1),true));  
                                         
                        var UnavailableDatesItm = itm.unavailableDates.filter(unAvlDate => (
                            Utility.convertToISO(Utility.appendTimePart(Utility.convertISOToDate((unAvlDate.startTime).toString()), new Date(2020, 1, 1), true))) == _ItemStartDate && (unAvlDate.preliminary == false || unAvlDate.type =="blockedTimeslot"));
                        //--preliminary FALSE = another booking cannot be made on top
                        if (UnavailableDatesItm != undefined && UnavailableDatesItm.length > 0)
                        {                          
                            let _dt = moment(_ItemStartDate).format('Do MMM')
                            var _isAdded = count.find(x => x == _dt);
                            if (!_isAdded)
                                count.push(_dt);
                        }
                    });
                  
                } else {
                  
                    var _ItemStartDate = Utility.convertToISO(Utility.appendTimePart(Utility.convertISOToDate(itm.startDate.toString()), new Date(2020, 1, 1), true)) ;
                    var UnavailableDatesItm = itm.unavailableDates.filter(unAvlDate => 
                        Utility.convertToISO(Utility.appendTimePart(Utility.convertISOToDate((unAvlDate.startTime).toString()), new Date(2020, 1, 1), true)) == _ItemStartDate
                        && (unAvlDate.preliminary == false || unAvlDate.type == "blockedTimeslot"));
                    
                    if (UnavailableDatesItm != undefined && UnavailableDatesItm.length == itm.unavailableDates.length) {
                        let _dt = moment(_ItemStartDate).format('Do MMM')
                        var _isAdded = count.find(x => x == _dt);
                        if (!_isAdded)
                            count.push(_dt);                                       
                    }
                }
            }
        }
        return count;
    }

    static GetWeekDaysRecurrent(recurrentFilter, weekDayss) {
        var selectedWeekDays = [];
        if (recurrentFilter != undefined && recurrentFilter.recurrenceWeekDays != undefined) {
            var weekArray = JSON.parse("[" + recurrentFilter.recurrenceWeekDays + "]");
            weekArray.forEach((week, index) => {
                var findWeek = weekDayss.find(x => x.id == week);
                selectedWeekDays.push(findWeek);
            });
        }
      
        return selectedWeekDays;
    }

    static timeslotsCountData(bookingVenues) {
        if (bookingVenues != undefined) {

            var total = 0;
            this.distinctdates = [];


            bookingVenues.forEach((ven, v) => {

                ven.bookingItems.forEach((x, i) => {
                    if (x.recurrentBookingItem != undefined && x.recurrentBookingItem.length > 0) {
                        x.recurrentBookingItem.forEach((b, indx) => {
                                var _recurrentDate = moment(b.startTime).format('YYYY-MM-DD');
                                var isAdded = this.distinctdates.find(y => y == _recurrentDate);
                                if (!isAdded) {
                                    this.distinctdates.push(_recurrentDate);
                            }                            
                        });
                    }
                    else if (x.adHocBookingItem != undefined) {
                        var _adHocDate = moment(x.adHocBookingItem.startTime).format('YYYY-MM-DD');
                        var isAdded = this.distinctdates.find(y => y == _adHocDate);
                        if (!isAdded) {
                            this.distinctdates.push(_adHocDate);
                        }
                    }
                });
            })
            return this.distinctdates;
        }
        else {
            return this.distinctdates;
        }
    }

    static GetTotalNumofDays(pkgs) {
        if (pkgs != undefined) {
            this.Totaldistinctdates = [];
            let pkgArray = pkgs;
            var Total = 0;
            var VenueTotal = 0;
            pkgArray.forEach((pk, i) => {
                //pk.bookingVenues.forEach((bvnue, v) => {
                    //var count = this.timeslotsCountData(bvnue.bookingItems);
                    //VenueTotal = VenueTotal + count;
                //});  

                let days = this.timeslotsCountData(pk.bookingVenues);
                days.forEach((day,d) => {
                    var isAdded = this.Totaldistinctdates.find(y => y == day);
                    if (!isAdded) {
                        this.Totaldistinctdates.push(day);
                    }

                });
               
             //   VenueTotal = VenueTotal + count; 
   
            });
            return this.Totaldistinctdates.length;
        }
        else {
            return 0;
        }


    }

    static GetSelectedLocations(pkg) {
        var locations = [];
        pkg.bookingVenues.forEach((ven, v) => {
            ven.bookingItems.forEach((itm, i) => {

                if (itm.asset && itm.asset.length > 0) {
                    var locationCount = locations.find(id => id == itm.asset[0].id);
                    if (!locationCount) {
                        locations.push(itm.asset[0].id);
                }
                }

            });
        });
       
        return locations.length;
    }

    static CheckUnavailableDays(itm, timeSlot, unavailableDatesItem) {
        console.log('CheckUnavailableDays');
        if (itm != undefined && timeSlot != undefined && itm.unavailableDates != undefined) {


            var _timeSlotStartDate = Utility.convertToISO(Utility.appendTimePart(Utility.convertISOToDate(timeSlot.startTime.toString()), new Date(2020, 1, 1), true));
           // var _date = moment(_timeSlotStartDate).format('Do MMM');


            var dts = unavailableDatesItem;
            if (dts) {
                var unAvailableDate = dts.find(d => d == _timeSlotStartDate);
                if (unAvailableDate) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return false;
        }
    }

    static GetCutOffDate(seasonId, bookingType) {

        var seasonCutOffDate = "";

        var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings)
        var _findBookingType = _regularXmlData.booking.bookingTypes.find(x => Number(x.id) == bookingType.id);

        if (_findBookingType.seasons.find(x => Number(x.id) == seasonId).cutoffDate != "" && _findBookingType.seasons.find(x => Number(x.id) == seasonId).cutoffDate != undefined) {
            seasonCutOffDate = _findBookingType.seasons.find(x => Number(x.id) == seasonId).cutoffDate;
        }
        else {
            seasonCutOffDate = _findBookingType.seasons.find(x => Number(x.id) == seasonId).to;
        }
        return seasonCutOffDate;
    }

    static RemoveConcatination(result, bPackages) {

        var booking;
        var bespokePackages = bPackages;
        var pkgName = '';

        if (result.bookingPackages) {
            booking = result;
        }
        else if (result.body) {

            if (result.body.data)
            booking = result.body.data;
        }

        if (booking) {

            if (booking.bookingPackages.length > 0) {
                booking.bookingPackages.forEach((pkg, ind) => {

                    var _packageId;
                    if (pkg.packageId) {
                        _packageId = pkg.packageId;
                    }
                    else if (pkg.package) {
                        _packageId = pkg.package.id;
                    }

                    var _pkgQuestionnaire;
                    if (booking.packagebookingQuestionnaires && booking.packagebookingQuestionnaires.length > 0) {
                        _pkgQuestionnaire = booking.packagebookingQuestionnaires;
                    }
                    else if (booking.bookingQuestionnaires) {

                        if (booking.bookingQuestionnaires.packageQuestionnaires && booking.bookingQuestionnaires.packageQuestionnaires.length > 0) {
                            _pkgQuestionnaire = booking.bookingQuestionnaires.packageQuestionnaires;
                        }
                    }

                    var _package = bespokePackages.find(p => p.id == _packageId);
                    if (_package) {

                        pkg.name = pkg.name.replace(_package.name + " - ", '');

                        if (_pkgQuestionnaire) {
                            _pkgQuestionnaire.forEach((pq, index) => {
                                pq.packageName = pq.packageName.replace(_package.name + " - ", '');

                            });
                        }

                    }
                   
                })

            }
        }

        
      
        return result;
    }

    static ValidateRecurrentDates(recurrentTimeSlots, endBy, getValidDates: boolean) {

        var _dates = [];
        if (recurrentTimeSlots.length > 0) {
            recurrentTimeSlots.forEach((rec, rIndex) => {

                var _endTime = moment(rec.endTime).format("YYYY-MM-DD");
                var _seasonEndDate = moment(endBy).format("YYYY-MM-DD");

                if (getValidDates) {
                    if (new Date(_seasonEndDate) >= new Date(_endTime)) {
                        _dates.push(rec);
                    }
                } else
                {
                    if (new Date(_seasonEndDate) < new Date(_endTime)) {
                        _dates.push(rec);
                    }
                }
                
            });
        }

        return _dates;
    }

    static validateMinimumBookingDuration(startTime, endTime, minimumBookingDuration) {
        var _Stime = startTime.getTime() / (1000 * 60);
        var _Etime = endTime.getTime() / (1000 * 60);
        var duration = _Etime - _Stime;
        if (duration >= minimumBookingDuration) {
            return true;
        }
        else {
            return false;
        }
    }
}
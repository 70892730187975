import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';


import { Observable } from "rxjs";

import { HttpResponse, HttpResponseBase, HttpEvent } from '@angular/common/http';
import { map, catchError, switchMap, debounceTime } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';
import { BaseParam } from '../models/base-param';
import { AdHocDocument } from '../models/booking/adhoc-document';
import { QuestionSummary } from 'src/modules/models/booking/question-summary';
import { Question } from '../models/item/question'
import { Booking } from 'src/modules/models/booking/booking';
import { BookingSearchResult } from 'src/modules/models/booking/booking-search/booking-search-result';
import { InvoiceSearchResult, InvoicePreviewResult } from 'src/modules/models/booking/booking-search/invoice-search-result';
import { ParameterMapper } from 'src/modules/services/base/parameter-mapper';
import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';
import { BookingCancelPatch } from 'src/modules/models/booking/booking-cancel/booking-cancel';

//model
import { RecurrentTimeSlots } from 'src/modules/models/regular-portal/recurrent-time-slots';


@Injectable({
    providedIn: 'root'
})

export class BookingService {

    constructor(private _baseService: BaseService) {

    }
    BookingSave(postdata: Booking, orderItemName: string[]): Observable<any> {

        let changingOrder = [];
        changingOrder.push("PrivateEventFacilityTimeslot");
        changingOrder.push("Allocation");
        if (orderItemName.length > 0) {
            orderItemName.forEach(a => changingOrder.push(a));
        }
        changingOrder.push("BookingPackage");

        var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));


        json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
        json.included.reverse();
        console.log(JSON.stringify(json));

        //return this._baseService.WebAPIPOST("api/V4.1/bookings?include=BookingQuestionnaires,bookingquestions,question,bookingPackages,package,bookingitems,item", json).pipe(map(response => {
        //    var booking = (new Deserializer().deserialize(response));
        //    console.log("BookingSave object ", booking)
        //    return booking;
        //}));

        return this._baseService.WebAPIPOSTResponse("api/V4.1/bookings?include=BookingQuestionnaires,bookingquestions,question,bookingPackages,package,bookingitems,item,answerChoices,BookingAnswerChoice,bookingQuestionChoices", json, true).pipe(map((response: HttpResponse<any>) => {
            let retObj = {};
            let resp: any = response.body
            if (resp && resp.data)
                retObj['booking'] = (new Deserializer().deserialize(resp));
            if (resp && resp.errors && resp.errors.length > 0)
                retObj['errors'] = resp.errors[0].detail;
            //var booking = (new Deserializer().deserialize(response));
            //console.log("BookingSave object ", booking)
            return retObj;
        }));
    }

    BookingPatch(id: string, postdata: Booking, isClientBookingApproval: boolean = false, clientEmail: string = null): Observable<any> {
        console.log(postdata);

        var _clientEmail = "";
        if (clientEmail != null) {
            _clientEmail = "&clientEmail=" + clientEmail;
        }

        var _isClientBookingApproval = "";
        if (isClientBookingApproval) {
            _isClientBookingApproval = "?isClientBookingApproval=" + isClientBookingApproval;
        }
        return this._baseService.Internal_WebAPI_PATCH("api/booking/" + id + _isClientBookingApproval + _clientEmail, postdata).pipe(map(response => {
            console.log("Booking Patch", response)
            return response;
        }));
    }

    //BookingNotePatch(postdata: Booking): Observable<any> {
    //    return this._baseService.Internal_WebAPI_PATCH("api/booking/note-patch", postdata).pipe(map(response => {
    //        console.log("Note Patch", response)
    //        return response;
    //    }));
    //}

    BookingSearch(baseParam: BaseParam): Observable<BookingSearchResult> {

        var filter = "";
        if (baseParam) {
            filter = ParameterMapper.mapFilters(baseParam.filter);
            filter = ParameterMapper.mapFilters(baseParam.paging, filter);
            if (baseParam.sort) {
                if (filter.length > 0) filter += "&";
                filter += "sort=" + baseParam.sort;
            }
        }
        return this._baseService.Internal_WebAPI_GET("api/booking/?" + filter).pipe(
            map((response: HttpResponse<any>) => {
                var res = new BookingSearchResult();
                if (response.ok) {
                    res.data = response.body.Data;
                    res.total = response.body.Total;
                    res.isError = response.body.IsError;
                    if (response.body.Error) {
                        res.errorMessage = response.body.Error.ErrorMessage;
                        res.errorCode = response.body.Error.ErrorCode;
                    } else if (response.body.Errors)
                    {
                        res.errorMessage = response.body.Errors[0].ErrorMessage;
                        res.errorCode = response.body.Errors[0].ErrorCode;
                    }
                }
                return res;
            })
        );
    }
    InvoiceSearch(id: string, baseParam: BaseParam): Observable<InvoiceSearchResult> {

        var filter = "";
        if (baseParam) {
            filter = ParameterMapper.mapFilters(baseParam.filter);
            filter = ParameterMapper.mapFilters(baseParam.paging, filter);
            if (baseParam.sort) {
                if (filter.length > 0) filter += "&";
                filter += "sort=" + baseParam.sort;
            }
        }
        return this._baseService.Internal_WebAPI_GET("api/booking/" + id + "/invoices/?" + filter).pipe(
            map((response: HttpResponse<any>) => {
                var res = new InvoiceSearchResult();
                if (response.ok) {
                    res.data = response.body.Data;
                    res.total = response.body.Total;
                    res.isError = response.body.IsError;
                    if (response.body.Error) {
                        res.errorMessage = response.body.Error.ErrorMessage;
                        res.errorCode = response.body.Error.ErrorCode;
                    }
                }
                return res;
            })
        );
    }
    InvoiceView(id: string): Observable<InvoicePreviewResult> {

        return this._baseService.Internal_WebAPI_GET("api/booking/invoices/" + id).pipe(
            map((response: HttpResponse<any>) => {
                var res = new InvoicePreviewResult();
                if (response.ok) {
                    res.data = response.body.Data;
                    res.isError = response.body.IsError;
                    if (response.body.Error) {
                        res.errorMessage = response.body.Error.ErrorMessage;
                        res.errorCode = response.body.Error.ErrorCode;
                    }
                }
                return res;
            })
        );
    }

    BookingSelect(id: string): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/booking/" + id).pipe(
            map((response) => {
                return response;
            })
        );
    }
    //need to remove use invoice search
    BookingInvoiceSearch(id: string): Observable<any> {

        return this._baseService.InternalWebAPIGET("api/booking/" + id + "/Invoices").pipe(
            map((response) => {
                return response;
            })
        );
    }

    BookingInvoiceSave(bookingId: string): Observable<any> {
        return this._baseService.Internal_WebAPI_POST("api/booking/" + bookingId + "/invoices", null).pipe(
            map((response: HttpResponse<any>) => {
                var res = new InvoiceSearchResult();
                if (response.ok) {
                    res.data = response.body.data;
                    res.isError = response.body.isError;
                    if (response.body.data.errors != null) {
                        res.errorMessage = response.body.errors[0].errorMessage;
                        res.errorCode = response.body.errors[0].errorCode;
                    }
                }
                return res;
            })
        );
    }

    // temporry - to cross check the values 
    BookingSelectWebapi(id: string): Observable<any> {
        return this._baseService.WebAPIGet("api/v4.1/bookings/" + id + "?include=bookingitems,allocations,timeslot,asset,BookingQuestionnaires,bookingquestions,question,BookingItemAssociations").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("bookingselect Webapi -", json)
                return json;
            })
        );
    }

    BookingDocumentsSave(id: string, postdata: AdHocDocument): Observable<any> {
        var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));
        return this._baseService.WebAPIPOST("api/V4.1/bookings/" + id + "/documents", json).pipe(map(response => {
            var data = (new Deserializer().deserialize(response));
            console.log("BookingDocumentSave", data)
            return data;
        }));
    }

    SendBookingEnquiryConfirmationEmail(id: string, email: string, BookingRef: string, fromEmail: string, packageClassId: string): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/Booking/SendBookingConfimationEmail?email=" + email + "&BookingId=" + id + "&BookingRef=" + BookingRef + "&fromEmail=" + fromEmail + "&packageClassId=" + packageClassId).pipe(
            map((response) => {
                return response;
            })
        );

    }

    BookingCancelPatch(id: string, bookingCancelPatchObject: Booking): Observable<any> {
        return this._baseService.Internal_WebAPI_PATCH("api/Booking/" + id + "/cancel", bookingCancelPatchObject).pipe(
            debounceTime(2000),
            map((response) => {
                //var JsonData = JSON.parse(response['data']);
                //var data = (new Deserializer().deserialize(JsonData));
                return response;
            })
        );
    }

    PostQuestionAndAnswer(questionSummaries: QuestionSummary[], bookingID: string) {
        return this._baseService.Internal_WebAPI_POST("api/booking/answer-post/" + bookingID, questionSummaries).pipe(
            map((response) => {
                return response;
            })
        );
    }

    PostBulkQuestionAndAnswer(questionSummaries: QuestionSummary[], bookingID: string) {
        return this._baseService.Internal_WebAPI_POST("api/booking/" + bookingID + "/answers", questionSummaries).pipe(
            map((response) => {
                return response;
            })
        );
    }


    //BookingSearchUisngContactRef(id: string, pagenumber: number, pagesize: number, sort: string, bookingRef: string, bookingDateFrom: string, bookingDateTo: string, eventDateFrom: string, eventDateTo: string, statusIds: string, isPendingPayments: boolean): Observable<Booking[]> {
    BookingPackages(id: string): Observable<any> {
        return this._baseService.WebAPIGet("api/v4.1/bookings/" + id + "?include=bookingpackages,package").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);

                return json;
            })
        );
    }


    PostTask(jsonObj) {

        var json = new Serializer().serialize(jsonObj);


        return this._baseService.Internal_WebAPI_POST("api/booking/task-post", json).pipe(
            map((response: any) => {
                var JsonData = JSON.parse(response.body.data);
                var result = new Deserializer().deserialize(JsonData);
                return result;
            })
        )

    }

    //    var _datefilter = '';
    //    var _bookingReffilter = '';
    //    var _bookingStatusfilter = '';
    //    var _pendingPaymentsfilter = '';

    //    if (isPendingPayments) {
    //        _pendingPaymentsfilter = `&InvoiceStatus=1,2&PaymentStatus=3,2`;

    //    }

    //    if (bookingRef) {
    //        _bookingReffilter = `&BookingRef=${bookingRef}`;
    //    }

    //    if (statusIds) {
    //        _bookingStatusfilter = `&BookingStatusId=${statusIds}`;
    //    }

    //    if ((bookingDateFrom && bookingDateFrom.length) && (bookingDateTo && bookingDateTo.length) && (eventDateFrom && eventDateFrom.length) && (eventDateTo && eventDateTo.length)) {
    //        //all dates filter applied
    //        _datefilter = `&EventDateFrom=${eventDateFrom}&EventDateTo=${eventDateTo}&BookingDateFrom=${bookingDateFrom}&BookingDateTo=${bookingDateTo}`;

    //    }
    //    else if ((bookingDateFrom && bookingDateFrom.length) && (bookingDateTo && bookingDateTo.length)) {
    //        //all booking date filter applied
    //        _datefilter = `&BookingDateFrom=${bookingDateFrom}&BookingDateTo=${bookingDateTo}`;

    //    } else if ((eventDateFrom && eventDateFrom.length) && (eventDateTo && eventDateTo.length)) {
    //        //all event date filter applied
    //        _datefilter = `&EventDateFrom=${eventDateFrom}&EventDateTo=${eventDateTo}`;

    //    }
    //    else if (bookingDateFrom && bookingDateFrom.length) {
    //        //only booking Start date
    //        _datefilter = `&BookingDateFrom=${bookingDateFrom}`;


    //    } else if (bookingDateTo && bookingDateTo.length) {
    //        //only booking End Date
    //        _datefilter = `&BookingDateTo=${bookingDateTo}`;


    //    } else if (eventDateFrom && eventDateFrom.length) {
    //        //only Event Start date
    //        _datefilter = `&EventDateFrom=${eventDateFrom}`;


    //    } else if (eventDateTo && eventDateTo.length) {
    //        //only Event End Date
    //        _datefilter = `&EventDateTo=${eventDateTo}`;


    //    }


    //    return this._baseService.WebAPIGet('api/v4.1/bookings?ContactRef=' + id + '&page.number=' + pagenumber + '&page.size=' + pagesize + '&sort=' + sort + _datefilter + _bookingReffilter + _bookingStatusfilter + _pendingPaymentsfilter).pipe(
    //        map((response) => {
    //            var json = new Deserializer().deserialize(response);
    //            return json;
    //        })
    //    );


    //}

    //GetAllInvoiceForSelectedBooking(bookingID: string): Observable<any[]> {
    //    return this._baseService.WebAPIGet("api/v4.1/bookings/" + bookingID + "/invoices").pipe(
    //        map((response) => {
    //            var json = new Deserializer().deserialize(response);

    //            return json;
    //        })
    //    );
    //}
}

import { Component, OnInit, ViewChild, Output, Input, EventEmitter, ViewChildren, QueryList, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { SystemService } from '../../../../services/system.service';
import { QASIntegrationService } from '../../../../services/qas-integration.service';
import { Deserializer } from 'json-api-format';
import { IndividualClient } from './../../../../models/client/Individual-client';
import { ReferenceData } from './../../../../models/client/referenceData';
import { Contact } from './../../../../models/client/Contact';
import { CommunicationMethod } from './../../../../models/client/client';
import { Client, Address, User, Venue, Tax, PriceConcession } from './../../../../models/client/client';
import { QASAddress } from './../../../../models/client/qas-address';
import { ClientRegistration, CommunicationTypeValidation } from 'src/modules/models/settings/casual-portal/client-registration-config';
import { Addresses, AddressDetail } from './../../../../models/client/Address';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutePath } from '.././../../../../modules/booking-portal/route-path';
//import { Login } from './../../../../models/login/login';
import { Store, ICart, SetContact, Redirect, PatchBookingContact, DoBooking } from './../../../../store/index';
import { MatSnackBar } from '@angular/material';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { environment } from 'src/environments/environment'
import { SystemConfig } from 'src/modules/system-configuration'


import { Observable } from "rxjs";
import { map, startWith, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { Utility } from 'src/modules/utility';

import { CustomerAdditionalDetail } from 'src/modules/models/client/customer-additional-detail';
import { Note } from 'src/modules/models/client/note';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';


@Component({
    selector: 'opt-individual',
    templateUrl: './individual.component.html',
    //styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit, AfterViewInit {
    @Output() navigated: EventEmitter<number> = new EventEmitter<number>();
    @Input() isFromBooking: string;
    @Input() tabIndex: number;
    individuallientSaveForm: FormGroup;
    private fileReader = new FileReader();
    Image: string;
    ImageEx: string;
    Email: string;
    SelectedTitleId: string;
    SelectedTitleName: string;
    SelectedPhoneTypeId: string;
    SelectedPhoneTypeName: string;
    SelectedEmailTypeId: string;
    SelectedEmailTypeName: string;
    SelectedComTypeId: string;
    SelectedComTypeName: string;
    addressSearchText: string;
    IsEnableManualAddress: boolean = false;
    PrivacyPolicyLink: string;
    TermsandConditionLink: string;
    IsFromLogin: boolean;
    IsFromBookingDetail: boolean;
    CurrentUrl: string;
    Address_Country: string;
    Address_Line1: string;
    Address_Line2: string;
    Address_Line3: string;
    Address_City: string;
    Address_CountyCode: string;
    Address_PostCode: string;
    DefaultCountryId: number;
    CommunicationTypeValidation: CommunicationTypeValidation[] = [];
    loadingStyle: boolean;
    QASLoader: boolean;

    Client: IndividualClient;
    Titles: Array<ReferenceData>;
    Contact: Contact;
    // login: Login;
    CommunicationTypes: Array<ReferenceData>;
    CommunicationTypesPhone: Array<ReferenceData>;
    CommunicationTypesEmail: Array<ReferenceData>;
    CustomerRegConfig = new ClientRegistration();
    AddressList: Addresses;
    filteredOptions: AddressDetail[];
    TempAdd: Array<string>;
    

    acceptedImageTypes = { 'image/png': true, 'image/jpeg': true, 'image/gif': true };
    @ViewChild('imageDrop') imageDrop;
    @ViewChild('imageInput') el;
    @ViewChild('imageError') imageError
    url: string

    isChecked = false;
    isEmailSent: boolean;
    siteKey: string;
    size: string;
    lang: string;
    theme: string;
    type: string;
    recaptcha;
    isCaptchaVerified = false;
    defaultCountryName: string;
    isLoad = false;
    addressLoaded = false;
    defaultCurrencyId: number;
    // phoneNumberPattern = /^[- +()]*[0-9][- +()0-9]*$/;
    phoneNumberPattern: RegExp;
    agreedText: string;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    nameValidation: RegExp;
    pwPolicy: any;
    showValidationMessage: boolean = false;
    validationMessage: string = "";
    isValidEnforceComplexPassword: boolean;
    isValidCharactersMinimum: boolean;
    isValidOneLowercase: boolean;
    isValidOneUppercase: boolean;
    isValidOneDigit: boolean;
    isValidOneSpecialCharacter: boolean;
    isDisabledSubmit: boolean = true;
    isPasswordValid: boolean = true;
    isNoResultFound: boolean = false;
    placholderSampleNumber: string;

    priceConcessionQuestionText: string;
    PriceConcessionAnswerOptions: any[] = [];

    IsEnableToCreateAdditionalDetailsSectionForIndividualClients: boolean = false;
    IsEnableToCreateAdditionalDocumentSectionForIndividualClients: boolean = false;
    additionalDetail: CustomerAdditionalDetail;
    additionalDetailNote: string;
    public files: UploadFile[] = [];
    public fileSelector: ElementRef;
    @ViewChild('fileInputTag') fileInputTagVariable: ElementRef; status: boolean = false;


    requestToAccessReqularBookingHeaderText : string ="";
    requestToAccessReqularBookingAcceptText: string = "";
    reqularBookingAccessRequestHeaderText: string = "";
    reqularBookingAccessRequestSubHeaderText: string = "";
    profileImageHeaderTextForIndividualClients: string = "";

    requestToRegularBookingAccess: boolean = false;
    requestToRegularBookingAccessNote: string;
    @ViewChild('fileInputTagforProfileImage') fileInputTagforProfileImage: ElementRef;
    profileImage: any;
    enableProfileImageUpload: boolean;
    enableWebPortalAccess: boolean;
    showAddressSearchBar: boolean = true;
    appData: AppSetting;
    docSize: number;
    ClientFileUploadDescription: any;

    constructor(private _customerService: CustomerService, private _systemService: SystemService, private _qasIntegrationService: QASIntegrationService, private _formBuilder: FormBuilder, private route: Router, private _sysConfig: SystemConfig, private store: Store<any>, public snackBar: MatSnackBar, private sanitizer: DomSanitizer, ) {
        this.SelectedTitleId = '';
        this.SelectedTitleName = '';
        this.Client = new IndividualClient();
        this.Titles = new Array<ReferenceData>();
        this.CommunicationTypes = new Array<ReferenceData>();
        this.CommunicationTypesPhone = new Array<ReferenceData>();
        this.CommunicationTypesEmail = new Array<ReferenceData>();
        this.AddressList = new Addresses();
        this.AddressList.AddressDetails = [];
        this.Contact = new Contact();
        this.Contact.client = new Client();
        this.Image = '';
        this.ImageEx = '';
        this.IsFromBookingDetail = false;
        this.IsFromLogin = false;
        this.PrivacyPolicyLink = '';
        this.TermsandConditionLink = '';
        this.DefaultCountryId = 0;
        var appsettings = environment.AppSetting as AppSetting;
        this.nameValidation = new RegExp(appsettings.ClientRegistrationConfig.NameValidation);
        this.defaultCurrencyId = environment.DefaultCurrencyId;
        this.defaultCountryName = environment.DefaultCountryName;
        this.siteKey = environment.ReCaptchaSiteKey;
        this.enableProfileImageUpload = environment.EnableProfileImageUpload;
        this.enableWebPortalAccess = environment.EnableWebPortalAccess;
        this.size = 'Normal';
        this.lang = 'en';
        this.theme = 'Light';
        this.type = 'Image';
        this.recaptcha = 'recaptcha1'
        this.isPasswordValid = true;
        if (environment.PriceConcessionQuestionText && environment.PriceConcessionQuestionText != "") {
            this.priceConcessionQuestionText = environment.PriceConcessionQuestionText;
            this.PriceConcessionAnswerOptions = environment.PriceConcessionAnswerOptions ? environment.PriceConcessionAnswerOptions : [];
        }
        this.IsEnableToCreateAdditionalDetailsSectionForIndividualClients = environment.IsEnableToCreateAdditionalDetailsSectionForIndividualClients;
        this.IsEnableToCreateAdditionalDocumentSectionForIndividualClients = environment.IsEnableToCreateAdditionalDocumentSectionForIndividualClients;

        this.requestToAccessReqularBookingHeaderText= environment.RequestToAccessReqularBookingHeaderText ;
        this.requestToAccessReqularBookingAcceptText = environment.RequestToAccessReqularBookingAcceptText;
        this.reqularBookingAccessRequestHeaderText =environment.ReqularBookingAccessRequestHeaderText ;
        this.reqularBookingAccessRequestSubHeaderText =environment.ReqularBookingAccessRequestSubHeaderText ;
        this.profileImageHeaderTextForIndividualClients = environment.ProfileImageHeaderTextForIndividualClients;


        this.additionalDetail = new CustomerAdditionalDetail();
        this.additionalDetail.note = new Note();
        this.additionalDetail.documents = [];

        if (environment.ExternalIntegrationIdForAddress == 0 || environment.ExternalIntegrationIdForAddress == undefined) {
            this.showAddressSearchBar = false
        }
        this.appData = (environment.AppSetting as AppSetting);
        this.docSize = this.appData.ClientRegistrationConfig.MaximumDocUploadSize ? this.appData.ClientRegistrationConfig.MaximumDocUploadSize : 3;
        this.ClientFileUploadDescription = this.sanitizer.bypassSecurityTrustHtml(this.appData.ClientRegistrationConfig.ClientFileUploadDescription);
    }

    ngOnInit() {

        if ((this.route.url).indexOf('%3F') > -1) {
            this.CurrentUrl = ((this.route.url).split('%3F')[1]).split('%3D')[0];
            if (this.CurrentUrl == "fromLogin") {
                this.IsFromLogin = (((this.route.url).split('%3F')[1]).split('%3D')[1] == '1') ? true : false;
            }
            else if (this.CurrentUrl == "fromBookingDetail") {
                this.IsFromBookingDetail = (((this.route.url).split('%3F')[1]).split('%3D')[1] == '1') ? true : false;
            }
        }

        this.individuallientSaveForm = this._formBuilder.group({
            FirstName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
            LastName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
            //   PersonalPhoneNumber: ['', Validators.pattern(this.phoneNumberPattern)],
            UserName: ['', [Validators.required, Validators.maxLength(50)]],
            PersonalPhoneNumber: [''],
            PersonalEmail: ['', Validators.pattern(this.emailPattern)],
            Password: ['', [Validators.required, this.passwordValidator.bind(this)]],
            ConfirmPassword: ['', [ConfPasswordValidator]],
            Address_Country: [''],
            Address_Line1: ['',[Validators.maxLength(50)]],
            Address_Line2: [''],
            Address_Line3: [''],
            Address_City: [''],
            Address_CountyCode: [''],
            Address_PostCode: [''],
            Address: ['', this.appData.ClientRegistrationConfig.EnableManualAddress ? '' : [Validators.required]],
            Additional_Detail_Note: ['', (environment.IsPriceConcessionQuestionRequired && this.priceConcessionQuestionText && this.IsEnableToCreateAdditionalDetailsSectionForIndividualClients) ? [Validators.required] : ''],
            recaptcha1: ['', Validators.required],
            Request_To_Begular_BookingAccess: [''],
            Request_To_Regular_Booking_Access_Note:[''],
        });

        if (environment.DisableUsernameTextBox) {
            this.individuallientSaveForm.controls.UserName.disable();
        }
        this.QASLoader = false;
        this.loadPasswordPolicy(); //load and bind pasword policy..
        this.GetConfigurationSettings();

        if (this.showAddressSearchBar == false) {
            this.individuallientSaveForm.controls.Address_Line1.setValidators([Validators.required]);
        }

        // address functions 
        this._sysConfig.loadAppConfiguration().then(() => {
            console.log("value added ");

            this.agreedText = environment.AgreedText;

            this.GetDropDownDetails();
            this.individuallientSaveForm.controls.Address.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), mergeMap(input =>
                this.GetQASAddress(false, '', input)

            ))
                .subscribe(data => {

                    this.isNoResultFound = false;

                    if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {

                        this.AddressList = data;
                    }
                    else {
                        this.isNoResultFound = true;
                        this.AddressList.AddressDetails = [];
                    }
                    this.QASLoader = false;
                });

            this.url = environment.PrivacyPolicyLink;

        });
    }

    // address functions 
    DisableAddressFromField() {
        this.individuallientSaveForm.controls['Address_Country'].disable();
        this.individuallientSaveForm.controls['Address_Line1'].disable();
        this.individuallientSaveForm.controls['Address_Line2'].disable();
        this.individuallientSaveForm.controls['Address_Line3'].disable();
        this.individuallientSaveForm.controls['Address_City'].disable();
        this.individuallientSaveForm.controls['Address_CountyCode'].disable();
        this.individuallientSaveForm.controls['Address_PostCode'].disable();
    }


    GetQASAddress(isFormattedAddress: boolean, QASID: string, searchText: string) {

        (searchText == "") ? (this.QASLoader = false, this.AddressList.AddressDetails = []) : '';

        (!isFormattedAddress && searchText != "") ? (this.QASLoader = true) : (this.QASLoader = false);

        let qasAddressParam = new QASAddress();
        qasAddressParam.isFormattedAddress = isFormattedAddress;
        qasAddressParam.QASId = QASID;
        qasAddressParam.SearchText = searchText;
        //console.log('QAS Adress')
        //console.log(qasAddressParam);
        return this._customerService.getQASAddress(qasAddressParam);
    }

    GetFormattedAddress(address: string) {
        console.log('Address loading')
        let selectedAdd = this.AddressList.AddressDetails.find(f => f.Address == address);
        // console.log(selectedAdd)
        let postCode = selectedAdd.Postcode;
        this.GetQASAddress(true, selectedAdd.QASID, '')
            .subscribe(data => {
                this.AddressList = new Addresses();
                if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                    //console.log(data.AddressDetails);
                    // var sentenceCase = this.sentenceCase(data.AddressDetails[0])     
                    //   console.log(data.AddressDetails[0]);
                    //   this.Address_Country = Utility.sentenceCase(data.AddressDetails[0].Country);
                    this.Address_Country = (data.AddressDetails[0].Country == '') ? this.defaultCountryName : Utility.sentenceCase(data.AddressDetails[0].Country);
                    this.Address_Line1 = Utility.sentenceCase(data.AddressDetails[0].AddressLine1);
                    this.Address_Line2 = (data.AddressDetails[0].AddressLine2 != null) ? Utility.sentenceCase(data.AddressDetails[0].AddressLine2) : ' ';
                    this.Address_Line3 = (data.AddressDetails[0].AddressLine3 != null) ? Utility.sentenceCase(data.AddressDetails[0].AddressLine3) : ' ';
                    this.Address_City = Utility.sentenceCase(data.AddressDetails[0].Locality);
                    this.Address_CountyCode = data.AddressDetails[0].StateCode;
                    this.Address_PostCode = data.AddressDetails[0].Postcode;
                    this.addressLoaded = true;
                    this.loadingStyle = true;
                }
            });
        this.AddressList = new Addresses();
        this.QASLoader = false;
    }//Format selected address


    EmailAdressChanged(email) {
        if (environment.AutoPopulateUsernameFromEmail) {
            this.individuallientSaveForm.controls.UserName.setValue(email);
        }
    }

    //  validation functions 
    mobileNumberValidation() {
        var sampleValueArray = [];
        this.individuallientSaveForm.get('PersonalPhoneNumber').clearValidators()
        this.CommunicationTypeValidation = environment.CommunicationTypeValidation;
        if (this.CommunicationTypeValidation.length > 0) {
            this.CommunicationTypeValidation.forEach(ctv => {
                if (ctv.Rule != undefined && ctv.Rule.length > 0 && ctv.CommunicationTypeId != undefined && ctv.CommunicationTypeId != "") {
                    var ids = ctv.CommunicationTypeId.split(",");
                    if (ids != undefined && ids.length > 0 && ids.indexOf(this.SelectedPhoneTypeId) > -1) {
                        ctv.Rule.forEach(rule => {
                            if (this.DefaultCountryId.toString() == rule.countryId) {
                                this.phoneNumberPattern = new RegExp(rule.Value);
                                // this.placholderSampleNumber = rule.SampleNumber;
                                if (rule.SampleNumber != "") {
                                    sampleValueArray.push(rule.SampleNumber);
                                }
                                //if (ctv.Rule.length > 1) {
                                //    if (rule.SampleNumber != "") {
                                //        //var resultSampleValue = ctv.Rule.map(e => {
                                //        //    return e.SampleNumber;
                                //        //}).join(', ');
                                //        //this.placholderSampleNumber = resultSampleValue;
                                //        sampleValueArray.push(rule.SampleNumber);
                                //    }
                                //} else {
                                //    if (rule.SampleNumber != "") {
                                //        this.placholderSampleNumber = rule.SampleNumber;
                                //    }
                                //}
                            }
                        });

                        if (this.phoneNumberPattern == undefined || this.phoneNumberPattern == null) {
                            var selectedRule = ctv.Rule.find(a => a.countryId == "");
                            this.phoneNumberPattern = new RegExp(selectedRule.Value);
                            // this.placholderSampleNumber = selectedRule.SampleNumber;
                        }

                    }
                }
            });
            this.placholderSampleNumber = sampleValueArray.join(', ');
        }
        if (this.phoneNumberPattern != undefined && this.phoneNumberPattern != null) {
            console.log("value added pattern", this.phoneNumberPattern, "phone type ", this.SelectedPhoneTypeId, "PlaceHolderNumber", this.placholderSampleNumber);
            this.individuallientSaveForm.get('PersonalPhoneNumber').setValidators([Validators.pattern(this.phoneNumberPattern)])
            this.individuallientSaveForm.get('PersonalPhoneNumber').updateValueAndValidity();
        }
    }
    loadPasswordPolicy() {


        let passwordPolicyResult = this._customerService.GetAnonymousPasswordPolicies();
        passwordPolicyResult.subscribe(policy => {
            if (!policy.IsError) {
                this.pwPolicy = policy.Data;
                console.log("Password Policy -> ", policy);
            } else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: [policy.ErrorMessage]
                });
            }
        },
            err => {
                console.log("Error", err);
            }
        );



    }
    passwordStrength(event) {

        console.log("password Keydown fire", event.target.value);
        this.isPasswordValid = this.validatePassword(event.target.value);



    }
    validatePassword(password): boolean {
        var isValid = true;

        // this.pass_Strength_progress = 0;
        // this.pass_Strength_count = 0;
        //   this.showValidationMessage = false;
        if (this.pwPolicy != null) {

            if (this.pwPolicy.EnforceComplexPassword) {

                var EnforceComplexPasswordValue = true;

                if (this.pwPolicy.EnforceDigit) {



                    var EnforceDigitValidation = new RegExp(".*[0-9].*");

                    if (EnforceDigitValidation.test(password)) {



                        this.isValidOneDigit = true;
                    }
                    else {
                        isValid = false;
                        EnforceComplexPasswordValue = false;
                        this.isValidOneDigit = false;

                    }
                }

                if (this.pwPolicy.EnforceMinimumPasswordLength) {
                    if (password != undefined && password != null && password.length >= this.pwPolicy.PasswordLength) {
                        this.isValidCharactersMinimum = true;

                    }
                    else {
                        isValid = false;
                        EnforceComplexPasswordValue = false;
                        this.isValidCharactersMinimum = false;

                    }
                }

                if (this.pwPolicy.EnforceLowercase) {
                    if ((/[a-z]/.test(password))) {
                        this.isValidOneLowercase = true;

                    }
                    else {
                        isValid = false;
                        EnforceComplexPasswordValue = false;
                        this.isValidOneLowercase = false;

                    }

                    if (this.pwPolicy.EnforceUppercase) {
                        if ((/[A-Z]/.test(password))) {
                            this.isValidOneUppercase = true;
                        }
                        else {
                            isValid = false;
                            EnforceComplexPasswordValue = false;
                            this.isValidOneUppercase = false;
                        }
                    }

                    if (this.pwPolicy.EnforceSpecialCharacters) {
                        if ((/[!@@#$%^&*(),.?"`~:;\[\]'/{}|\\<>+=._-]/.test(password))) {
                            this.isValidOneSpecialCharacter = true;
                        }
                        else {
                            isValid = false;
                            EnforceComplexPasswordValue = false;
                            this.isValidOneSpecialCharacter = false;
                        }
                    }

                    if (this.pwPolicy.EnforceSpecialConditions) {
                        let thisComponent = this;
                        let _FirstName = this.individuallientSaveForm.controls.FirstName.value.toLowerCase();
                        let _LastName = this.individuallientSaveForm.controls.LastName.value.toLowerCase();

                        if (_FirstName != null && _FirstName != "" && password.toLowerCase() == _FirstName) {

                            thisComponent.showValidationMessage = true;
                            thisComponent.validationMessage = _FirstName + " cannot be a password";
                            isValid = false;
                        }

                        if (_LastName != null && _LastName != "" && password.toLowerCase() == _LastName) {

                            thisComponent.showValidationMessage = true;
                            thisComponent.validationMessage = _LastName + " cannot be a password";
                            isValid = false;
                        }

                    }

                    if (EnforceComplexPasswordValue) {
                        this.isValidEnforceComplexPassword = true;
                    } else {
                        this.isValidEnforceComplexPassword = false;
                    }
                }
            }
            return isValid;
        }
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.isLoad = true;
        });
    }

    uploadClick() {
        let ele: HTMLElement = this.el.nativeElement as HTMLElement;
        ele.click();
        //this.el.getNativeElement().click();
    }

    GetConfigurationSettings() {
        let result = this._systemService.loadConfiguration();
        result.subscribe(data => {
            if (data != null) {
                this.CustomerRegConfig = data.ClientRegistrationConfig;
                this.PrivacyPolicyLink = data.ClientRegistrationConfig.PrivacyPolicyLink;
                this.TermsandConditionLink = data.ClientRegistrationConfig.TermsandConditionLink;
                this.DefaultCountryId = Number(data.ClientRegistrationConfig.DefaultCountryId.Id);
                //  this.CommunicationTypeValidation = data.ClientRegistrationConfig.CommunicationTypeValidation;
                this.IsEnableManualAddress = data.ClientRegistrationConfig.EnableManualAddress;
                (this.IsEnableManualAddress) ? '' : this.DisableAddressFromField();
            }
        })
    }



    OpenPrivacyPolicy() {
        window.open(this.PrivacyPolicyLink, "_blank");
    }
    OpenTermAndConditions() {
        window.open(this.TermsandConditionLink, "_blank");
    }

  
    SaveIndividualClient() {
        console.log('individualclient')
        this.Client.type = "individualclient"
        this.Client.tid = "1";
        this.Client.firstName = this.individuallientSaveForm.controls.FirstName.value;
        this.Client.lastName = this.individuallientSaveForm.controls.LastName.value;
        this.Client.titleId = +this.SelectedTitleId;
        this.Client.title = this.SelectedTitleName;
        this.Client.clientCategoryId = this.CustomerRegConfig.IndividualClientConfig.ClientCategoryConfig.Id;
        this.Client.clientCategory = this.CustomerRegConfig.IndividualClientConfig.ClientCategoryConfig.Name;
        this.Client.clientTypeId = this.CustomerRegConfig.IndividualClientConfig.ClientTypeConfig.Id;
        this.Client.clientType = this.CustomerRegConfig.IndividualClientConfig.ClientTypeConfig.Name;
        this.Client.active = true;
        this.Client.dateRegistered = new Date();
        this.Client.clientName = this.SelectedTitleName + ' ' + this.individuallientSaveForm.controls.FirstName.value + ' ' + this.individuallientSaveForm.controls.LastName.value;
        this.Client.creditLimit = 0;
        this.Client.birthYear = 0;
        this.Client.birthMonth = 0;
        this.Client.birthDay = 0;
        this.Client.anniversaryYear = 0;
        this.Client.anniversaryMonth = 0;
        this.Client.anniversaryDate = 0;
        this.Client.password = this.individuallientSaveForm.controls.Password.value;
      

        this.PriceConcessionAnswerOptions.forEach((PriceConcessionAnswerText) => {
            if (PriceConcessionAnswerText.PriceConcessionId) {
                if (PriceConcessionAnswerText.Key == this.additionalDetailNote) {
                    this.Client.priceConcession = new PriceConcession();
                    this.Client.priceConcession.id = PriceConcessionAnswerText.PriceConcessionId;
                }
            }

        });

        let userEmail = this.individuallientSaveForm.controls.PersonalEmail.value;
        let username = this.individuallientSaveForm.controls.UserName.value;
        this.Client.userName = username;

        this.Client.profilePicture = (this.Image != null && this.Image != "") ? (this.Image.split(',')[1]) + '.' + this.ImageEx.split('/')[1] : null;
        this.Client.defaultCurrencyId = this.defaultCurrencyId;
        let accManager = new User();
        accManager.type = "user";
        accManager.id = (this.CustomerRegConfig.AccountManager.Id).toString();
        accManager.userName = this.CustomerRegConfig.AccountManager.Name;

        this.Client.accountManager = accManager;
        this.Client.creditLimit = 0;

        let defVenue = new Venue();
        defVenue.type = "venue";
        defVenue.id = (this.CustomerRegConfig.Venue.Id).toString();
        defVenue.name = this.CustomerRegConfig.Venue.Name;

        this.Client.defaultVenue = defVenue
        if (this.CustomerRegConfig.Tax.Id > 0) {
            this.Client.tax = new Tax();
            //this.Client.tax.tid = "1";
            this.Client.tax.id = (this.CustomerRegConfig.Tax.Id).toString();
            this.Client.tax.name = this.CustomerRegConfig.Tax.Name;
        }

        if (this.profileImage) {
          this.Client.profilePicture = this.profileImage;
        }

        let addt = new Address();
        addt.tid = "1";
        addt.country = this.individuallientSaveForm.controls.Address_Country.value;
        addt.address1 = this.individuallientSaveForm.controls.Address_Line1.value;
        addt.address2 = this.individuallientSaveForm.controls.Address_Line2.value;
        addt.address3 = this.individuallientSaveForm.controls.Address_Line3.value;
        addt.countryID = this.DefaultCountryId;
        addt.city = this.individuallientSaveForm.controls.Address_City.value;
        addt.county = this.individuallientSaveForm.controls.Address_CountyCode.value;
        addt.postCode = this.individuallientSaveForm.controls.Address_PostCode.value;

        this.Client.address = addt;

        this.Client.communicationMethods = new Array<CommunicationMethod>();
        if (this.individuallientSaveForm.controls.PersonalPhoneNumber.value != null && this.individuallientSaveForm.controls.PersonalPhoneNumber.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "3";
            comMethod.communicationTypeID = this.SelectedPhoneTypeId;
            comMethod.communicationType = this.SelectedPhoneTypeName;
            comMethod.value = this.individuallientSaveForm.controls.PersonalPhoneNumber.value;
            //contact.communicationMethods.push(comMethod);
            this.Client.communicationMethods.push(comMethod);
        }
        if (this.individuallientSaveForm.controls.PersonalEmail.value != null && this.individuallientSaveForm.controls.PersonalEmail.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "4";
            comMethod.communicationTypeID = this.SelectedEmailTypeId;
            comMethod.communicationType = this.SelectedEmailTypeName;
            comMethod.value = this.individuallientSaveForm.controls.PersonalEmail.value;
            //contact.communicationMethods.push(comMethod);
            this.Client.communicationMethods.push(comMethod);
        }

        if (this.individuallientSaveForm.invalid) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Please check the indicated fields.'],
            });
        }
        else if (this.addressLoaded || this.IsEnableManualAddress) {
            let clientSaveResult = this._customerService.individualClientSave(this.Client);
            clientSaveResult.subscribe(data => {
                if (data != null) {
                    if (data.id != null) {
                        this.Contact.ref = data.contacts[0].ref;
                        this.Contact.id = data.contacts[0].id;
                        this.Contact.type = data.type;
                        this.Contact.client.id = data.id;
                        this.Contact.client.clientRef = data.clientRef;                        
                        this.Contact.title = data.title;
                        this.Contact.client.clientName = data.clientName;
                        this.Contact.client.clientRef = data.clientRef;
                        this.Contact.firstName = this.individuallientSaveForm.controls.FirstName.value;
                        this.Contact.lastName = this.individuallientSaveForm.controls.LastName.value
                        this.Contact.userName = username;
                        this.Contact.invoiceEmailAddress = userEmail;                      
                        if (data.priceConcession && data.priceConcession.id) {
                           
                            this.Contact.client.priceConcessionId = data.priceConcession.id;
                        }

                        this.Contact.bookingTypeList = (data.contacts[0] as any).bookingTypes ? (data.contacts[0] as any).bookingTypes : data.bookingTypes;
                        this.Contact.clientVenueIds = data.clientVenues;

                        this._customerService.SendCustomerRegisterConfirmation(userEmail, data.contacts[0].id).subscribe(result => { });
                        if (data.profilePicture != undefined)
                            this.Contact.profilePicture = data.profilePicture;
                        this.store.dispatch(new SetContact(this.Contact));

                        // additional details save
                        if (((this.additionalDetailNote || (this.additionalDetail.documents.length > 0)) && this.IsEnableToCreateAdditionalDetailsSectionForIndividualClients) || this.individuallientSaveForm.controls.Request_To_Begular_BookingAccess.value) {
                            this.additionalDetail.note.entityTypeID = "5";
                            this.additionalDetail.contactId = this.Contact.id;
                            this.additionalDetail.contactRef = this.Contact.ref;
                            this.additionalDetail.clientId = this.Contact.client.id;
                            this.additionalDetail.clientRef = this.Contact.client.clientRef;
                            this.additionalDetail.isUpdate = false;
                            this.additionalDetail.note.plainText = "";
                            this.additionalDetail.sendAdditionalDetailNotificationMail = ((this.additionalDetailNote != undefined && this.additionalDetailNote != "") || this.additionalDetail.documents.length > 0)
                            if (this.additionalDetailNote) {
                              this.additionalDetail.note.plainText = "Client Note - Additional Note Created At : " + Utility.convertToISO(new Date())+" " +this.additionalDetailNote;
                            }
                        if (this.individuallientSaveForm.controls.Request_To_Regular_Booking_Access_Note.value) {
                              this.additionalDetail.note.plainText += "Client Note - Reqular Booking Note - 1 Created At: " + Utility.convertToISO(new Date()) + " " + this.individuallientSaveForm.controls.Request_To_Regular_Booking_Access_Note.value;
                              //this.additionalDetail.sendNotificationForRegularBookingAccess = true;
                            }

                            if (this.requestToRegularBookingAccess) {
                                this.additionalDetail.sendNotificationForRegularBookingAccess = true;
                            }
                            

                            let clientAdditionalDetailSaveResult = this._customerService.AdditionalDetail(this.additionalDetail);
                            clientAdditionalDetailSaveResult.subscribe(data => {
                                if (data.isError) {
                                    let errorData = [];
                                    if (data.errors != null && data.errors.length > 0) {
                                        data.errors.forEach((error, i) => {
                                            errorData.push(error.detail);
                                        });
                                        this.snackBar.openFromComponent(AlertMessageComponent, {
                                            duration: 3000,
                                            verticalPosition: 'top',
                                            data: errorData,
                                        });
                                    }
                                }
                                this.urlRedirection();
                            },
                                err => {
                                    console.log(err);
                                    this.urlRedirection();
                                });
                        } else {
                            this.urlRedirection();
                        }
                    }
                }
            },
                error => {
                    let err = JSON.parse(error.message);
                    console.log(err);
                    let errorData = [];
                    if (err.errorCode == 500) {
                        errorData.push(err.errorMessage);
                    }
                    else if (err.errorCode == 400) {
                        if ((err.errors)) {
                            if (err.errors != null && err.errors.length > 0) {
                                err.errors.forEach((error, i) => {
                                    errorData.push(error.detail);
                                });
                            }
                        }
                    }
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                }
            )
        }


    }
    urlRedirection() {
        if (this.CurrentUrl == 'fromLogin' && this.IsFromLogin) {
            if (Utility.urlHistorty.length > 0) {
                var link = Utility.urlHistorty[Utility.urlHistorty.length - 1]
                this.route.navigate([`${link.split('?')[0]}`], { queryParams: Utility.sortParams(link) })
            } else {
                this.route.navigate([RoutePath.Facility])
            }
        } else if (this.CurrentUrl == 'fromBookingDetail') {
            this.route.navigate([RoutePath.BookingCart])
        }
        else {
            this.store.dispatch(new DoBooking());
        }
    }
    GetDropDownDetails() {
        let titleResult = this._customerService.getClientTiltles();
        titleResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let title of data.data) {
                    let ti = new ReferenceData();
                    ti.id = title.id;
                    ti.name = title.attributes.name;

                    this.Titles.push(ti);
                }
            }
            this.SelectedTitleId = this.Titles[0].id;
            this.SelectedTitleName = this.Titles[0].name;
        },
            err => {

            }
        );
        let communicationTypeResult = this._customerService.getCommunicationTypes();
        communicationTypeResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let comType of data.data) {
                    if (comType.attributes.format == 1) {
                        let comPhone = new ReferenceData();
                        comPhone.id = comType.id;
                        comPhone.name = comType.attributes.name;
                        var isExist = comPhone.name.toLowerCase().search("office");
                        if (isExist == -1) {
                            this.CommunicationTypesPhone.push(comPhone);
                        }
                    }
                    else if (comType.attributes.format == 2) {
                        let comEmail = new ReferenceData();
                        comEmail.id = comType.id;
                        comEmail.name = comType.attributes.name;
                        var isExist = comEmail.name.toLowerCase().search("office");
                        if (isExist == -1) {
                            this.CommunicationTypesEmail.push(comEmail);
                        }
                    }
                    else {
                        let com = new ReferenceData();
                        com.id = comType.id;
                        com.name = comType.attributes.name;

                        this.CommunicationTypes.push(com);
                    }

                }
            }

            var CommunicationTypesMobileIndex = this.CommunicationTypesPhone.map(function (e) { return e.name; }).indexOf('Mobile');
            this.SelectedPhoneTypeId = this.CommunicationTypesPhone[CommunicationTypesMobileIndex].id;
            this.SelectedPhoneTypeName = this.CommunicationTypesPhone[CommunicationTypesMobileIndex].name;
            this.SelectedEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedEmailTypeName = this.CommunicationTypesEmail[0].name;
            this.SelectedComTypeId = this.CommunicationTypes[0].id;
            this.SelectedComTypeName = this.CommunicationTypes[0].name;
            this.mobileNumberValidation();
        },
            err => {

            }
        );
    }//Get drop down details

    SelectTitle(selectedTitleId: string) {
        this.SelectedTitleId = selectedTitleId;
        this.SelectedTitleName = this.Titles.find(i => i.id === selectedTitleId).name;
    }

    SelectComType(selectedComTypeId: string, basicComType: number, isAdditional: boolean) {
        if (!isAdditional) {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
                this.mobileNumberValidation();
            }
            else if (basicComType == 2) {
                this.SelectedEmailTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }
        else {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
                this.mobileNumberValidation();
            }
            else if (basicComType == 2) {
                this.SelectedEmailTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }

    }


    checkfiles(files) {
        if (this.acceptedImageTypes[files[0].type] !== true) {
            this.imageError.nativeElement.innerHTML = "Not a valid image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else if (files.length > 1) {
            this.imageError.nativeElement.innerHTML = "Please choose 'One' Image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else {
            this.imageError.nativeElement.style.display = 'none';
            this.imageDrop.nativeElement.style.display = 'block';
            this.readfiles(files);
        }
    }//checkfiles

    readfiles(files) {
        this.fileReader.readAsDataURL(files[0]);
        this.ImageEx = files[0].type;
        setTimeout(() => this.Image = this.fileReader.result.toString(), 500);

    }//readfiles

    imageUploadSubmitted() {
        console.log('IMAGE VALUE SUBMIT = =  ', this.individuallientSaveForm.controls.imageInput.value);
    }

    allowDrop(e) {
        e.preventDefault();
    }

    drop(e) {
        //e.preventDefault();
        this.imageDrop.innerHTML = "";
        this.checkfiles(e.dataTransfer.files);
    }

    imageChange(event) {
        this.imageDrop.innerHTML = "";
        this.checkfiles(event.target.files);
    }//imageChange

    PasswordValidation() {
        let password = this.individuallientSaveForm.controls.Password.value;
        let confirmPassword = this.individuallientSaveForm.controls.ConfirmPassword.value;

        return password === confirmPassword ? null : { notSame: true }
    }

    Redirect(event, id, url) {

        //if (this.IsFromBookingDetail && this.CurrentUrl == "fromBookingDetail") {
        //    this.route.navigate([RoutePath.BookingCart]) 
        //}
        //else {
        event.preventDefault();
        if (url == 'fromBookingDetail') {
            return this.route.navigate([RoutePath.BookingCart])
        }
        else if (id == 1)
            return this.navigated.emit(1);
        else
            return this.route.navigate([RoutePath.Facility])
        //}
    }

    onChange(event) {
        this.isChecked = !this.isChecked;
    }

    handleSuccess(e) {
        this.isCaptchaVerified = true;
    }

    handleExpire() {
        this.isCaptchaVerified = false;
    }

    handleLoad() {
        console.log('load')
    }

    disabledButtonChecking() {
        var Address_Country = (this.Address_Country == undefined) ? '' : this.Address_Country
        var Address_Line1 = (this.Address_Line1 == undefined) ? '' : this.Address_Line1
        var Address_Line2 = (this.Address_Line2 == undefined) ? '' : this.Address_Line2
        var Address_Line3 = (this.Address_Line3 == undefined) ? '' : this.Address_Line3
        var Address_City = (this.Address_City == undefined) ? '' : this.Address_City
        var Address_PostCode = (this.Address_PostCode == undefined) ? '' : this.Address_PostCode;
        if (this.isCaptchaVerified && this.isChecked || (this.IsEnableManualAddress && (this.QASLoader || this.addressLoaded))) {
            if (Address_Country != '' || Address_Line1 != '' || Address_Line2 != '' ||
                Address_Line3 != '' || Address_City != '' || Address_PostCode != '') {
                return true;
            }
            return false;
        }
        return false;
    }

    //#region File upload

    public dropped(event: UploadEvent) {
      
        
        this.files = event.files;
        let snack = this.snackBar;

        for (const droppedFile of event.files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.checkFileExtesion(file.name)) {
         
                        if (file.size < this.docSize * 1048576) {
                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                let aHD = new AdHocDocument();
                                aHD.name = file.name;
                                aHD.fileSize = Utility.calculateFileSize(file.size);

                                let stringdata = myReader.result.toString().split(',');
                                aHD.documentFile = stringdata[stringdata.length - 1];
                                aHD.file = file;
                                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                                this.additionalDetail.documents.push(aHD);
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
                        });
                    }
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    onFileChanged(event) {
     
        if (this.checkFileExtesion(event.target.files[0].name)) {
           
            if (event.target.files[0].size < this.docSize * 1048576) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    let aHD = new AdHocDocument();
                    aHD.file = event.target.files[0];
                    aHD.name = event.target.files[0].name;
                    aHD.fileSize = Utility.calculateFileSize(event.target.files[0].size);
                    let stringdata = myReader.result.toString().split(',');
                    aHD.documentFile = stringdata[stringdata.length - 1];
                    aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                    this.additionalDetail.documents.push(aHD);
                    this.fileInputTagVariable.nativeElement.value = "";
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
            });
        }
    }

    removeFile(index) {
        this.additionalDetail.documents.splice(index, 1);
    }

    checkFileExtesion(name: string) {
        let supportTypes = ["doc", "docx", "pdf", "txt", "xls", "xlsx", "jpeg", "jpg", "png"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
    }

    get64Stringdata(file: AdHocDocument) {
        if (file && file.file && file.file.size) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
        }
        else return null;
    }
    //#endregion

   //#region profile image upload
    onFileChangedForProfileImage(event) {
      
      if (this.IsFileExtesionImage(event.target.files[0].name)) {
         
          if (event.target.files[0].size < this.docSize * 1048576) {
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {       
          this.profileImage = myReader.result;
          this.fileInputTagforProfileImage.nativeElement.value = "";
        };
        myReader.readAsDataURL(event.target.files[0]);
      }
      else {
        this.snackBar.openFromComponent(AlertMessageComponent, {
          duration: 3000,
          verticalPosition: 'top',
            data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
        });
      }
    }
    else {
      this.snackBar.openFromComponent(AlertMessageComponent, {
        duration: 3000,
        verticalPosition: 'top',
        data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png"]
      });
    }
  }

  public droppedForProfileImage(event: UploadEvent) {
    if (this.profileImage)
      return;
    this.files = event.files;
      let snack = this.snackBar;
   
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
            if (this.IsFileExtesionImage(file.name)) {
             
                if (file.size < this.docSize * 1048576) {
              var myReader: FileReader = new FileReader();
              myReader.onloadend = (e) => {               
                this.profileImage = myReader.result;
              };
              myReader.readAsDataURL(file);
            }
            else {
              snack.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                  data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize +"MB in size"]
              });
            }
          }
          else {
            snack.openFromComponent(AlertMessageComponent, {
              duration: 3000,
              verticalPosition: 'top',
              data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png"]
            });
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  IsFileExtesionImage(name: string) {
    let supportTypes = ["jpeg", "jpg", "png"];
    let fileExtensionArray = name.split('.');
    let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
    return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
  }
   //#endregion profile image upload

  test() {
    console.log(this.requestToRegularBookingAccess)
    }

    passwordValidator(control: FormControl) {
        let password = control.value;
        if (password && this) {
            const confirmConroller = control.root.get('ConfirmPassword');
            console.log(this.validatePassword(password));
            if (this.validatePassword(password)) {
                //return null;
            } else {
                return {
                    passwordStrength: {
                        errorMsg: "Password Invalid"
                    }
                }
            }
            if (confirmConroller) {
                const confirmPw = confirmConroller.value;
                if (password !== confirmPw && confirmConroller.touched) {
                    confirmConroller.setErrors({ 'incorrect': true });
                } else {
                    confirmConroller.setErrors({ 'incorrect': null });
                    confirmConroller.updateValueAndValidity();
                }
            }
        }
        return null;
    }

}

export function ConfPasswordValidator(control: AbstractControl) {

    if (control !== null && control !== undefined) {
        const confirmPw = control.value;

        const pwConroller = control.root.get('Password');
        if (pwConroller) {
            const pw = pwConroller.value;

            if (pw !== confirmPw) {
                return {
                    isError: true
                }
            }
        }
    }
    return null;
}
//export function ConfPasswordValidator(control: AbstractControl) {


//    if (control !== null && control !== undefined) {
//        const Pw = control.value;

//        const confirmConroller = control.root.get('ConfirmPassword');
//        if (Pw) {
//            const confirmPw = confirmConroller.value;

//            if (Pw !== confirmPw && confirmConroller.touched) {
//                confirmConroller.setErrors({ 'incorrect': true });
//            } else {
//                confirmConroller.setErrors({ 'incorrect': null });
//                confirmConroller.updateValueAndValidity();
//            }
//        }
//    }
//    return null;
//}


﻿import { MatSnackBar } from '@angular/material';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { environment } from 'src/environments/environment';

export class PackageFilter {
    PackageClass: number;
    Package: number;
    BussinessAreaId: number;
    EventName: string;
    snackBar: MatSnackBar;
    ledgerNumber: string; 
    PackageDetail: BespokePackage;
    FilteredPackageClasses: any[];
    constructor()
    {
        this.FilteredPackageClasses=environment.PackageClasses.filter(cls => cls.Id != "0");
    }
}
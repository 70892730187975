﻿import { adapterFactory, DateAdapter } from './date-fns-index';
import { WeekView, DayView, HourView, HourSegmentView,DaySegmentView } from './../calendar/week-view'


//momemnet
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateFormat } from 'src/modules/date-format';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';



const moment = _rollupMoment || _moment;


export class CalendarUtil
{

    static PopulateWeekView(viewDate: Date, weekStartsOn: DAYS_OF_WEEK, dayStart: number = 0, dayEnd: number = 24, hourSegments: number = 2, daySegments: number = 4): WeekView {
        let da = adapterFactory();
        let vw = new WeekView();
        vw.displayDate = viewDate;
        vw.year = viewDate.getFullYear();
        //vw.weekId
        vw.weekDays = [];
        let hrs = CalendarUtil.GetHoursPerDay(viewDate, dayStart, dayEnd);
        CalendarUtil.GetWeekDays(viewDate, weekStartsOn).forEach(d => {
            let day = new DayView();
            day.date = d;
            day.dayId = 0;
            day.hours = [];
            day.year = vw.year;
            day.daySegments = [];
            for (let i = 1; i <= daySegments; i++) {
                let hsv = new DaySegmentView();
                hsv.startMin = ((24 * 60) / daySegments) * (i - 1);              
                hsv.endMin = hsv.startMin + (24 * 60 / daySegments);
                hsv.date = d;
                day.daySegments.push(hsv);
            }

            hrs.forEach(h => {
                let hv = new HourView();
                hv.date = h;
                hv.hourSegments = [];
                let start = new Date(h.getTime());
                for (let i = 1; i <= hourSegments; i++) {
                    let hsv = new HourSegmentView();
                    hsv.startMin = (start.getHours() * 60) + start.getMinutes()+ (60 / hourSegments) * (i - 1);
                   // start = da.addMinutes(start, (60 / hourSegments) * i)
                    hsv.endMin = hsv.startMin + (60 / hourSegments);//(start.getHours() * 60) + start.getMinutes();
                    hsv.date = h;
                    hv.hourSegments.push(hsv);
                }
                day.hours.push(hv);
            });
            vw.weekDays.push(day);
        });

        return vw;
    }

    static getEndOfMonth(year) {
        let da = adapterFactory();
        let endofMonth = da.endOfMonth(year)

        return endofMonth;
        }
    static GetWeekDays(viewDate: Date, weekStartsOn: DAYS_OF_WEEK)
    {
        let days: Date[] = [];       
        let da = adapterFactory();
        let startOfWeek = da.startOfWeek(viewDate, { weekStartsOn: parseInt(weekStartsOn.toString()) });
        for (let i = 0; i < 7; i++)
        {
            days.push(da.addDays(startOfWeek, i));
        }
        return days;
    } 
    static GetHoursPerDay(viewDate: Date,dayStart:number=0,dayEnd:number=24) {
        let da = adapterFactory();
        let st = da.startOfDay(viewDate);
        let ed = da.endOfDay(viewDate);
        let days: Date[] = da.eachHourOfInterval({ start: da.addHours(st, dayStart), end: da.addHours(st, dayEnd) });
      
        return days;
    } 
    static isSameDate(day1: Date, day2: Date)
    {
        let da = adapterFactory();
        return da.isSameDay(day1, day2);
    }
    static MinToHours(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + (rminutes.toString.length == 1 ? ("0") : "") + rminutes
    }

    static ConvertToMinutes(time) {

        var getHours = time.getHours();
        var getMinutes = time.getMinutes();

        if (getMinutes != 0) {
            var TotalMinutes = getHours * 60;
            TotalMinutes = TotalMinutes + getMinutes;
        } else {
            var TotalMinutes = (getHours + getMinutes) * 60;
        }

        return TotalMinutes;
    }

    static GetStartEndTime(hours , duration , date) {
        var time = hours.split(':');
        var _startDate = moment(date);
        _startDate.set({
            hours: Number(time[0]),
            minutes: Number(time[1])
        })
        var _endDate = moment(date);
        _endDate.set({
            hours: Number(time[0]),
            minutes: Number(time[1])
        })
        var startdays = _startDate;
        var endDays = _endDate.add(duration, 'minutes');

        return { startTime: startdays , endTime: endDays }
    }

    static getDuration(startTime, endTime) {
        var _duration;
        var _Stime = startTime.getTime() / (1000 * 60);
        var _Etime = endTime.getTime() / (1000 * 60);
        _duration = _Etime - _Stime;

        return _duration;
    }

    static getStartMin(startTime) {
        var _startMin = (startTime.getHours() * 60) + startTime.getMinutes();
        return _startMin;
    }
}


export enum DAYS_OF_WEEK {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
}

const DEFAULT_WEEKEND_DAYS: number[] = [
    DAYS_OF_WEEK.SUNDAY,
    DAYS_OF_WEEK.SATURDAY,
];
const DAYS_IN_WEEK: number = 7;
const HOURS_IN_DAY: number = 24;
const MINUTES_IN_HOUR: number = 60;
export const SECONDS_IN_DAY: number = 60 * 60 * 24;

import { Component, OnInit, Inject, EventEmitter, ViewChild} from '@angular/core';
import { BookingCommonComponent } from 'src/modules/regular-booking/booking-common.component';
import { forkJoin } from 'rxjs';
//MatComponent
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatMenuTrigger, MAT_SNACK_BAR_DATA } from "@angular/material";
import { MatSnackBar } from '@angular/material/snack-bar';

//service
import { BookingService } from 'src/modules/services/booking.service';
import { RegualrBookingService } from 'src/modules/services/regular-booking.service';
import { PackageService } from 'src/modules/services/package.service';
import { AssetService } from 'src/modules/services/asset.service';
import { ViewportScroller } from '@angular/common';
import { BehaviorSubject, Subject, Observable } from 'rxjs'
import { Router, ActivatedRoute, Params } from '@angular/router';
//component
import { WpAddTeamsComponent } from 'src/modules/regular-booking/wp-add-teams/wp-add-teams.component';
import { WPErrorMessage } from 'src/modules/regular-booking/messages/wp-error-message/wp-error-message';
import { WPInfoMessage } from 'src/modules/regular-booking/messages/wp-info-message/wp-info-message';

//store
import { Store, IRegularBooking, LoadBooking, SpinnerLoaded, SpinnerLoading } from 'src/modules/store/regular-booking/index';
import { ICart} from 'src/modules/store/index';
//model
import { Booking } from 'src/modules/models/regular-portal/booking/booking';
import { VenueAssetsearchparams, Page } from 'src/modules/models/regular-portal/venue-assets-search-param'
import { RegularBookingSettings, Season } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
import { Package } from 'src/modules/models/booking/package';
import { Booking as BookingS, BookingPackage as BookingPackageS, Contact, Client } from 'src/modules/models/regular-portal/booking/save-booking/booking';
import { BookingItem as BookingItemS, Item } from 'src/modules/models/regular-portal/booking/save-booking/booking-item';
import { RecurrentTimeSlots, RolloverRecurrentTimeSlots } from 'src/modules/models/regular-portal/recurrent-time-slots';
import { BookingItemVm } from 'src/modules/models/regular-portal/booking/manage-teams/booking-item-vm';

import { BookingPackage, BookingVenues } from 'src/modules/models/regular-portal/booking/booking-package';
import * as _ from 'lodash';

//helper classes
import { Utility } from 'src/modules/utility';
import { CalendarUtil, DAYS_OF_WEEK } from 'src/modules/regular-booking/wp-calendar/calendar/calendar-util';
import { RegularBookingAdapter } from 'src/modules/models/regular-portal/regular-booking-adapter';
///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateFormat } from 'src/modules/date-format';
//Coommon
import { environment } from 'src/environments/environment';
const moment = _rollupMoment || _moment;
@Component({
    selector: 'opt-wp-rollover-booking-modal',
    templateUrl: './wp-rollover-booking-modal.component.html',
    styleUrls: ['./wp-rollover-booking-modal.component.scss'],
    providers: [{ provide: MAT_SNACK_BAR_DATA, useValue: { displayDefaultIndicatorType: false }}]
})
export class WpRolloverBookingModalComponent extends BookingCommonComponent implements OnInit {
    displayotherComponents = false;
    displaySeasonComponents = false;
    showRolloverTeams: boolean = false;
    $selectBooking = new EventEmitter();
    rolloverBooking = new Booking();
    $bookingSave = new EventEmitter();
    rollOverBookingTypeId: number;
    rollOverSeasonId: number;
    RollOverBookingpackage = [];

    rollOverCurrentSeason: Season;
    availableVenues: any;
    avalibilityAssetList = [];
    unavailbleFacility = [];
    rolloverStartDate = new Date();
    rolloverEndDate = new Date();
    selectedStartDate: any;
    endBy = new Date();
    StartFrom = new Date();

    recurrentCheckBox: boolean = false;
    addHocCheckBox: boolean = false;
    documentCheckBox: boolean = false;
    questionCheckBox: boolean = false;
    upsellCheckBox: boolean = false;
    messageDetail: string = '';
    recurrentTimeSlots: RecurrentTimeSlots[];
    availableRecurrentTimeslots: RolloverRecurrentTimeSlots[]
    clientPriceConcessionId: any;
    protected cart$: Observable<ICart>;
    clientVenueIds: any[] = [];
    earliestRecurrentStartDate: Date;
    totalRecurrentDatesCount: number = 0;
    totalAdHocDatesCount: number = 0;
    totalUpsellItemsCount: number = 0;
    
    constructor(_stores: Store<any>, public _packageService: PackageService, private _bookingService: BookingService, private _regualrbookingService: RegualrBookingService, _store: Store<IRegularBooking>, private _route: ActivatedRoute
        , private dialog: MatDialog, public _assetService: AssetService, @Inject(MAT_DIALOG_DATA) public data: any, private vps: ViewportScroller, private snackBar: MatSnackBar) {
        super(_store, _route, _regualrbookingService, _assetService, _packageService);


        this.cart$ = _stores.select('cart');

    }
    @ViewChild(WpAddTeamsComponent) private wpAddTeamsComponent: WpAddTeamsComponent;
    ngOnInit() {
        super.ngOnInit();
        
        this.$selectBooking.safeSubscribe(this, (result: Booking) => {
            this.rolloverBooking = result; 
            if (this.rolloverBooking) {
                this.rollOverBookingTypeId = this.rolloverBooking.bookingTypeId;
               // this.rollOverSeasonId = this.rolloverBooking.seasonId;

                if (this.rolloverBooking.bookingPackages) {                   
                    this.rolloverBooking.bookingPackages.forEach((bkpackage, bIndex) => {
                        bkpackage.id = String((+bkpackage.id)*-1);
                        this.RollOverBookingpackage.push(bkpackage);
                    });


                    //get the Season
                   // this.setUpRolloverSeason()
                    this.validateFacility();
                    this.GetEarliestRecurrenStartDate();
                }


                
            }
              
            
         
            this.displayotherComponents = true;
            this.displaySeasonComponents = true;
        });

        this.$bookingSave.safeSubscribe(this, res => {
            if (res)
                console.log(res);
            this.handSuccessFailure(res);
            

        });

        this.LoadBooking(this.data.bookingId, this.$selectBooking, false, true);

        this.cart$.safeSubscribe(this, state => {
            this.populateICartDetails(state);
        });

        
    }

    populateICartDetails(state) {
        if (state.contact) {
                if (state.contact.clientVenueIds.length > 0) {
                    this.clientVenueIds = state.contact.clientVenueIds
            } 
            if (state.contact.client.priceConcessionId) {

                this.clientPriceConcessionId = state.contact.client.priceConcessionId;
            }

        }

    }

    GetItemsAvalability() {
        this.availableRecurrentTimeslots = new Array();
        this.totalAdHocDatesCount = 0;
        this.totalUpsellItemsCount = 0;
        const reqs = [];
        var recrrFilters = [];
        var earliestStartDate;
        var _returnCreateTeamsBooking = this.wpAddTeamsComponent.getBookingFormat() as any;

        if (this.rolloverBooking) {
            if (this.rolloverBooking && _returnCreateTeamsBooking) {
                _returnCreateTeamsBooking.bookingPackages.forEach((bkpackage, bIndex) => {

                    var findRolloverBookingPacakge = this.rolloverBooking.bookingPackages.find(bk => bk.id == String(bkpackage.id));

                    if (findRolloverBookingPacakge) {
                        if (findRolloverBookingPacakge.bookingVenues.length > 0) {
                            bkpackage.bookingItems = [];
                            findRolloverBookingPacakge.bookingVenues.forEach((ven, vIndex) => {
                                if (ven.bookingItem.length > 0) {                                   
                                    ven.bookingItem.forEach((item, bkIndex) => {

                                        var isUpsell = this.CheckUpsellItems(item);                                      
                                        if (item.recurrenceFilter) {
                                            var _recurrenceItem = _.cloneDeep(item.recurrenceFilter)

                                            var _newStartDate = this.GetNewStartDate(item);
                                            _recurrenceItem.recurrenceStartDate = this.GetDate(_newStartDate, item.recurrenceFilter.recurrenceStartDate);

                                            const _recurrentStartTime = Utility.convertToISO(Utility.appendTimePart(moment(_recurrenceItem.recurrenceStartDate).toDate(), moment(item.recurrenceFilter.startTime).toDate(), true));
                                            const _recurentEndTime = Utility.convertToISO(Utility.appendTimePart(moment(_recurrenceItem.recurrenceStartDate).toDate(), moment(item.recurrenceFilter.endTime).toDate(), true));

                                            _recurrenceItem.startTime = _recurrentStartTime;
                                            _recurrenceItem.endTime = _recurentEndTime;
                                            _recurrenceItem.recurrenceEndAfter = item.recurrentBookingItem.length;
                                            _recurrenceItem.recurrenceEndDate = undefined;
                                            _recurrenceItem.assets = item.asset[0].id;
                                            _recurrenceItem.id = "-" + _recurrenceItem.id;
                                            reqs.push(this._regualrbookingService.postRecurrentOption(_recurrenceItem));
                                            recrrFilters.push(_recurrenceItem);

                                        }

                                        else if (item.adHocBookingItem) {

                                            var _adhocDateTime = this.validateAdhocBookingDateTime(item);

                                            if (_adhocDateTime.startTime && _adhocDateTime.endTime) {
                                                if (!isUpsell) {
                                                    this.totalAdHocDatesCount = this.totalAdHocDatesCount + 1;
                                                }
                                                else {
                                                    this.totalUpsellItemsCount = this.totalUpsellItemsCount + 1;
                                                }
                                            }                                                                                      
                                        }                                        
                                    });
                                }
                            });
                        }
                    }
                });


                forkJoin(reqs).safeSubscribe(this, results => {
                    this.totalRecurrentDatesCount = 0;
                    results.forEach((result, indx) => {

                        this.recurrentTimeSlots = result;

                        if (this.recurrentTimeSlots && this.recurrentTimeSlots.length > 0) {

                            var avaialableTimeslots = this.recurrentTimeSlots.filter(ts => new Date(ts.endTime) <= this.rolloverEndDate);// rolloverEndDate to be changed to saeson cutoff date

                            if (avaialableTimeslots.length > 0) {

                                var timeslot = new RolloverRecurrentTimeSlots();
                                timeslot.recurrenceOption = recrrFilters[indx];
                                timeslot.availableFinalDate = avaialableTimeslots[avaialableTimeslots.length - 1].endTime;
                                timeslot.totalCount = this.recurrentTimeSlots.length;
                                timeslot.availableTimeslots = avaialableTimeslots;
                                timeslot.id = recrrFilters[indx].id;
                                var isAdded = this.availableRecurrentTimeslots.find(tmslot => tmslot.id == timeslot.id)
                                if (!isAdded) {
                                    this.availableRecurrentTimeslots.push(timeslot);
                                    this.totalRecurrentDatesCount = this.totalRecurrentDatesCount + avaialableTimeslots.length;
                                }
                            }
                        }
                    });
                });
            }

        }
    }
    handSuccessFailure(res) {

        if (res.body.data) {
            window.open(environment.RegularBookingPortalUrl + "#/booking-stepper/?OpenBooking=" + res.body.data.id + "&rollover=1", "_self");
        }
        else if (res.body.isError) {

            var errorMsg;
            if (res.body.errors[0].errorCode == 400) {
                errorMsg = res.body.errors[0].detail;
            }
            else {
                errorMsg = 'The booking cannot be rolled over, please contact us in order to assist you better.';
            }
             
            this.DisplayErrorSnackBar(errorMsg);
        }
    }

    DisplayErrorSnackBar(errorMsg) {
        const snackBarRef = this.snackBar.openFromComponent(WPErrorMessage, {
            panelClass: ['message-snackbarfull', 'opt-regular-booking'],
            duration: 5000,
            verticalPosition: 'top',
            data: { messageDetail: errorMsg }
        });
    }
    setUpRolloverSeason() {
        var RegularSetting = (environment.RegularBookingSetting as RegularBookingSettings)
        var findBookingType = RegularSetting.booking.bookingTypes.find(x => x.id == String(this.rollOverBookingTypeId));
        if (findBookingType) {
            const _findSeason = findBookingType.seasons.find(s => s.id == String(this.rolloverBooking.seasonId));
            if (_findSeason)
            this.rollOverCurrentSeason = _findSeason;
            this.rolloverStartDate = new Date(this.rollOverCurrentSeason.from);
            this.rolloverEndDate = new Date(this.rollOverCurrentSeason.to);

            var  _date = this.rollOverCurrentSeason.to;

            this.endBy = new Date(_date);
            this.StartFrom = new Date(this.rollOverCurrentSeason.from);
        
        }
    }


    get getSalesCateGoriesIdList() {
        let _salesCatList = [];
        if (this.booking && this.booking.bookingPackages) {
            this.booking.bookingPackages.forEach((bk, bkIndex) => {
                if (bk.salesCategories) {
                    bk.salesCategories.forEach((s, sIndex) => {
                        if (s.id) {
                            let findDuplicate = _salesCatList.find(sl => sl == s.id);
                            if (!findDuplicate) {
                                _salesCatList.push(s.id);
                            }
                        }
                    });
                }
            });
        };
        return _salesCatList.join();
    }


    //active inactive facility
    validateFacility() {
        this.unavailbleFacility = [];
        this.rolloverBooking.bookingPackages.forEach((bookingPacakge, index) => {
            bookingPacakge.bookingVenues.forEach((bkVenue, VIndex) => {
                bkVenue.bookingItem.forEach((facilitry, fIndex) => {
                    facilitry.asset.forEach((aset, aIndex) => {
                        let _findAsset = this.unavailbleFacility.find(a => Number(a) == Number(aset.id));
                        if (!_findAsset) {
                            this.unavailbleFacility.push(aset.id);
                        }
                    });
                });


            });
            //////////location assets
            
            let searchParam = new VenueAssetsearchparams();
            searchParam.searchpharese = "";
            const selectedIds = this.clientVenueIds.map(({ id }) => id);
            searchParam.listVenuids = selectedIds.join();
            searchParam.assetIds = this.unavailbleFacility.join();
            searchParam.SalesCategoryId = this.getSalesCateGoriesIdList;
            //this.clientSaleCategories.toString();
          //  let sFacility = _assetTypeIds.toString();
            //sFacility.length == 0 ? "" : sFacility;
            searchParam.assetTypeIds = "";
            searchParam.From = 0;
            searchParam.To = 0;
            searchParam.page = new Page();
            searchParam.page.number = 0;
            searchParam.page.size = 0; 
            searchParam.priceConcessionIds = this.GetconcessionId();
            searchParam.bookingTypeIds = this.rollOverBookingTypeId.toString();
            /////////////////////
         //   console.log(index);
            this._assetService.getFacilityAndResource(searchParam).safeSubscribe(this, response => {
                console.log(response);
                if (this.unavailbleFacility && response) {
                                       
                    this.availableVenues = response;
                    this.availableVenues.forEach((ven, indx) => {
                        ven.assetList.forEach((asst, ind) => {

                            var isAdded = this.avalibilityAssetList.find(ast => ast.id == asst.id);
                            if (!isAdded) {
                                this.avalibilityAssetList.push(asst);
                            }
                            
                        });
                        

                    });

                }
            },
                error => { }
                , () => { }
            )

        });


      
       // console.log(_listVenueId);
    }

    GetconcessionId() {
        var concessionId;
        if (this.clientPriceConcessionId) {
            concessionId = this.clientPriceConcessionId;
        }
        else {
            var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings)
            var _BookingTypedefaultConcessionId = _regularXmlData.booking.bookingTypes.find(bk => Number(bk.id) == this.rollOverBookingTypeId).defaultPriceConcessionId;
            concessionId = _BookingTypedefaultConcessionId;
        }

        return concessionId;
    }

    navigateNextStepper(event) {
        console.log(event);
    }

    ReciveSeasonChanges(event) {

        this.showRolloverTeams = true;

        if (event == true) {
            this.rollOverSeasonId = this.rolloverBooking.seasonId;
        }
        else {
            this.rollOverSeasonId = this.rolloverBooking.seasonId = event;
            //this.rolloverBooking.seasonId = event;
        }
        
        //findSeason;

      
        if (event) {
            if (this.wpAddTeamsComponent != undefined) {
                this.wpAddTeamsComponent.setDefaultBookingPackage();
            }

        }
        this.setUpRolloverSeason();
    }
    getRollOverBookingpackage() {
        return this.RollOverBookingpackage;
    }

    validateRolloverStartDate() {

        if (this.selectedStartDate == undefined || this.selectedStartDate == null) {
          
            var errorMsg = 'Please select a date to rollover the booking';
            this.DisplayErrorSnackBar(errorMsg);
            return false;
        }
        else {
            
            return true;
        }
    }

    CheckUpsellItems(bitem: BookingItemVm) {
       
        var isUpsell: boolean;
        switch (bitem.item.type.toLowerCase()) {

            case "wineitem":
                return isUpsell = true;
                break;
            case "serviceitem":
                return isUpsell = true;
                break;
            case "productitem":
                return isUpsell = true;
                break;
            case "cateringitem":
                return isUpsell = true;
                break;
          
        }

        return isUpsell;
    }

    CheckadHocItems(bitem: BookingItemVm) {

        var isAdHocItem: boolean;
        switch (bitem.item.type.toLowerCase()) {

            case "brandingareaitem":
                return isAdHocItem = true;
                break;
            case "diningitem":
                return isAdHocItem = true;
                break;
            case "generaladmissionitem":
                if (bitem.recurrenceFilter == undefined)
                return isAdHocItem = true;
                break;
            case "parkingspaceitem":
                return isAdHocItem = true;
                break;
            case "privatesuiteitem":
                return isAdHocItem = true;
                break;
            case "resourceitem":
                return isAdHocItem = true;
                break;
            case "seatingitem":
                return isAdHocItem = true;
                break;

        }

        return isAdHocItem;
    }


    //OnClick
    onClickCreateBooking() {

       var isDateValid =  this.validateRolloverStartDate();
        if (isDateValid) {

            var _returnCreateTeamsBooking = this.wpAddTeamsComponent.getBookingFormat() as any;
            var incrementBookingItemValue = 0;

            if (this.rolloverBooking && _returnCreateTeamsBooking) {

                //event Name
                _returnCreateTeamsBooking.eventName = "Copy of " + _returnCreateTeamsBooking.eventName;

                if (this.addHocCheckBox || this.recurrentCheckBox || this.upsellCheckBox) {

                    _returnCreateTeamsBooking.bookingPackages.forEach((bkpackage, bIndex) => {

                        var findRolloverBookingPacakge = this.rolloverBooking.bookingPackages.find(bk => bk.id == String(bkpackage.id));

                        if (findRolloverBookingPacakge) {
                            if (bkpackage.package) {
                                if (findRolloverBookingPacakge.bookingVenues.length > 0) {
                                    bkpackage.bookingItems = [];
                                    findRolloverBookingPacakge.bookingVenues.forEach((ven, vIndex) => {
                                        if (ven.bookingItem.length > 0) {
                                            ven.bookingItem.forEach((bitem, bkIndex) => {

                                                var item = _.cloneDeep(bitem);

                                                var isUpsell = this.CheckUpsellItems(bitem);

                                                var isAdHoc = this.CheckadHocItems(bitem);

                                                let _assets = this.CheckAssetAvailability(item);
                                                if (_assets.length > 0 || isUpsell) {

                                                    var curentIncrment = incrementBookingItemValue - 1;
                                                    incrementBookingItemValue = curentIncrment;

                                                    if (item.recurrenceFilter != undefined && item.recurrenceFilter.id && this.recurrentCheckBox) {
                                                        let bookingItem = new BookingItemS();
                                                        //var curentIncrment = incrementBookingItemValue - 1;
                                                        //incrementBookingItemValue = curentIncrment;
                                                        bookingItem.id = String(curentIncrment);
                                                        bookingItem.recurrent = true;
                                                        bookingItem.upsell = true;
                                                        bookingItem.quantity = 1;
                                                        //bookingItem.covers = item.attendees;
                                                        //bookingItem.attendees = item.attendees;
                                                        bookingItem.covers = bkpackage.attendees;
                                                        bookingItem.attendees = bkpackage.attendees;

                                                        bookingItem.item = new Item();
                                                        bookingItem.item.id = item.item.id;
                                                        bookingItem.item.type = item.item.type;
                                                        bookingItem.type = 'privateBooking' + bookingItem.item.type;

                                                        if (item.recurrenceFilter) {

                                                            var _startDate;
                                                            var _endDate;
                                                            //var _newStartDate = this.GetNewStartDate(item);

                                                            //item.recurrenceFilter.recurrenceStartDate = this.GetDate(_newStartDate, item.recurrenceFilter.recurrenceStartDate);


                                                            if (this.availableRecurrentTimeslots.length > 0) {

                                                                var rcrrItm = this.availableRecurrentTimeslots.find(itm => itm.recurrenceOption.id == "-"+item.recurrenceFilter.id);
                                                                if (rcrrItm) {

                                                                    item.recurrenceFilter.recurrenceStartDate = rcrrItm.recurrenceOption.recurrenceStartDate;

                                                                    if (item.recurrenceFilter.recurrenceEndAfter) {
                                                                        item.recurrenceFilter.recurrenceEndAfter = rcrrItm.availableTimeslots.length;
                                                                    }
                                                                    else if (item.recurrenceFilter.recurrenceEndDate) {
                                                                        item.recurrenceFilter.recurrenceEndDate = rcrrItm.availableFinalDate;
                                                                        item.recurrenceFilter.recurrenceEndAfter = undefined;
                                                                    }

                                                                    const _recurrentStartTime = Utility.convertToISO(Utility.appendTimePart(moment(this.rolloverStartDate).toDate(), moment(item.recurrenceFilter.startTime).toDate(), true));
                                                                    const _recurentEndTime = Utility.convertToISO(Utility.appendTimePart(moment(this.rolloverStartDate).toDate(), moment(item.recurrenceFilter.endTime).toDate(), true));

                                                                    item.recurrenceFilter.startTime = _recurrentStartTime;
                                                                    item.recurrenceFilter.endTime = _recurentEndTime;
                                                                    bookingItem.recurrenceOption = item.recurrenceFilter;
                                                                    bookingItem.recurrenceOption.id = "-" + bookingItem.recurrenceOption.id;
                                                                    bkpackage.bookingItems.push(bookingItem);
                                                                }                                                              
                                                            }
                                                        }
                                                    }

                                                    else if (isAdHoc && this.addHocCheckBox) {
                                                        //Add hoc
                                                        let addHocBookingItem = new BookingItemS();
                                                        //var curentIncrment = incrementBookingItemValue - 1;
                                                        //incrementBookingItemValue = curentIncrment;
                                                        addHocBookingItem = this.PopulateAdhocTypeItems(curentIncrment, item);
                                                        if (addHocBookingItem.startTime && addHocBookingItem.endTime) {
                                                            bkpackage.bookingItems.push(addHocBookingItem);
                                                        }
                                                    }

                                                    else if (isUpsell && this.upsellCheckBox) {

                                                            let upSellItem = new BookingItemS();

                                                        upSellItem = this.PopulateAdhocTypeItems(curentIncrment, item);
                                                            if (upSellItem.startTime && upSellItem.endTime) {
                                                                bkpackage.bookingItems.push(upSellItem);
                                                            }                                                   
                                                    }
                                                }
                                            });
                                        }
                                    });

                                };
                            }
                            else {
                                
                                var errorMsg = 'Please select an activilty type for each team!';
                                this.DisplayErrorSnackBar(errorMsg);
                            }
                        }
                    });

                    var BkngItemEmptyPackg = [];
                    var _Package = [];
                    _Package = _returnCreateTeamsBooking.bookingPackages.filter(pkg => pkg.bookingItems != undefined);
                    if (_Package.length > 0) {

                        BkngItemEmptyPackg = _Package.filter(pkg => pkg.bookingItems.length == 0);

                        if (BkngItemEmptyPackg.length > 0) {

                            BkngItemEmptyPackg.forEach((itm, ind) => {
                                itm.bookingItems = undefined;
                            });
                        }
                    }                
                }

                if (this.questionCheckBox) {                  
              var bookingQuestionnaires = [];
              if (this.rolloverBooking.globalbookingQuestionnaires && this.rolloverBooking.globalbookingQuestionnaires.questionsTobeDisplay && this.rolloverBooking.globalbookingQuestionnaires.questionsTobeDisplay.length > 0) {
                var globalQuestionsTobePost = [];
                this.rolloverBooking.globalbookingQuestionnaires.questionsTobeDisplay.forEach(qtd => {
                  if (this.isQuestionAnswered(qtd)) {
                    globalQuestionsTobePost.push(qtd);
                  }
                });
                if (globalQuestionsTobePost.length > 0) {
                  // changing the object as bookingQuestionnaires to send it with Booking object 
                  bookingQuestionnaires.push({
                    type: "globalBookingQuestionnaire",
                    id: "1",
                    bookingQuestions: globalQuestionsTobePost
                  });
                }
              }
              if (this.rolloverBooking.packagebookingQuestionnaires && this.rolloverBooking.packagebookingQuestionnaires.length > 0) {
                this.rolloverBooking.packagebookingQuestionnaires.forEach((pbq,index) => {
                  if (pbq.questionsTobeDisplay && pbq.questionsTobeDisplay.length > 0) {
                    var packageQuestionsTobePost = [];
                    pbq.questionsTobeDisplay.forEach(pqtd => {
                      if (this.isQuestionAnswered(pqtd)) {
                        packageQuestionsTobePost.push(pqtd);
                      }
                    });
                    if (packageQuestionsTobePost.length > 0) {
                      bookingQuestionnaires.push({
                        type: "packageBookingQuestionnaire",
                        id: (index + 1).toString(),
                        bookingQuestions: packageQuestionsTobePost,
                        bookingPackage: {
                          type: "bookingPackage",
                          id: pbq.packageId,
                          package: {
                            id: this.getPackageId(pbq.packageId),
                            type:"package"
                          }
                        }
                      });                     
                    }
                  }
                });
              }
              if (bookingQuestionnaires.length>0) {
                _returnCreateTeamsBooking.bookingQuestionnaires = bookingQuestionnaires;
              }

                    console.log(_returnCreateTeamsBooking, this.rolloverBooking);
                    console.log(bookingQuestionnaires)
                }
                
                var Isvalid = this.wpAddTeamsComponent.validateTeamNameAndAttendees();
                if (Isvalid != false) {
                super.SaveBooking(_returnCreateTeamsBooking, this.$bookingSave, false, this.rolloverBooking.id, this.documentCheckBox);
                }
                else {
                    //window.scrollTo(0, 0);

                    //setTimeout(() => {
                    this.vps.scrollToAnchor('scrollElement')
                    //}, 500);

                    //var el = document.querySelector('scrollElement');
                    //el.scrollTop = 1000;
                }


            }
            else {
                var isTeam = this.wpAddTeamsComponent.validatePackageAndTeam();

                if (!isTeam) {
                    //setTimeout(() => {
                    this.vps.scrollToAnchor('scrollElement')
                    //}, 500);
                }
            }
        }


    }


    validateAdhocBookingDateTime(item) {

            let addHocBookingItem = new BookingItemS();

            var _sDate = new Date(item.adHocBookingItem.startDate);
            if (_sDate >= this.rolloverStartDate) {
                addHocBookingItem.startTime = Utility.convertToISO(Utility.appendTimePart(moment(_sDate).toDate(), moment(_sDate).toDate(), true));
            }
            else {
                addHocBookingItem.startTime = this.GetDate(_sDate, addHocBookingItem.startTime);
            }

            var _eDate = new Date(item.adHocBookingItem.endDate)
            if (_eDate >= this.rolloverStartDate) {
                addHocBookingItem.endTime = Utility.convertToISO(Utility.appendTimePart(moment(_eDate).toDate(), moment(_eDate).toDate(), true));
            }
            else {

                addHocBookingItem.endTime = this.GetDate(_eDate, addHocBookingItem.endTime);
            }

            return addHocBookingItem;
        }

    PopulateAdhocTypeItems(curentIncrment, item) {


        let addHocBookingItem = new BookingItemS();
        addHocBookingItem.id = String(curentIncrment);
                addHocBookingItem.recurrent = false;
                addHocBookingItem.adhoc = true;
                addHocBookingItem.upsell = true;

                var _adhocDateTime = this.validateAdhocBookingDateTime(item);
                if (_adhocDateTime.startTime && _adhocDateTime.endTime) {

                    addHocBookingItem.startTime = _adhocDateTime.startTime;
                    addHocBookingItem.endTime = _adhocDateTime.endTime;
                }

                addHocBookingItem.covers = item.attendees;
                addHocBookingItem.attendees = item.attendees;

                addHocBookingItem.item = new Item();
                addHocBookingItem.item.id = item.item.id;
                addHocBookingItem.item.type = item.item.type;
                addHocBookingItem.quantity = 1;
                addHocBookingItem.type = item.type;

                return addHocBookingItem;
            }

    CheckAssetAvailability(item) {
        let _findAsset = new Array();
        if (item.asset.length > 0) {
            item.asset.forEach((aset, aIndex) => {
                var _asset = this.avalibilityAssetList.find(asset => Number(asset.id) == Number(aset.id));
                if (_asset) {
                    _findAsset.push(_asset);
                }

            });
        }
        return _findAsset;
    }

    GetDate(_sDate, _returnDate) {


        var count: number = 0;
        var i: number;

        for (i = 0; i >= count; count++) {

            var startingYear = new Date(this.rolloverStartDate).getFullYear();
            startingYear = startingYear + i;
            var month = _sDate.getMonth();
            var date = _sDate.getDate();
            var hour = _sDate.getHours();
            var minutes = _sDate.getMinutes();
            var sec = _sDate.getSeconds();
            var ms = _sDate.getMilliseconds();
          var  _Date = new Date(startingYear, month, date, hour, minutes, sec, ms);

            if (this.rolloverEndDate >= _Date && _Date >= this.rolloverStartDate) {
                _returnDate = Utility.convertToISO(Utility.appendTimePart(moment(_Date).toDate(), moment(_Date).toDate(), true));
                return _returnDate;
                break;
            }

            else if (this.rolloverEndDate >= _Date) {
                i++;
            }
            else {
                break;
            }
        }

    }


    GetNewStartDate(item) {

        var _oSDate = new Date(item.recurrenceFilter.recurrenceStartDate);
        var _oBookingStartDate = new Date(this.earliestRecurrentStartDate);
        var _newStartDate = new Date(this.rolloverStartDate);
        var monthDiff = 0;
        var diffDays = 0;

        monthDiff = (_oSDate.getFullYear() - _oBookingStartDate.getFullYear()) * 12 + (_oSDate.getMonth() - _oBookingStartDate.getMonth());
        
        if (monthDiff > 0) {
            _oBookingStartDate.setMonth(_oBookingStartDate.getMonth() + monthDiff);
            _newStartDate.setMonth(_newStartDate.getMonth() + monthDiff);
        }

        var diff = Math.floor((Date.UTC(_oSDate.getFullYear(), _oSDate.getMonth(), _oSDate.getDate()) - Date.UTC(_oBookingStartDate.getFullYear(), _oBookingStartDate.getMonth(), _oBookingStartDate.getDate())) / (1000 * 60 * 60 * 24));
        _newStartDate.setDate(_newStartDate.getDate() + diff);


        return _newStartDate;
    }

    getPackageId(bookingPackageId) {
    var packageid = "0";
    if (this.rolloverBooking.bookingPackages && this.rolloverBooking.bookingPackages.length>0 )
    {
      var bookingpacakge = this.rolloverBooking.bookingPackages.find(bp => bp.id == String((+bookingPackageId) * -1));
      if (bookingpacakge && bookingpacakge.package) {
          packageid = bookingpacakge.package.id;
      }
    }
    return packageid;
  }



    DateSelection() {
        this.rolloverStartDate = this.selectedStartDate;
        console.log(this.rolloverBooking);        
        this.GetItemsAvalability();
    }

    RolloverTeamsEmiter(event) {
       
    }

    getRolloverBooking() {

        return;
    }

    getFacilityAvalability() {
        //this.unavailbleFacility
        //return this.avalibilityAssetList.length
        var unavailableFacilityCount = 0;
        if (this.unavailbleFacility.length) {

            this.unavailbleFacility.forEach((astId, index) => {

                let _findavailableAsset = this.avalibilityAssetList.find(asset => asset.id == astId);
                if (!_findavailableAsset) {
                    unavailableFacilityCount++;
                }
            });
      
        }
        return unavailableFacilityCount;

      //if()
    }

    PopulateBookingItems(type , booking) {
        if (type == 'recurrent') {
           // booking.

        } else if (type == 'addhoc') {

        }

    }


    validateRecurrentItems(event) {

        if (event.checked) {
            if (this.rolloverBooking) {

                var isRecurrentItems: boolean = false;

                for (let bkpackage of this.rolloverBooking.bookingPackages) {
                    if (bkpackage.bookingVenues.length > 0) {
                                           
                      var bVenues =  bkpackage.bookingVenues.filter(vn => vn.bookingItem.length > 0);

                        if (bVenues.length> 0) {
                            for (let ven of bVenues) {

                                var _recurrItems = ven.bookingItem.filter(itm => itm.recurrenceFilter != undefined);
                                if (_recurrItems.length > 0) {
                                       // this.GetRecurrenceAvalability();
                                        isRecurrentItems = true;
                                        break;
                                    }
                               
                            };
                        }

                    }
             
                }

            if (!isRecurrentItems || this.totalRecurrentDatesCount == 0) {
              
                var errorMsg = 'There are no recurrent dates to be rolled over.';
                this.DisplayErrorSnackBar(errorMsg);
            }
            }
        }
        
    }
    validateAdhocItems(event) {
        if (event.checked) {
            if (this.rolloverBooking) {

                var isAdhocItems: boolean = false;

                    for (let bkpackage of this.rolloverBooking.bookingPackages){
                    if (bkpackage.bookingVenues.length > 0) {
                     
                        for (let ven of bkpackage.bookingVenues){
                            if (ven.bookingItem.length > 0) {
                                var _adhocItems = ven.bookingItem.find(itm => itm.adHocBookingItem != undefined)
                                if (_adhocItems) {
                                    isAdhocItems = true;  
                                    break;                             
                                }
                            }
                        };                                          
                }
                        if (isAdhocItems) { break; }     

                }

                if (!isAdhocItems || this.totalAdHocDatesCount == 0) {
                   
                    var errorMsg = 'There are no adhoc dates to be rolled over.';
                    this.DisplayErrorSnackBar(errorMsg);
                }
            }
        }
        
    }
    validateUpsellItems(event) {
        if (event.checked) {
            if (this.rolloverBooking) {

                var isUpsell: boolean = false;

                for (let bkpackage of this.rolloverBooking.bookingPackages) {
                    if (bkpackage.bookingVenues.length > 0) {

                        var bVenues = bkpackage.bookingVenues.filter(vn => vn.bookingItem.length > 0);

                        if (bVenues.length > 0) {
                            for (let ven of bVenues) {

                                var VItems = ven.bookingItem.filter(itm => itm != undefined);
                                for (let bItem of VItems) {
                                    isUpsell = this.CheckUpsellItems(bItem);
                                    if (isUpsell) {
                                        break;
                                    }

                                }
                                if (isUpsell) { break; }
                            };
                        }

                    }
                    if (isUpsell) { break; }
                }

                if (!isUpsell || this.totalUpsellItemsCount == 0) {
                   
                    var errorMsg = 'There are no upsell items to be rolled over.';
                    this.DisplayErrorSnackBar(errorMsg);
                }
            }
        }
        
    }

    DisplayMessage(event) {
        if (event.checked) {
            const snackBarRef = this.snackBar.openFromComponent(WPInfoMessage, {
                panelClass: ['message-snackbarfull', 'opt-regular-booking'],
                duration: 5000,
                verticalPosition: 'top',
                data: { messageDetail: 'Answers for date specific questions will not be rolled over.' }
            });

        }
    }

    GetEarliestRecurrenStartDate() {
        var AllRecurrentStartDates = [];
        if (this.rolloverBooking) {
            this.rolloverBooking.bookingPackages.forEach((p, indxP) => {
                p.bookingVenues.forEach((v, indxV) => {
                    v.bookingItem.forEach((itm, indxI) => {
                        if (itm.recurrenceFilter) {
                            AllRecurrentStartDates.push(itm.recurrenceFilter.recurrenceStartDate);
                        }
                        
                    })
                })
            })
            if (AllRecurrentStartDates.length > 0) {
                var sortArray = AllRecurrentStartDates.sort((a, b) => a < b ? -1 : a > b ? 1 : 0);
                this.earliestRecurrentStartDate = sortArray[0];
            }
        }
    }

}

﻿import { BaseResource } from '../base-resource';
import { jsonIgnore } from 'json-ignore';

export class AdHocDocument extends BaseResource {
    constructor() {
        super();
        this.type = "adHocDocument";
    }

    name: string;
    description: string;
    documentFile: string;
    documentCategoryId: string;
    @jsonIgnore() documentFilePath: string;
    @jsonIgnore() documentCategory: string;
    @jsonIgnore() fileSize: string;
    @jsonIgnore() documentFileOriginal: string;
    @jsonIgnore() file: File;
}
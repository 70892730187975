import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpResponse } from '@angular/common/http';

import { Observable, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { Asset } from '../models/asset/asset';
import { Assetsearchparams } from '../models/asset/asset-search-params'
import { AssestSearchResult } from '../models/asset/asset-search-result';
import { Facility } from '../models/asset/facility';
import { Amenity } from '../models/amenity/amenity';
import { LinkedDocument } from '../models/linked-document/linked-document';
import { FacilitySerachResult } from '../models/asset/facility-search-result'
import { FacilitySummary } from '../models/asset/facility-summary'
import { Configuration } from '../models/asset/Configuration'
import { Item } from '../models/item/item'
import { ReferenceData } from '../models/reference-data'
import { environment } from '../../environments/environment'
import { FacilityImages } from '../models/asset/facility-image';
import { Timeslot } from '../models/asset/timeslots'
import { FacilityVenueImages } from '../models/asset/facility-venue-image';
import { ConfigurationImage } from '../models/asset/configuration-image';
import { BaseResource } from '../models/base-resource';
import { Utility } from 'src/modules/utility';
import { DomSanitizer } from '@angular/platform-browser';
import { VenueWiseAssets } from 'src/modules/models/regular-portal/location-resource';
import { AssetType } from 'src/modules/models/regular-portal/AssetType';
import { VenueAssetsearchparams } from 'src/modules/models/regular-portal/venue-assets-search-param'

@Injectable({
    providedIn: 'root'
})
export class AssetService {

    constructor(private _baseService: BaseService, public sanitizer: DomSanitizer
    ) {

    }
    //todo: parameter, not used
    assetSearch(parameters: Assetsearchparams): Observable<AssestSearchResult> {
        return this._baseService.WebAPIGet("api/v4.1/assets?" + parameters.geturlforsearch()).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                //console.log("Deserialized web api output");
                //console.log(json);
                //console.log("Convert to object");
                var lst: Asset[] = [];
                json.forEach((c, i) => {
                    lst.push((c as Asset));
                });
                //console.log(lst);
                //var arr = new Serializer().serialize(lst);
                //console.log('serilized object');
                //console.log(arr);
                var assetsearch = { assetsList: lst, numberOfResult: response['meta'].TotalResults };
                return assetsearch
            })
        );
    }

    //load facility details
    facilityDetail(assertId: string, saleschannelId: number): Observable<Facility> {
        var thispage = this;
        return this._baseService.WebAPIGet("api/v4.1/assets/" + assertId + "?saleschannelId=" + saleschannelId + "&include=Facility.Amenities,documents,Venue,Item,Facility.configurations").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                //console.log("Deserialized web api output");
                ///console.log(json);
                //console.log("Convert to object");
                var assert: Facility = new Facility();
                assert.id = json.id;
                assert.active = json.active;
                assert.facilityTypeId = json.facilityTypeId;
                assert.facilityType = json.facilityType;
                assert.facilityCategoryId = json.facilityCategoryId;
                assert.facilityCategory = json.facilityCategory;
                assert.capacity = json.capacity;
                assert.unitOfMesurementId = json.unitOfMesurementId;
                assert.unitOfMesurement = json.unitOfMesurement;
                assert.name = json.name;
                assert.url = json.url;
                assert.openingTime = json.openingTime;
                assert.closingTime = json.closingTime;
                assert.description = thispage.sanitizer.bypassSecurityTrustHtml(json.description);
                assert.keywords = json.keywords;
                assert.operationalMinutes = json.operationalMinutes;
                assert.directions = json.directions;
                assert.facilityTypeId = json.facilityTypeId;

                if ((json.latitude != undefined || json.latitude != "") && (json.longitude != undefined || json.longitude != "")) {
                    assert.latitude = json.latitude;
                    assert.longitude = json.longitude;
                }
                if (json.venue) {
                    assert.venue.id = json.venue.id;
                    assert.venue.name = json.venue.name;
                    assert.venue.active = json.venue.active;
                    assert.venue.defaultFunctionSheetMethod = json.venue.defaultFunctionSheetMethod;
                    assert.venue.description = json.venue.description;
                    assert.venue.emailInvoiceType = json.venue.emailInvoiceType;
                    assert.venue.email = json.venue.email;
                    assert.venue.etag = json.venue.etag;
                    assert.venue.fax = json.venue.fax;
                    assert.venue.googleMapUrl = json.venue.googleMapUrl;
                    assert.venue.latitude = json.venue.latitude;
                    assert.venue.longitude = json.venue.longitude;
                    assert.venue.organisation = json.venue.organisation;
                    assert.venue.prefix = json.venue.prefix;
                    assert.venue.siteCode = json.venue.siteCode;
                    assert.venue.taxTypeName = json.venue.taxTypeName;
                    assert.venue.telephone = json.venue.telephone;
                    assert.venue.url = json.venue.url;
                    assert.venue.userDefault = json.venue.userDefault;
                    assert.venue.venueType = json.venue.venueType;
                    assert.venue.venueTypeId = json.venue.venueTypeId;
                }

                if (json.item != undefined && json.item.length > 0) {
                    json.item.forEach((c, i) => {
                        let item = (c as Item);
                        if (c.salesCategories != undefined && c.salesCategories.length > 0) {
                            item.salesCategories = c.salesCategories.map(a => +a.id);
                        }
                        assert.items.push(item);
                    });
                }

                //if (json.configurations) {
                //    assert.eventConfiguration.id = json.configurations[0].id;
                //    assert.configurations = json.configurations;
                //}
                assert.configurations = [];
                if (json.configurations != undefined) {
                    json.configurations.forEach((c, i) => {
                        let configuration = (c as Configuration);
                        if (c.internetEnabled == true) {
                            if (c.internetName != undefined && c.internetName != "") {
                                configuration.name = c.internetName;
                            }
                            if (c.configurationCategoryId != undefined) {
                                configuration.configurationCategoryId = c.configurationCategoryId;
                            }

                            assert.configurations.push(configuration);
                        }

                    });

                    //  console.log(assert.configarations);


                    //facility.eventConfigurationid = jsonfacility.configurations.id;
                    // facility.configarations = jsonfacility.configurations;
                }

                if (json.amenities != undefined && json.amenities.length > 0) {
                    json.amenities.forEach((c, i) => {
                        assert.amenities.push((c as Amenity));
                    });
                }

                if (json.documents != undefined && json.documents.length > 0) {
                    json.documents.forEach((c, i) => {
                        if (c.documentFile != undefined) {
                            assert.linkedDocuments.push((c as LinkedDocument));
                        }

                    });
                }

                return assert
            })
        );
    }

    //Timeslots
    // filter option like ?fromdate=2019-12-05T16:00:00&todate=2019-12-05T18:00:00
    facilityAvailablity(assertId: string, filterOption: string): Observable<object[]> {
        return this._baseService.WebAPIGet("api/v4.1/assets/" + assertId + "/timeslots?" + filterOption).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                //var slots = json as Timeslot[];

                var slots: Timeslot[] = [];
                if (json != undefined && json.length > 0) {
                    json.forEach(t => {
                        let timeslot = new Timeslot();
                        timeslot.preliminary = t.preliminary;
                        timeslot.multidayId = t.multidayId;
                        timeslot.capacity = t.capacity;
                        timeslot.allocatedQuantity = t.allocatedQuantity;
                        timeslot.startTime = Utility.convertISOToDate(t.startTime);
                        timeslot.endTime = Utility.convertISOToDate(t.endTime);
                        timeslot.reserved = t.reserved;
                        timeslot.asset = t.asset as BaseResource;
                        timeslot.eventConfiguration = t.eventConfiguration as BaseResource;
                        timeslot.privateEventInstance = t.privateEventInstance as BaseResource;
                        timeslot.type = t.type;
                        if (t.owner != undefined && t.owner != null) {
                            let timeslotOwner = new Timeslot();
                            timeslotOwner.preliminary = t.owner.preliminary;
                            timeslotOwner.multidayId = t.owner.multidayId;
                            timeslotOwner.capacity = t.owner.capacity;
                            timeslotOwner.allocatedQuantity = t.owner.allocatedQuantity;
                            timeslotOwner.startTime = Utility.convertISOToDate(t.owner.startTime);
                            timeslotOwner.endTime = Utility.convertISOToDate(t.owner.endTime);
                            timeslotOwner.reserved = t.owner.reserved;
                            timeslotOwner.asset = t.owner.asset as BaseResource;
                            timeslotOwner.eventConfiguration = t.owner.eventConfiguration as BaseResource;
                            timeslotOwner.privateEventInstance = t.owner.privateEventInstance as BaseResource;
                            timeslotOwner.owner = t.owner.owner as Timeslot;
                            timeslotOwner.type = t.type;
                            timeslot.owner = timeslotOwner;

                        }

                        slots.push(timeslot);

                    });
                }

                return slots;

                //var return_slots: Timeslot[] = [];
                //if (slots) {
                //    //return_slots = slots.filter(t => !t.preliminary && t.startTime != t.endTime);
                //    slots.forEach(t => {
                //        if (/*!t.preliminary &&*/ t.startTime != t.endTime) {
                //            if (t.owner != null) {
                //                var owner = slots.find(o => o.id == t.owner.id && o.preliminary);
                //                if (owner == undefined || owner == null) {
                //                    return_slots.push(t);
                //                }
                //            } else {
                //                return_slots.push(t);
                //            }
                //        }
                //    });
                //}
                ////remove preliminary slots preliminary
                //return return_slots;

            })
        );
    }
    timeslotSearch(assertId: string, fromDate: string, toDate: string): Observable<Timeslot[]> {
        return this._baseService.WebAPIGetResponse("api/v4.1/assets/timeslots?FromDate=" + fromDate +
            "&toDate=" + toDate +
            "&AssetId=" + assertId, true).pipe(
                map((response: HttpResponse<any>) => {
                    var json = new Deserializer().deserialize(response.body);
                    // need to do timeslot mapping
                    //return (json as Timeslot[])

                    //   var slots = json as Timeslot[];
                    var slots: Timeslot[] = [];
                    if (json != undefined && json.length > 0) {
                        json.forEach(t => {
                            let timeslot = new Timeslot();
                            timeslot.preliminary = t.preliminary;
                            timeslot.multidayId = t.multidayId;
                            timeslot.capacity = t.capacity;
                            timeslot.allocatedQuantity = t.allocatedQuantity;
                            timeslot.startTime = Utility.convertISOToDate(t.startTime);
                            timeslot.endTime = Utility.convertISOToDate(t.endTime);
                            timeslot.reserved = t.reserved;
                            timeslot.asset = t.asset as BaseResource;
                            timeslot.eventConfiguration = t.eventConfiguration as BaseResource;
                            timeslot.privateEventInstance = t.privateEventInstance as BaseResource;
                            timeslot.type = t.type;
                            if (t.owner != undefined && t.owner != null) {
                                let timeslotOwner = new Timeslot();
                                timeslotOwner.preliminary = t.owner.preliminary;
                                timeslotOwner.multidayId = t.owner.multidayId;
                                timeslotOwner.capacity = t.owner.capacity;
                                timeslotOwner.allocatedQuantity = t.owner.allocatedQuantity;
                                timeslotOwner.startTime = Utility.convertISOToDate(t.owner.startTime);
                                timeslotOwner.endTime = Utility.convertISOToDate(t.owner.endTime);
                                timeslotOwner.reserved = t.owner.reserved;
                                timeslotOwner.asset = t.owner.asset as BaseResource;
                                timeslotOwner.eventConfiguration = t.owner.eventConfiguration as BaseResource;
                                timeslotOwner.privateEventInstance = t.owner.privateEventInstance as BaseResource;
                                timeslotOwner.owner = t.owner.owner as Timeslot;
                                timeslotOwner.type = t.type;
                                timeslot.owner = timeslotOwner;
                            }

                            slots.push(timeslot);

                        });
                    }

                    return slots;

                    //var return_slots: Timeslot[] = [];
                    ////remove preliminary slots preliminary
                    //if (slots) {
                    //    //return_slots = slots.filter(t => !t.preliminary && t.startTime != t.endTime);
                    //    slots.forEach(t => {
                    //        if (/*!t.preliminary &&*/ t.startTime != t.endTime) {
                    //            if (t.owner != null) {
                    //                var owner = slots.find(o => o.id == t.owner.id && o.preliminary);
                    //                if (owner == undefined || owner == null) {
                    //                    return_slots.push(t);
                    //                }
                    //            } else {
                    //                return_slots.push(t);
                    //            }
                    //        }
                    //    });
                    //}

                    //return return_slots;
                })
            );
    }
    //todo: parameter
    facilitySearchByVenue(FilterOption: string): Observable<Facility[]> {
        return this._baseService.WebAPIGet("api/v4.1/assets" + FilterOption).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                //console.log("Deserialized web api output");
                //console.log(json);
                //console.log("Convert to object");
                var lst: Facility[] = [];
                json.forEach((c, i) => {
                    c.url = environment.ApiUrl + "dist/assets/images/no-image.png";
                    lst.push((c as Facility));
                });
                return lst;
            })
        );
    }

    getVenueRoomsImages(facilities: any) {
       
        if (facilities) {
            facilities.forEach((c, i) => {
                c.url = environment.ApiUrl + "dist/assets/images/no-image.png";
            
            });

            var facilitiesIds = facilities.map(x => { return x.id; });
        }

      
        //debugger;
        if (facilitiesIds == "") {
            return null;
        } else {
            return this._baseService.InternalWebAPIGET("api/assets/LoadFacilityVenueRoomsImages/" + facilitiesIds).pipe(
                map((response) => {
                    //console.log("19444")
                    var img = (response as FacilityVenueImages[]);
                    if (img) {
                        img.forEach((c, i) => {
                            if (facilities) {
                                var facilityIndex = facilities.findIndex(i => i.id == c.id);
                                if (facilityIndex > -1) {
                                    var facilityObj = facilities[facilityIndex];
                                    facilityObj.url = environment.ApiUrl + c.url;
                                    facilities[facilityIndex] = facilityObj;
                                }
                            }
                        });
                    }
                    return facilities
                })
            );
        }
    }

    getVenueRoomsImagesForCart(facilities: any) {
       
        if (facilities) {
            facilities.forEach((c, i) => {
                c.url = environment.ApiUrl + "dist/assets/images/no-image.png";
               
            });
            var facilitiesIds = facilities.map(x => { return x.id; });
        }

   
        //debugger;
        if (facilitiesIds == "") {
            return null;
        } else { //LoadFacilityVenueRoomsImages
          
            return this._baseService.InternalWebAPIPOST("api/assets/LoadFacilityThumbnails/", facilitiesIds).pipe(
                map((response) => {
                    //console.log("19444")
                    var img = (response as FacilityVenueImages[]);
                    if (img) {
                        img.forEach((c, i) => {
                            if (facilities) {
                                var indxes = facilities.map((e, index) => e.id === c.id ? index : '').filter(String);

                                if (indxes) {
                                    indxes.forEach((x, y) => {
                                        var facilityObj = facilities[x];
                                        facilityObj.url = environment.ApiUrl + c.url;
                                        facilities[x] = facilityObj;
                                    });
                                }
                                //var facilityObj = facilities[i];
                                //facilityObj.url = environment.ApiUrl + c.url;
                                //facilities[i] = facilityObj;

                            }
                        });
                    }
                    return facilities
                })
            );
        }
    }

    getFacilityThumbnails(facilities: any) {
       
        if (facilities) {
            facilities.forEach((c, i) => {
                c.url = environment.ApiUrl + "dist/assets/images/no-image.png";
             
            });
            var facilitiesIds = facilities.map(x => { return x.id; });
        }

        //debugger;
        if (facilitiesIds == "") {
            return null;
        } else {
           
            return this._baseService.InternalWebAPIPOST("api/assets/LoadFacilityThumbnails/", facilitiesIds).pipe(
                map((response) => {
                    var img = (response as FacilityVenueImages[]);
                    if (img) {
                        img.forEach((c, i) => {
                            if (facilities) {
                                var facilityIndex = facilities.findIndex(i => i.id == c.id);
                                if (facilityIndex > -1) {
                                    var facilityObj = facilities[facilityIndex];
                                    facilityObj.url = environment.ApiUrl + c.url;
                                    facilities[facilityIndex] = facilityObj;
                                }
                            }
                        });
                    }
                    return facilities
                })
            );
        }
    }



    getFacilityThumbnailsImages(ids: any) {

        if (ids == "") {
            return null;
        } else {

            return this._baseService.InternalWebAPIGET("api/assets/LoadFacilityThumbnails/" + ids).pipe(
                map((response) => {
                    var img = (response as FacilityVenueImages[]);
                    return img
                })
            );
        }
    }

    getItemThumbnails(items: any) {
       
        if (items) {
            items.forEach((c, i) => {
                c.url = environment.ApiUrl + "dist/assets/images/no-image.png";
               
            });

            var itemIds = items.map(x => { return x.id; });
        }
        //debugger;
        if (itemIds == "") {
            return null;
        } else {
           
            return this._baseService.InternalWebAPIGET("api/assets/LoadItemThumbnails/" + itemIds).pipe(
                map((response) => {
                    var img = (response as FacilityVenueImages[]);
                    return img;
                })
            );
        }
    }

    facilittySearch(parameters: Assetsearchparams, textLimit?: string, showAvailableFacilities?: boolean): Observable<FacilitySerachResult> {
        var thispage = this;
        return this._baseService.WebAPIGetResponse("api/v4.1/assets?" + parameters.geturlforsearch(showAvailableFacilities), true).pipe(
            //tap(res => { console.log(res) }),
            map((response: HttpResponse<any>) => {
                var retObj = new FacilitySerachResult();
                retObj.facilities = [];
                retObj.itemList = [];
                retObj.totalFacilities = 0;
                retObj.unfilteredResults = 0;

                if (response.status == 200) {
                    //debugger;
                    if (response.body['meta']) {
                        retObj.totalFacilities = response.body['meta'].totalResults;
                        retObj.unfilteredResults = response.body['meta'].unfilteredResults;
                    }
                    var json = new Deserializer().deserialize(response.body);
                    //console.log("Deserialized web api output");
                    //console.log(json);
                    //console.log("Convert to object");


                    json.forEach((jsonfacility, i) => {
                        let facility: FacilitySummary = new FacilitySummary();

                        facility.id = jsonfacility.id;
                        facility.name = jsonfacility.name;
                        facility.type = jsonfacility.type;
                        facility.tid = jsonfacility.tid;
                        /*facility.webDescription = (jsonfacility.description);
                        facility.description = thispage.sanitizer.bypassSecurityTrustHtml(jsonfacility.description);
                        if (textLimit && textLimit != "" && jsonfacility.description) {
                            facility.shortDescription = thispage.sanitizer.bypassSecurityTrustHtml(jsonfacility.description.slice(0,textLimit));
                        } else
                        {
                            facility.shortDescription = thispage.sanitizer.bypassSecurityTrustHtml(jsonfacility.description);
                        }*/
                        if(jsonfacility.description)
                            facility.webDescription = (jsonfacility.description);
                        else
                            facility.webDescription ="";
                        facility.description = thispage.sanitizer.bypassSecurityTrustHtml(facility.webDescription);
                        if (textLimit && textLimit != "") {
                            facility.shortDescription = thispage.sanitizer.bypassSecurityTrustHtml(facility.webDescription.slice(0, parseInt(textLimit)));
                        }
                        else {
                            facility.shortDescription = thispage.sanitizer.bypassSecurityTrustHtml(facility.webDescription);
                        }
                        facility.capacity = jsonfacility.capacity;
                        facility.openingTime = jsonfacility.openingTime;
                        facility.closingTime = jsonfacility.closingTime;
                        facility.facilityTypeId = jsonfacility.facilityTypeId;

                        //facility.startTime = parameters.starttime;
                        //facility.endTime = parameters.endtime;

                        /*
                        cheack if name availabe in asset if not get venue name
                        */

                        if (jsonfacility.directions != undefined || jsonfacility.directions != "") {
                            facility.directions = jsonfacility.directions;

                        } else {
                            facility.directions = jsonfacility.venue.direction
                        }
                        facility.venue = jsonfacility.venue;
                        if (jsonfacility.latitude == undefined || jsonfacility.latitude == "") {
                            if (jsonfacility.venue) {
                                facility.latitude = jsonfacility.venue.latitude;
                                facility.longitude = jsonfacility.venue.longitude;
                            }
                        } else {
                            facility.latitude = jsonfacility.latitude;
                            facility.longitude = jsonfacility.longitude;
                        }
                        // facility.amenities = jsonfacility.ameniies;
                        facility.configurations = [];
                        if (jsonfacility.configurations != undefined) {
                            jsonfacility.configurations.forEach((c, i) => {
                                let configuration = (c as Configuration);
                              if (c.internetEnabled == true && (parameters.filterattendens == "0" || configuration.capacity>=(+parameters.filterattendens))) {
                                    if (c.internetName != undefined && c.internetName != "" ) {
                                        configuration.name = c.internetName;
                                    }
                                    if (c.configurationCategoryId != undefined) {
                                        configuration.configurationCategoryId = c.configurationCategoryId;
                                    }

                                    //1   No Plan
                                    //2   Table Plan
                                    //3   Seating Chart
                                    //4   Car Park  
                                    //if (c.configurationTypeId != undefined) {
                                    //    switch (c.configurationTypeId) {
                                    //        case 1:
                                    //            configuration.configurationTypeId = c.configurationTypeId;
                                    //            break;
                                    //        case 2:
                                    //            configuration.configurationTypeId = c.configurationTypeId;
                                    //            break;
                                    //        case 3:
                                    //            configuration.configurationTypeId = c.configurationTypeId;
                                    //            break;
                                    //        case 4:
                                    //            configuration.configurationTypeId = c.configurationTypeId;
                                    //            break;
                                    //    }
                                    //}
                                    facility.configurations.push(configuration);
                                }

                            });

                            console.log(facility.configurations);


                            //facility.eventConfigurationid = jsonfacility.configurations.id;
                            // facility.configarations = jsonfacility.configurations;
                        }

                        if (jsonfacility.amenities != undefined && jsonfacility.amenities.length > 0) {
                            jsonfacility.amenities.forEach((c, i) => {
                                //console.log("", c);
                                facility.amenities.push((c as Amenity));
                            });
                        }

                        var filltereditemarry = jsonfacility.item;
                        if (filltereditemarry != undefined && filltereditemarry.length != 0) {
                            filltereditemarry.forEach((jsonItem, i) => {
                                var itm = jsonItem as Item
                                facility.items.push(itm);// populate from item search
                                retObj.itemList.push(parseInt(itm.id));
                            });
                        }

                        retObj.facilities.push(facility);
                    });
                }
                return retObj;
            },
                catchError(err => { console.log(err); return (err) })
            ));

    }

    //for dropdown loading
    facilityList(): Observable<any[]> {
        return this._baseService.InternalWebAPIGET("api/assets").pipe(
            map((response) => {
                return (response as any[])
            })
        );
    }

    //Load facility images by id
    loadFacilityImages(facilityId: string): Observable<FacilityImages> {
        return this._baseService.InternalWebAPIGET("api/assets/LoadFacilityImages/" + facilityId).pipe(
            map((response) => {
                var ret = new FacilityImages();
                var img = (response as FacilityImages);
                if (img.small)
                    img.small.forEach(i =>
                        ret.small.push(environment.ApiUrl + i)
                    );
                if (img.medium)
                    img.medium.forEach(i =>
                        ret.medium.push(environment.ApiUrl + i)
                    );
                if (img.large) {
                    img.large.forEach(i =>
                        ret.large.push(environment.ApiUrl + i)
                    );
                }
                if (ret.large.length == 0) {
                    ret.large.push(environment.ApiUrl + "Content/images/facility-image.jpg")
                }
                return ret
            })
        );

    }

    IsUpsellAvailable(salesCategoryIds: string): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/assets/IsUpsellAvailable?salesCategoryIds=" + salesCategoryIds).pipe(
            map((response: any) => {
                return (response);
            })
        );
    }


    getConfigurationImages(configurations: Configuration[]): Observable<any> {
        return this._baseService.Internal_WebAPI_POST("api/assets/configuration-images", configurations).pipe(
            map((response: HttpResponse<any>) => {
                var images = (response.body as ConfigurationImage[]);
                return images;
            })
        );
    }

    loadPriceAvaaliblty(itemID, concessionId): Observable<any> {
        return this._baseService.InternalWebAPIGET("api/product/prices?itemId=" + itemID + "&priceConcessionId=" + concessionId).pipe(
            map((response: any)  => {
                var priceItemData = response.Data;
                return priceItemData;
            })
        );

    }


    getFacilityAndResource(parameters: VenueAssetsearchparams): Observable<VenueWiseAssets[]> {
        return this._baseService.InternalWebAPIGET("api/assets/location-resources?" + parameters.geturlforsearch()).pipe(
            //return this._baseService.InternalWebAPIGET("api/assets/location-resources").pipe(
            map((response) => {
                return response;
                var json = new Deserializer().deserialize(response);
                var retObj = new VenueWiseAssets();

            })
        );

    };


    GetAssetType(): Observable<AssetType> {
        return this._baseService.InternalWebAPIGET("api/assets/asset-type?assetClassId=1").pipe(
            map((response) => {
                return response;
            })
        );

    };







} 

﻿import { BaseParam } from '../base-param';

export  class ItemSearchParams extends BaseParam
{
    itemidlist: number[] = []
    ItemTypeid: number
    page: number =1
    pagesize: number =1
    FromDate: string
    Todate: string
    salesChannelId: number
    geturlforsearch() {

        return "ItemIds=" + this.getitemidlist() + "&ItemTypeid=" + this.ItemTypeid + "&FromDate=" + this.FromDate + "&ToDate=" + this.Todate + "&SalesChannelId=" + this.salesChannelId
            + "&include=itemPriceGroups,ItemAssets,Question,itemSalesChannels";
    }

    getitemidlist() {

        return this.itemidlist.join(",");
    }

}
﻿import { Component, OnInit, Input, Inject } from '@angular/core';
//Angular Material
import { MAT_SNACK_BAR_DATA } from '@angular/material';
@Component({
    selector: 'wp-error-message',
    templateUrl: './wp-error-message.html'
})

export class WPErrorMessage implements OnInit {

    //if errorMessageType =1 then button header will display
    //if errorMessageType =2 only the text
    @Input() errorMessageType: number;
    @Input() displayMessage: string;
    @Input() messageSummary: any;
    @Input() MessageDetail: any;
    @Input() MessageDetailList: any[];
    @Input() displyconflitButton: any;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any = null) {

    }


    ngOnInit() {
        console.log();

        if (this.MessageDetail == undefined) {
            this.MessageDetail = this.data.messageDetail;
        }

        if (this.data.messageDetailList != undefined) {
            this.MessageDetailList = this.data.messageDetailList;
        }
       
    }

}
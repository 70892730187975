import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment'
import { ViewportScroller } from '@angular/common';
import * as _ from 'lodash';
//cart
import { Store, ICart, SetContact } from 'src/modules/store/index';
import { Observable } from "rxjs";

//services
import { CustomerService } from 'src/modules/services/customer.service';

//Angular Material
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';

//classes
import { Contact } from 'src/modules/models/client/contact';
import { ClientEmailModel } from 'src/modules/models/client/client-email-model';
import { IndividualClient } from 'src/modules/models/client/Individual-client';
import { CorporateClient } from 'src/modules/models/client/corporate-client';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component';
import { ReferenceData } from 'src/modules/models/client/referenceData';
import { ClientRegistration, CommunicationTypeValidation } from 'src/modules/models/settings/casual-portal/client-registration-config';
import { Client, Address, User, Venue, Tax, CommunicationMethod } from 'src/modules/models/client/client';

@Component({
  selector: 'opt-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrls: ['./manage-contacts.component.scss']
})
export class ManageContactsComponent implements OnInit {

    Client: CorporateClient;
    contactsList: Contact[];
    manageCotactsForm: FormGroup;
    phoneNumberPattern: RegExp;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    nameValidation = /^[A-Za-z ]+$/;

    SelectedTitleId: string;
    SelectedTitleName: string;
    SelectedPhoneTypeId: string;
    SelectedPhoneTypeName: string;
    SelectedEmailTypeId: string;
    SelectedEmailTypeName: string;
    DefaultCountryId: number;
    Titles: Array<ReferenceData>;
    CommunicationTypes: Array<ReferenceData>;
    CommunicationTypesPhone: Array<ReferenceData>;
    CommunicationTypesEmail: Array<ReferenceData>;
    CommunicationTypeValidation: CommunicationTypeValidation[] = [];
    placholderSampleNumber: string;
    emailType: string;
    phoneType: string;
    emailAddress: string;
    phoneNumber: string;
    clientDetails: Client;
    isEmailAddressChanged: boolean = false;
    _email: string;
    isEditContact: boolean = false;
    sendPwResetEmail: boolean;
    editingContactId: string;
    @ViewChild('focusContent') EditElement: ElementRef;
    @ViewChild('focusContactList') ContactsListElement: ElementRef;


    constructor(private _customerService: CustomerService, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public snackBar: MatSnackBar, private vps: ViewportScroller) {

        this.Client = new CorporateClient();
        this.Titles = new Array<ReferenceData>();
        this.CommunicationTypes = new Array<ReferenceData>();
        this.CommunicationTypesPhone = new Array<ReferenceData>();
        this.CommunicationTypesEmail = new Array<ReferenceData>();
        this.DefaultCountryId = 0;
        
    }

    ngOnInit() {
        
        this.InitializeForm();

        this.manageCotactsForm.controls.PersonalEmail_1.valueChanges.subscribe(changedEmail => {

            if (this._email != changedEmail && this.isEditContact) {
                this.isEmailAddressChanged = true;
            }
            else{
                this.isEmailAddressChanged = false;
            }
        })

        this.GetCustomerDetailsList();
        this.GetDropDownDetails();

  }

    InitializeForm() {

        this.manageCotactsForm = this.fb.group({

            FirstName: ['', [Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
            LastName: ['', [Validators.maxLength(50), Validators.pattern(this.nameValidation)]],
            UserName: ['', [Validators.maxLength(50), Validators.required]],
            PersonalPhoneNumber_1: [''],
            PersonalEmail_1: ['', Validators.pattern(this.emailPattern)],

        });
    }

    clearform() {

        this.manageCotactsForm.controls['FirstName'].reset();
        this.manageCotactsForm.controls['LastName'].reset();
        this.manageCotactsForm.controls['UserName'].reset();
        this.manageCotactsForm.controls['PersonalPhoneNumber_1'].reset();
        this.manageCotactsForm.controls['PersonalEmail_1'].reset();
        this.SelectedTitleName = this.Titles[0].name;
        this.SelectedTitleId = this.Titles[0].id;


        this.manageCotactsForm.controls['FirstName'].setErrors(null);
        this.manageCotactsForm.controls['LastName'].setErrors(null);
        this.manageCotactsForm.controls['UserName'].setErrors(null);
        this.manageCotactsForm.controls['PersonalPhoneNumber_1'].setErrors(null);
        this.manageCotactsForm.controls['PersonalEmail_1'].setErrors(null);
    }
    updateform() {
        this.manageCotactsForm.controls['FirstName'].updateValueAndValidity();
        this.manageCotactsForm.controls['LastName'].updateValueAndValidity();
        this.manageCotactsForm.controls['UserName'].updateValueAndValidity();
        this.manageCotactsForm.controls['PersonalPhoneNumber_1'].updateValueAndValidity();
        this.manageCotactsForm.controls['PersonalEmail_1'].updateValueAndValidity();
    }

    SaveCorporateContact() {

        this.updateform();
        if (this.manageCotactsForm.invalid) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Please check the indicated fields.'],
            });
        } else {

            let clientSaveResult = this._customerService.patchPostContact(this.SetContactData())
            clientSaveResult.subscribe(response => {
                if (response.body.Data != null) {

                    this.Getcontacts();
                    this.GetCustomerDetailsList();

                    var message;
                    if (this.isEditContact) {
                        if (this.sendPwResetEmail) {
                            this.sendPwResetEmail = false;
                            this.SendPasswordResetEmail();

                        }
                        message = 'Contact has been edited successfully.'
                        this.isEditContact = false;
                        this.ScrollPopup("end", '.contact-list-container', this.ContactsListElement)
                    }
                    else {
                        this.SendPasswordResetEmail();
                        message = 'Contact has been added successfully.'
                        this.ScrollPopup("end", '.contact-list-container', this.ContactsListElement)
                    }
                    this.clearform();
                    this.openSnackBarSuccess([message]);                    
                }
                else if (response.body.IsError) {

                    let errorData = [];
                    let apiErrors = [];
                    apiErrors = response.body.Errors;

                    if (apiErrors.length > 0) {

                        apiErrors.forEach((err, indx) => {
                            errorData.push(err.Detail);
                        })

                        this.snackBar.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: errorData,
                        });
                    }

                }
            });
       }
    
    }

    SetContactData() {

        let _contact = new Contact();

        if (!this.isEditContact) {

            _contact = new Contact();
           // _contact.id = "-1";
            _contact.active = true;
            _contact.titleId = Number(this.SelectedTitleId);
            _contact.firstName = this.manageCotactsForm.controls.FirstName.value;
            _contact.lastName = this.manageCotactsForm.controls.LastName.value;
            _contact.userName = this.manageCotactsForm.controls.UserName.value;
            _contact.decisionMaker = false;

            _contact.address = new Address();
            _contact.address = this.clientDetails.address;

            _contact.defaultVenue = new Venue();
            _contact.defaultVenue.id = this.clientDetails.defaultVenue.id;

            let _address = new Address();
            _address.id = "-10"
            _address.address1 = this.clientDetails.address.address1;
            _address.address2 = this.clientDetails.address.address2;
            _address.address3 = this.clientDetails.address.address3;
            _address.postCode = this.clientDetails.address.postCode;
            _address.city = this.clientDetails.address.city;
            _address.county = this.clientDetails.address.county;
            _address.countryID = this.clientDetails.address.countryID;

            _contact.invoiceAddress = new Address();
            _contact.invoiceAddress = _address;

            _contact.mailingAddress = new Address();
            _contact.mailingAddress = _address;

            _contact.deliveryAddress = new Address();
            _contact.deliveryAddress = _address;

            _contact.communicationMethods = [] as CommunicationMethod[];
            if (this.manageCotactsForm.controls.PersonalPhoneNumber_1.value != null && this.manageCotactsForm.controls.PersonalPhoneNumber_1.value != "") {
                let comMethod = new CommunicationMethod();
                comMethod.id = "-1";
                comMethod.communicationTypeID = this.SelectedPhoneTypeId;
                comMethod.value = this.manageCotactsForm.controls.PersonalPhoneNumber_1.value;
                _contact.communicationMethods.push(comMethod);
            }
            if (this.manageCotactsForm.controls.PersonalEmail_1.value != null && this.manageCotactsForm.controls.PersonalEmail_1.value != "") {
                let comMethod = new CommunicationMethod();
                comMethod.id = "-2";
                comMethod.communicationTypeID = this.SelectedEmailTypeId;
                comMethod.value = this.manageCotactsForm.controls.PersonalEmail_1.value;
                _contact.communicationMethods.push(comMethod);
            }

            _contact.accountManager = new User();
            _contact.accountManager.id = this.clientDetails.accountManager.id;
            _contact.client = new Client();
            _contact.client.id = this.data.primaryContact.client.id;
        }

        else if (this.isEditContact && this.contactsList.length > 0) {
            let _cntList = _.cloneDeep(this.contactsList);

            _cntList.forEach((cnt, index) => {
                               
                if (cnt.id == this.editingContactId) {

                    cnt.titleId = Number(this.SelectedTitleId);
                    cnt.title = this.SelectedTitleName;
                    cnt.firstName = this.manageCotactsForm.controls.FirstName.value;
                    cnt.lastName = this.manageCotactsForm.controls.LastName.value;

                    //cnt.communicationMethods = [] as CommunicationMethod[];
                    if (this.manageCotactsForm.controls.PersonalPhoneNumber_1.value != null && this.manageCotactsForm.controls.PersonalPhoneNumber_1.value != "") {
                        let comMethod = new CommunicationMethod();

                        var _Comindx = cnt.communicationMethods.findIndex(cm => cm.communicationTypeID == this.SelectedPhoneTypeId)
                        if (_Comindx != undefined && _Comindx > -1) {
                            comMethod.id = cnt.communicationMethods[_Comindx].id;

                            comMethod.communicationType = this.SelectedPhoneTypeName;
                            comMethod.communicationTypeID = this.SelectedPhoneTypeId;
                            comMethod.value = this.manageCotactsForm.controls.PersonalPhoneNumber_1.value;
                            cnt.communicationMethods[_Comindx] = comMethod;
                        }
                        else if (_Comindx == -1) {

                            comMethod.id = "-1";

                            comMethod.communicationType = this.SelectedPhoneTypeName;
                            comMethod.communicationTypeID = this.SelectedPhoneTypeId;
                            comMethod.value = this.manageCotactsForm.controls.PersonalPhoneNumber_1.value;
                            cnt.communicationMethods[cnt.communicationMethods.length] = comMethod;
                        }
                        
                    }
                    if (this.manageCotactsForm.controls.PersonalEmail_1.value != null && this.manageCotactsForm.controls.PersonalEmail_1.value != "") {
                        let comMethod = new CommunicationMethod();

                        var _Comindx = cnt.communicationMethods.findIndex(cm => cm.communicationTypeID == this.SelectedEmailTypeId)
                        if (_Comindx != undefined && _Comindx > -1) {
                            comMethod.id = cnt.communicationMethods[_Comindx].id;

                            comMethod.communicationType = this.SelectedEmailTypeName;
                            comMethod.communicationTypeID = this.SelectedEmailTypeId;
                            comMethod.value = this.manageCotactsForm.controls.PersonalEmail_1.value;
                            cnt.communicationMethods[_Comindx] = comMethod;
                        }

                       
                    }
                    //_contacts.push(cnt);
                    _contact =  _.cloneDeep(cnt);
                    _contact.userName = undefined;
                }
            });
        }
        return _contact;
    }

    Cancelled() {

        this.clearform();
        this.updateform();
        this.isEditContact = false;
        this.isEmailAddressChanged = false;
    }

    GetCustomerDetailsList() {

        this._customerService.GetContactDetails(this.data.primaryContact.client.id).subscribe(responses => {
            if (responses) {
                console.log("response ", responses);

                this.clientDetails =new Client();
                this.contactsList = [] as Contact[];
                responses.forEach((response, index) => {

                    var cnt = new Contact();
                    this.clientDetails = response.client;
                    cnt.client = response.client;
                    cnt.firstName = response.firstName;
                    cnt.lastName = response.lastName;
                    cnt.userName = response.userName;
                    cnt.id = response.id;
                    cnt.ref = response.ref;
                    cnt.title = response.title;
                    cnt.communicationMethods = response.communicationMethods;
                    cnt.invoiceAddress = response.invoiceAddress;
                    cnt.mailingAddress = response.mailingAddress;
                    cnt.deliveryAddress = response.deliveryAddress;
                    cnt.active = response.active;
                    cnt.notes = response.notes;
                    cnt.accountManager = new User();
                    cnt.accountManager = response.accountManager;
                    cnt.defaultVenue = new Venue()
                    cnt.defaultVenue = response.defaultVenue;
                    cnt.isPrimaryContact = response.isPrimaryContact;
                    cnt.dateRegistered = response.dateRegistered;
                    if (response.communicationMethods != undefined && response.communicationMethods.length > 0) {
                        response.communicationMethods.forEach(function (communicationMethod) {
                            if (communicationMethod.communicationType.toLowerCase().indexOf("email") > -1) {
                                cnt.invoiceEmailAddress = communicationMethod.value;
                            }
                        });
                    }

                    if (response.profilePicture != undefined)
                        // cnt.profilePicture = environment.WebApiUrl + response.profilePicture;
                        if (response.client != undefined) {



                            if (response.client.type != undefined) {
                                cnt.type = response.client.type;
                                if (response.client.type == "corporateClient") {
                                    cnt.client = new CorporateClient();
                                    (cnt.client as CorporateClient).companyRegistrationNo = response.client.companyRegistrationNo;
                                    (cnt.client as CorporateClient).companyName = response.client.companyName;
                                    cnt.client.communicationMethods = response.client.communicationMethods;
                                    cnt.client.accountManager = response.client.accountManager;
                                    cnt.client.defaultVenue = response.client.defaultVenue;
                                    cnt.client.clientCategoryId = response.client.clientCategoryId;
                                    cnt.client.clientTypeId = response.client.clientTypeId;
                            
                                    cnt.client.address = response.client.address;
                                    (cnt.client as CorporateClient).legalName = response.client.legalName;

                                }
                                else
                                    cnt.client = new IndividualClient();

                                cnt.client.id = response.client.id;

                            }

                            if (response.client.clientName != undefined) {
                                cnt.client.clientName = response.client.clientName;
                            }
                        }

                    this.contactsList.push(cnt);
                });


            }

        });
    }

    GetDropDownDetails() {

        let titleResult = this._customerService.getClientTiltles();
        titleResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let title of data.data) {
                    let ti = new ReferenceData();
                    ti.id = title.id;
                    ti.name = title.attributes.name;

                    this.Titles.push(ti);
                }
            }
            this.SelectedTitleId = this.Titles[0].id;
            this.SelectedTitleName = this.Titles[0].name;
        },
            err => {

            }
        );
        let communicationTypeResult = this._customerService.getCommunicationTypes();
        communicationTypeResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let comType of data.data) {
                    if (comType.attributes.format == 1) {
                        let comPhone = new ReferenceData();
                        comPhone.id = comType.id;
                        comPhone.name = comType.attributes.name;

                        this.CommunicationTypesPhone.push(comPhone); //Get phone com types
                    }
                    else if (comType.attributes.format == 2) {
                        let comEmail = new ReferenceData();
                        comEmail.id = comType.id;
                        comEmail.name = comType.attributes.name;

                        this.CommunicationTypesEmail.push(comEmail); //Get email com types
                    }
                    else {
                        let com = new ReferenceData();
                        com.id = comType.id;
                        com.name = comType.attributes.name;

                        this.CommunicationTypes.push(com); //Get other com types
                    }

                }
            }
            this.SelectedPhoneTypeId = this.CommunicationTypesPhone[0].id;
            this.SelectedPhoneTypeName = this.CommunicationTypesPhone[0].name;
            this.SelectedEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedEmailTypeName = this.CommunicationTypesEmail[0].name;
            this.mobileNumberValidation("PersonalPhoneNumber_1", this.SelectedPhoneTypeId);



        },
            err => {

            }
        );
    }

    mobileNumberValidation(validationControlName: string, SelectedPhoneTypeId: string) {
        var pattern: RegExp;
        var sampleValueArray = [];
        this.manageCotactsForm.get(validationControlName).clearValidators();

        this.CommunicationTypeValidation = environment.CommunicationTypeValidation;
        if (this.CommunicationTypeValidation.length > 0) {
            this.CommunicationTypeValidation.forEach(ctv => {
                if (ctv.Rule != undefined && ctv.Rule.length > 0 && ctv.CommunicationTypeId != undefined && ctv.CommunicationTypeId != "") {
                    var ids = ctv.CommunicationTypeId.split(",");
                    if (ids != undefined && ids.length > 0 && ids.indexOf(SelectedPhoneTypeId) > -1) {
                        ctv.Rule.forEach(rule => {
                            if (this.DefaultCountryId.toString() == rule.countryId) {
                                pattern = new RegExp(rule.Value);
                                if (rule.SampleNumber != "") {
                                    sampleValueArray.push(rule.SampleNumber);
                                }
                            }
                        });

                        if (pattern == undefined || pattern == null) {
                            var selectedRule = ctv.Rule.find(a => a.countryId == "");
                            pattern = new RegExp(selectedRule.Value);
                        }
                    }
                }
            });
            this.placholderSampleNumber = sampleValueArray.join(', ');
        }

        if (pattern != undefined && pattern != null) {
            this.manageCotactsForm.get(validationControlName).setValidators([Validators.pattern(pattern), Validators.required])
            this.manageCotactsForm.get(validationControlName).updateValueAndValidity();
        }
    }

    SelectTitle(selectedTitleId: string) {
        this.SelectedTitleId = selectedTitleId;
        this.SelectedTitleName = this.Titles.find(i => i.id === selectedTitleId).name;
    }

    SelectComType(selectedComTypeId: string, basicComType: number, isAdditional: boolean) {
        if (!isAdditional) {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
                this.mobileNumberValidation("PersonalPhoneNumber_1", this.SelectedPhoneTypeId);
            }
            else if (basicComType == 2) {
                this.SelectedEmailTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }       

    }

    Getcontacts() {
        if (this.contactsList) {
            let contct = this.contactsList.filter(cnt => cnt.isPrimaryContact == false);
            return contct;
        }       
    }

    GetEmailType(communicationMethods) {

        let email;
        var emailType;
        communicationMethods.forEach((com, index) => {
            email = this.CommunicationTypesEmail.find(i => i.id == com.communicationTypeID);
            if (email && email != "") {
                emailType = email.name;
            }
        });

        return emailType;
    }

    GetPhoneType(communicationMethods) {

        let phone;
        var phoneType;
        communicationMethods.forEach((com, index) => {
            phone = this.CommunicationTypesPhone.find(i => i.id == com.communicationTypeID);
            if (phone && phone != "") {
                phoneType = phone.name;
            }
        });

        return phoneType;
    }

    GetEmailAddress(communicationMethods) {

        let emailaddrs;
        var emailAddress;
        communicationMethods.forEach((com, index) => {
            emailaddrs = this.CommunicationTypesEmail.find(i => i.id == com.communicationTypeID);
            if (emailaddrs && emailaddrs != "") {
                emailAddress = com.value;
            }
        });

        return emailAddress;
    }

    GetPhoneNumber(communicationMethods) {

        let phoneNum;
        var phoneNumbr;
        communicationMethods.forEach((com, index) => {
            phoneNum = this.CommunicationTypesPhone.find(i => i.id == com.communicationTypeID);
            if (phoneNum && phoneNum != "") {
                phoneNumbr = com.value;
            }
        });

        return phoneNumbr;
    }

    ChangeActiveStatus(status, contact: Contact) {

        if (status) {
            contact.active = true;
        }
        else {
            contact.active = false;
        }

        if (contact.notes && contact.notes.length > 0) {
            contact.notes = null;
        }
        let result = this._customerService.patchPostContact(contact);
        result.subscribe(data => {
            var message;
            if (data.body.IsError == false) {
                message = 'Status has been edited successfully.'
            }
            else {
                message = data.body.ErrorMessage;
            }
            this.openSnackBarSuccess([message]);                
        });
    }

    GetActiveStatus(status, contact) {

        if (status) {
            contact.active = true;
        }
        else {
            contact.active = false;
        }
    }

    PopulateFormToEdit(contact: Contact) {
        this.isEditContact = true;
        this.ScrollPopup("start", '.add-contact-container', this.EditElement)
        this.editingContactId = contact.id;
        this.manageCotactsForm.controls['FirstName'].setValue(contact.firstName);
        this.manageCotactsForm.controls['LastName'].setValue(contact.lastName);

        
        this.manageCotactsForm.controls['PersonalPhoneNumber_1'].setValue(this.GetPhoneNumber(contact.communicationMethods));
        this._email = this.GetEmailAddress(contact.communicationMethods);
        this.manageCotactsForm.controls['PersonalEmail_1'].setValue(this.GetEmailAddress(contact.communicationMethods));
        this.manageCotactsForm.controls['UserName'].setValue(contact.userName);
        this.SelectedTitleName = contact.title;
        //this.SelectedTitleId = contact.titleId.toString();
        this.SelectedPhoneTypeName = this.GetPhoneType(contact.communicationMethods);
        this.SelectedEmailTypeName = this.GetEmailType(contact.communicationMethods);

        
    }

    GetFirstLetterOfName(firstName: string) {
        var initial = firstName.charAt(0)
        return initial;
    }

    openSnackBarSuccess(message) {
        this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: message,
            duration: 3000,
            verticalPosition: 'top',
        });
       
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top',
        });
    }

    ScrollPopup(scrollPosition , _class, element ) {
        const focusingControl: HTMLElement = element.nativeElement.querySelector(_class);
        focusingControl.scrollIntoView({ block: scrollPosition, behavior: "smooth" });
    }

    SendPasswordResetEmail() {

        //let forgetPassword = {
        //    email: this.manageCotactsForm.controls.PersonalEmail_1.value,
        //    type: "ClientEmailModel"
        //}

        let clientEmail = new ClientEmailModel();
        clientEmail.email = this.manageCotactsForm.controls.PersonalEmail_1.value;

        this._customerService.sendForgetPasswordEmail(clientEmail)
            .subscribe(data => {
                console.log("Response of Password reset email api =>",data)
            });
    }

    AcceptConditions(event) {
       // this.isEmailAddressChanged = false;
        if (event.checked == true) {
            this.sendPwResetEmail = true;
        }
        else {
            this.sendPwResetEmail = false;
        }
        
    }

    ActiveStatus(status) {

        if (status == true) {
            return true;
        }
        else {
            return false;
        }
    }

    InactiveStatus(status) {
        if (status == false) {
            return true;
        }
        else {
            return false;
        }
    }
}

﻿import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'CurrencyFormat' })

export class CurrencyFormat implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: number, isNumber: boolean = false): string {
        if (value != undefined && value != null && !isNaN(value)) {
            value = Math.round(value * 1000) / 1000;
            if (isNumber) {
                return this.currencyPipe.transform(value, '', '', '1.2-2').replace(/,/gi, '');
            }
            else {
                return this.currencyPipe.transform(value, environment.Currency, 'symbol', '1.2-2');
            }
        }
        return this.currencyPipe.transform(0, environment.Currency, 'symbol', '1.2-2');
    }
}
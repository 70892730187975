import { Component } from '@angular/core';

@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html'
})

export class SiteHeaderComponent {
    constructor() { }
}

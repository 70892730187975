import { BaseResource } from '../base-resource';
import { BookingStatus } from './booking-status';
import { BookingPackages } from './booking-packages';
import { Notes } from './booking-notes';
import { BookingContact } from './booking-contact';
import { PriceConcession } from './price-group';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'
import { Question } from 'src/modules/models/item/question';

import { jsonIgnore } from 'json-ignore';
import { environment } from 'src/environments/environment';

export class Booking extends BaseResource {
    constructor() {
        super();
        this.type = "booking";
        this.salesChannelId = environment.SalesChannel;
    }

    bookingReference: string;
    attendees: number;
    bookingDate: string;
    confirmedDate: string;
    cancelled: boolean;
    confirmed: boolean;
    margin: number;
    note: string;
    poReference: string;
    salesChannelId: number;
    bookingPackages: BookingPackages[]; ///sss
    @jsonIgnore() entityConfigurationProfileId: number;
    contact: BookingContact
    priceConcession: PriceConcession;
    eventName: string;
    temporary: boolean;
    eventStartDate: string;
    eventEndDate: string;
    statusText: string;
    questionSummaries: any[];
    documents: AdHocDocument[];
    bookingStatus: BookingStatus;
    dueAmount: number;
    invoicedAmount: number;
    grossAmount: number;
    taxAmount: number;
    netAmount: number;
    bookingInvoices: BookingInvoice[];
    paidAmount: number;
    grossAmountExcludingBond: number;
    notes: Notes[];
    bookingTypeId: number;
}

export class BookingInvoice extends BaseResource {
    invoiceReference: string;
    invoiceDate: string;
    totalAmount: number;
    taxAmount: number;
    invoiceOutstandingAmount: number;
    paymentDueDate: string;
    paidAmount: number;
    includeBond: boolean;
}

export class BookingAdapter {
    static populateQuestions(booking, bookingQuestionnaires) {
        let totalCount = 0;
        if (booking && booking.facilitySummaries && booking.facilitySummaries.length > 0) {
            booking.facilitySummaries.forEach((fcs) => {
                if (fcs && fcs.bookingItemSummaries && fcs.bookingItemSummaries.length > 0) {
                    fcs.bookingItemSummaries.forEach((bis) => {
                        var filterdbookingItemQuestionnaires = bookingQuestionnaires.filter(function (bookingQuestionnaire) {
                            return bookingQuestionnaire && bookingQuestionnaire != null && bookingQuestionnaire.bookingItem &&
                                bookingQuestionnaire.bookingItem != null && bookingQuestionnaire.bookingItem.id == bis.bookingItemId;
                        });

                        if (filterdbookingItemQuestionnaires && filterdbookingItemQuestionnaires.length > 0) {
                            bis.question = [];
                            bis.question = bis.question as Question[];
                            filterdbookingItemQuestionnaires.forEach((fbiq) => {
                                if (fbiq.bookingQuestions) {
                                    fbiq.bookingQuestions.forEach(biq => {
                                        let question = biq.question;
                                        question.id = biq.id;
                                      question.isBookingQuestion = true;

                                      if (biq.type == "textQuestionAnswer" && biq.answer && biq.answer.trim() != "") {
                                        question.answeredText = biq.answer;
                                        question.originalAnswer = question.answeredText;
                                        question.isSaved = true;

                                      } else if (biq.type == "booleanQuestionAnswer" && (biq.answer !== undefined)) {
                                        question.answeredText = (biq.answer) ? "Yes" : "No";
                                        question.originalAnswer = question.answeredText;
                                        question.isSaved = true;

                                      }
                                      else if (biq.type == "singleChoiceQuestionAnswer") {
                                        if (biq.bookingAnswerChoice) {
                                          question.answeredText = biq.bookingAnswerChoice.bookingQuestionChoice.id;
                                          question.originalAnswer = question.answeredText;
                                          question.isSaved = true;
                                          question.bookingAnswerChoice = biq.bookingAnswerChoice;
                                        }
                                        
                                        question.bookingQuestionChoices = biq.bookingQuestionChoices;
                                      }
                                      else if (biq.type == "multipleChoiceQuestionAnswer") {
                                        question.selectedAnswers = [];
                                        question.bookingQuestionChoices = biq.bookingQuestionChoices;
                                          if (biq.bookingQuestionChoices && biq.bookingAnswerChoice) {
                                              if (biq.bookingAnswerChoice.length > 0) {

                                                  biq.bookingQuestionChoices.forEach(qac => {
                                                      let isSelected = biq.bookingAnswerChoice.find(bac => bac.bookingQuestionChoice.id == qac.id);
                                                      if (isSelected) {
                                                          if (!question.quantityRequired) {
                                                              qac.isSelectedAnswer = (isSelected.quantity == 1);
                                                          }
                                                          qac.Quantity = isSelected.quantity;
                                                          question.selectedAnswers.push(qac.id);
                                                      }
                                                      if (question.selectedAnswers.length > 0) question.isSaved = true;
                                                  });
                                              }                                         
                                        }
                                      }
                                        bis.question.push(question);
                                        totalCount++;
                                    });
                                }
                            });
                            bis.totalAnsweredQuestions = totalAnsweredQuestionsCount(bis.question);
                        }

                        // booking upsell item question pop
                        if (bis.upsellSummaries && bis.upsellSummaries.length > 0) {
                            bis.upsellSummaries.forEach(us => {
                                var filterdbookingUpsellQuestionnaires = bookingQuestionnaires.filter(function (bookingQuestionnaire) {
                                    return bookingQuestionnaire && bookingQuestionnaire != null && bookingQuestionnaire.bookingItem &&
                                        bookingQuestionnaire.bookingItem != null && bookingQuestionnaire.bookingItem.id == us.bookingItemId;
                                });

                                if (filterdbookingUpsellQuestionnaires && filterdbookingUpsellQuestionnaires.length > 0) {
                                    us.question = [];
                                    filterdbookingUpsellQuestionnaires.forEach((fbuq) => {
                                        if (fbuq.bookingQuestions) {
                                            fbuq.bookingQuestions.forEach(biq => {
                                                let question = biq.question;
                                                question.id = biq.id;
                                              question.isBookingQuestion = true;

                                                if (biq.type == "textQuestionAnswer" && biq.answer && biq.answer.trim() != "") {
                                                    question.answeredText = biq.answer;
                                                    question.originalAnswer = question.answeredText;
                                                    question.isSaved = true;
                                                } else if (biq.type == "booleanQuestionAnswer" && (biq.answer !== undefined)) {
                                                    question.answeredText = (biq.answer) ? "Yes" : "No";
                                                    question.originalAnswer = question.answeredText;
                                                    question.isSaved = true;
                                                } else if (biq.type == "singleChoiceQuestionAnswer" ) {
                                                  if (biq.bookingAnswerChoice) {
                                                    question.answeredText = biq.bookingAnswerChoice.bookingQuestionChoice.id;
                                                    question.originalAnswer = question.answeredText;
                                                    question.isSaved = true;
                                                    question.bookingAnswerChoice = biq.bookingAnswerChoice;
                                                  }                                                  
                                                  question.bookingQuestionChoices = biq.bookingQuestionChoices;

                                                }
                                                else if (biq.type == "multipleChoiceQuestionAnswer") {
                                                  question.selectedAnswers = [];
                                                  question.bookingQuestionChoices = biq.bookingQuestionChoices;
                                                    if (biq.bookingQuestionChoices && biq.bookingAnswerChoice) {
                                                        if (biq.bookingAnswerChoice.length > 0) {

                                                            biq.bookingQuestionChoices.forEach(qac => {
                                                                let isSelected = biq.bookingAnswerChoice.find(bac => bac.bookingQuestionChoice.id == qac.id);
                                                                if (isSelected) {
                                                                    if (!question.quantityRequired) {
                                                                        qac.isSelectedAnswer = true;
                                                                    }
                                                                    qac.Quantity = isSelected.quantity;
                                                                    question.selectedAnswers.push(qac.id);
                                                                }
                                                                question.isSaved = (question.selectedAnswers.length > 0);
                                                            });
                                                        }
                                                   
                                                  }
                                                }
                                                us.question.push(question);
                                                totalCount++;
                                            });
                                        }
                                    });
                                    //calculating un-answered count
                                    bis.totalAnsweredQuestions += totalAnsweredQuestionsCount(us.question);
                                }
                            });
                        }
                    });
                }
            });
        }

        var filterdbookingPackageQuestionnaires = bookingQuestionnaires.filter(function (bookingQuestionnaire) {
            return bookingQuestionnaire && bookingQuestionnaire != null && bookingQuestionnaire.type == "packageBookingQuestionnaire" && bookingQuestionnaire.bookingPackage &&
                bookingQuestionnaire.bookingPackage != null && bookingQuestionnaire.bookingPackage.id == booking.bookingPackageId;
        });

        if (filterdbookingPackageQuestionnaires && filterdbookingPackageQuestionnaires.length > 0) {
            booking.packageQuestion = [];
            filterdbookingPackageQuestionnaires.forEach((pbiq) => {
                if (pbiq.bookingQuestions) {
                    pbiq.bookingQuestions.forEach(bpq => {
                        let question = bpq.question;
                        question.id = bpq.id;
                        question.isBookingQuestion = true;

                      if (bpq.type == "textQuestionAnswer" && bpq.answer && bpq.answer.trim() != "") {
                        question.answeredText = bpq.answer;
                        question.originalAnswer = question.answeredText;
                        question.isSaved = true;

                      } else if (bpq.type == "booleanQuestionAnswer" && (bpq.answer !== undefined)) {
                        question.answeredText = (bpq.answer) ? "Yes" : "No";
                        question.originalAnswer = question.answeredText;
                        question.isSaved = true;

                      } else if (bpq.type == "singleChoiceQuestionAnswer") {
                        if (bpq.bookingAnswerChoice) {
                          question.answeredText = bpq.bookingAnswerChoice.bookingQuestionChoice.id;
                          question.originalAnswer = question.answeredText;
                          question.isSaved = true;
                          question.bookingAnswerChoice = bpq.bookingAnswerChoice;
                        }
                        question.bookingQuestionChoices = bpq.bookingQuestionChoices;
                      }
                      else if (bpq.type == "multipleChoiceQuestionAnswer") {
                        question.selectedAnswers = [];
                        question.bookingQuestionChoices = bpq.bookingQuestionChoices;
                        if (bpq.bookingQuestionChoices && bpq.bookingAnswerChoice) {
                          if (bpq.bookingAnswerChoice.length > 0) {

                            bpq.bookingQuestionChoices.forEach(qac => {
                              let isSelected = bpq.bookingAnswerChoice.find(bac => bac.bookingQuestionChoice.id == qac.id);
                              if (isSelected) {
                                if (!question.quantityRequired) {
                                  qac.isSelectedAnswer = (isSelected.quantity == 1);
                                }
                                qac.Quantity = isSelected.quantity;
                                question.selectedAnswers.push(qac.id);
                              }
                              if (question.selectedAnswers.length > 0) question.isSaved = true;
                            });
                          }
                        }
                        question.answerChoices = question.bookingQuestionChoices;
                      }
                        booking.packageQuestion.push(question);
                        totalCount++;
                    });
                }
            });
            booking.totalAnsweredPackageQuestions = totalAnsweredQuestionsCount(booking.packageQuestion);
        }
        return { booking: booking, totalQuestionCount: totalCount };
    }
}

function totalAnsweredQuestionsCount(question): number {
    var count = 0;
    if (question) {
        for (var q of question) {
            if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion")
                && (q.answeredText != undefined && q.answeredText != null && q.answeredText != "") && (q.mandatory !== undefined)) {
                count++;
            } else if (q.type == "multipleChoiceQuestion") {
              var answeredCount = 0;
                if (q.bookingQuestionChoices) {
                  q.bookingQuestionChoices.forEach((ans, indx) => {
                  if (q.quantityRequired && ans.Quantity) answeredCount++;
                  else if (ans.isSelectedAnswer) answeredCount++;
                });
                if (answeredCount > 0)
                  count++;
              }
            }
        }
    }
    return count;
}

import { Component, OnInit} from '@angular/core';
import { BaseComponent } from 'src/modules/shared/base.component';
import { environment } from 'src/environments/environment';

import { MatSnackBar } from '@angular/material';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { UpsellSummary } from 'src/modules/models/item/item';

import { ItemService } from 'src/modules/services/item.service';

import { Store, ICart, UpdateFacility } from 'src/modules/store/index';

import { Observable } from "rxjs";

import { Utility } from 'src/modules/utility';

@Component({
  selector: 'opt-booking-common',
  template:''
}) 

export class BookingCommonComponent extends BaseComponent implements OnInit
{
    protected cart$: Observable<ICart>;

    selectedClass: number  
    isLoggedIn: boolean;
    customerDefaultPriceConsessionId: number;

    priceChangedFacilities: FacilitySummary[];
    addedFacilities: FacilitySummary[];
    hasZeroPrice: boolean;

    isAllImmediateConfirmed: boolean;
    
    constructor(private  _store: Store<any>, private _itemSvc: ItemService, private _snackBar: MatSnackBar)
    {
         super();
         this.cart$ = _store.select('cart');
    }
    ngOnInit() {
      
        this.cart$.safeSubscribe(this, state =>
        {
            this.populateStoreData(state);
           
        });
    }
    populateStoreData(state: ICart)
    {
        if (state != undefined && state.packageFilter != undefined && state.packageFilter != null)
        this.selectedClass = state.packageFilter.PackageClass;
        this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
        this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
        this.addedFacilities = state.addedFacilites;
        this.priceChangedFacilities = state.priceChangedFacilities;
        this.hasZeroPrice = state.hasZeroPrice;
    }
    getCalculatedItemPrice(facilitySummary: FacilitySummary)
    {
        if (facilitySummary.item.calculatedMinPrice && facilitySummary.item.calculatedMinPrice.priceIncludingTax>-1) return facilitySummary.item.calculatedMinPrice;
        if (facilitySummary.item.calculatedMaxPrice && facilitySummary.item.calculatedMaxPrice.priceIncludingTax>-1) return facilitySummary.item.calculatedMaxPrice
    }
   

    calculateItemPrices(facilitySummaries: FacilitySummary[], start: string, end: string, calculateForAllItem: boolean=false) {
        let packageClassId = (this.selectedClass && !isNaN(this.selectedClass)) ? this.selectedClass : environment.DefaultPackageClassId;
        let concessionsId = FacilityAdaptor.getBookingPriceConcession(this.customerDefaultPriceConsessionId, this.isAllImmediateConfirmed);
       
        let request = [];
        if (facilitySummaries && facilitySummaries.length > 0) {
            facilitySummaries.forEach((facilitySummary, index) => {
                let itm = (facilitySummary.selectedItem ? facilitySummary.selectedItem : facilitySummary.item);
                if (calculateForAllItem) {
                    facilitySummary.items.forEach((itemData, idx) => {
                        let res = FacilityAdaptor.getMinMaxPriceConcession(concessionsId, packageClassId, itemData);

                        let item = {
                            index: index,
                            itemId: itemData.id,
                            minPriceConcessionId: res.minPriceConcession,
                            maxPriceConcessionId: res.maxPriceConcession,
                            quantity: 1,
                            startTime: start,
                            endTime: end,
                        };
                        request.push(item);
                    });
                } else if (itm) {
                    let res = FacilityAdaptor.getMinMaxPriceConcession(concessionsId, packageClassId, itm);
                    let item = {
                        index: index,
                        itemId: itm.id,
                        minPriceConcessionId: res.minPriceConcession,
                        maxPriceConcessionId: res.maxPriceConcession,
                        quantity: 1,
                        startTime: start,
                        endTime: end,
                    };
                    request.push(item);
                }
            })
        }
        if (request != undefined && request.length > 0) {
            let itemPrice = this._itemSvc.GetItemPrices(request);
            itemPrice.subscribe(result => {
                if (result != undefined)
                    result.forEach(data => {
                        let itm = facilitySummaries[data.index].items.find(i => i.id == data.itemId);
                        if (itm) {
                            if (!data.isError) {

                                {
                                    itm.calculatedMaxPrice = new ItemPriceGroup;
                                    itm.calculatedMinPrice = new ItemPriceGroup;

                                    itm.calculatedMaxPrice.priceIncludingTax = data.maximumPrice;
                                    itm.calculatedMinPrice.priceIncludingTax = data.minimumPrice;
                                }
                            } else {
                                itm.calculatedMaxPrice = undefined;
                                itm.calculatedMinPrice = undefined;
                                    //this._snackBar.openFromComponent(AlertMessageComponent, {
                                    //    duration: 5000,
                                    //    verticalPosition: 'top',
                                    //    data: [data.errorMessage],
                                    //});
                            }
                        }
                    })
            });
        }
    }
    calculateCartPrices() {
        let facilitySummaries = this.addedFacilities;
        let packageClassId = (this.selectedClass && !isNaN(this.selectedClass)) ? this.selectedClass : environment.DefaultPackageClassId;
        let concessionsId = FacilityAdaptor.getBookingPriceConcession(this.customerDefaultPriceConsessionId, this.isAllImmediateConfirmed);

        this.calculateAllPrices(facilitySummaries, concessionsId, packageClassId);
    }
    calculateAllPrices(facilitySummaries: FacilitySummary[], concessionsId: number, packageClassId: number) {
        let request = [];
        let upsellItemRequest = [];
        if (facilitySummaries && facilitySummaries.length > 0) {
            facilitySummaries.forEach((facilitySummary, index) => {
                let itm = facilitySummary.item;
                let res = FacilityAdaptor.getMinMaxPriceConcession(concessionsId, packageClassId, itm);
                let start = Utility.convertToISO(Utility.convertISOToDate(facilitySummary.startTime));
                let end = Utility.convertToISO(Utility.convertISOToDate(facilitySummary.endTime));
                let item = {
                    index: index,
                    itemId: itm.id,
                    minPriceConcessionId: res.minPriceConcession,
                    maxPriceConcessionId: res.maxPriceConcession,
                    quantity: 1,
                    startTime: start,
                    endTime: end,
                };
                request.push(item);

                if (facilitySummary.upsellSummaries != undefined && facilitySummary.upsellSummaries.length > 0) {
                    //     let time = 0;
                    facilitySummary.upsellSummaries.forEach(function (us, idx) {
                        if (us.minPrice == undefined || us.maxPrice == undefined ||
                            (us.minPrice.priceTypeId != 5 && us.minPrice.priceTypeId != 7 && us.maxPrice.priceTypeId != 5 && us.maxPrice.priceTypeId != 7)) {
                            let minConsession = 0, maxConcession = 0;
                            if (us.itemPriceGroups.findIndex(c => c.concessionId == res.minPriceConcession) > 0) {
                                minConsession = res.minPriceConcession
                            }
                            if (us.itemPriceGroups.findIndex(c => c.concessionId == res.maxPriceConcession) > 0) {
                                maxConcession = res.maxPriceConcession
                            }
                            if (minConsession == 0 && maxConcession == 0) {
                                minConsession = us.minPrice.concessionId;
                                maxConcession = us.maxPrice.concessionId;
                            }
                            let upselItem = {
                                index: index,
                                itemId: us.id,
                                minPriceConcessionId: minConsession == 0 ? maxConcession : minConsession,
                                maxPriceConcessionId: maxConcession == 0 ? minConsession : maxConcession,
                                quantity: us.qty,
                                startTime: start,
                                endTime: end
                            };
                            upsellItemRequest.push(upselItem);
                        }
                    });
                }
            })
        }
        if (request != undefined && request.length > 0) {
            this._itemSvc.GetItemPrices(request).subscribe(result => {
                if (result != undefined)
                    result.forEach(data => {
                        let itm = facilitySummaries[data.index].items.find(i => i.id == data.itemId);
                        if (itm) {
                            if (!data.isError) {

                                {
                                    itm.calculatedMaxPrice = new ItemPriceGroup;
                                    itm.calculatedMinPrice = new ItemPriceGroup;

                                    itm.calculatedMaxPrice.priceIncludingTax = data.maximumPrice;
                                    itm.calculatedMinPrice.priceIncludingTax = data.minimumPrice;
                                    facilitySummaries[data.index].item.calculatedMaxPrice.priceIncludingTax = data.maximumPrice;
                                    facilitySummaries[data.index].item.calculatedMinPrice.priceIncludingTax = data.minimumPrice;


                                }
                            } else {
                                itm.calculatedMaxPrice = undefined;
                                itm.calculatedMinPrice = undefined;
                                facilitySummaries[data.index].item.calculatedMaxPrice = undefined;
                                facilitySummaries[data.index].item.calculatedMinPrice = undefined;
                            }
                            this._store.dispatch(new UpdateFacility(facilitySummaries[data.index]));
                        }
                    });
                //Get Upsell Prices
                if (upsellItemRequest.length > 0) {
                    this._itemSvc.GetItemPrices(upsellItemRequest).subscribe(result => {
                        if (result != undefined)
                            result.forEach(data => {
                                let facility = JSON.parse(JSON.stringify(facilitySummaries[data.index]));
                                if (facility.upsellSummaries != undefined && facility.upsellSummaries.length > 0) {
                                    let UpsellSummary: UpsellSummary = facility.upsellSummaries.filter(upsell => upsell.id == data.itemId);

                                    if (UpsellSummary != undefined && UpsellSummary != null) {
                                        if (!data.isError) {
                                            UpsellSummary[0].minPrice = new ItemPriceGroup;
                                            UpsellSummary[0].maxPrice = new ItemPriceGroup;

                                            UpsellSummary[0].minPrice.priceIncludingTax = data.minimumPrice;
                                            UpsellSummary[0].maxPrice.priceIncludingTax = data.maximumPrice;
                                            //facility.upsellSummaries[indexnum] = UpsellSummary[indexnum];
                                            facilitySummaries[data.index] = facility;

                                        } else {
                                            UpsellSummary[0].minPrice = undefined;
                                            UpsellSummary[0].maxPrice = undefined;
                                        }
                                        this._store.dispatch(new UpdateFacility(facilitySummaries[data.index]));
                                    }
                                }
                            });
                    });
                }
            });
        }
    }
    calculatedPriceRange(itm) {
        return BookingAdaptor.isRange(itm.calculatedMinPrice, itm.calculatedMaxPrice)
    }

    getcalculatedPrice(itm) {       
        var prc = BookingAdaptor.getItemPrice(itm, 1);
        if (prc) {
            return prc.priceIncludingTax;
        }
    }
}


import { BaseParam } from '../base-param';
import { environment } from 'src/environments/environment';

export class Assetsearchparams extends BaseParam {
    searchpharese: string;
    starttime: string;
    endtime: string;
    pagenumber: number;
    pagesize: number;
    filterattendens: string;
    filterfacilites: string;
    filtersuberbs: string;
    sort: string;
    assetclass: number = 1;
    activeStatus: number = 1;
    salesChannelId: number = environment.SalesChannel;
    assetname: string = "";
    duration: number;
    timelable: string;
    considerOpeningHours: boolean;
    assetTypeId: string;
    url: string;
    SalesCategoryId: string;
    BookingTypeIds: number = 1;

    geturlforsearch(showAvailableFacilities?: boolean) {

        this.url = "page.number=" + this.pagenumber + "&page.size=" + this.pagesize +
            (this.filterattendens != "0" && this.filterattendens != undefined && this.filterattendens != "" ? ("&capacityFrom=" + this.filterattendens) : "") +
            "&sort=" + this.sort +
            "&assetClassId=" + this.assetclass +
            "&activeStatus=" + this.activeStatus +
            "&BookingTypeIds=" + this.BookingTypeIds+
            (this.assetname.length > 0 && this.assetname != "0" && this.assetname != "" ? ("&assetName=" + this.assetname) : "") +
            "&saleschannelId=" + this.salesChannelId +
            (this.assetTypeId != "0" && this.assetTypeId != undefined && this.assetTypeId != "" ? ("&AssetTypeId=" + this.assetTypeId) : "") +
            ((this.SalesCategoryId && this.SalesCategoryId.length > 0) ? ("&SalesCategoryId=" + this.SalesCategoryId):"") +
          "&include=venue,Facility.Amenities,Facility.configurations"

        
        if (environment.AppSetting &&
            environment.AppSetting["BookingConfig"] &&
            environment.AppSetting["BookingConfig"]["CheckAvailability"] &&
            environment.AppSetting["BookingConfig"]["CheckAvailability"]["DisplayPreliminarySlotAsUnavailble"] == true) {
            this.url = this.url + "&ConsiderPreliminaryAsUnavailable=true";
        }
        if (showAvailableFacilities) {
            this.url = this.url +
                "&StartTime=" + this.starttime +
                "&EndTime=" + this.endtime +
                (this.timelable == "Any" ? ("&duration=" + this.duration) : "") +
                "&considerOpeningHours=" + this.considerOpeningHours;
        }

        if (this.searchpharese != undefined) {
            this.url = this.url + "&SearchText=" + this.searchpharese;
        }

        return this.url;

    }
}

﻿import { BaseResource } from '../base-resource';
import { Item } from './item';
import { PriceGroup } from './price-group';
import { Allocations } from './allocations';
import { Asset } from './asset';
import { jsonIgnore } from 'json-ignore';

export class BookingItems extends BaseResource {
    constructor() {
        super();
        this.item = new Item();
        //this.priceGroup = new PriceGroup();
        this.type = "PrivateBookingGeneraladmissionitem";
        //this.priceTypeId = 1;
        //this.unitPrice = 0;
        this.itineraryItem = false;
        //this.despatchItem = false;
        //this.printBeforeDespatch = false;
        //this.scanBeforeDespatch = false;
        //this.showInDocuments = false;
        //this.estimatedUnitCost = 0;
    }

    name: string;
    priceTypeId: number;
    quantity: number;
    @jsonIgnore() entityConfigurationProfileId: number;
    startTime: string;
    endTime: string;
    allocations: Allocations[];
    item: Item;
    priceGroup: PriceGroup;
    unitPrice: number;
    itineraryItem: boolean;
    despatchItem: boolean;
    printBeforeDespatch: boolean;
    scanBeforeDespatch: boolean;
    showInDocuments: boolean;
    estimatedUnitCost: number;
    upsell: boolean;
    bookingItemAssociations: BookingItemAssociation[];
}

export class BookingItemAssociation extends BaseResource {
    constructor() {
        super();
        this.type = "BookingItemAssociation";
        this.asset = new Asset();
    }

    allocatedQuantity: number;
    startTime: string
    endTime: string;
    asset: Asset;
    primaryBookingSpaceItem: any;
}

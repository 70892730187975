import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatSnackBar, MatPaginator, PageEvent, MatTabChangeEvent } from "@angular/material";
import { Router, ActivatedRoute } from '@angular/router';

import { DOCUMENT } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatMenuTrigger } from "@angular/material";
import { environment } from 'src/environments/environment';
import { BookingFilter } from 'src/modules/models/booking/booking-search/booking-filter';
import { BaseParam, Paging } from 'src/modules/models/base-param';

import { WpMessageComponent } from '../../modals/wp-message/wp-message.component';
import { WpChangeRequestComponent } from '../../modals/wp-change-request/wp-change-request.component';
import { WpRequestAccessComponent } from '../../modals/wp-request-access/wp-request-access.component';
import { WpAlreadyAccessComponent } from '../../modals/wp-already-access/wp-already-access.component';

import { BookingService } from "src/modules/services/booking.service";
import { CustomerService } from 'src/modules/services/customer.service';

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { Utility } from 'src/modules/utility';
import { Store, ICart ,SignOut} from 'src/modules/store/index';
import { IRegularBooking, LoadBooking, StoreAssets, SpinnerLoaded, SpinnerLoading, ClearStore } from 'src/modules/store/regular-booking/index';
import { Observable, Subscription } from "rxjs";

//loader
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { FacilitySearchLoading, FacilitySearchLoaded } from 'src/modules/store/loading/actions'
import { Contact } from 'src/modules/models/client/contact';

import { ClearCart } from 'src/modules/Init';

@Component({
    selector: 'opt-booking-search',
    templateUrl: './booking-search.component.html'
})

export class BookingSearchComponent implements OnInit, OnDestroy {
    @ViewChild('pastBookingPaginator') paginator: MatPaginator;

    tabSelectedIndex: number = 0;
    // showLoader: boolean = true;
    cart$: Observable<ICart>;
    loader$: Observable<ILoadingStatus>;
    cart_subscriber: any;
    bookings: any = [];
    recordCount: number = 0;
    filter = new BookingFilter();
    pageSize = 5;
    pagenumber = 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    sortBy: string = "BookingDate";
    ascending: boolean = false;
    bookingStates: any[];
    selectedBookingStates: number[] = [];
    isExpanded: boolean = true;
    isAdvance: boolean = false;
    isUpcomingBooking: boolean = true;
    selectedBookingId: number = 0;
    contactRef: string;
    clientRef: string;
    bsSubscrption: Subscription;
    sortOptions = [];
    contacts: Contact []= [];
    clientId: string;
    filterByContact: any;
    isBookingSearchByClient: boolean;
    PaymentPortalUrl: string = environment.PaymentPortalUrl;;


    constructor(private snackBar: MatSnackBar, private _customerService: CustomerService, private _store: Store<IRegularBooking>,  private store: Store<any>, private _bookingService: BookingService, @Inject(DOCUMENT) private document: Document, private dialog: MatDialog, private router: Router) {
        this.cart$ = this.store.select('cart');
        this.cart_subscriber = this.cart$.subscribe(state => {
            if ((state != undefined && state.contact != undefined && state.contact.id != undefined && state.contact.id != null && state.contact.id != "")) {
                this.contactRef = state.contact.ref;
                this.clientRef =  state.contact.client.clientRef;
                this.clientId = state.contact.client.id;              
            } else {
                this.router.navigate(['/client/login']);
            }
            this.bookingStates = environment.BookingStates;
            this.sortOptions = environment.SortOptions;
            this.isBookingSearchByClient = environment.IsBookingSearchByClient;
            this.filterByContact = this.contactRef;
        });

    }

    ngOnInit() {
        window.scroll(0, 0);
        var currentTab = +localStorage.getItem("selectedTab");
        if (currentTab) {
            this.tabSelectedIndex = currentTab;
            this.changeMode(this.tabSelectedIndex == 0);
        } else {
            this.bookingSearch();
        }

        this.loadContacts();      
    }


    loadContacts() {
        if (this.isBookingSearchByClient) {

            this._customerService.GetContactDetails(this.clientId).subscribe(data => {
                if (data != null) {
                    this.contacts = data;
                }
            },
                err => {
                    this.contacts = [];
                });
        }
       
    }

    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
        if (this.bsSubscrption)
            this.bsSubscrption.unsubscribe();
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.tabSelectedIndex = tabChangeEvent.index;
        localStorage.setItem("selectedTab", this.tabSelectedIndex.toString());
        this.changeMode(this.tabSelectedIndex == 0);
    }

    updateStates(id) {
        this.selectedBookingStates = [];
        if (id) {
            this.selectedBookingStates.push(id);
        }
    }

    isCheckedState(val) {
        return this.selectedBookingStates.find(st => st == val) !== undefined;
    }

    toggleSort() {
        this.ascending = !this.ascending;
        this.bookingSearch();
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

    expand(bk) {
        if (this.isExpanded && this.selectedBookingId == bk.BookingId) {
            this.isExpanded = false;
            this.selectedBookingId = 0;
        } else {
            this.isExpanded = true;
            this.selectedBookingId = bk.BookingId;
        }

        if (this.isExpanded && bk.bookingInvoices == undefined) {
            this._bookingService.InvoiceSearch(bk.BookingId, new BaseParam()).subscribe(res => {
                if (!res.isError) {
                    bk.bookingInvoices = res.data;
                }
            });
        }
    }

    bookingSearch() {
        if (this.isAdvance)
            this.advanceSearch();
        else
            this.simpleSearch();
    }

    simpleSearch() {
        this.isAdvance = false;
        var filter = new BookingFilter();
        filter.searchText = this.filter.searchText;

        this.filter = new BookingFilter();
        this.filter.searchText = filter.searchText;
        this.selectedBookingStates = [];
        this.onBookingSearch(filter)
    }

    advanceSearch() {
        this.isAdvance = true;
        var filter = this.filter;
        if (this.filter.eventDateFrom)
            filter.eventDateFrom = Utility.convertToISO(this.getDateOnly(new Date(this.filter.eventDateFrom)));
        if (this.filter.eventDateTo)
            filter.eventDateTo = Utility.convertToISO(this.getDateOnly(new Date(this.filter.eventDateTo)));
        if (this.filter.bookingDateFrom)
            filter.bookingDateFrom = Utility.convertToISO(this.getDateOnly(new Date(this.filter.bookingDateFrom)));
        if (this.filter.bookingDateTo)
            filter.bookingDateTo = Utility.convertToISO(this.getDateOnly(new Date(this.filter.bookingDateTo)));
        filter.bookingStatusId = this.selectedBookingStates.join(',');
        this.onBookingSearch(filter);
    }

    getDateOnly(dt: Date) {
        dt.setHours(0, 0, 0, 0);
        return dt;
    }

    onBookingSearch(filter: BookingFilter) {
        this.selectedBookingId = 0;
        var searchParam = new BaseParam();
        searchParam.paging = new Paging();
        searchParam.paging.number = (this.pagenumber + 1).toString();
        searchParam.paging.size = this.pageSize.toString();

        var currentFilter = JSON.parse(JSON.stringify(filter));

        currentFilter.clientRef = this.clientRef;
        currentFilter.contactRef = this.filterByContact;

        var dt = new Date();
        if (this.isUpcomingBooking) {
            if (this.filter.eventDateFrom == undefined || this.filter.eventDateFrom == "")
                currentFilter.eventDateFrom = Utility.convertToISO(dt);
        } else {
            dt.setMinutes(new Date().getMinutes() - 1);
            if (this.filter.eventDateTo == undefined || this.filter.eventDateTo == "")
                currentFilter.eventDateTo = Utility.convertToISO(dt);
        }
        searchParam.filter = currentFilter;
        searchParam.sort = (this.ascending ? '' : '-') + this.sortBy;

        if (this.bsSubscrption)
            this.bsSubscrption.unsubscribe();

        if ((currentFilter.contactRef && currentFilter.contactRef.length > 0) || (currentFilter.clientRef && currentFilter.clientRef.length > 0)) {
            this.store.dispatch(new FacilitySearchLoading());
            this.bsSubscrption = this._bookingService.BookingSearch(searchParam).subscribe((data) => {
                this.bookings = data.data;
                this.recordCount = data.total;
                if (data.errorCode == "401")
                {
                    this.openSnackBarError(["Session Timeout"]);
                    ClearCart();
                    this.store.dispatch(new SignOut());
                    this.router.navigate(['/client/login']);
                }
            },
                err => {
                    this.bookings = [];
                    this.recordCount = 0;
                },
                () => {
                    this.store.dispatch(new FacilitySearchLoaded());
                });
        }
    }

    pageclickevent(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.pagenumber = pageEvent.pageIndex;
        this.bookingSearch();
    }

    clear() {
        this.selectedBookingStates = [];
        this.selectedBookingStates.push();
        this.filter = new BookingFilter();
        this.filterByContact = this.contactRef;
    }

    changeMode(isUpcoming: boolean) {
        this.pagenumber = 0;
        this.isUpcomingBooking = isUpcoming;
        this.bookingSearch();
    }

    //onPayForInvoice(inv) {
    //    document.location.href = environment.ApiUrl + "/payment?InvoiceRef=" + inv.InvoiceRef;
    //}

    onInvoiceView(inv) {
        this.store.dispatch(new FacilitySearchLoading());
        this._bookingService.InvoiceView(inv.InvoiceId).subscribe((res) => {
            if (res.data) {
                //     window.open((environment.WebApiUrl + res.data), '_blank');
                window.open((res.data), '_blank');
            }
        },
            err => { this.openSnackBarError(["Unable to load invoice information. Please try again."]); },
            () => {
                this.store.dispatch(new FacilitySearchLoaded());
            });
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 3000,
            verticalPosition: 'top'
        });
    }

    //wp-modals

    openWpMessage(): void {
        const dialogRef = this.dialog.open(WpMessageComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }


    openWpChangeRequest(): void {
        const dialogRef = this.dialog.open(WpChangeRequestComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }

    openWpRequestAccess(): void {
        const dialogRef = this.dialog.open(WpRequestAccessComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }

    openWpAlreadyAccess(): void {
        const dialogRef = this.dialog.open(WpAlreadyAccessComponent, {
            panelClass: ['w60modal', 'dialog-container-common'],
            width: '150px'
        });
    }



    openBookingDetail(booking) {

        if (booking.IsDraftBooking) {

            window.open(environment.RegularBookingPortalUrl + "#/booking-stepper/?OpenBooking=" + booking.BookingId, "_self");

        } else {

            this.router.navigate(['/booking-details/' + booking.BookingId]);


        }
    }

    RedirectToPayment(invoiceref) {


        var url = this.PaymentPortalUrl;
        window.open(url + "payment?InvoiceRef=" + invoiceref, "_self")

    }
}

import { Component, ChangeDetectorRef } from '@angular/core';
import { Observable, forkJoin, from, Subject } from "rxjs";
import { Store, ICart, AddFacility, PackageSearch } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store'
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})

export class MainComponent {
    loader$: Observable<ILoadingStatus>;

    constructor(private store: Store<any>, private cd: ChangeDetectorRef) {
        this.loader$ = this.store.select('loader');
        console.log("LOADER ", this.loader$)
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }
}

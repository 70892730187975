﻿
export class QuestionPackageFilters {
    id: string;
    name: string;
    questionItemFilters: QuestionItemFilters[];

    // answer: Answer;
}
export class QuestionItemFilters{
    id: string;
    name: string;
}
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/modules/shared/base.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PageEvent } from '@angular/material';
import { Store, IRegularBooking, LoadBooking, SpinnerLoaded, SpinnerLoading, StoreBookingType, SaveSeason, StoreAssets, ClearStore } from 'src/modules/store/regular-booking/index';
import { ICart } from 'src/modules/store/index';

import { Observable } from "rxjs";
import { RegualrBookingService } from 'src/modules/services/regular-booking.service';
import { AssetService } from 'src/modules/services/asset.service';

import { RegularBookingSettings } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
//model
import { Booking } from 'src/modules/models/regular-portal/booking/booking';
import { BookingPackage } from 'src/modules/models/regular-portal/booking/booking-package';
import { Tree, TreeNode } from 'src/modules/regular-booking/wp-calendar/calendar/tree-data';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { Allocation } from 'src/modules/models/regular-portal/booking/save-booking/allocations';
import { Timeslot } from 'src/modules/models/regular-portal/booking/save-booking/timeslot';
import { Asset } from 'src/modules/models/regular-portal/booking/save-booking/Asset';
import { EventConfiguration } from 'src/modules/models/booking/event-configuration';
import { Package } from 'src/modules/models/booking/package';
import { PackageService } from 'src/modules/services/package.service';
//save
import { Booking as BookingS, BookingPackage as BookingPackageS, Contact, Client } from 'src/modules/models/regular-portal/booking/save-booking/booking';
import { BookingItem as BookingItemS, Item } from 'src/modules/models/regular-portal/booking/save-booking/booking-item';
import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';
import { RegularBookingAdapter } from 'src/modules/models/regular-portal/regular-booking-adapter';
//helper classes
import { Utility } from 'src/modules/utility';
import { CalendarUtil, DAYS_OF_WEEK } from 'src/modules/regular-booking/wp-calendar/calendar/calendar-util';

///Date
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateFormat } from 'src/modules/date-format';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';

//common
import { cacheUtility } from 'src/modules/cache-utillity';

import { VenueAssetsearchparams, Page } from 'src/modules/models/regular-portal/venue-assets-search-param';

import { EventView } from 'src/modules/regular-booking/wp-calendar/calendar/week-view';

const moment = _rollupMoment || _moment;


@Component({
    selector: 'opt-rb-booking-common',
    template: ''
})

export class BookingCommonComponent extends BaseComponent implements OnInit {
    protected rbStore$: Observable<IRegularBooking>;
    protected cart$: Observable<ICart>;
    booking: Booking
    seasonId: number;
    assetList: TreeNode[] = [];
    seasonName: string;
    bookingId: string;
    recurrentOption: RecurrenceOption;
    bookingType: any;
    contact: Contact;
    selectedStepIndex: number = 0;
    _BookingTypedefaultConcessionId: any;
    listSaleCategories: any[] = [0];
    clientSaleCategories: any[] = [];
    $bookingLoad = new EventEmitter();
    customerDefaultPriceConsessionId: number;
    isLoggedIn: boolean;
    showErrorMessage: boolean = false;
    displayMessage: string;
    ApiUrl: string;
    isLoading: boolean = false;
    listDataAsset: any;
    assetIdArray: any[] = [];
    //selectedList: any[] = [];
    searchstring: string = "";
    listVenuids: any[] = [];
    locationPageSize: number = 0;
    addedDocuments: AdHocDocument[];
    events: EventView[] = [];
    availableDocumentCategories: string[] = [];
    requiredDocumentCategories: string[] = [];
    documentCategoryHeader: string;
    _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings);
    isRolloverBooking: boolean;
    clientPriceConcessionId: any;
    bookingTypeId: string = "0";

    constructor(protected _store: Store<any>, private route: ActivatedRoute,
        private regBookingService: RegualrBookingService, public _assetService: AssetService, public _packageService?: PackageService) {
        super();
        this.rbStore$ = _store.select('rbStore');
        this.cart$ = _store.select('cart');
        this.ApiUrl = environment.ApiUrl;

        this.isRolloverBooking = Boolean(Number(this.route.snapshot.queryParams["rollover"]));
    }
    ngOnInit() {
        this._store.dispatch(new SpinnerLoaded());
        this.rbStore$.safeSubscribe(this, state => {
            this.populateStoreData(state);
            // console.log('booking data from common store', this.booking);
        });

        this.cart$.safeSubscribe(this, state => {
            if (state) {
                this.contact = new Contact();
                this.contact.id = state.contact.id
                this.contact.client = new Client();

                if (state.contact.client) {
                    this.contact.client.id = state.contact.client.id;
                }
            }
            this.populateContactData(state);
            // console.log('booking data from common store', this.booking);
        });


        this.$bookingLoad.safeSubscribe(this, (res: Booking) => {
            if (res && this.contact && res.contact) {

                if ((this.contact.id != res.contact.id)) {
                    window.open(this.ApiUrl + '#/bookings', "_self");
                }
            }

            const regularSetting = environment.RegularBookingSetting as RegularBookingSettings;
            if (res && res.bookingTypeId) {
                let _bookingType = regularSetting.booking.bookingTypes.find(bk => bk.id == String(res.bookingTypeId));
                if (res.bookingStatus.id != _bookingType.bookingWorkFlow.draftBookingStatusId) {
                    window.open(this.ApiUrl + '#/bookings', "_self");
                }
            }

            //this.LoadStepper(res);
        });

        this.bookingId = this.route.snapshot.queryParams["bookingId"];
        if (!this.booking || this.booking == null || this.bookingId != this.booking.id) {
            if (this.bookingId) {
                this._store.dispatch(new ClearStore());
                this.LoadBooking(this.bookingId, this.$bookingLoad, true);
            }
        } else {
            this.selectedStepIndex = 1;

        }

        this.cart$.safeSubscribe(this, state => {
            //   console.log('regular booking store', state);
            this.contact = state.contact;

        });

    }
    filterAvailableDocumentCategories() {
        if (this.bookingType && this.bookingType.availableDocumentCategories)
            this.availableDocumentCategories = this.bookingType.availableDocumentCategories.split(",");

        if (this.bookingType && this.bookingType.requiredDocumentCategories)
            this.requiredDocumentCategories = this.bookingType.requiredDocumentCategories.split(",");

        if (this.bookingType) {
            this.documentCategoryHeader = this.bookingType.documentCategoryHeader;

        }
    }
    loadAddedBookingDocuments(eventEmitter: EventEmitter<any> = null) {
        if (this.booking && this.booking.id) {
            this._store.dispatch(new SpinnerLoading());
            this.regBookingService.getBookingDocuments(this.booking.id).safeSubscribe(this,
                response => {
                    eventEmitter.emit(response);
                },
                error => {
                    console.log(error);
                },
                () => {
                    this._store.dispatch(new SpinnerLoaded());
                    console.log('documentTemplateCategories loaded')
                }
            );
        }
    }
    populateContactData(state: ICart) {
        this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
        this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
        //clientvenueId
        if (state.contact.clientVenueIds) {
            var assignedids = [];
            for (let res of state.contact.clientVenueIds) {
                assignedids.push(res.id);
            }

            this.listVenuids = assignedids;
        }

        if (state.contact.client) {

            this.clientPriceConcessionId = state.contact.client.priceConcessionId;
        }

    }

    populateCartDetails(state) {
        if (state.bookingPackage) {
            var SaleCategories = state.bookingPackage.salesCategories;
            this.clientSaleCategories = SaleCategories;
        }

        if (state.contact.clientVenueIds) {
            var assignedids = [];
            for (let res of state.contact.clientVenueIds) {
                assignedids.push(res.id);
            }
            this.listVenuids = assignedids;
        }
    }
    populateStoreData(state: IRegularBooking) {
        if (state) {
            this.booking = state.booking;
            this.seasonId = state.seasonId;
            this.bookingType = state.BookingType;
            this.recurrentOption = state.recurrentOption;
            this.assetList = state.AssetList;
            this.isLoading = state.isLoading;
            this.locationPageSize = state.locationPageSize;

            if (state.BookingType) {
                this.bookingTypeId = state.BookingType.id;
            }

            if (state.BookingType && state.seasonId) {

                let _seasons = state.BookingType.seasons.find((e) => e.id == state.seasonId.toString());
                if (_seasons)
                    this.seasonName = _seasons.name;
            }
            if (this.bookingType)
                this.filterAvailableDocumentCategories();
        }
    }

    LoadStepper() {
        // this._store.dispatch(new SpinnerLoading());

        var _SelectedList = [];
        this.assetIdArray
        if (this.booking && this.booking.id) {
            if (this.booking.bookingTypeId != null || this.booking.bookingTypeId != undefined) {
                var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings)
                this.bookingType = _regularXmlData.booking.bookingTypes.find(typ => Number(typ.id) == this.booking.bookingTypeId);
                if (this.bookingType) {
                    this._store.dispatch(new StoreBookingType(this.bookingType));
                } else {
                    window.open(environment.CasualBookingPortalUrl, "_self");
                }
            }
            if (this.booking.seasonId) {
                if (this.booking.seasonId) {
                    //if (environment.RegularBookingSetting != undefined) {
                    //    var _seasonCaraousalData = (environment.RegularBookingSetting as RegularBookingSettings);
                    //    if (_seasonCaraousalData != undefined && this.booking.bookingTypeId != undefined) {
                    //        //find the bookingType
                    //        let _findBookingType = _seasonCaraousalData.booking.bookingTypes.find(bkType => bkType.id == String(this.booking.bookingTypeId));
                    //        if (_findBookingType) {
                    //            //find the Season
                    //            const _findSeason = _findBookingType.seasons.find(s => s.id == String(this.booking.seasonId));
                    //            if (_findSeason) {
                    //                var currentDate = moment().format('YYYY-MM-DD');
                    //                if (_findSeason.cutoffDate && _findSeason.cutoffDate != "") {
                    //                    if (new Date(currentDate) <= new Date(_findSeason.cutoffDate)) {
                    //                        this._store.dispatch(new SaveSeason(this.booking.seasonId));
                    //                    };
                    //                } else {
                    //                    if (_findSeason.to && _findSeason.to !="") {
                    //                        if (new Date(currentDate) <= new Date(_findSeason.to)) {
                    //                            this._store.dispatch(new SaveSeason(this.booking.seasonId));
                    //                        };
                    //                    }

                    //                }

                    //            };
                    //        }
                    //    }
                    //};
                    this._store.dispatch(new SaveSeason(this.booking.seasonId));
                } else {
                    this.selectedStepIndex = 0;
                }
            }
            const regularSetting = environment.RegularBookingSetting as RegularBookingSettings;
            if (regularSetting.booking.locationCacheTime) {
                const _validCacheAssets = cacheUtility.fetch(this.booking.id, regularSetting.booking.locationCacheTime)

                if (_validCacheAssets != null) {
                    this._store.dispatch(new StoreAssets(_validCacheAssets));
                    if (!this.isRolloverBooking && this.bookingTypeId != '4') {
                        this.selectedStepIndex = 2;
                    } else {
                        this.selectedStepIndex = 1;
                    }

                    return;
                } else {
                    if (this.bookingTypeId == '4') {
                        this.cacheLocations(this.listVenuids, this.listSaleCategories, "", 0, 0, 0, 0, this.NavigateStepperAfterCacheAssets);
                        return;
                    }                    
                }
            }
            //   this.selectedList = [];
            if (this.booking.bookingPackages && this.booking.bookingPackages.length > 0) {

                this.selectedStepIndex = 1;
                this.booking.bookingPackages.forEach((pk, i) => {
                    if (pk.bookingVenues != undefined && pk.bookingVenues.length > 0) {
                        pk.bookingVenues.forEach((bk, pkIndex) => {
                            //Array pushing
                            let _selectedVenue: TreeNode;
                            if (_SelectedList) {
                                _selectedVenue = _SelectedList.find(sl => sl.id == bk.venue.id);
                            };

                            if (!_selectedVenue) {
                                _selectedVenue =
                                    {
                                        label: bk.venue.name, id: bk.venue.id, data: null, selectable: false, parentId: null,
                                    children: [], selectedItem: null
                                    }
                                _SelectedList.push(_selectedVenue);
                            };


                            if (_selectedVenue) {
                                //BookingItem foreach for assets
                                bk.bookingItem.forEach((bkItem, bkindex) => {
                                    //Assets forEach
                                    if (bkItem) {

                                        bkItem.asset.forEach((ast, asIndex) => {
                                            if (ast != undefined) {

                                                let findAsset = _SelectedList.find(sl => sl.id == ast.id);
                                                if (!findAsset) {

                                                    let _findSlectedAsset = _selectedVenue.children.find(aset => aset.id == ast.id);
                                                    if (!_findSlectedAsset) {
                                                        _selectedVenue.children.push(
                                                            { label: ast.name, id: ast.id, children: [], data: null, selectable: true, parentId: _selectedVenue.id, selectedItem:null}
                                                        );
                                                    }

                                                }
                                            }
                                            //chcek asset assing preiously
                                            if (this.assetIdArray.length > 0) {
                                                var findAsset = this.assetIdArray.find(as => as == ast.id);
                                                if (!findAsset) {
                                                    this.assetIdArray.push(ast.id);
                                                }
                                            } else {

                                                this.assetIdArray.push(ast.id);
                                            }

                                        });
                                    }
                                });
                            }
                        });

                    };
                    //if (pk.bookingItem && pk.bookingItem.length > 0) {
                    //    pk.bookingItem.forEach((bkitm, index) => {
                    //        bkitm.asset.forEach((aset, i) => {
                    //            if (aset.id)
                    //                _assetIdArray.push(aset.id);
                    //        });
                    //    });
                    //    
                    //}
                });

                this.getFacilityAndResources([], 0, this.searchstring, this.listVenuids, "", 0, 0, this.assetIdArray.join(), _SelectedList); //  From: number = 0;    To: number = 0;




                //store assetData
                if (this.assetIdArray.length > 0) {
                    //        this.getFacilityAndResource(this.listVenuids, this.listSaleCategories, "", 0, 0, 0, 0);
                }

            } else {
                this.selectedStepIndex = 0;
            }



        } else {
            //handle booking save error
        }




    }



    populateBookingItems(bookingId, selectedTab, recOption, assetData, addHocDates) {
        var booking = new BookingS();
        booking.id = bookingId;

        booking.bookingPackages = [];

        let bpkge = new BookingPackageS();
        bpkge.id = assetData.bookingPackage.id;
        bpkge.bookingItems = [];
        let _team;
        if (this.booking && this.booking.bookingPackages) {
            _team = this.booking.bookingPackages.find(p => p.id == assetData.bookingPackage.id);
        }
        booking.bookingPackages.push(bpkge);
        if (selectedTab == 1) {

            let bookingItem = new BookingItemS();
            if (assetData.event.id != undefined) {
                bookingItem.id = assetData.event.id;
            } else if (assetData.event && assetData.event.BookingItemIds.length > 0) {
                bookingItem.id = assetData.event.BookingItemIds[0];
            } else {
                bookingItem.id = '-1';
                bookingItem.recurrent = true;
                bookingItem.upsell = true;
                bookingItem.quantity = 1;
                if (_team) {
                    bookingItem.covers = _team.attendees;
                    bookingItem.attendees = _team.attendees;

                    bookingItem.allocations = new Allocation();
                    bookingItem.allocations = this.getAllocation(assetData, _team, recOption.startTime, recOption.endTime);



                }
            }

            bookingItem.item = new Item();

            if (assetData.event.treeNode) {

                bookingItem.item.id = assetData.event.treeNode.selectedItem.id;
                bookingItem.item.type = assetData.event.treeNode.selectedItem.type;

                    //bookingItem.item.id = assetData.event.treeNode.data.items[0].id;
                    //bookingItem.item.type = assetData.event.treeNode.data.items[0].type;

            }
            else {
                bookingItem.item.id = assetData.event.item.id;
                bookingItem.item.type = assetData.event.item.type;
            }
            bookingItem.type = 'privateBooking' + bookingItem.item.type;

            bookingItem.recurrenceOption = recOption;

            bpkge.bookingItems.push(bookingItem);

            //add hoc}}
        } else {
            let incrementNumber: number = 0;
            addHocDates.forEach((date, index) => {
                incrementNumber = incrementNumber - 1;
                let bookingItem = new BookingItemS();
                let convertHours = CalendarUtil.MinToHours(assetData.event.startMin);
                // let momendate = moment(date.startTime).format()
                let res = CalendarUtil.GetStartEndTime(convertHours, assetData.event.durationMin, date.startTime);
                bookingItem.id = (incrementNumber).toString();
                bookingItem.recurrent = false;
                bookingItem.adhoc = true;
                bookingItem.upsell = true;
                bookingItem.startTime = Utility.convertToISO(Utility.appendTimePart(moment(res.startTime).toDate(), moment(res.startTime).toDate(), true));
                bookingItem.endTime = Utility.convertToISO(Utility.appendTimePart(moment(res.endTime).toDate(), moment(res.endTime).toDate(), true));
                bookingItem.item = new Item();
                if (assetData.event.treeNode) {
                    //bookingItem.item.id = assetData.event.treeNode.data.items[0].id;
                    //bookingItem.item.type = assetData.event.treeNode.data.items[0].type;

                    bookingItem.item.id = assetData.event.treeNode.selectedItem.id;
                    bookingItem.item.type = assetData.event.treeNode.selectedItem.type;
                }
                else {
                    bookingItem.item.id = assetData.event.item.id;
                    bookingItem.item.type = assetData.event.item.type;
                }
                bookingItem.type = 'privateBooking' + bookingItem.item.type;
                bookingItem.quantity = 1
                if (_team) {
                    bookingItem.covers = _team.attendees;
                    bookingItem.attendees = _team.attendees;


                    bookingItem.allocations = new Allocation();
                    bookingItem.allocations = this.getAllocation(assetData, _team, bookingItem.startTime, bookingItem.endTime);

                }

                bpkge.bookingItems.push(bookingItem);
            })

        }
        return booking;
    }

    getBookingItem(items, _findBookingPackage) {

        var _item = new Item();
        items.forEach((itm, itmIndex) => {

            if (itm.validateSalesCategory != 2) {
                if (itm && itm.salesCategories) {
                    let _findAssetChildren = itm.salesCategories.findIndex(sales =>
                        (_findBookingPackage.salesCategories && _findBookingPackage.salesCategories.findIndex(s => s.id == sales.id) >= 0));

                    if (_findAssetChildren >= 0) {                       
                        return _item = itm;

                    }
                }

              }
        });
        return _item;
    }
    SaveBooking(booking, eventEmitter: EventEmitter<any> = null, isStopLoading = false, rolloverBookingid = "0", isRolloverBookingDocument = false, ) {
        this._store.dispatch(new SpinnerLoading());
        this.regBookingService.SaveBooking(booking, rolloverBookingid, isRolloverBookingDocument).safeSubscribe(this,
            response => {

                var res;
                var minDate = "";
                var maxDate = "";

                this._packageService.privatePackages(0, minDate, maxDate).subscribe(data => {
                    if (data.length > 0) {
                        var bespokePackages = data;
                        res = RegularBookingAdapter.RemoveConcatination(response, bespokePackages);
                        if (eventEmitter)
                            eventEmitter.emit(res);
                        if (isStopLoading || (response && response.status != 200))
                            this._store.dispatch(new SpinnerLoaded());
                    }
                    else {
                        if (eventEmitter)
                            eventEmitter.emit(response);
                        if (isStopLoading || (response && response.status != 200))
                            this._store.dispatch(new SpinnerLoaded());
                    }

                });

            },
            error => {
                if (eventEmitter)
                    eventEmitter.emit(error)
                this._store.dispatch(new SpinnerLoaded());
            },
            () => {

                //console.log('Booking Patch Done')
            }
        );
    }


    SaveQuestions(questionToPatch, eventEmitter: EventEmitter<any> = null) {
        this.regBookingService.QuestionSavePatch(this.booking.id, questionToPatch).safeSubscribe(this, response => {
            console.log(response);
            if (eventEmitter) {
                eventEmitter.emit(response)

            }
        },
            error => {
                this.showErrorMessage = true;
                this.displayMessage = error;
                console.log(error)
            },
            () => { console.log("data are returning from this point") }
        );
    }
    LoadBooking(bookingId, eventEmitter: EventEmitter<any> = null, reloadStore = false, bookingRollover = false) {
        if (bookingId) {
            this._store.dispatch(new SpinnerLoading());
            this.regBookingService.SelectBooking(bookingId).safeSubscribe(this, result => {
                if (bookingRollover == false && result != undefined)

                    var res;
                var minDate = "";
                var maxDate = "";

                this._packageService.privatePackages(0, minDate, maxDate).subscribe(data => {
                    if (data.length > 0) {
                        var bespokePackages = data;
                        res = RegularBookingAdapter.RemoveConcatination(result, bespokePackages);
                        this._store.dispatch(new LoadBooking(res));
                        this._store.dispatch(new SpinnerLoaded());
                        if (eventEmitter)
                            eventEmitter.emit(res)
                        if (reloadStore) {
                            this.LoadStepper();
                        }
                    }
                    else {
                        if (eventEmitter)
                            eventEmitter.emit(result)
                        if (reloadStore) {
                            this.LoadStepper();
                        }
                    }
                });


            },
                (err) => {
                    if (eventEmitter)
                        eventEmitter.emit(err)
                },
                () => {
                    this._store.dispatch(new SpinnerLoaded());
                });
        }

    }



    convertSeasonDateToDate(selectedDate) {
        var DateSpilit = selectedDate.split("-");
        var newDate = new Date(DateSpilit[0], (Number(DateSpilit[1]) - 1), DateSpilit[2]);


        //  var formartDate = new Date(DateSpilit[0]);
        var dp: DateFormat = new DateFormat('en');
        return dp.transform(newDate);
    }


    convertGetToDate(convertDate) {
        var dp: DateFormat = new DateFormat('en');
        return dp.transform(convertDate);
    }



    //Load assets
    getFacilityAndResources(_listVenuids, _listSaleCategories, _searchstring, _selectedVenue, _selectedAssetids, _From, _To, assetIds, _SelectedList) {
        let searchParam = new VenueAssetsearchparams();
        searchParam.searchpharese = this.searchstring;
        let venuid = _listVenuids.toString();
        if (venuid.length == 0) { venuid = "" }
        searchParam.listVenuids = venuid;
        searchParam.SalesCategoryId = this.clientSaleCategories.toString();
        let sFacility = _selectedAssetids.toString();
        searchParam.assetIds = assetIds
        sFacility.length == 0 ? "" : sFacility;
        searchParam.assetTypeIds = sFacility;
        searchParam.From = _From == 0 ? 0 : _From;
        searchParam.To = _To == 0 ? 0 : _To;
       // searchParam.priceConcessionIds = this.GetconcessionId();
        searchParam.bookingTypeIds = this.booking.bookingTypeId.toString();

        if (this.isRolloverBooking) {
            searchParam.page = new Page();
            searchParam.page.size = 0;
            searchParam.page.number = 0;

        }

        let listVenuids = _listVenuids.toString();
        if (this.regBookingService != null) {
            this.regBookingService.getFacilityAndResource(searchParam).safeSubscribe(this, response => {
                console.log(response);
                if (response) {
                    this.listDataAsset = response;
                    _SelectedList.forEach((parent, pIndex) => {
                        var findAsset = this.listDataAsset.find(re => re.id == parent.id);
                        if (findAsset) {
                            findAsset.assetList.forEach((chl, cIndex) => {
                                var findChildren = parent.children.find(ch => ch.id == chl.id);
                                if (findChildren) {
                                    findChildren.data = chl;
                                }

                            });
                        }

                    });
                    if (_SelectedList != undefined && _SelectedList != null && _SelectedList.length > 0) {

                        this._store.dispatch(new StoreAssets(_SelectedList));

                        if (!this.isRolloverBooking && this.bookingTypeId != '4') {
                            this.selectedStepIndex = 2;
                        }
                        else {
                            this.selectedStepIndex = 1;
                        }

                    }
                }
                console.log(_SelectedList);
            });
        }


    }


    loadEventWeekViewData(event = null) {
        this.events = [];
        if (this.booking && this.booking.bookingPackages) {

            this.booking.bookingPackages.forEach((bkpkacge, bkIndex) => {
                if (bkpkacge.bookingVenues && bkpkacge.bookingVenues.length > 0) {
                    bkpkacge.bookingVenues.forEach((bkVenue, bIndex) => {
                        if (bkVenue.bookingItem && bkVenue.bookingItem.length > 0) {
                            bkVenue.bookingItem.forEach((bkItem, IIndex) => {
                                if (bkItem.id.startsWith("R")) {
                                    if (bkItem.recurrentBookingItem) {
                                        bkItem.recurrentBookingItem.forEach((recurrent, rIndex) => {
                                            let reccurentStartDate = new Date(recurrent.startDate);
                                            let reccurentEndDate = new Date(recurrent.endDate);
                                            this.getEventDataWeekView(reccurentStartDate, reccurentEndDate, bkItem, bkpkacge);

                                        })
                                    }
                                } else {

                                    let adHocStartDate = new Date(bkItem.startDate);
                                    let adHocEndDate = new Date(bkItem.endDate);

                                    this.getEventDataWeekView(adHocStartDate, adHocEndDate, bkItem, bkpkacge);



                                }
                            })

                        }

                    });
                }
            });
        }

        return this.events;
    }


    getEventDataWeekView(startDate, endDate, bkItem, bkpkacge) {
        let ev = new EventView();
        ev.startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0);
        ev.startTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes());
        ev.endTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes());
        ev.eventViewType = 1;
        ev.description = bkpkacge.name;
        //  ev.bookingPackageName = bkpkacge.name;
        if (bkItem.asset.length > 0) {
            ev.treeNode = new TreeNode();
            ev.treeNode.id = bkItem.asset[0].id;
            ev.treeNode.label = bkItem.asset[0].name;
        }

        this.events.push(ev);
    }


    getAllocation(assetData, _team, startTime, endTime) {
        let allocations = new Allocation();
        allocations.attendees = _team.attendees;
        allocations.id = '-1';
        allocations.timeslot = new Timeslot();
        allocations.timeslot.id = '-1';


        //   allocations.timeslot.eventConfiguration = new EventConfiguration();
        // allocations.timeslot.eventConfiguration.id = '-1';

        allocations.timeslot.asset = new Asset();
        allocations.timeslot.asset.id = String(assetData.assetId);


        if (assetData.event && assetData.event.treeNode.data) {
            allocations.timeslot.asset.type = assetData.event.treeNode.data.type.toLowerCase();
            if (assetData.event.treeNode.data.type.toLowerCase() == 'resource') {

                allocations.timeslot.type = 'PrivateEventResourceTimeslot';

            } else if (assetData.event.treeNode.data.type.toLowerCase() == 'brandingArea') {

                allocations.timeslot.type = 'PrivateEventBrandingAreaTimeslot';

            } else {
                allocations.timeslot.type = 'PrivateEventFacilityTimeslot';
            }
        }



        allocations.timeslot.startTime = startTime;
        allocations.timeslot.endTime = endTime;

        return allocations;
    }

    get showPrice() {
        if (this.bookingType) {
            if (this.bookingType.displayPrice == false) {
                return false;
            }
            return true;
        }
        return true;
    }


    generateBookingTeams() {

    }

    //Booking pacakage generate for rollover Booking
    getGeneratedBookingPackage(bespokePackages, teams, teamgroupType) {
        let _bookingPackage = [];
        // let bpkge = new BookingPackage();

        if (teams && teams.length > 0) {
            teams.forEach((team, index) => {
                let bpkge = new BookingPackage();
                bpkge.id = team.id;
                //bpkge.name = team.activityGroup;
                var packageDetails = bespokePackages.find(bp => bp.id == team.packageId)
                if (packageDetails) {
                    bpkge.name = packageDetails.name + " - " + team.activityGroup;
                }

                bpkge.attendees = Number(team.participants);


                if (team.id.startsWith('-')) {
                    bpkge.quantity = 1;
                    bpkge.package = new Package()
                    bpkge.package.id = team.packageId;
                    var findSeason = teamgroupType.seasons.find(s => s.id == String(this.seasonId));
                    bpkge.startDate = RegularBookingAdapter.getStartDateEndDate(findSeason.from);
                    bpkge.endDate = Utility.convertToISO(Utility.appendTimePart(moment(findSeason.to).toDate(), moment(findSeason.to).toDate(), true));
                }
                _bookingPackage.push(bpkge);
            })
        }

        //  console.log(bookingPackage);
        console.log(_bookingPackage);
        return _bookingPackage;
    }
    isQuestionAnswered(ques) {
        var isAnsweredForTheQuestion = false;
        switch (ques.type) {


            case "textQuestionAnswer":
                if (ques.answer !== '' && ques.answer != undefined) {
                    isAnsweredForTheQuestion = true
                }
                break;

            case "richTextQuestionAnswer":
                if (ques.answer !== '' && ques.answer != undefined) {
                    isAnsweredForTheQuestion = true
                }
                break;
            case "booleanQuestionAnswer":
                if (ques.answer != undefined) {
                    isAnsweredForTheQuestion = true
                }

                break;

            case "singleChoiceQuestionAnswer":
                if (ques.bookingAnswerChoice != undefined) {
                    isAnsweredForTheQuestion = true
                }

                break;


            case "multipleChoiceQuestionAnswer":
                if (ques.bookingAnswerChoice != undefined && ques.bookingAnswerChoice.length > 0) {
                    if (ques.question.quantityRequired) {
                        isAnsweredForTheQuestion = true;
                        ques.bookingAnswerChoice.forEach(function (bac) {
                            if (bac.quantity == undefined || bac.quantity == 0 || bac.quantity == "") {
                                isAnsweredForTheQuestion = false;
                            }
                        });

                    } else {
                        isAnsweredForTheQuestion = true
                    }
                }
                break;
            default:
                isAnsweredForTheQuestion = true;

        }
        return isAnsweredForTheQuestion;
    }


    get getSalesCateGoriesIdList() {
        let _salesCatList = [];
        if (this.booking && this.booking.bookingPackages) {
            this.booking.bookingPackages.forEach((bk, bkIndex) => {
                if (bk.salesCategories) {
                    bk.salesCategories.forEach((s, sIndex) => {
                        if (s.id) {
                            let findDuplicate = _salesCatList.find(sl => sl == s.id);
                            if (!findDuplicate) {
                                _salesCatList.push(s.id);
                            }
                        }
                    });
                }
            });
        };
        return _salesCatList.join();
    }


    GetconcessionId() {
        var concessionId;
        if (this.clientPriceConcessionId) {
            concessionId = this.clientPriceConcessionId;
        }
        else {
            var _regularXmlData = (environment.RegularBookingSetting as RegularBookingSettings)
            var _BookingTypedefaultConcessionId = _regularXmlData.booking.bookingTypes.find(bk => bk.id == this.bookingType.id).defaultPriceConcessionId;
            concessionId = _BookingTypedefaultConcessionId;
        }

        return concessionId;
    }



    public getTabIndexByName(tabName: string): number {

        var _stepperIndex: number = 0;

        if (this.bookingType) {
            if (tabName === 'Date & Time') {
                _stepperIndex = (this.bookingType.id == 4 ? 1 : 2);
            }
            else if (tabName === 'Questionnaire') {
                _stepperIndex = (this.bookingType.id == 4 ? 2 : 3);
            }
            else if (tabName === 'Documents') {
                _stepperIndex = (this.bookingType.id == 4 ? 3 : 4);
            }
            else if (tabName === 'Submit Application') {
                _stepperIndex = (this.bookingType.id == 4 ? 4 : 5);
            }
        }

        return _stepperIndex;
    }




    cacheLocations(_listVenuids, _listSaleCategories, _searchstring, _selectedVenue, _assetTypeIds, _From, _To, callBack: (thisComponent: any) => void = null, locationPage: Page = null, pageEvent: PageEvent = null) {

        let searchParam = new VenueAssetsearchparams();
        searchParam.searchpharese = this.searchstring;
        let venuid = _listVenuids.toString();
        if (venuid.length == 0) { venuid = "" }
        searchParam.listVenuids = venuid;
        searchParam.assetIds = "";
        searchParam.SalesCategoryId = this.getSalesCateGoriesIdList
        let sFacility = _assetTypeIds.toString();
        sFacility.length == 0 ? "" : sFacility;
        searchParam.assetTypeIds = sFacility;
        searchParam.From = _From == 0 ? 0 : _From;
        searchParam.To = _To == 0 ? 0 : _To;
        searchParam.priceConcessionIds = this.GetconcessionId();
        searchParam.bookingTypeIds = this.bookingTypeId;

        let listVenuids = _listVenuids.toString();
        this._store.dispatch(new SpinnerLoading());

        this._assetService.getFacilityAndResource(searchParam).safeSubscribe(this, res => {

            var _listData: any;
            _listData = res;
            var _children = [];
            var _newSelectedList = [];

            if (_listData.length > 0) {
                for (var i = 0; i < _listData.length; i++) {
                    for (var x = 0; x < _listData[i].assetList.length; x++) {
                        _children.push({ 'children': _listData[i].assetList[x].assetList, 'data': _listData[i].assetList[x], 'id': _listData[i].assetList[x].id, 'label': _listData[i].assetList[x].name, 'parentId': _listData[i].id, 'selectable': true });
                    }
                    _newSelectedList.push({ 'children': _children, 'data': null, 'id': _listData[i].id, 'label': _listData[i].name, 'parentId': null, 'selectable': false });
                    _children = [];
                }

                var selectedList = _newSelectedList;

                cacheUtility.pushToCache(selectedList, this.booking.id);

                this._store.dispatch(new StoreAssets(selectedList));
                if (callBack != null) {
                    callBack(this);
                }
            }
            this._store.dispatch(new SpinnerLoaded());
        }, error => {
            this._store.dispatch(new SpinnerLoaded());
        },
        );
    }

    NavigateStepperAfterCacheAssets(thisComponent) {
        thisComponent.selectedStepIndex++;
    }
}




import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html'
})

export class SiteHeaderComponent {
    imagePath: string = environment.ApiUrl + environment.publishFolder;

    constructor() { }
}

import { DecimalPipe } from '@angular/common';

import { Venue } from '../venue/venue';
import { BookingFacility } from './booking-facility';
import { Amenity } from '../amenity/amenity';
import { ItemPriceGroup } from '../item/item-price-group';
import { Item, UpsellSummary } from '../item/item';
import { Configuration } from './configuration';
import { CalenderTimeslot } from './timeslots';
import { BespokePackage } from './bespoke-package';
import { Allocations } from 'src/modules/models/booking/allocations';

import { environment } from '../../../environments/environment';
import { SafeHtml } from '@angular/platform-browser';

export class FacilitySummary extends BookingFacility {
    index: number;
    active: boolean;
    classId: number;
    webDescription: string;
    description: SafeHtml;
    shortDescription: SafeHtml;
    name: string;
    operationalMinutes: number;

    directions: string;
    capacity: string;  //need to remove
    venue: Venue;
    amenities: Amenity[] = [];
    openingTime: string;
    closingTime: string;
    minPrice: ItemPriceGroup;
    maxPrice: ItemPriceGroup;
    allocations: Allocations[] = []
    availableUpsellItemCategories = [];
    isCombinedRoomFacility: boolean;
    mainFacilityIndexId: number;

    isUpsellAvailable: boolean;
    configurations: Configuration[] = [];
    items: Item[] = [];
    upsellSummaries: UpsellSummary[] = [];
    //package: BespokePackage;
    latitude: string;
    longitude: string;

    calenderTimeslots: CalenderTimeslot[] = [];
    availableTimeslots: CalenderTimeslot[] = [];
    timeslotLoaded: boolean = false;
    availabilityLabel: string = "";
  isDefultExpandAlternativeConfiguration: boolean = false;
    facilityTypeId: string;
    url: string = environment.ApiUrl + "dist/assets/images/no-image.png";
    constructor() {
        super();      
    }
    isPriceRange=()=> {        
        if (this.maxPrice == null || this.minPrice == null) {
            return false;
            }
       
        var min = (this.minPrice != undefined) ? this.minPrice!.priceIncludingTax : 0;
        var max = (this.maxPrice != undefined) ? this.maxPrice!.priceIncludingTax : 0;

        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    }
    getPrice=()=> {
        return (this.minPrice != undefined) ? this.minPrice : this.maxPrice;
    } 
    isItemPriceRange = (itm: Item, isCalculated = 0) =>
    {
        if (!itm)
            itm = this.item;

        let min: ItemPriceGroup, max: ItemPriceGroup;
        if (isCalculated == 0) {
            min = itm.itemMinPrice;
            max = itm.itemMaxPrice;
        } else {
            min = itm.calculatedMinPrice;
            max = itm.calculatedMaxPrice;
        }
        if (max == null || min == null) {
            return false;
        }

        var _min = (min != undefined) ? min!.priceIncludingTax : 0;
        var _max = (itm.itemMaxPrice != undefined) ? itm.itemMaxPrice!.priceIncludingTax : 0;

        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(_min, '1.2-2') != dp.transform(_max, '1.2-2');
    }
    getItemPrice=(itm: Item, isCalculated = 0)=> {
        if (!itm)
            itm = this.item;
        if (isCalculated == 0)
            return (itm.itemMinPrice != undefined) ? itm.itemMinPrice : itm.itemMaxPrice;
        else
            return (itm.calculatedMinPrice != undefined) ? itm.calculatedMinPrice : itm.calculatedMaxPrice;
    }  

    getimageurl=() =>{
        return environment.ApiUrl + "Private/Facility/Thumbnail/" + this.id + ".jpg";
    }

    getAvailabilityLabel=()=> {
        var available = false;
        
        if (this.calenderTimeslots == undefined || this.calenderTimeslots.filter(c => !c.isUnAvailableDueToOperationalHours && !c.isSelectedTimeSlot && !c.isBumpInBumpOut).length == 0)
            return "FULLY AVAILABLE";
        else {
            var cnf = this.getSelectedConfiguration();
            if (cnf && this.availableTimeslots != undefined) {
                var availableSlots = this.availableTimeslots.filter(s => (s.end - s.start) > (cnf.setupTime + cnf.teardownTime) / 60.0);
                if (availableSlots.length == 0) {
                    return "NOT AVAILABLE";
                } else if (availableSlots.length > 0) {
                    return "PARTIALLY AVAILABLE";
                }
            } else
            {
                return "NOT AVAILABLE";
            }
        }
        return "";
    }
    getSelectedConfiguration=() =>
    {        
        if (this.selectedConfiguration != undefined) {
            return this.selectedConfiguration;
        }
        return this.configuration;        
    }
}

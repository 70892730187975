﻿import { BaseResource } from '../base-resource';
import { Question } from 'src/modules/models/item/question';
export class BespokePackage extends BaseResource {
    name: string;
    businessAreaId: number;
    businessArea: string;
    packageClassId: number;
    priceTypeId: number
    priceType: string;
    taxId: number;
    eventId: number;
    salesCategories: string[];
    price: number;
    attendees: number;
    question: Question[];
    totalAnsweredQuestions: number;
}
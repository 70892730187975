﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { MmcLoaderComponent } from './mmc-loader/mmc-loader.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { Routes, RouterModule } from '@angular/router';
import { OptMaterialModule } from './opt-material/opt-material.module';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { WarningMessageComponent } from './warning-message/warning-message.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { QuestionnaireModalComponent } from './questionnaire-modal/questionnaire-modal.component';
import { SecondarySearchComponent } from '../shared/secondary-search/secondary-search.component';

//Store
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { statusReducer } from 'src/modules/store/loading/reducer';
import { LoadingActions } from 'src/modules/store/loading/actions';

import { cartReducer } from '../store/cart-reducer';
import { CartActionTypes } from '../store/actions';
import { ICart, AppState } from '../store/cart';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from '../store/effects/login-effects';

//Pipe
import { CurrencyPipe } from '@angular/common';
import { CurrencyFormat } from 'src/modules/currency-format';
import { DateFormat } from 'src/modules/date-format';


import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { environment} from '../../environments/environment'

export const reducers: ActionReducerMap<AppState> = { cart: cartReducer, loader: statusReducer };

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['cart'], rehydrate: true })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


//Date format
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;


//Routing
const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'facility'
    },  
    //{
    //    path: 'booking-cart',
    //    loadChildren: 'src/modules/booking-portal/booking.module#BookingModule'
    //}, 

    {
        path: 'client',
        loadChildren: 'src/modules/booking-portal/client/client.module#ClientModule'

    },  
  
];
export const DT_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        OptMaterialModule,    
        RouterModule.forChild(routes), 
       // StoreModule.forFeature('cart-header', reducers, { metaReducers }), 
        RecaptchaFormsModule,
        RecaptchaModule.forRoot()
    ],
    exports: [        
        CommonModule,
        HttpClientModule,
        SubHeaderComponent,
        MmcLoaderComponent,
        AlertMessageComponent,
        ToastMessageComponent,
        SuccessMessageComponent,
        WarningMessageComponent,
        DeleteConfirmationComponent,
        NotFoundComponent,
        ComingSoonComponent,
        CurrencyFormat,
        DateFormat,
        QuestionnaireModalComponent,
        SecondarySearchComponent    
    ],
    declarations: [
        SubHeaderComponent,
        MmcLoaderComponent,
        AlertMessageComponent,
        ToastMessageComponent,
        SuccessMessageComponent,
        WarningMessageComponent,
        DeleteConfirmationComponent,
        NotFoundComponent,
        ComingSoonComponent,
        CurrencyFormat,
        DateFormat,
        QuestionnaireModalComponent,
        SecondarySearchComponent
    ],
    entryComponents: [
        AlertMessageComponent,
        SuccessMessageComponent,
        WarningMessageComponent,
        DeleteConfirmationComponent,
        QuestionnaireModalComponent
    ],
    providers: [CurrencyPipe,
        CurrencyFormat,
        DateFormat,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6LdfjpAUAAAAAIeNoRQ_cqpibuV1PTNIwCyyszrY',
            } as RecaptchaSettings,
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DT_FORMATS },
    ]
})
export class OptCommonModules { }

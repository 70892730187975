import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { Store, UpdateFacility, Redirect, DeleteFacility, DoBooking, SetImmediateData } from 'src/modules/store/index';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { Observable } from "rxjs";

import { environment } from 'src/environments/environment';

import { BookingService } from 'src/modules/services/booking.service';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { SystemConfig } from 'src/modules/system-configuration'
import { MatSnackBar } from '@angular/material';
//import { PriceConcession } from 'src/modules/models/booking/price-group'
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { DecimalPipe } from '@angular/common'
import { UpsellSummary } from 'src/modules/models/item/item';
import { Router } from '@angular/router';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { ItemService } from 'src/modules/services/item.service';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import { Booking } from 'src/modules/models/booking/booking';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'opt-cart-panel',
    templateUrl: './cart-panel.component.html'
})

export class CartPanelComponent extends BookingCommonComponent implements OnInit {
    @Input() facilitySummaries: FacilitySummary[];
    @Input() defaultEventName: string;
    @Input() isValidPackage: boolean;
    @Input() selectedPackageDetail: BespokePackage;

    //@Input() isAllImmediateConfirmed: boolean;

    @Output() submitted: EventEmitter<number> = new EventEmitter<number>();
    @Output() makeEventNameValidation: EventEmitter<number> = new EventEmitter<number>();
    @Output() validatePackage: EventEmitter<number> = new EventEmitter<number>();
    @Output() ChangedStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    value: boolean = false;  
   
    isAgreed: boolean = false;
    isUpdated: boolean = false;
    isSaved: boolean = false;
   // url: string;
    bookingTermAndConditionMessage: string;
    bookingCartPageTopNavPannelMessage: any;
    
    systemDefaultPriceConsessionId: number;
    isSystemAllowedImmediateConfirmation: boolean;

    booking: Booking;
    isAllImmediateConfirmed: boolean;
    constructor(private store: Store<any>, private _bookingService: BookingService, public dialog: MatDialog, private _sysConfig: SystemConfig,
        private _itemService: ItemService,
        public snackBar: MatSnackBar, private router: Router, private sanitizer: DomSanitizer)
    {
        super(store, _itemService,snackBar);
        this.customerDefaultPriceConsessionId = 0;
        this.systemDefaultPriceConsessionId = 0;
        this.isSystemAllowedImmediateConfirmation = false;
        this.isLoggedIn = false;

    }

    ngOnInit() {
        super.ngOnInit();
       // this.url = environment.TermsandConditionLink;
        this.bookingTermAndConditionMessage = environment.BookingTermAndConditionMessage;
        this.bookingCartPageTopNavPannelMessage = this.sanitizer.bypassSecurityTrustHtml(environment.Messages.BookingCartPageTopNavPannel);
        //this.priceDisplayOption = environment.PriceDisplayOption;
        //this.cart_subscriber = this.cart$.subscribe(state => {
        //    if (state != undefined && state.packageFilter != undefined && state.packageFilter != null)
        //        this.selectedClass = state.packageFilter.PackageClass;
        //    this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        //    this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
        //    this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? (state.contact.client.priceConcessionId != "") ? state.contact.client.priceConcessionId : 0 : 0;
        //    this.addedFacilities = state.addedFacilites;
        //    this.priceChangedFacilities = state.priceChangedFacilities;
        //    this.hasZeroPrice = state.hasZeroPrice;
        //});
        this.cart$.safeSubscribe(this,state =>
        {          
            this.addedFacilities = state.addedFacilites;
            this.priceChangedFacilities = state.priceChangedFacilities;
            this.hasZeroPrice = state.hasZeroPrice;

            this.booking = state.booking;
            this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
        });
    }

    //ngOnDestroy() {
    //    if (this.cart_subscriber)
    //        this.cart_subscriber.unsubscribe();
    //}

    isValidFacilitySummaries(facilitySummaries: FacilitySummary[]) {
        let selectedPackageClass = environment.PackageClasses.find(a => a.Id == this.selectedClass);
        let isvalid = (facilitySummaries != undefined) && (facilitySummaries.length > 0);
        let isAttendeesValid = true;
        let isValidTime = true;
        let leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        let minimumDate = new Date();
        minimumDate.setDate(minimumDate.getDate() + leadDays);
        if (leadDays != 0)
            minimumDate.setHours(0, 0, 0, 0);

        if (isvalid) {
            facilitySummaries.forEach(function (fc) {
                if (isAttendeesValid) {
                    isAttendeesValid = (fc.attendees != undefined && !isNaN(fc.attendees) && fc.attendees != 0)
                }
                if (isValidTime) {
                    isValidTime = (fc.startTime != undefined && (new Date(fc.startTime) > minimumDate));
                }
                if ((!fc.item.calculatedMinPrice || !(fc.item.calculatedMinPrice.priceIncludingTax>-1)) &&
                    (!fc.item.calculatedMaxPrice || !(fc.item.calculatedMaxPrice.priceIncludingTax>-1))) {
                    isvalid = false;
                }
                fc.upsellSummaries.forEach(function (ups) {
                    if ((!ups.minPrice || !(ups.minPrice.priceIncludingTax>-1)) &&
                        (!ups.maxPrice || !(ups.maxPrice.priceIncludingTax>-1)))
                    {
                        isvalid = false;
                    }

                });

            });
        }
        return { isvalid: isvalid, isAttendeesValid: isAttendeesValid, isValidTime: isValidTime };
    }
    isValid(facilitySummaries: FacilitySummary[])
    {
        let av = this.isValidFacilitySummaries(facilitySummaries);
        return av.isvalid && av.isAttendeesValid && av.isValidTime;
    }
    public openEditPopup(val: FacilitySummary) {

        this.dialog.open(EditModalComponent, {
            data: { facility: val },
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
            // DialogPosition : top
        });
    }

    clickEvent2() {
        //this.value = !this.value;
        this.ChangedStatus.emit(true);
    }

    isHaveupsellSummaryPrice(facility) {
     let price = this.upsellSummaryPrice(facility);
      return (price > -1)
    }
    deleteItem(obj: FacilitySummary) {
        let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            data: {
                message: ' Are you sure you need to delete this venue?',
                yesText: 'YES',
                noText: 'NO'
            },
            panelClass: ['custom-dialog-container', 'w30modal'],
            height: 'auto',
            width: '30%'
        });
        const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
            if (data == true) {
                this.store.dispatch(new DeleteFacility(obj.index));
            }
        });
    }

    clickSubmitEnquiry() {
        // //  this.store.dispatch(new BookingSaveStarted());
        this.validatePackage.emit(1);
        if (this.isValidPackage) {
            let isPriceConcessionAvailable = FacilityAdaptor.isPriceConcessionAvailable(this.facilitySummaries, this.selectedClass);
            let selectedPackageClassDetail = environment.PackageClasses.find(a => a.Id == this.selectedClass);
            let _appSetting = environment.AppSetting as AppSetting;

           if (environment.IsAllowMultiplePriceConsession || 
              _appSetting.BookingConfig.PriceDisplayOption == 1 || 
              _appSetting.BookingConfig.PriceDisplayOption == 2 ||
                (selectedPackageClassDetail.PriceMinConsessionId == "0" && selectedPackageClassDetail.PriceMaxConsessionId == "0")
                || (_appSetting.BookingConfig.PriceDisplayOption == 3 &&
                    (isPriceConcessionAvailable.isHasValidMinPriceConcession || isPriceConcessionAvailable.isHasValidMaxPriceConcession))
            )
            {
                let ValidFacilitySummary = this.isValidFacilitySummaries(this.facilitySummaries);

                if (!ValidFacilitySummary.isValidTime) {
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 5000,
                        verticalPosition: 'top',
                        data: [environment.InsufficientSelectedTime],
                    });
                    return;
                }

                if (this.isAgreed && ValidFacilitySummary.isvalid) {
                    if (ValidFacilitySummary.isAttendeesValid) {
                      if (this.defaultEventName != null && this.defaultEventName != "" && this.defaultEventName != undefined) {
                        if (this.validateMandatoryQuestion() ){
                          this._sysConfig.loadAppConfiguration().then(() => {
                            this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
                            this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;
                            // this.store.dispatch(new DoBooking());
                            if (!this.booking || !this.booking.id) {
                              this.store.dispatch(new DoBooking());
                            } else {
                              //if booking id is there and immediate booking redirect to payment

                              if (this.isAllImmediateConfirmed) {
                                this.store.dispatch(new SetImmediateData(this.booking));
                              }
                            }
                          });
                        }
                      } else {
                          this.makeEventNameValidation.emit(1);
                      }
                    } else {
                        this.snackBar.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Please specify the number of attendees"],
                        });
                        //    this.store.dispatch(new BookingSaveCompleted());
                    }
                }
            } else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 5000,
                    verticalPosition: 'top',
                    data: ["selected Event type is not applicable for selected items, please change back the event type"],
                });
                //   this.store.dispatch(new BookingSaveCompleted());
            }
        }
    }

    validateMandatoryQuestion() {
      let allMandatoryQuestionsAnswerd = true;
        if (this.selectedPackageDetail && this.selectedPackageDetail.question && this.selectedPackageDetail.question.length > 0) {
          this.selectedPackageDetail.question.forEach(pq => {
            if (allMandatoryQuestionsAnswerd && pq.mandatory && (!FacilityAdaptor.isQuestionAnswerd(pq))) {
              allMandatoryQuestionsAnswerd = false;
            }
          });
        }
        if (!allMandatoryQuestionsAnswerd) {
          this.snackBar.openFromComponent(AlertMessageComponent, {
              duration: environment.messageDuration,
            verticalPosition: 'top',
            data: ["Please fill all mandatory event questions."],
          });
          return allMandatoryQuestionsAnswerd;
        }
      if (this.facilitySummaries && this.facilitySummaries.length > 0) {
        this.facilitySummaries.forEach(fc => {
          if (allMandatoryQuestionsAnswerd && fc.item && fc.item && fc.item.question && fc.item.question.length > 0) {
            fc.item.question.forEach(fq => {
              if (allMandatoryQuestionsAnswerd && fq.mandatory && (!FacilityAdaptor.isQuestionAnswerd(fq))) {
                allMandatoryQuestionsAnswerd = false;
              }
            });
            if (allMandatoryQuestionsAnswerd && fc.upsellSummaries && fc.upsellSummaries) {
              fc.upsellSummaries.forEach(us => {
                if (allMandatoryQuestionsAnswerd && us.question && us.question.length > 0) {
                  us.question.forEach(uq => {
                    if (allMandatoryQuestionsAnswerd && uq.mandatory && (!FacilityAdaptor.isQuestionAnswerd(uq))) {
                      allMandatoryQuestionsAnswerd = false;
                    }
                  });
                }
              });
            }
          }
        });
      }
      if (!allMandatoryQuestionsAnswerd) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
          duration: environment.messageDuration,
          verticalPosition: 'top',
          data: ["Please fill all mandatory facility questions."],
        });
        return allMandatoryQuestionsAnswerd;
      }
      return allMandatoryQuestionsAnswerd;
    }

    //isPriceRange(minPrice, maxPrice) {
    //    if (minPrice == undefined || maxPrice == undefined)
    //        return false;

    //    var min = minPrice != undefined ? minPrice!.priceIncludingTax : 0;
    //    var max = maxPrice != undefined ? maxPrice!.priceIncludingTax : 0;
    //    // return min != max;
    //    var dp: DecimalPipe = new DecimalPipe('en');
    //    return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    //}

    isPriceRange(facilitySummary: FacilitySummary, upsell: UpsellSummary = null, isUpsell: boolean) {

      if (!isUpsell && facilitySummary && facilitySummary.item && (facilitySummary.item.calculatedMinPrice == undefined || facilitySummary.item.calculatedMinPrice.priceIncludingTax == undefined || facilitySummary.item.calculatedMaxPrice == undefined || facilitySummary.item.calculatedMaxPrice.priceIncludingTax == undefined)) return false;
        if ((isUpsell && upsell.minPrice == null && upsell.maxPrice == null)) return false;

        //if (this.customerDefaultPriceConsessionId != 0 || this.isSystemAllowedImmediateConfirmation && facilitySummary.item.allowImmediateConfirmedBookings == 1) {
        //    return false;
        //} else {
            
        if (isUpsell)
            return BookingAdaptor.isRange(upsell.minPrice, upsell.maxPrice);
        else if (facilitySummary && facilitySummary.item)
            return BookingAdaptor.isRange(facilitySummary.item.calculatedMinPrice, facilitySummary.item.calculatedMaxPrice);
       // }
    }
  
    deleteUpsellItem(facilitySummary: FacilitySummary, upsellSummary: UpsellSummary) {
        let indexnum = facilitySummary.upsellSummaries.findIndex(x => x.id == upsellSummary.id);
        if (indexnum != undefined) {
            facilitySummary.upsellSummaries.splice(indexnum, 1);
            this.store.dispatch(new UpdateFacility(facilitySummary));
        }
    }

    signUp() {
        this.store.dispatch(new Redirect("SIGNUPFROMBOOKINGDETAIL"));
    }

    Login() {
        this.router.navigate(["/client/login"], { queryParams: { DirectLoginFromBooking: "1" } });
    }

    upsellSummaryPrice(facility) {
        var maxupsellPrice = facility.maxPrice;
        var minupsellPrice = facility.minPrice;
        var upsellPriceAvailble = (maxupsellPrice != undefined) ? maxupsellPrice : minupsellPrice
        if (upsellPriceAvailble != undefined) {
            if (maxupsellPrice.priceIncludingTax != null) {
                return maxupsellPrice.priceIncludingTax;
            } else if (minupsellPrice.priceIncludingTax != null) {
                return minupsellPrice.priceIncludingTax;
            }
        }
    }
    getPreviousFacilitySummary(facilitySummary: FacilitySummary)
    {
        var priceChangeFacility = this.priceChangedFacilities.find(x => x.index == facilitySummary.index);
        return priceChangeFacility
    }
    getPreviousUpsell(facilitySummary: FacilitySummary, upsell: UpsellSummary = null) {
        var priceChangeFacility = this.priceChangedFacilities.find(x => x.index == facilitySummary.index);
        if (priceChangeFacility && priceChangeFacility.upsellSummaries) {
            var prevUpsell = priceChangeFacility.upsellSummaries.filter(u => u.id == upsell.id);
            if (prevUpsell && prevUpsell.length>0)
            return prevUpsell[0]
        }
    }
    isPriceChangedAfterLoggedIn(facilitySummary: FacilitySummary, upsell: UpsellSummary=null)
    {       
        var priceChangeFacility = this.getPreviousFacilitySummary(facilitySummary);
        if (!upsell) {
            let min, max, prevmin, prevmax;
            if (facilitySummary.item.calculatedMinPrice != undefined)
                min = facilitySummary.item.calculatedMinPrice.priceIncludingTax;
            if (facilitySummary.item.calculatedMaxPrice != undefined)
                max = facilitySummary.item.calculatedMaxPrice.priceIncludingTax;

            if (priceChangeFacility) {
                if (priceChangeFacility.item.calculatedMinPrice != undefined)
                    prevmin = priceChangeFacility.item.calculatedMinPrice.priceIncludingTax;
                if (priceChangeFacility.item.calculatedMaxPrice != undefined)
                    prevmax = priceChangeFacility.item.calculatedMaxPrice.priceIncludingTax;
            }
            return this.isLoggedIn && this.priceChangedFacilities != undefined && this.priceChangedFacilities.length > 0 &&
                (min != prevmin || max!=prevmax) 

        } else
        {
            var prevUpsell = this.getPreviousUpsell(facilitySummary, upsell);
            if (prevUpsell)
            {               
                return this.isLoggedIn && this.priceChangedFacilities != undefined && this.priceChangedFacilities.length > 0 &&
                    (   (upsell.minPrice && upsell.minPrice.priceIncludingTax &&
                         Math.round(upsell.minPrice.priceIncludingTax * 1000) / 1000 != Math.round(prevUpsell.minPrice.priceIncludingTax*1000)/1000 ) ||
                    (upsell.maxPrice && upsell.maxPrice.priceIncludingTax &&
                    Math.round(upsell.maxPrice.priceIncludingTax*1000)/1000 != Math.round(prevUpsell.maxPrice.priceIncludingTax*1000)/1000)
                    );
            }
        }
       
    }
   
}

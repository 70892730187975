import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SiteLayoutComponent } from './site-layout.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [SiteLayoutComponent, SiteHeaderComponent, SiteFooterComponent],
    exports: [SiteLayoutComponent, SiteHeaderComponent, SiteFooterComponent]
})

export class SiteLayoutModule { }

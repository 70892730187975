﻿import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Init } from '../Init';
import { BookingModule } from './booking.module';

import 'hammerjs';

Init().then(() => {
    platformBrowserDynamic().bootstrapModule(BookingModule);
});


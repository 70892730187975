export class RoutePath {
    static readonly Facility: string = '/facility';
    static readonly BookingCart: string = '/booking-cart';
    static readonly BookingCartShowQuestion: string = '/booking-cart?showQuestion=1';
    static readonly Login: string = '/client';
    static readonly SignUp: string = '/client/setup?fromLogin=1';
    static readonly SignUpFromBookingDetail: string = '/client/setup?fromBookingDetail=1';
    static readonly MyBooking: string = '/bookings';
    static readonly MyProfile: string = '/my-profile';
}

﻿import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Utility } from './utility';

@Pipe({ name: 'dateFormat' })

//-- Format as DateTime  {{ booking.eventStartDate | dateFormat }}
//-- Format as Date  {{ booking.eventStartDate | dateFormat:'date' }}
//-- Format as Time  {{ booking.eventStartDate | dateFormat:'time' }}

export class DateFormat extends DatePipe implements PipeTransform {
    daysOfWeekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    transform(value: Date, format: string = 'date'): string {
        if (value != undefined && value != null) {
            if (format == 'date') {
                return super.transform(value, environment.DateFormat);
            } else if (format == 'time') {
                return super.transform(value, environment.TimeFormat)
            } else if (format == 'fulldate') {
                return super.transform(value, environment.DateFormat) + ' ' + this.daysOfWeekday[Utility.convertISOToDate(value).getDay()];
            }
            else {
                return super.transform(value, environment.DateTimeFormat)
            }

        }
        else return "";
    }
}
﻿import { BaseResource } from 'src/modules/models/base-resource';
//import { BookingItem } from 'src/modules/models/regular-portal/booking/booking-item';
import { BookingItemVm } from 'src/modules/models/regular-portal/booking/manage-teams/booking-item-vm';
import { Venue } from 'src/modules/models/regular-portal/booking/manage-teams/venue';


export class BookingPackage extends BaseResource {
    startDate: string;
    endDate: string;   
    name: string;  
    quantity: number;
    attendees: number;
    package: Package; 
   // bookingItems: BookingItem[] 

    //properties for manage teams 
    totalAmount: number;
    packageStart: Date;
    packageEnd: Date;
    recurrentPatternId: string;	
    selectedDates: any[]=[];
    unavailableDates: number;	
    specialDates: number;
    bookingItem: BookingItemVm[];
    bookingVenues: BookingVenues[];
    venue: Venue;
    unavailableDatesPackage: Date[];
    packageRecurrenceOption: string;
    selectedLocationsCount: number;
    salesCategories: any[] =[];
}
export class BookingVenues {
    venue: Venue;
    bookingItem: BookingItemVm[];
}
export class Package extends BaseResource {
}
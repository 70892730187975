﻿//import { enableProdMode } from '@angular/core'
import { environment } from '../environments/environment';
import { SystemConfig } from 'src/modules/system-configuration';
import { SystemService } from 'src/modules/services/system.service';
import { BaseService } from 'src/modules/services/base/base.service';
import { HttpClient, HttpHandler, HTTP_INTERCEPTORS, HttpRequest, HttpEvent, HttpInterceptor, HttpXhrBackend } from '@angular/common/http';
import { Injector } from "@angular/core";

//import { OptHttpProvider, APIRequestHandler } from 'src/modules/services/base/api-request-handler';
import { forkJoin } from 'rxjs';

export function Init() {
    if (window["ApiUrl"])
        environment.ApiUrl = window["ApiUrl"];
    if (window["WebAPIBaseUrl"])
        environment.WebApiUrl = window["WebAPIBaseUrl"];
    if (window["Token"])
        environment.Token = window["Token"];
    if (window["Theme"])
        environment.Theme = window["Theme"];
    if (window["RegularBookingPortalUrl"])
        environment.RegularBookingPortalUrl = window["RegularBookingPortalUrl"];
    if (window["CasualBookingPortalUrl"])
        environment.CasualBookingPortalUrl = window["CasualBookingPortalUrl"];
    if (window["PaymentPortalUrl"])
        environment.PaymentPortalUrl = window["PaymentPortalUrl"];
    if (window["PortalType"])
        environment.PortalType = window["PortalType"];
    let _cart;
    if (localStorage.getItem('cart'))
        _cart = JSON.parse(localStorage.getItem('cart'));
    if (!window["LoggedInUser"] && _cart != undefined && _cart != null && _cart["contact"] && _cart["contact"]["id"])
    {
       // ClearCart();
       
    }
    const injector = Injector.create({
        providers: [
            { provide: HttpClient, deps: [HttpHandler] },
            { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest }) },
        ],
    });

    const httpClient: HttpClient = injector.get(HttpClient);
    var sysConfig = new SystemConfig(new SystemService(new BaseService(httpClient)));

    return forkJoin([sysConfig.loadAppConfiguration(), sysConfig.loadRegularBookingConfiguration()]).toPromise();
}
export function ClearCart() {
    let _cart;
    if (localStorage.getItem('cart'))
        _cart = JSON.parse(localStorage.getItem('cart'));
    if (_cart != undefined && _cart != null && _cart["contact"] && _cart["contact"]["id"]) {
        _cart["contact"] = { firstName: "", profilePicture: "" };     
        localStorage.setItem('cart', JSON.stringify(_cart));
        localStorage.removeItem('rbStore');
        window["ShowSessionOut"] = "1";
    }
}
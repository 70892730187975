import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";

import { BookingService } from 'src/modules/services/booking.service';
import { Notes } from 'src/modules/models/booking/booking-notes';
import { Booking } from 'src/modules/models/booking/booking';

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component'

import { EntityType, NoteType } from 'src/modules/models/bookingNotes-enum';

@Component({
    selector: 'opt-cancel-booking',
    templateUrl: './cancel-booking.component.html',
    styleUrls: ['./cancel-booking.component.scss']
})

export class CancelBookingComponent {

    showLoader: boolean = false;
    requestString: string;
    bookingTypeId: number;

    constructor(public dialogRef: MatDialogRef<CancelBookingComponent>, public snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any, private _bookingService: BookingService) {

        this.bookingTypeId = this.data.bookingTypeId;
    }


    confirm() {
        this.showLoader = true;

        var booking = new Booking();
        booking = this.data;
        booking.id = this.data.id;
        booking.bookingTypeId = this.bookingTypeId;
        booking.bookingReference = this.data.bookingRef;
        booking.notes = [];

     
        if (this.requestString && this.requestString.length) {
            var bookingNotes = new Notes();
            bookingNotes.plainText = this.requestString;
            bookingNotes.entityTypeID = EntityType.Booking;
            bookingNotes.entityType = 'Booking';
            bookingNotes.noteTypeId = NoteType.CancelRequest;
            bookingNotes.noteType = 'Booking Cancel Request';

            booking.notes.push(bookingNotes);

        }

        this._bookingService.BookingCancelPatch(this.data.bookingId, booking).subscribe(result => {
            if (result) {
                if (result.body.data != null && result.body.data.cancelled) {
                    this.openSnackBarSuccess(["Your request has been forwarded and we will respond shortly."]);
                }
                else if (result.body.isError) {
                    this.openSnackBarError(['Booking cancel request has been failed, ' + result.body.errors[0].detail]);
                }

                setTimeout(() => {
                    this.showLoader = false;
                    this.dialogRef.close(true);
                }, 2000);

            } else {
                this.showLoader = false;
                this.openSnackBarError(['Booking cancel request has been failed. Please try again.']);
                this.dialogRef.close(false);
            }
        });
    }

    openSnackBarSuccess(message) {
        this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top'
        });
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top'
        });
    }
}
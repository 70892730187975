import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { OptFormModules } from 'src/modules/shared/form.modules';
import { OptCommonModules } from 'src/modules/shared/common.modules';

import { BookingRoutingModule } from './booking-routing.module';
import { OptHttpProvider } from '../services/base/api-request-handler';
import { OptMaterialModule } from '../shared/opt-material/opt-material.module';

//Layout
import { ThemeUtility } from '../layout/layout';

//components
import { MainComponent } from './main.component';
import { FacilitySearchComponent } from './facility/facility-search/facility-search.component';
import { DocModalComponent } from './booking-cart/doc-modal/doc-modal.component';
import { CalendarModalComponent } from './common/calendar-modal/calendar-modal.component';
import { EditModalComponent } from './booking-cart/edit-modal/edit-modal.component';
import { ModalContentComponent } from './common/modal-content/modal-content.component';
import { ModalWindowComponent } from './test-component/modal-window/modal-window.component';
import { FacilityDetailComponent } from './facility/facility-detail/facility-detail.component';
import { SearchComponent } from './facility/facility-search/search/search.component';
import { UpsellComponent } from './booking-cart/upsell/upsell.component';
import { BookingCartComponent } from './booking-cart/booking-cart.component';
import { MmcCartComponent } from './booking-cart/mmc-cart/mmc-cart.component';
import { MmcEnquiryComponent } from './mmc-enquiry/mmc-enquiry.component';
import { CartPanelComponent } from './booking-cart/cart-panel/cart-panel.component';
import { SelectConfigurationComponent } from './booking-cart/edit-modal/select-configuration/select-configuration.component';
import { BaseComponent } from 'src/modules/shared/base.component';

//Html plugins
import { NguCarouselModule } from '@ngu/carousel';
import { MmcCarouselComponent } from './facility/facility-detail/mmc-carousel/mmc-carousel.component';
import { SimpleComponent } from './facility/facility-detail/mmc-carousel/simple/simple.component';
import { NestedComponent } from './facility/facility-detail/mmc-carousel/nested/nested.component';
import { MmcSliderComponent } from './facility/facility-detail/mmc-slider/mmc-slider.component';

//Booking view
import { BookingSearchComponent } from './booking-view/booking-search/booking-search.component';
import { BookingDetailsComponent } from './booking-view/booking-details/booking-details.component';
import { BookingChangeRequestComponent } from './booking-view/booking-change-request/booking-change-request.component';
import { TimepickerConfig } from 'opt-timepicker';

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';

export function getTimepickerConfig(): TimepickerConfig {
    return Object.assign(new TimepickerConfig(), {
        hourStep: 1,
        minuteStep: environment.MinimumBookingDuration,
        showMeridian: true,
        meridians: ['AM', 'PM'],
        readonlyInput: false,
        showMinutes: true,
        showSeconds: false
    });
}

import { TimepickerModule } from 'opt-timepicker';
import { NgxGalleryModule } from 'ngx-gallery';

// Services
import { AssetService } from '../services/asset.service';
import { EventService } from '../services/event.service';
import { ItemService } from '../services/item.service';
import { PackageService } from '../services/package.service';
import { BookingService } from '../services/booking.service';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';
import { SystemService } from 'src/modules/services/system.service';
import { SystemConfig } from 'src/modules/system-configuration';
import { QASIntegrationService } from '../services/qas-integration.service';

// Google Map
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { GoogleMapsConfig } from 'src/modules/google-maps-config'

// File Drop 
import { FileDropModule } from 'ngx-file-drop';

//Store
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { statusReducer } from '../store/loading/reducer';
import { cartReducer } from '../store/cart-reducer';
import { AppState } from '../store/cart';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from '../store/effects/login-effects';
import { FacilityEffects } from '../store/effects/facility-effects';
import { environment } from 'src/environments/environment';
import { NgxCaptchaModule } from 'ngx-captcha';

const reducers: ActionReducerMap<AppState> = { cart: cartReducer, loader: statusReducer };

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['cart'], rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

import { CusModules } from './client/cus.modules';
import { CustomerProfileComponent } from './account/customer-profile/customer-profile.component';
import { ModalsComponent } from './modals/modals.component';
import { AddExtrasComponent } from './modals/add-extras/add-extras.component';
import { CancelBookingComponent } from './modals/cancel-booking/cancel-booking.component';
//import { BookingChangeRequestComponent } from './modals/booking-change-request/booking-change-request.component';
import { QuestionnaireComponent } from './modals/questionnaire/questionnaire.component';
import { AlternativeOptionsComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/alternative-options/alternative-options.component';
import { FacilityCommonComponent } from './facility/facility-common.component';
import { WpMessageComponent } from './modals/wp-message/wp-message.component';
import { WpChangeRequestComponent } from './modals/wp-change-request/wp-change-request.component';
import { WpRequestAccessComponent } from './modals/wp-request-access/wp-request-access.component';
import { WpAlreadyAccessComponent } from './modals/wp-already-access/wp-already-access.component';
import { WpSeasonsCarouselComponent } from 'src/modules/regular-booking/wp-seasons-carousel/wp-seasons-carousel.component';
import { WpAddTeamsComponent } from 'src/modules/regular-booking/wp-add-teams/wp-add-teams.component';
import { WPErrorMessage } from 'src/modules/regular-booking/messages/wp-error-message/wp-error-message';
import { WPBoookingRolloverComponent } from 'src/modules/regular-booking/wp-booking-rollover/wp-booking-rollover';
import { WpRolloverBookingModalComponent } from 'src/modules/regular-booking/popups/wp-rollover-booking-modal/wp-rollover-booking-modal.component';
import { ManageContactsComponent } from './modals/manage-contacts/manage-contacts.component';
import { WpConfirmationMessageComponent } from 'src/modules/regular-booking/messages/wp-confirmation-message/wp-confirmation-message.component';
import { WPInfoMessage } from 'src/modules/regular-booking/messages/wp-info-message/wp-info-message';
import { ExtraAllocationComponent } from './modals/extra-allocation/extra-allocation.component';
import { ExtraDeletionComponent } from './modals/extra-deletion/extra-deletion.component';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import { CdkColumnDef } from '@angular/cdk/table';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
    imports: [
        OptFormModules,
        BrowserModule,
        BrowserAnimationsModule,
        BookingRoutingModule,
        ThemeUtility.getThemeModule(),
        OptMaterialModule,
        TimepickerModule.forRoot(),
        NgxGalleryModule,
        OptCommonModules,
        CusModules,
        NguCarouselModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([LoginEffects, FacilityEffects]),
        FormsModule,
        AgmCoreModule.forRoot({ apiKey: environment.GoogleAPIKEY }),
        HttpClientModule,
        FileDropModule,
        NgxCaptchaModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatTableModule,
        MatRadioModule
    ],
    declarations: [
        MainComponent,
        FacilitySearchComponent,
        SearchComponent,
        FacilityDetailComponent,
        MmcCarouselComponent,
        SimpleComponent,
        NestedComponent,
        MmcSliderComponent,
        ModalWindowComponent,
        DocModalComponent,
        ModalContentComponent,
        BookingCartComponent,
        MmcCartComponent,
        CalendarModalComponent,
        EditModalComponent,
        MmcEnquiryComponent,
        CartPanelComponent,
        UpsellComponent,
        BookingSearchComponent,
        BookingDetailsComponent,
        BookingChangeRequestComponent,
        CustomerProfileComponent,
        ModalsComponent,
        AddExtrasComponent,
        CancelBookingComponent,
       // BookingChangeRequestComponent,
        QuestionnaireComponent,
        AlternativeOptionsComponent,
        SelectConfigurationComponent,
        FacilityCommonComponent,
        BaseComponent,
        WpMessageComponent,
        WpChangeRequestComponent,
        WpRequestAccessComponent,
        WpAlreadyAccessComponent,
        WPBoookingRolloverComponent,
        WpSeasonsCarouselComponent,
        WpAddTeamsComponent,
        WPErrorMessage,
        WPInfoMessage,
        WpRolloverBookingModalComponent,
      //  WpAlreadyAccessComponent,
        ManageContactsComponent,
        WpConfirmationMessageComponent,
        ExtraAllocationComponent,
        ExtraDeletionComponent
    ],
    entryComponents: [
        ModalWindowComponent,
        DocModalComponent,
        CalendarModalComponent,
        EditModalComponent,
        AddExtrasComponent,
        UpsellComponent,
        AlertMessageComponent,
        CancelBookingComponent,
      //  BookingChangeRequestComponent,
        QuestionnaireComponent,
        AlternativeOptionsComponent,
        WpMessageComponent,
        WpChangeRequestComponent,
        WpRequestAccessComponent,
        WpAlreadyAccessComponent,
        ManageContactsComponent,
       // WpAlreadyAccessComponent,
        WPBoookingRolloverComponent,
        WpRolloverBookingModalComponent,
        WpConfirmationMessageComponent,
        WPErrorMessage,
        WPInfoMessage,
        ExtraAllocationComponent,
        ExtraDeletionComponent
    ],
    providers: [OptHttpProvider,
        AssetService, EventService, ItemService, PackageService, SystemService, SystemConfig,
        BookingService, QASIntegrationService,
        ScrollTopService, CdkColumnDef,
        { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
        { provide: TimepickerConfig, useFactory: getTimepickerConfig }
    ],
    bootstrap: [MainComponent]
})

export class BookingModule { }


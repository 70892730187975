import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html'
})

export class SiteFooterComponent{
  imagePath: string = environment.ApiUrl + environment.publishFolder;
  constructor() { }
}

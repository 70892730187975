﻿import { Action } from '@ngrx/store';
import { Booking } from 'src/modules/models/regular-portal/booking/booking';
import { Tree, TreeNode } from 'src/modules/regular-booking/wp-calendar/calendar/tree-data';
import { BookingTypes } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
import {RecurrenceOption  } from 'src/modules/models/regular-portal/recurrence-option';
export enum RBActionTypes {
    STORE_BOOKING = 'STORE_BOOKING',
    STORE_SEASON = 'STORE_SEASON',
    STORE_ASSETS = 'STORE_ASSETS',
    STORE_BOOKINGTYPE = 'STORE_BOOKINGTYPE',
    STORE_RECURRENT_OPTION = 'STORE_RECURRENT_OPTION',
    STORE_CLEARSTOREOPTION = 'STORE_CLEARSTOREOPTION',
    SPINNERLOADED = 'SPINNERLOADED',
    SPINNERLOADING = 'SPINNERLOADING',
    STORE_LOCATIONPAGESIZE = 'STORE_LOCATIONPAGESIZE',

}

export class LoadBooking implements Action {
    readonly type = RBActionTypes.STORE_BOOKING
    constructor(public payload: Booking) { }
}

export class SaveSeason implements Action {
    readonly type = RBActionTypes.STORE_SEASON
    constructor(public payload: number) { }
}

export class StoreAssets implements Action {
    readonly type = RBActionTypes.STORE_ASSETS
    constructor(public payload: TreeNode[]) { }
}

export class StoreRecurrentOption implements Action {
    readonly type = RBActionTypes.STORE_RECURRENT_OPTION
    constructor(public payload: RecurrenceOption) { }
}

export class StoreBookingType implements Action {
    readonly type = RBActionTypes.STORE_BOOKINGTYPE
    constructor(public payload: BookingTypes) { }
}

export class ClearStore implements Action {
    readonly type = RBActionTypes.STORE_CLEARSTOREOPTION
    constructor(public payload: string = null) { }
}


export class SpinnerLoaded implements Action {
    readonly type = RBActionTypes.SPINNERLOADED
    constructor() { }
}

export class SpinnerLoading implements Action {
    readonly type = RBActionTypes.SPINNERLOADING
    constructor() { }
}
export class LocationPageSize implements Action {
    readonly type = RBActionTypes.STORE_LOCATIONPAGESIZE
    constructor(public payload: number) { }
}

export type RBActions = LoadBooking | SaveSeason | StoreAssets | StoreBookingType | StoreRecurrentOption | ClearStore | SpinnerLoaded | SpinnerLoading | LocationPageSize;   
﻿
import { BaseResource } from '../base-resource';

export class Note extends BaseResource {
    public plainText: string;
    public richText: string;
    public html: string;
    public entityTypeID: string;
    public entityType: string;
    public noteTypeId: string;
    public noteType: string;
    public entityConfigurationProfileId: string;
}
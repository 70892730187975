import { Component} from '@angular/core';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html'
})

export class SiteLayoutComponent{
  constructor() { }
}

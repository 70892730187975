﻿import { Action } from '@ngrx/store';


export enum LoadingActionTypes {
    FACILITY_SEARCH_LOADING = 'FACILITY_SEARCH_LOADING',
    FACILITY_SEARCH_LOADED = 'FACILITY_SEARCH_LOADED',
    BOOKING_SAVE_STARTED = 'BOOKING_SAVE_STARTED',
    BOOKING_SAVE_COMPLETED = 'BOOKING_SAVE_COMPLETED',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
}

export class FacilitySearchLoading implements Action {
    readonly type = LoadingActionTypes.FACILITY_SEARCH_LOADING    
}

export class FacilitySearchLoaded implements Action {
    readonly type = LoadingActionTypes.FACILITY_SEARCH_LOADED   
}
export class BookingSaveStarted implements Action {
    readonly type = LoadingActionTypes.BOOKING_SAVE_STARTED
}

export class BookingSaveCompleted implements Action {
    readonly type = LoadingActionTypes.BOOKING_SAVE_COMPLETED
}

export class LoadingStarted implements Action {
    readonly type = LoadingActionTypes.LOADING
}

export class LoadingCompleted implements Action {
    readonly type = LoadingActionTypes.LOADED
}

export type LoadingActions =
    FacilitySearchLoading | FacilitySearchLoaded|
    BookingSaveStarted | BookingSaveCompleted |
    LoadingStarted | LoadingCompleted
    ;   